import {ModalType} from "data/enums";
import {useViewController} from "data/services/locator/locator_hook.service";
import {isEqual} from "lodash";
import {observer} from "mobx-react";
import React from "react";
import {ModalErrorIcon} from "views/components/modals/modal_common.component";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {Bindings} from "data/constants/bindings";
import {ModalGeneral} from "./modal_general.component";

export const ModalLeagueRemove: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController, {});
	const {visibleModal, close, modalContent} = controller;
	const isVisible = isEqual(visibleModal, ModalType.LEAGUE_REMOVE);

	const leagueName = modalContent?.message || "";

	const onConfirm = () => {
		if (modalContent?.callback) {
			modalContent?.callback();
		}
		close();
	};

	if (!isVisible) {
		return null;
	}

	return (
		<ModalGeneral
			isVisible
			icon={<ModalErrorIcon />}
			heading="Are you sure you want to delete your league? This cannot be undone."
			confirmText="Confirm"
			showCancel={true}
			onClick={onConfirm}
			onClose={close}
			subHeading={`You will be deleting "${leagueName}"`}
		/>
	);
});
