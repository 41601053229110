import {LeagueH2H, ModalType, RequestState} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {useNavigate} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";
import type {ILeague, ILeagueAddParams} from "data/types/leagues";
import {AxiosError} from "axios";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {get} from "lodash";
import {H2H_SIZE_OPTIONS} from "data/constants";
import {isAllTrue} from "data/utils/helpers";

interface IInit {
	navigate?: ReturnType<typeof useNavigate>;
	regenerateParam: string | null;
}

export interface ICreateLeagueController extends ViewController<IInit> {
	i18n: ILocalizationStore;

	isInviteStepActive: boolean;
	isCreateStepActive: boolean;

	get user(): IUser | undefined;
	get createdLeague(): ILeague | undefined;
	get startRound(): number | null;
	get currentRound(): IRound | undefined;
	get H2HLeagueSize(): number | null;
	get ShowH2HSetting(): boolean;
	get H2HLeagueSizeArray(): number[];
	get scheduledRounds(): IRound[];
	get defaultLeagueType(): LeagueH2H;
	get isUpdateDisabled(): boolean;
	get isRegenerate(): boolean;
	get leagueName(): string;
	get isLoaded(): boolean;
	get requestState(): RequestState;
	goBack(): void;
	skipStep(): void;
	markHasChanges(): void;

	setStartRound(round: number): void;
	setH2HLeagueSize(size: number): void;
	setShowH2HSetting(show: string): void;
	setLeagueName(name: string): void;

	createLeague(payload: ILeagueAddParams): Promise<void>;
}

enum Steps {
	create,
	invite,
}

@injectable()
export class CreateLeagueController implements ICreateLeagueController {
	@observable private _activeStep = Steps.create;
	@observable private _createdLeague: ILeague | undefined;
	@observable _hasChanges: boolean = false;
	@observable _isLoading: boolean = false;

	private _navigate!: IInit["navigate"];
	@observable private _startRound: number | null = 1;
	@observable private _H2HLeagueSize: number | null = 2;
	private _showH2HSetting: boolean = false;
	private _H2HLeagueSizeArray: number[] = H2H_SIZE_OPTIONS;
	@observable private _leagueName: string = "";
	@observable private _leagueTypeSetting: LeagueH2H = LeagueH2H.Open;
	@observable private _isRegenerate: number = 0;
	@observable private _isLoaded: boolean = false;
	@observable private _request = RequestState.IDLE;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get requestState() {
		return this._request;
	}
	get user() {
		return this._userStore.user;
	}

	get defaultLeagueType() {
		return this._leagueTypeSetting;
	}

	get leagueName() {
		return this._leagueName;
	}

	get isRegenerate() {
		return Boolean(this._isRegenerate);
	}

	get isLoaded() {
		return this._isLoaded;
	}
	get isCreateStepActive() {
		return this._activeStep === Steps.create;
	}

	get isInviteStepActive() {
		return this._activeStep === Steps.invite;
	}

	get startRound() {
		return this._startRound;
	}

	get H2HLeagueSize() {
		return this._H2HLeagueSize;
	}

	get H2HLeagueSizeArray() {
		return this._H2HLeagueSizeArray;
	}

	get ShowH2HSetting() {
		return this._showH2HSetting;
	}

	get createdLeague() {
		return this._createdLeague;
	}

	get scheduledRounds() {
		return this._roundsStore.scheduleRounds;
	}

	get currentRound() {
		return this._roundsStore.currentRound;
	}

	get isUpdateDisabled() {
		return !this._hasChanges || this._leagueName === "";
	}

	setLeagueName(name: string) {
		if (name !== "") {
			this.markHasChanges();
		}
		this._leagueName = name;
	}

	setStartRound(round: number) {
		if (this._startRound !== round) {
			this.markHasChanges();
		}
		this._startRound = round;
	}

	setH2HLeagueSize(size: number) {
		this.markHasChanges();
		this._H2HLeagueSize = size;
	}

	setShowH2HSetting(show: string) {
		this.markHasChanges();
		this._showH2HSetting = show === LeagueH2H.H2H;
	}

	markHasChanges() {
		this._hasChanges = true;
	}

	prefillSettings(league: ILeague) {
		this._isRegenerate = league.id;
		this.setLeagueName(league.name);
		this.setStartRound(Number(league.startId));
		this._leagueTypeSetting = league.type;
		if (league.type.toLowerCase() === LeagueH2H.H2H) {
			this._showH2HSetting = true;
			this._H2HLeagueSize = Number(league.size);
		}
	}

	@action
	async createLeague(payload: ILeagueAddParams): Promise<void> {
		try {
			this._request = RequestState.PENDING;
			const formData = new FormData();
			let league: ILeague | undefined;
			if (this._isRegenerate) {
				formData.append("leagueId", JSON.stringify(this._isRegenerate));
			}
			if (payload.avatar) {
				formData.append("avatar", payload.avatar);
				formData.append("startId", JSON.stringify(payload.startId));
				formData.append("privacy", payload.privacy);
				formData.append("type", payload.type?.toLowerCase() as string);
				formData.append("size", JSON.stringify(payload.size));
				formData.append("finals", JSON.stringify(payload.finals));
				formData.append("h2hTiebreaker", payload.h2hTiebreaker as string);
				formData.append("name", payload.name);

				league = await this._leaguesStore.createLeague(
					formData,
					Boolean(this._isRegenerate)
				);
				if (league) {
					runInAction(() => {
						this._activeStep = Steps.invite;
						this._createdLeague = league;
						this._hasChanges = false;
					});
					return;
				}
			} else {
				if (this._isRegenerate) {
					payload.leagueId = this._isRegenerate;
				}
				league = await this._leaguesStore.createLeague(
					payload,
					Boolean(this._isRegenerate)
				);
				if (league) {
					runInAction(() => {
						this._request = RequestState.SUCCESS;
						this._activeStep = Steps.invite;
						this._createdLeague = league;
						this._hasChanges = false;
					});
					return;
				}
			}
			runInAction(() => {
				this._request = RequestState.IDLE;
			});
		} catch (err) {
			trackSentryErrors(err, {}, "create league");
			this._request = RequestState.ERROR;
			const error = err as AxiosError;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
		}
	}

	goBack() {
		this._navigate!("/leagues");
	}

	skipStep() {
		const league = this.createdLeague;

		if (league) {
			this._navigate!(`/leagues/${league.id}/about`);
		}
	}

	async init({navigate, regenerateParam}: IInit) {
		this._navigate = navigate;
		if (this._isLoaded) {
			return;
		}
		const isRegenEmpty = this._leaguesStore.regenerateList.length === 0;
		if (isRegenEmpty && regenerateParam) {
			await this._leaguesStore.fetchRegenerateList();
		}
		const league = this._leaguesStore.regenerateList.find(
			(league) => league.id === Number(regenerateParam)
		);
		if (league && regenerateParam) {
			this.prefillSettings(league);
		}
		void this._roundsStore.fetchRounds();

		const startRoundId = get(this._roundsStore.scheduleRounds, "[0].id", 1);

		if (isAllTrue([Boolean(startRoundId), !regenerateParam])) {
			runInAction(() => {
				this._startRound = startRoundId;
			});
		}
		this._isLoaded = true;
	}

	dispose(): void {
		return;
	}
}
