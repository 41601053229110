import type {ComponentType} from "react";
import type {AxiosError} from "axios";
import {get, reduce} from "lodash";
import type {IApiResponse} from "data/services/http";
import {captureMessage} from "@sentry/react";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const extractErrorMessage = (error: AxiosError<IApiResponse>) =>
	error.response?.data?.errors?.[0]?.message || error.message;

export const extractErrorStatus = (error: AxiosError<IApiResponse>) =>
	get(error.response, "status", 400);

interface ICurrencyFormat {
	input: number;
	showDecimal?: boolean;
}

export const currencyFormat = ({input, showDecimal}: ICurrencyFormat) => {
	/**
	 * checking first if its whole number or not then display decimal places
	 */
	const decimals = showDecimal && (input > 1000000 || input < -1000000) ? 3 : 0;
	if (input % 1 !== 0) {
		return Intl.NumberFormat("en-AU", {
			notation: "compact",
			style: "currency",
			maximumFractionDigits: 3,
			minimumFractionDigits: 2,
			currency: "AUD",
		}).format(input);
	} else {
		return Intl.NumberFormat("en-AU", {
			notation: "compact",
			minimumFractionDigits: decimals,
			style: "currency",
			currency: "AUD",
		}).format(Number(input));
	}
};

export const copyToClipboard = async (text: string) => {
	if (!navigator?.clipboard) {
		console.warn("Clipboard not supported");
		return false;
	}

	try {
		await navigator.clipboard.writeText(text);
		return true;
	} catch (error) {
		trackSentryErrors(error, {}, "copyToClipboard");
		return false;
	}
};
export const trackSentryErrors = (
	exception: unknown,
	context: Record<string, unknown>,
	contextName: string = "unknown error"
) => {
	const errorName = get(exception, "message", get(exception, "text", contextName));

	captureMessage(errorName, (scope) => {
		scope.setContext(
			contextName,
			reduce(
				context,
				(acc, _value, key) => {
					if (context[key] !== undefined) {
						acc[key] = JSON.stringify(context[key]);
					}

					return acc;
				},
				{} as Record<string, unknown>
			)
		);

		scope.setTag("handler", contextName);
		scope.setTransactionName(contextName);

		return scope;
	});
};

export * from "data/utils/countdown";
export * from "data/utils/socialShare";
