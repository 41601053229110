import {RequestState, ModalType, LeagueType} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {useNavigate} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";
import {ILeague} from "data/types/leagues";
import {AxiosError} from "axios";
import React from "react";
interface IInit {
	navigate?: ReturnType<typeof useNavigate>;
}

export interface IRegenerateLeaguesController extends ViewController<IInit> {
	isLoaded: boolean;
	isEmptyRegenLeagues: boolean;
	regenerateLeagues: ILeague[];
	cancelRecoverId: number;
	setCancelRecoverId: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	resetCancelRecoverId: () => void;
	goToRegenCreate: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	removeLeagueRegenerate: () => Promise<void>;
}

@injectable()
export class RegenerateLeaguesController implements IRegenerateLeaguesController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _cancelRecoverId = 0;
	@observable private _filterByType: LeagueType = LeagueType.All;

	private _navigate: IInit["navigate"];

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isLoaded() {
		return [RequestState.SUCCESS, RequestState.ERROR].includes(this._requestState);
	}

	get cancelRecoverId() {
		return this._cancelRecoverId;
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get isEmptyRegenLeagues() {
		return !this._leaguesStore.regenerateList.length;
	}

	get regenerateLeagues() {
		return this._leaguesStore.regenerateList;
	}

	@action
	removeLeagueRegenerate = async () => {
		await this._leaguesStore.removeRegenerateLeague(this._cancelRecoverId);
		this.resetCancelRecoverId();
	};

	resetCancelRecoverId = () => {
		this._cancelRecoverId = 0;
	};

	setCancelRecoverId = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._cancelRecoverId = Number(e.currentTarget.value);
	};

	goToRegenCreate = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._navigate!(`leagues/create?recover=${e.currentTarget.value}`);
	};

	async init(params: IInit) {
		this._navigate = params.navigate;
		if (this.isLoaded || this.isLoading) {
			return;
		}
		this._requestState = RequestState.PENDING;

		try {
			await this._leaguesStore.fetchRegenerateList();
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (err) {
			trackSentryErrors(err, {}, "my leagues init");
			const error = err as AxiosError;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
			this._requestState = RequestState.ERROR;
		}
	}

	dispose(): void {
		return;
	}
}
