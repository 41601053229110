import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {
	IAuthApiProvider,
	IBaseAFLIdParams,
	ILoginPayload,
	IRecoverUserPayload,
	IRegisterAFLIdPayload,
} from "data/providers/api/auth.api.provider";
import type {
	IUserApiProvider,
	IRegistrationPayload,
	IFantasyTeamUserUpdate,
	IUpdateUserPayload,
	IFantasyUser,
} from "data/providers/api/user.api.provider";
import type {
	IForgotPasswordPayload,
	IPasswordApiProvider,
	IResetPasswordPayload,
} from "data/providers/api/password.api.provider";
import type {AxiosError, AxiosResponse} from "axios";
import {
	REACT_APP_SSO_CLIENT_ID,
	REACT_APP_SSO_REDIRECT_URI,
	REACT_APP_SSO_URL,
} from "data/constants";
import {getLogoutParams, isErrorStatusError} from "data/utils/helpers";
import {ModalType, RequestState} from "data/enums";
import {IUpdateUserAccount} from "views/pages/my_account/my_account.controller";
import {IApiResponse} from "data/services/http";
import {trackSentryErrors} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";
import pkceChallenge from "pkce-challenge";
import {v4 as uuidv4} from "uuid";

export interface IAFLIDProfile {
	country: string;
	email: string;
	firstName: string;
	id: string;
	lastName: string;
	mobile: string;
	supportedClub: string;
}

export interface IAFLIDUser {
	identityToken: string;
	aflIdProfile: IAFLIDProfile;
}

export interface IUser {
	id: number;
	userId?: number;
	email: string;
	firstName: string;
	fantasyTeamName: string;
	isNotificationsEnabled: boolean;
	sponsorOptIn: boolean;
	createdAt: string;
	gender: string;
	birthday: string;
	avatarVersion: number;
	supportedSquadId: number;
	state: string;
	country: string;
}

export interface IUserStore {
	get user(): IUser | undefined;
	get userFantasyData(): IFantasyUser | undefined;
	get isAuthorized(): boolean;
	get wasLoggedOut(): boolean;
	get userLoadingStatus(): RequestState;
	get isNowRecovered(): boolean;

	forgotPassword(payload: IForgotPasswordPayload): Promise<AxiosResponse<void>>;
	resetPassword(payload: IResetPasswordPayload): Promise<AxiosResponse<void>>;
	register(payload: IRegistrationPayload): Promise<void>;
	update(payload: IUpdateUserAccount): Promise<void>;
	updateFormData(payload: FormData): Promise<void>;
	updateFantasyUser(payload: IFantasyTeamUserUpdate): Promise<void>;
	deactivate(): Promise<void>;
	login(payload: ILoginPayload): Promise<void>;
	recoverUser(payload: IRecoverUserPayload): Promise<void>;
	loginAFLID(payload: IBaseAFLIdParams): Promise<void>;
	registerAFLID(payload: IRegisterAFLIdPayload): Promise<void>;
	logout(): Promise<void>;
	requestUser(): Promise<void>;
	getLoginLink: () => void;
}

const {code_verifier, code_challenge} = pkceChallenge(128);

@injectable()
export class UserStore implements IUserStore {
	@observable _userLoadingStatus: RequestState = RequestState.IDLE;
	@observable private _user?: IUser = undefined;
	@observable private _userFantasyData?: IFantasyUser = undefined;
	@observable private _wasLoggedOut = false;
	@observable private _isNowRecovered = false;

	constructor(
		@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider,
		@inject(Bindings.PasswordApiProvider) private _passwordApi: IPasswordApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get userLoadingStatus() {
		return this._userLoadingStatus;
	}

	get isAuthorized() {
		return Boolean(this.user);
	}

	get wasLoggedOut() {
		return this._wasLoggedOut;
	}

	get isNowRecovered() {
		return this._isNowRecovered;
	}

	get user() {
		return this._user;
	}

	get userFantasyData() {
		return this._userFantasyData;
	}
	@action getLoginLink = () => {
		const nonce: string = uuidv4();
		const myState: string = uuidv4();
		localStorage.setItem("code_verifier", code_verifier);
		localStorage.setItem("code_challenge", code_challenge);
		localStorage.setItem("myState", myState);
		localStorage.setItem("nonce", nonce);
		const AUTHORISE_API = `${REACT_APP_SSO_URL}/authorize?`;
		const params = [
			"scope=openid email profile",
			"response_type=code",
			`redirect_uri=${REACT_APP_SSO_REDIRECT_URI}`,
			`state=${myState}`,
			`nonce=${nonce}`,
			`client_id=${REACT_APP_SSO_CLIENT_ID}`,
			`code_challenge=${code_challenge}`,
			"code_challenge_method=S256",
		];
		window.location.href = `${AUTHORISE_API}${params.join("&")}`;
	};

	@action
	async requestUser() {
		try {
			this._userLoadingStatus = RequestState.PENDING;
			const response = await this._userApi.user();
			const {user} = response.data.success;
			runInAction(() => {
				this._user = user;
			});
			const responseFantasyData = await this._userApi.userFantasyProfile();
			const fantasyData = responseFantasyData.data.success;
			runInAction(() => {
				this._userFantasyData = fantasyData;
				this._userLoadingStatus = RequestState.SUCCESS;
			});
		} catch (err) {
			const error = err as AxiosError<IApiResponse>;
			this.onError(error, true);
		}
	}

	@action
	async login(payload: ILoginPayload) {
		try {
			const response = await this._authApi.login(payload);
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			const {user} = response.data.success;

			runInAction(() => {
				this._user = user;
				this._wasLoggedOut = false;
			});
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async loginAFLID(payload: IBaseAFLIdParams) {
		/**
		 * not throwing error here
		 * because we want user to get redirected
		 * to register via 409 status if they are new.
		 */
		const response = await this._authApi.loginAFLID(payload);
		const {user, identityToken, sessionId} = response.data.success;

		window.mobileBridge?.postMessage(JSON.stringify({action: "login", sid: sessionId}));

		localStorage.setItem("idt", identityToken);

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async registerAFLID(payload: IRegisterAFLIdPayload) {
		try {
			const response = await this._authApi.registerAFLID(payload);
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			const {user, sessionId} = response.data.success;

			window.mobileBridge?.postMessage(
				JSON.stringify({action: "registration", sid: sessionId})
			);

			runInAction(() => {
				this._user = user;
				this._wasLoggedOut = false;
			});
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async register(payload: IRegistrationPayload) {
		try {
			const response = await this._userApi.register(payload);
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			const {user} = response.data.success;

			runInAction(() => {
				this._user = user;
				this._wasLoggedOut = false;
			});
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async updateFormData(payload: FormData) {
		try {
			const response = await this._userApi.updateFormData(payload);
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			const {user} = response.data.success;

			runInAction(() => {
				this._user = user;
			});
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async update(payload: IUpdateUserPayload) {
		try {
			const response = await this._userApi.update({
				...payload,
			});
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			const {user} = response.data.success;

			runInAction(() => {
				this._user = user;
			});
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async updateFantasyUser(payload: IFantasyTeamUserUpdate) {
		try {
			const response = await this._userApi.updateFantasyUser(payload);
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			// handle error
			runInAction(() => {
				this._wasLoggedOut = false;
			});
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async recoverUser(payload: IRecoverUserPayload) {
		try {
			const response = await this._authApi.recoverUser(payload);
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			const {user} = response.data.success;

			runInAction(() => {
				this._user = user;
				this._wasLoggedOut = false;
				this._isNowRecovered = true;
			});
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async logout() {
		const id_token = localStorage.getItem("idt");
		await this._authApi.logout();

		window.mobileBridge?.postMessage(JSON.stringify({action: "logout"}));

		if (id_token) {
			const logoutURL = `${REACT_APP_SSO_URL}/logout${getLogoutParams(id_token)}`;
			window.location.assign(logoutURL);
		} else {
			// Hard redirect so that the store is cleared
			window.location.replace("/");
		}

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}

	@action
	async deactivate() {
		await this._userApi.deactivate_account();

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}

	@action private onError = (error: AxiosError<IApiResponse>, skipModal?: boolean) => {
		const isSentryError = isErrorStatusError(error);
		if (!isSentryError) {
			trackSentryErrors(error, {}, "user store API error");
		}
		if (skipModal) {
			return;
		}
		this._modalsStore.showModal(ModalType.ERROR, {
			message: error.response?.data.errors[0].message || "",
		});
	};

	forgotPassword(payload: IForgotPasswordPayload) {
		return this._passwordApi.forgotPassword(payload);
	}

	resetPassword(payload: IResetPasswordPayload) {
		return this._passwordApi.resetPassword(payload);
	}
}
