import styled from "@emotion/styled";
import {
	css,
	FormControl,
	FormControlLabel,
	FormLabel,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	TextField,
} from "@mui/material";
import {grey} from "@mui/material/colors";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import React, {Fragment, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ButtonPrimary} from "views/components/buttons/buttons.component";
import {LeagueInviteForm} from "views/components/leagues/league_invite_form/league.invite_form.component";
import {Container, PageHeaderContainer} from "views/components/structure/structure.component";
import {ICreateLeagueController} from "views/pages/create_league/create_league.controller";
import {Bindings} from "data/constants/bindings";
import {LeagueH2H, LeagueType, RequestState} from "data/enums";
import {Exist} from "views/components/exist/exist.component";
import UpLoadAvatarSvg from "assets/img/icon-upload-avatar.svg";
import {PageTitle} from "views/components/composition/composition.component";
import {AFLWSelect} from "views/components/form/aflwSelect.component";
import {AFLWInput} from "views/components/form/aflwInput.component";
import {CustomToolTip} from "views/components/tooltip/tooltip.components";
import {
	H2HContainer,
	H2HFormControlContainer,
} from "views/components/leagues/league_h2h/league.h2h.component";
import IconLeaguesPlaceholder from "assets/img/icon-leagues-placeholder.svg";
import {Preloader} from "views/components/preloader";
import {isEmpty} from "lodash";

const mobileViewQuery = `max-width: 730px`;

export const CreateLeagueWrapper = styled.div`
	display: flex;
	background: var(--textWhite);
	border: 0.5px solid ${grey[300]};
	border-radius: 10px;

	@media (max-width: 870px) {
		border-radius: 0px;
	}
`;

export const FormLabelExtended = styled(FormLabel)`
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.15px;
	margin-bottom: 12px;
	margin-top: 20px;
	align-items: center;
	display: flex;
	@media (${mobileViewQuery}) {
		display: flex;
		justify-content: space-between;
	}
`;

export const Side = styled.div<{isActive: boolean}>`
	width: 100%;
	padding: 20px;
	opacity: 0.3;
	overflow: hidden;
	cursor: not-allowed;
	pointer-events: none;
	${(props) =>
		props.isActive &&
		css`
			opacity: 1;
			cursor: pointer;
			pointer-events: auto;
		`} @media (${mobileViewQuery}) {
		display: none;
		${(props) =>
			props.isActive &&
			css`
				display: block;
			`}
	}
	.MuiFormHelperText-root {
		word-break: break-all;
	}
`;

export const StepTitle = styled.h4`
	padding: 0;
	font-weight: 700;
	font-size: 16px;
	line-height: 130%;
	margin: 10px 0px;
	color: var(--textBlack);
`;

const Form = styled.form`
	margin: 20px 0 0 0;
	display: flex;
	flex-flow: column;
`;

export const TextFieldExtended = styled(TextField)`
	& label.Mui-focused {
		color: var(--textLightGrey);
	}
	margin-bottom: 20px;
`;

const PageWrapper = styled.div`
	@media (${mobileViewQuery}) {
		max-width: 100%;

		.select-wrapper {
			width: 100%;
		}
	}
`;

export const FormControlExtended = styled(FormControl)`
	max-width: 400px;
	width: 100%;
`;

export const checkedColor = "var(--aflwChargedCoral)";
export const borderColor = "var(--aflwBrightPurple)";
export const textColor = "var(--textLightGrey)";

const CreateLeagueTitle = styled(PageHeaderContainer)`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	h1 {
		margin: auto 0;
	}
	@media (${mobileViewQuery}) {
		max-width: 100%;
		flex-direction: column;
		gap: 10px;

		button {
			height: 43px;
			line-height: 43px;
		}
	}
`;

export const FileLabel = styled.div`
	height: 50px;
	width: fit-content;
	margin-bottom: 20px;
	display: flex;
	gap: 30px;
`;

export const LeagueAvatarImg = styled.img`
	height: 50px;
	width: 50px;
	border-radius: 4px;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	display: block;
`;

export const LeagueAvatarSvg = styled.img`
	position: absolute;
	left: 35px;
	top: 33px;
	z-index: 3;
`;

export const LeagueAvatarImgWrapper = styled.span`
	height: 50px;
	width: 50px;
	position: relative;
	z-index: 1;
	background: #f4f2f7;
	border-radius: 8px;
`;

export const LeagueAvatarWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const FileInput = styled.input`
	display: none;
`;

export const LeagueAvatarTitle = styled.span`
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
`;

export const ButtonAvatar = styled(ButtonPrimary)`
	background-color: var(--aflwPurple);
	height: 25px;
	min-width: 100px;
	line-height: 25px;
	cursor: pointer;
`;

const SubmitButton = styled(ButtonPrimary)`
	width: 200px;
	@media (${mobileViewQuery}) {
		width: 100%;
	}
`;

const SpanLabel = styled.span`
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 130%;
	color: var(--inputLabelActive);
`;

interface IFormElement {
	leagueName: HTMLInputElement;
	privacy: HTMLInputElement;
	startRound: HTMLInputElement;
	type: HTMLInputElement;
	H2HLeagueSize: HTMLInputElement;
	grandFinal: HTMLInputElement;
	h2hTiebreaker: HTMLInputElement;
	avatar: HTMLInputElement;
}

export const CreateLeaguePage: React.FC = observer(() => {
	const [searchParams] = useSearchParams();
	const regenerateParam = searchParams.get("regenerate");

	const controller = useViewController<ICreateLeagueController>(Bindings.CreateLeagueController, {
		navigate: useNavigate(),
		regenerateParam,
	});

	const [prevImg, setPreviewImg] = useState("");
	const [imgFile, setImgFile] = useState<File>();

	// const avatar_image = `${PUBLIC_URL}/media/squads/${_.get(
	// 	controller.user,
	// 	"supportedSquadId",
	// 	0
	// )}.png`;

	const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {files} = event.target as HTMLInputElement;
		const selectedFiles = files as FileList;
		const reader = new FileReader();
		const file = selectedFiles?.[0];

		reader.onload = (e) => {
			const imgPrev = reader.result;
			setPreviewImg(imgPrev as string);
		};

		if (file) {
			reader.readAsDataURL(file);
			setImgFile(file);
		}
	};

	const isStartRoundDisabled = false;

	const inputRef = useRef<HTMLInputElement>(null);

	const handleButtonClick = () => {
		if (inputRef.current) {
			inputRef.current.click();
		}
	};
	if (!controller.isLoaded) {
		return <Preloader />;
	}

	return (
		<Fragment>
			<PageWrapper className="pwrapper">
				<CreateLeagueTitle>
					<PageTitle>
						<Exist when={controller.createdLeague?.id}>
							Your league has been created
						</Exist>
						<Exist when={!controller.createdLeague?.id}>Create a League</Exist>
					</PageTitle>
					<Exist when={controller.createdLeague?.id}>
						<ButtonPrimary onClick={() => controller.skipStep()}>
							View League
						</ButtonPrimary>
					</Exist>
				</CreateLeagueTitle>
				<Container>
					<CreateLeagueWrapper>
						<Exist when={controller.isCreateStepActive}>
							<Side isActive={controller.isCreateStepActive}>
								<StepTitle>League Details</StepTitle>
								<Form
									onChange={() => {
										controller.markHasChanges();
									}}
									onSubmit={(evt: React.SyntheticEvent<HTMLFormElement>) => {
										evt.preventDefault();
										const {
											leagueName,
											privacy,
											type,
											startRound,
											H2HLeagueSize,
											grandFinal,
											h2hTiebreaker,
										} = evt.currentTarget as unknown as IFormElement;

										if (controller.startRound) {
											const privacyValue = privacy.value as Exclude<
												LeagueType,
												LeagueType.All
											>;

											void controller.createLeague({
												name: leagueName.value,
												privacy: privacyValue,
												startId: Number(startRound.value),
												type: type.value.toLowerCase(),
												size: Number(H2HLeagueSize?.value || 2),
												finals: grandFinal?.value === "true",
												h2hTiebreaker: h2hTiebreaker?.value || "percentage",
												avatar: imgFile,
											});
										}

										return false;
									}}>
									<FileLabel className={"avatar"}>
										<LeagueAvatarImgWrapper>
											<LeagueAvatarImg
												src={prevImg ? prevImg : IconLeaguesPlaceholder}
												alt="Upload"
											/>
											<LeagueAvatarSvg src={UpLoadAvatarSvg} />
										</LeagueAvatarImgWrapper>
										<LeagueAvatarWrapper>
											<LeagueAvatarTitle>League Avatar</LeagueAvatarTitle>
											<ButtonAvatar onClick={handleButtonClick}>
												Change
											</ButtonAvatar>
										</LeagueAvatarWrapper>
										<FileInput
											type="file"
											ref={inputRef}
											accept=".png, .jpg, .jpeg"
											id={"avatar"}
											name={"avatar"}
											onChange={onChangeFile}
										/>
									</FileLabel>
									<AFLWInput
										id="league-name"
										label="League name"
										name="leagueName"
										defaultValue={controller.leagueName}
										width="511px"
										required={true}
										placeholder="Enter a name for your league"
										errorText="Inappropriate names will not be accepted"
										onChange={(event) => {
											controller.setLeagueName(event.target.value);
										}}
									/>
									<Paper
										className="select-wrapper"
										sx={{
											display: "flex",
											alignItems: "center",
											width: "fit-content",
											boxShadow: "unset",
											marginTop: "40px",
										}}>
										<Exist when={!isEmpty(controller.scheduledRounds)}>
											<AFLWSelect
												labelId="start-round-label"
												label={"Starting Week"}
												width={"511px"}
												name="startRound"
												defaultValue={Number(controller.startRound)}
												required={true}
												onChange={(event) =>
													controller.setStartRound(
														event.target.value
															? Number(event.target.value)
															: 0
													)
												}
												value={controller.startRound}
												disabled={isStartRoundDisabled}>
												{controller.scheduledRounds.map((round, key) => (
													<MenuItem value={Number(round.id)} key={key}>
														Week {round.id}
													</MenuItem>
												))}
											</AFLWSelect>
										</Exist>
									</Paper>
									<FormControl>
										<FormLabelExtended id="privacy-label-form-id">
											<SpanLabel>Privacy Settings</SpanLabel>
											<CustomToolTip
												title={`If you would like your league to be available to anyone to join set this to Public. If you only want to share this with your friends set this to Private.`}
											/>
										</FormLabelExtended>
										<RadioGroup
											row
											aria-labelledby="privacy-label-form-id"
											defaultValue={LeagueType.Private}
											name="privacy">
											<FormControlLabel
												value={LeagueType.Private}
												control={
													<Radio
														sx={{
															color: "default",
															"&.Mui-checked": {
																color: checkedColor,
															},
														}}
													/>
												}
												label="Private"
											/>
											<FormControlLabel
												value={LeagueType.Public}
												control={
													<Radio
														sx={{
															color: "default",
															"&.Mui-checked": {
																color: checkedColor,
															},
														}}
													/>
												}
												label="Public"
											/>
										</RadioGroup>
									</FormControl>
									<FormControl>
										<FormLabelExtended id="type-label-form-id">
											<SpanLabel>League Type</SpanLabel>
											<CustomToolTip
												title={`
												Open Leagues are based on cumulative points scored. Head-to-Head (H2H) leagues emulate the real-life competition. Your team will be matched up against another team in your league each week. If your team scores more points, you win the matchup.
												`}
											/>
										</FormLabelExtended>
										<RadioGroup
											row
											aria-labelledby="type-label-form-id"
											defaultValue={controller.defaultLeagueType}
											name="type"
											onChange={(event) =>
												controller.setShowH2HSetting(event.target.value)
											}>
											<FormControlLabel
												value={LeagueH2H.Open}
												control={
													<Radio
														sx={{
															color: "default",
															"&.Mui-checked": {
																color: checkedColor,
															},
														}}
													/>
												}
												label="Open"
											/>
											<FormControlLabel
												value={LeagueH2H.H2H}
												control={
													<Radio
														sx={{
															color: "default",
															"&.Mui-checked": {
																color: checkedColor,
															},
														}}
													/>
												}
												label="Head to Head"
											/>
										</RadioGroup>
									</FormControl>

									<Exist when={controller.ShowH2HSetting}>
										<H2HContainer>
											<FormLabelExtended>
												Head To Head Settings
											</FormLabelExtended>

											<H2HFormControlContainer>
												<Paper
													className="select-wrapper"
													sx={{
														display: "flex",
														alignItems: "center",
														width: "100%",
														boxShadow: "unset",
														background: "unset",
													}}>
													<AFLWSelect
														labelId="h2h-league-size"
														label={"League Size"}
														width={"100%"}
														name="H2HLeagueSize"
														required={true}
														defaultValue={controller.H2HLeagueSize}
														onChange={(event) =>
															controller.setH2HLeagueSize(
																event.target.value
																	? Number(event.target.value)
																	: 2
															)
														}
														value={controller.H2HLeagueSize}
														disabled={isStartRoundDisabled}>
														{controller.H2HLeagueSizeArray.map(
															(size, key) => {
																return (
																	<MenuItem
																		key={key}
																		value={size}>
																		{size}
																	</MenuItem>
																);
															}
														)}
													</AFLWSelect>

													<CustomToolTip
														title={`The number of teams that can join your league will be limited to the size you select and League fixtures are generated based on this.`}
													/>
												</Paper>
											</H2HFormControlContainer>

											<FormControl>
												<FormLabelExtended id="grand-final-label-id">
													Grand Final
													<CustomToolTip
														title={`If you choose to have a Grand Final then the top 2 teams will play off for the premiership in a Grand Final match up during the last round of the season.`}
													/>
												</FormLabelExtended>
												<RadioGroup
													row
													aria-labelledby="grand-final-label-id"
													defaultValue={true}
													name="grandFinal">
													<FormControlLabel
														value={true}
														control={
															<Radio
																sx={{
																	color: "default",
																	"&.Mui-checked": {
																		color: checkedColor,
																	},
																}}
															/>
														}
														label="On"
													/>
													<FormControlLabel
														value={false}
														control={
															<Radio
																sx={{
																	color: "default",
																	"&.Mui-checked": {
																		color: checkedColor,
																	},
																}}
															/>
														}
														label="Off"
													/>
												</RadioGroup>
											</FormControl>

											<FormControl>
												<FormLabelExtended id="tiebreaker-mechanic-label-id">
													Tiebreaker Mechanic
												</FormLabelExtended>
												<RadioGroup
													row
													aria-labelledby="tiebreaker-mechanic-label-id"
													defaultValue={"percentage"}
													name="h2hTiebreaker">
													<FormControlLabel
														value={"percentage"}
														control={
															<Radio
																sx={{
																	color: "default",
																	"&.Mui-checked": {
																		color: checkedColor,
																	},
																}}
															/>
														}
														label="Percentage"
													/>
													<FormControlLabel
														value={"points_for"}
														control={
															<Radio
																sx={{
																	color: "default",
																	"&.Mui-checked": {
																		color: checkedColor,
																	},
																}}
															/>
														}
														label="Points For"
													/>
												</RadioGroup>
											</FormControl>
										</H2HContainer>
									</Exist>
									<div style={{marginTop: 20}}>
										<SubmitButton
											type="submit"
											disabled={
												controller.isUpdateDisabled ||
												controller.requestState !== RequestState.IDLE
											}>
											{
												{
													[RequestState.IDLE]: controller.isRegenerate
														? `Regenerate League`
														: `Create League`,
													[RequestState.PENDING]: <Preloader />,
													[RequestState.SUCCESS]: "Success",
													[RequestState.ERROR]: "Failed",
												}[controller.requestState]
											}
										</SubmitButton>
									</div>
								</Form>
							</Side>
						</Exist>

						<Exist when={controller.isInviteStepActive}>
							<Side isActive={controller.isInviteStepActive}>
								<LeagueInviteForm
									leagueId={controller.createdLeague?.id}
									regenerateParam={regenerateParam}
								/>
							</Side>
						</Exist>
					</CreateLeagueWrapper>
				</Container>
			</PageWrapper>
		</Fragment>
	);
});

export default CreateLeaguePage;
