import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore} from "data/stores/user/user.store";

import {type ISquadsStore} from "data/stores/squads/squads.store";
import {type IPlayersStore} from "data/stores/players/players.store";
// import {type ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {type ITeamStore} from "data/stores/team/team.store";

import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {getAvatar, isAllTrue} from "data/utils/helpers";
import {useNavigate, useSearchParams} from "react-router-dom";
import {RoundStatus} from "data/enums";
import {MEDIA_URL} from "data/constants";

interface IInit {
	isFromStatCenter: boolean;
	setSearchParams: ReturnType<typeof useSearchParams>[1];
	isOtherUser?: boolean;
	userId?: string;
	roundId?: string;
	navigate: ReturnType<typeof useNavigate>;
}

export interface ITeamController extends ViewController<IInit> {
	get teamName(): string;
	get teamView(): string;
	get isTeamLoaded(): boolean;
	get avatarUrl(): string;
	get isTradeMode(): boolean;
	get isTeamFull(): boolean;
	get isRoundLive(): boolean;
	get isShowTrades(): boolean;
	get hasUnsavedChanges(): boolean;
	get selectedRoundId(): number;
	get isTeamAutoSavePrompt(): boolean;
	get isAnyRoundComplete(): boolean;
	setTeamView: () => void;
	saveTeam: () => void;
}

const INITIAL_TEAM_VIEW = localStorage.getItem("team_view") || "field";

@injectable()
export class TeamController implements ITeamController {
	private _totalPlayers = 11;
	private _navigate!: IInit["navigate"];
	private _isOtherUser: boolean = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	@observable protected _hasFetchedJSONS: boolean = false;
	@observable protected _hasFetchedTeam: boolean = false;
	@observable private _teamView = INITIAL_TEAM_VIEW;

	get teamView() {
		return this._teamView;
	}

	get hasUnsavedChanges() {
		return (
			this._teamStore.hasUnsavedChanges &&
			!this._teamStore.team.isComplete &&
			!this._isOtherUser
		);
	}

	get isRoundLive() {
		return this._roundsStore.currentRound?.status === RoundStatus.Playing;
	}

	get isTeamAutoSavePrompt() {
		return this._teamStore.team.isComplete && !this.isTeamFull && !this._isOtherUser;
	}

	get isTeamLoaded() {
		return this._hasFetchedTeam;
	}

	get isAnyRoundComplete() {
		const currentRoundId = this._roundsStore.currentRound?.id || 1;
		return currentRoundId > 1;
	}

	get isTeamFull() {
		return this._teamStore.isTeamFullfilled();
	}
	get isShowTrades() {
		const selectedRoundId = this._roundsStore.selectedRound?.id;
		const actualRoundId = this._roundsStore.currentRound?.id;
		const isTeamStartRoundComplete = this._teamStore.isTeamStartRoundComplete;

		return selectedRoundId === actualRoundId && isTeamStartRoundComplete;
	}

	get selectedRoundId() {
		return this._roundsStore.selectedRound?.id || 1;
	}

	@action
	setTeamView = () => {
		const newView = this.teamView === "field" ? "list" : "field";
		runInAction(() => {
			this._teamView = newView;
			localStorage.setItem("team_view", newView);
		});
	};

	@action
	async checkFetchJSONs(): Promise<void> {
		if (this._hasFetchedJSONS) {
			return;
		}
		this._hasFetchedJSONS = true;
		await Promise.all([
			this._squadsStore.fetchSquads(),
			this._roundsStore.fetchRounds(),
			this._playersStore.safeFetchPlayers(),
			this._playersStore.fetchFavouritePlayers(),
		]);
	}

	@action
	async checkFetchTeam(): Promise<void> {
		if (this._hasFetchedTeam) {
			return;
		}
		await this._teamStore.requestTeam();
		this._hasFetchedTeam = true;
	}

	saveTeam = () => {
		this._teamStore.saveTeam();
	};

	get teamName() {
		return this._teamStore.teamName || "";
	}

	get avatarUrl(): string {
		const user = this._userStore.user;
		if (this._isOtherUser) {
			const teamData = this._teamStore.team;
			const userData = {
				userId: teamData.userId,
				id: teamData.id,
				avatarVersion: teamData.avatarVersion || 0,
				supportedSquadId: teamData.supportedSquadId || 0,
			};
			if (userData.avatarVersion > 0) {
				return getAvatar(userData);
			}
			return `${MEDIA_URL}squads/${userData.supportedSquadId}.png`;
		}
		if (user) {
			if (user.avatarVersion > 0) {
				return getAvatar(user);
			}
			return `${MEDIA_URL}squads/${user.supportedSquadId}.png`;
		}
		/**
		 * refactored to use same login in my account showing avatar
		 *
		 * ***/
		// const userId = this._teamStore.team.userId;
		// const userAvatarVersion = this._userStore.user?.avatarVersion || 0;
		// const teamAvatarVersion = this._teamStore.team.avatarVersion;
		// const avatarVersion = Number(teamAvatarVersion)
		// 	? Number(teamAvatarVersion)
		// 	: userAvatarVersion;

		// if (userId) {
		// 	return getAvatarImage({userId, avatarVersion, supportedSquadId: 0});
		// }

		return "";
	}

	get isTradeMode() {
		return this._teamStore.isTradeMode;
	}

	dispose(): void {
		return;
	}

	async init({isFromStatCenter, setSearchParams, isOtherUser, roundId, userId, navigate}: IInit) {
		this._navigate = navigate;
		await this.checkFetchJSONs();
		this._teamStore.setTeamLoading();
		if (!isFromStatCenter) {
			this._teamStore.setTradeMode(false);
		}

		const currentRound = this._roundsStore.currentRound?.id || 1;
		// LOGIC HERE IN CASE WE NEED TO REDIRECT IF CURRENT ROUND = 1 AND NOT COMPLETE
		// const isAnyRoundComplete = currentRound > 1;
		// if (isAllTrue([Boolean(isOtherUser), !isAnyRoundComplete])) {
		// 	navigate("/team");
		// 	return;
		// }

		// FETCH PAST TEAM OR USER TEAM
		if (isAllTrue([Boolean(isOtherUser), Boolean(roundId), Boolean(userId)])) {
			this._isOtherUser = Boolean(isOtherUser);
			this._teamStore.getUserPastTeam(Number(roundId), Number(userId));
			this._roundsStore.setSelectedRound(Number(roundId));
			this._hasFetchedTeam = true;
			return;
		}

		this._roundsStore.setSelectedRound(currentRound);
		this._teamBuilderStore.resetFilters();
		await this.checkFetchTeam();
		if (isAllTrue([!isFromStatCenter, currentRound !== 1])) {
			this._teamStore.fetchTrades();
		}

		if (isFromStatCenter) {
			setSearchParams("");
		}
	}
}
