import {action, computed, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IDictionary, IPlayer, type IPlayersStore} from "data/stores/players/players.store";
import {IActiveSwap, type ITeamStore} from "data/stores/team/team.store";
import {Bindings} from "data/constants/bindings";

import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import React, {Fragment, SyntheticEvent} from "react";
import {MatchStatus, PlayerPosition, PlayerStatus, RoundStatus, UTILITY} from "data/enums";
import {get, isNumber} from "lodash";
import {
	getIsFutureRound,
	getIsPlayerPlayingAndStarted,
	getPlayerDefaultPosition,
	getPlayerimage,
	isAllTrue,
	isAnyTrue,
	sumPlayerScoreValue,
} from "data/utils/helpers";
import {IPlayerExtended} from "./team_lineup.controller";
import {IS_DPP_SWAP_DISABLED, PLAYERS_IN_TEAM, PLAYER_PLACEHOLDER_IMG_URL} from "data/constants";
import {currencyFormat} from "data/utils";
import styled from "@emotion/styled";

export interface IParams {
	playerId: number;
	isBench: boolean;
	position: PlayerPosition | UTILITY.UTIL;
}

const GapWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const MarginDiv = styled.div`
	display: flex;
	align-items: center;
	margin: 0 2px;
`;

interface IStatusBoolean {
	isRoundIdMoreThanActualRound: boolean;
	isPlayerPlayingAndMatchStarted: boolean;
	isSeasonStarted: boolean;
	isMatchupPageAndPlayerHasntStarted: boolean;
	playerNotStartedNotMatchupAndActualIdSelected: boolean;
}

export interface IListPlayerController extends ViewController<IParams> {
	removeFromTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	setPosition: (e: SyntheticEvent<HTMLButtonElement>) => void;
	resetFilters: () => void;
	setCaptain: (e: SyntheticEvent<HTMLButtonElement>) => void;
	setViceCaptain: (e: SyntheticEvent<HTMLButtonElement>) => void;
	captainId: number | null;
	isActionsOpen: boolean;
	isPositionFixed: boolean;
	viceCaptainId: number | null;
	matchupRoundScore: number | string;
	lastRoundId: number;
	isCurrentRoundIndigenous: boolean;
	opponentInfoComponent: JSX.Element | undefined;
	isCaptainLocked: boolean;
	isViceCaptainLocked: boolean;
	swapPlayer: ({
		playerId,
		index,
		position,
		isBench,
		isTradeSwap,
	}: {
		playerId: number;
		index: number;
		position: PlayerPosition | UTILITY.UTIL;
		isBench: boolean;
		isTradeSwap?: boolean;
	}) => void;
	swapAction: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	tradeSwapAction: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	tradeOutAction: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	handleFavourites: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	activeSwap: IActiveSwap;
	doubleScoreId: number | null;
	setFavouritePlayer: (e: SyntheticEvent<HTMLButtonElement>) => void;
	favouritePlayers: number[];
	removeFavouritePlayer: (e: SyntheticEvent<HTMLButtonElement>) => void;
	toggleActionsOpen: () => void;
	activeSwapPosition: PlayerPosition | UTILITY.UTIL | "";
	initialSwapPlayer: IPlayer;
	initialSwapPlayerDefaultPos: PlayerPosition;
	canSwapPlayerPlayPosition: (position: PlayerPosition | UTILITY.UTIL) => boolean;
	isSwapDisabledEmpty: (position: PlayerPosition | UTILITY.UTIL) => boolean;
	isSwapButtonAvailable: (position: PlayerPosition | UTILITY.UTIL) => boolean;
	isPlayerLive: (squadId: number) => boolean;
	isPlayerLocked: (squadId: number) => boolean;
	playerRoundScore: (
		player: IPlayer,
		isMatchup?: boolean,
		skipDoubleScore?: boolean
	) => number | string;
	isEmergencyScoringPlayer: (playerId: number) => boolean;
	setTradeModeActive: () => void;
	setPositionFixed: (value: boolean) => void;
	addPlayerOutTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	addPlayerInTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	removePlayerOutTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	removePlayerInTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	isTeamStartRoundComplete: boolean;
	isPlayerIncomingTrade: boolean;
	isPlayerOutgoingTrade: boolean;
	isPlayerInitialSwap: boolean;
	isSelectedRoundComplete: boolean;
	playerToSwap: IPlayerExtended;
	playersById: IDictionary<IPlayerExtended>;
	player: IPlayerExtended | null;
	isSwapPlayerEmpty: boolean;
	isSwapDisabled: boolean;
	isCaptain: boolean;
	isViceCaptain: boolean;
	playerImgPath: string;
	playerImgPathSmall: string;
	isLocked: boolean;
	isLive: boolean;
	isBenchScoringPlayer: boolean;
	roundScore: string | number;
	isRoundScoreDNP: boolean;
	fadeForScoring: boolean;
	isPlayerAvailableForTrade: boolean;
	getStatValue: (prop: string) => string | number;
	isSwapButtonNowAvailable: boolean;
	isSwapDisabledEmptyPlayer: boolean;
	isFavourite: boolean;
	showSubButton: boolean;
	isTradeMode: boolean;
	isPlayerIncomingFromBE: boolean;
	isInitialSub: boolean;
	isTradeOutDisabled: boolean;
	isLeader: boolean;
	isLiveOrHasScore: boolean;
	playerStatus: PlayerStatus;
	isRoundOneComplete: boolean;
	isPlayerTradeSwap: boolean;
}

@injectable()
export class ListPlayerController implements IListPlayerController {
	@observable protected _playerId: number | undefined;
	@observable protected _isBench: boolean | undefined;
	@observable protected _position: PlayerPosition | UTILITY.UTIL | undefined;
	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isActionsOpen: boolean = false;

	get isActionsOpen() {
		return this._isActionsOpen;
	}

	get isPositionFixed() {
		return this._teamStore.isPositionFixed;
	}

	get utilityId() {
		return this._teamStore.utilityId;
	}

	get captainId() {
		return this._teamStore.captainId;
	}

	get viceCaptainId() {
		return this._teamStore.viceCaptainId;
	}

	get isInitialSub() {
		return this.activeSwap.initialSwap.playerId === this._playerId;
	}

	get isCaptainLocked() {
		if (this.captainId) {
			const captainSquad = get(this.playersById, this.captainId)?.squadId;
			return this.isPlayerLocked(captainSquad);
		}

		return false;
	}

	get isViceCaptainLocked() {
		if (this.viceCaptainId) {
			const viceCaptainSquad = get(this.playersById, this.viceCaptainId)?.squadId;
			return this.isPlayerLocked(viceCaptainSquad);
		}

		return false;
	}

	get showSubButton() {
		return this.activeSwap.initialSwap.playerId !== 0;
	}

	get isCurrentRoundIndigenous() {
		return this._roundsStore.isCurrentRoundIndigenous;
	}

	get isLeader() {
		return this.isCaptain || this.isViceCaptain;
	}

	get isLiveOrHasScore() {
		return this.isLive || this.isLocked;
	}

	get playerStatus() {
		return this.player?.status || PlayerStatus.Uncertain;
	}

	get activeSwap() {
		return this._teamStore.activeSwap;
	}

	get isSwapPlayerEmpty() {
		return this.activeSwap.initialSwap.playerId === 0;
	}

	get isPlayerInitialSwap() {
		if (this.playerToSwap) {
			return this.playerToSwap.id === this._playerId;
		}
		return false;
	}

	get isRoundOneComplete() {
		return this._roundsStore.isRoundOneComplete;
	}

	get isTradeMode() {
		return this._teamStore.isTradeMode;
	}
	@computed
	get isPlayerIncomingTrade() {
		const selectedRoundId = this._roundsStore.selectedRound?.id;
		const actualRoundId = this._roundsStore.currentRound?.id;
		if (selectedRoundId !== actualRoundId) {
			return false;
		}
		const playerId = this._playerId;
		if (!playerId) {
			return false;
		}
		const trades = this._teamStore.trades;
		return trades.reduce((isInvolved, trade) => {
			const newValue = trade.in === playerId;
			if (isInvolved) {
				return (isInvolved = true);
			}
			return (isInvolved = newValue);
		}, false);
	}

	@computed
	get isPlayerOutgoingTrade() {
		const selectedRoundId = this._roundsStore.selectedRound?.id;
		const actualRoundId = this._roundsStore.currentRound?.id;
		if (selectedRoundId !== actualRoundId) {
			return false;
		}
		const playerId = this._playerId;
		if (!playerId) {
			return false;
		}
		const trades = this._teamStore.trades;
		return trades.reduce((isInvolved, trade) => {
			const newValue = trade.out === playerId;
			if (isInvolved) {
				return (isInvolved = true);
			}
			return (isInvolved = newValue);
		}, false);
	}

	get isSubOutgoingTrade() {
		const trades = this._teamStore.trades;
		const subPlayer = this.activeSwap.initialSwap.playerId;
		return Boolean(
			trades.find((trade) => {
				return trade.out === subPlayer && !trade.in;
			})
		);
	}

	get isBenchScoringPlayer() {
		if (!this._playerId) {
			return false;
		}
		return this.isEmergencyScoringPlayer(this._playerId);
	}

	get isPlayerIncomingFromBE() {
		const selectedRoundId = this._roundsStore.selectedRound?.id;
		const actualRoundId = this._roundsStore.currentRound?.id;
		if (selectedRoundId !== actualRoundId) {
			return false;
		}
		const playerId = this._playerId;
		const trades = this._teamStore.trades;
		return trades.reduce((isInvolved, trade) => {
			const newValue = trade.in === playerId && Boolean(trade.fromBE);
			if (isInvolved) {
				return (isInvolved = true);
			}
			return (isInvolved = newValue);
		}, false);
	}

	get isSwapButtonNowAvailable() {
		if (!this._position) {
			return false;
		}
		return this.isSwapButtonAvailable(this._position);
	}

	get isSwapDisabledEmptyPlayer() {
		if (!this._position) {
			return false;
		}
		return this.isSwapDisabledEmpty(this._position);
	}

	get doubleScoreId() {
		// if currnt round not started and not round 1, use captain/vc from previous round
		const isCurrentRoundStarted =
			this._roundsStore.currentRound?.status !== RoundStatus.Scheduled;
		const isCurrentRoundOverOne = get(this._roundsStore.currentRound, "id", 1) > 1;
		const isViewingPastRound =
			get(this._roundsStore, "selectedRound.id", 1) <
			get(this._roundsStore, "currentRound.id", 1);
		if ((!isCurrentRoundStarted && isCurrentRoundOverOne) || isViewingPastRound) {
			return this._teamStore.pastRoundCaptain;
		}
		return this._teamStore.captainOrViceDouble;
	}

	get favouritePlayers() {
		return this._playersStore.favouritePlayers;
	}

	get isTradeOutDisabled() {
		return this._teamStore.isTradeOutDisabled;
	}

	get isTeamFirstRoundComplete() {
		return this._teamStore.isTeamStartRoundComplete;
	}

	get isTeamValidToBeSaved() {
		return (
			this._teamStore.isTeamFullfilled() &&
			this._teamStore.team.isComplete &&
			!this.isTeamFirstRoundComplete
		);
	}
	get activeSwapPosition() {
		return this.activeSwap.initialSwap.position === null
			? ("" as PlayerPosition)
			: this.activeSwap.initialSwap.position;
	}
	get player() {
		if (!this._playerId) {
			return null;
		}
		const player = get(this.playersById, this._playerId);

		return {
			...player,
			fixture: this.setFixture(player),
		};
	}
	setFixture(player: IPlayer) {
		const squadMatches = this._roundsStore.currentRound?.tournaments.filter((match) =>
			[match.homeSquadId, match.awaySquadId].includes(player.squadId)
		);
		if (squadMatches) {
			return squadMatches.map((match) => {
				const isPlayerHome = match.homeSquadId === player.squadId;
				const awaySquad = this._squadsStore.getSquadById(match.awaySquadId);
				const homeSquad = this._squadsStore.getSquadById(match.homeSquadId);
				const isComplete = match.status === MatchStatus.Complete;
				return {
					isPlayerHome,
					awaySquad,
					homeSquad,
					isComplete,
				};
			});
		}
		return [];
	}

	get playerToSwap() {
		const activeSwap = this._teamStore.activeSwap;
		return this.playersById[activeSwap.initialSwap.playerId];
	}

	get roundScore() {
		if (!this.player) {
			return "";
		}
		return this.playerRoundScore(this.player);
	}

	get matchupRoundScore() {
		if (!this.player) {
			return "";
		}
		return this.playerRoundScore(this.player, true);
	}

	get isFavourite() {
		if (!this._playerId) {
			return false;
		}
		return this.favouritePlayers.includes(this._playerId);
	}

	get isLocked() {
		const playerSquad = this.player?.squadId || 0;
		return this.isPlayerLocked(playerSquad);
	}

	get isLive() {
		const playerSquad = this.player?.squadId || 0;
		const isDNP = this.roundScore === "DNP";
		return this.isPlayerLive(playerSquad) && !isDNP;
	}
	get playersById(): IDictionary<IPlayerExtended> {
		return this._teamStore.extendedPlayersById;
	}

	get isCaptain() {
		const captain = this._teamStore.captainId;
		return captain === this._playerId;
	}

	get playerImgPath() {
		if (!this._playerId) {
			return PLAYER_PLACEHOLDER_IMG_URL;
		}
		return getPlayerimage(this._playerId, 500, this.player?.squadId || 0);
	}

	get playerImgPathSmall() {
		if (!this._playerId) {
			return PLAYER_PLACEHOLDER_IMG_URL;
		}
		return getPlayerimage(this._playerId, 100, this.player?.squadId || 0);
	}

	get initialSwapPlayer() {
		const players = this.playersById;
		const id = this.activeSwap.initialSwap.playerId;
		return get(players, id);
	}

	get initialSwapPlayerDefaultPos() {
		return getPlayerDefaultPosition(this.initialSwapPlayer);
	}

	get isTeamStartRoundComplete() {
		return this._teamStore.isTeamStartRoundComplete;
	}

	get isSelectedRoundComplete() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Complete;
	}

	getOpponentSquad(fixture: {
		isPlayerHome: boolean;
		awaySquad: ISquad | undefined;
		homeSquad: ISquad | undefined;
		isComplete: boolean;
	}) {
		return fixture.isPlayerHome ? fixture.awaySquad : fixture.homeSquad;
	}

	get opponentInfoComponent() {
		const isDoubleWeek = this.player?.fixture.length === 2;
		if (!this.player?.fixture.length) {
			return (
				<GapWrapper>
					<strong>BYE</strong>
				</GapWrapper>
			);
		}
		return (
			<GapWrapper>
				{this.player?.fixture.map((fixture, index) => {
					return (
						<Fragment key={index}>
							<strong>{this.getFixtureSign(fixture)}</strong>
							<span>{this.getOpponentSquad(fixture)?.abbreviation}</span>
							{isDoubleWeek && index === 0 && <MarginDiv>|</MarginDiv>}
						</Fragment>
					);
				})}
			</GapWrapper>
		);
	}

	getFixtureSign(fixture: {
		isPlayerHome: boolean;
		awaySquad: ISquad | undefined;
		homeSquad: ISquad | undefined;
		isComplete: boolean;
	}) {
		return fixture.isPlayerHome ? " V " : " @ ";
	}

	@computed
	get isSwapDisabled() {
		if (!this._playerId) {
			return true;
		}
		const player = this.playersById[this._playerId];
		if (!this.player) {
			return false;
		}
		return this.getIsSwapDisabled(player, this.initialSwapPlayer, this.activeSwap);
	}

	get isViceCaptain() {
		const vice = this._teamStore.viceCaptainId;
		return vice === this._playerId;
	}
	/* 
		Validation for whether player can be swapped for DPP in trade mode
		Checks if player is eligible for the position being swapped in the trade
	*/

	get isPlayerTradeSwap() {
		if (IS_DPP_SWAP_DISABLED) {
			return false;
		}
		const isSwapStarted = this.activeSwap.initialSwap.playerId;
		const isTradeOutNoIn = this._teamStore.trades.find((trade) => !trade.in && trade.out);
		if (this.isPlayerOutgoingTrade) {
			return true;
		}
		if (isSwapStarted && this.activeSwap.initialSwap.position) {
			return this.canSwapPlayerPlayPosition(this.activeSwap.initialSwap.position);
		}
		if (isTradeOutNoIn) {
			const playerPos = isTradeOutNoIn.out;
			const playerPositionTradedOut =
				this._teamStore.getPlayerPositionInTeam(playerPos).position;
			return this.canSwapPlayerPlayPosition(playerPositionTradedOut);
		}

		return false;
	}

	get isRoundScoreDNP() {
		return this.roundScore === "DNP";
	}

	get isRoundByeAndStarted() {
		const selectedRound = this._roundsStore.selectedRound;
		return (
			selectedRound?.status !== RoundStatus.Scheduled && this.getIsBye(selectedRound?.id || 1)
		);
	}

	get fadeForScoring() {
		const playerSquad = this.player?.squadId || 0;
		const isPlayerMatchStarted = this.isPlayerMatchStarted(playerSquad);
		const isDNPAndStartedPlaying = this.isRoundScoreDNP && isPlayerMatchStarted;
		const isUtilityAndStarted = isAllTrue([
			this._playerId === this.utilityId,
			isPlayerMatchStarted,
		]);
		const isBenchNotScoringAndStarted =
			Boolean(this._isBench) && !this.isBenchScoringPlayer && isPlayerMatchStarted;
		return isAnyTrue([
			isDNPAndStartedPlaying,
			isUtilityAndStarted,
			isBenchNotScoringAndStarted,
			this.isRoundByeAndStarted,
		]);
	}

	get isPlayerAvailableForTrade() {
		const isFirstRoundComplete = this._teamStore.isTeamStartRoundComplete;
		const isCurrentRoundNotComplete =
			this._roundsStore.currentRound?.status !== RoundStatus.Complete;
		const isPlayerNotLocked = !this.isLocked;
		return isFirstRoundComplete && isCurrentRoundNotComplete && isPlayerNotLocked;
	}

	canSwapPlayerPlayPosition = (position: PlayerPosition | UTILITY.UTIL) => {
		const accessor = this.activeSwap.initialSwap.playerId;
		const initialSwapPlayerPossiblePos = get(this.playersById[accessor], `position`);
		const initialSwapPlayerTeamPos = this._teamStore.getPlayerPositionInTeam(
			this.activeSwap.initialSwap.playerId
		)?.position;

		const canThisPlayerPlaySwapPosition =
			this.player?.position.includes(initialSwapPlayerTeamPos);
		if (canThisPlayerPlaySwapPosition) {
			return true;
		}
		if (this.activeSwap.initialSwap.playerId === 0) {
			return true;
		}
		if (position === UTILITY.UTIL) {
			return true;
		}
		const isSubPlayerExistAndValidPosition = isAllTrue([
			this.activeSwap.initialSwap.playerId !== 0,
			Boolean(this.initialSwapPlayer),
			this.initialSwapPlayerDefaultPos === position,
		]);
		const isSubPlayerAbleToPlayPosition = isAllTrue([
			Boolean(initialSwapPlayerPossiblePos),
			initialSwapPlayerPossiblePos.includes(position),
		]);
		return isAnyTrue([isSubPlayerExistAndValidPosition, isSubPlayerAbleToPlayPosition]);
	};

	isSwapDisabledEmpty = (position: PlayerPosition | UTILITY.UTIL) => {
		const player = this.playersById[this.activeSwap.initialSwap.playerId];
		if (player) {
			const positions = get(player, "position");

			const isPositionsIncludeSubPosition =
				position !== UTILITY.UTIL && positions && positions.includes(position);
			const isTeamEmptyPosition = this._teamStore.getValidEmptyPositionInTeam(positions);

			if (
				isAllTrue([
					Boolean(isTeamEmptyPosition),
					isPositionsIncludeSubPosition,
					Boolean(this.activeSwap.initialSwap.position),
				])
			) {
				return false;
			}
		}

		return isAnyTrue([
			isAllTrue([
				Boolean(this.activeSwap.initialSwap.position),
				![UTILITY.UTIL, position].includes(this.activeSwapPosition),
				position !== UTILITY.UTIL,
			]),
			isAllTrue([
				!this.canSwapPlayerPlayPosition(position),
				this.activeSwapPosition === UTILITY.UTIL,
			]),
			isAllTrue([
				Boolean(this.activeSwap.initialSwap.position),
				!this.canSwapPlayerPlayPosition(position),
			]),
		]);
	};

	isSwapButtonAvailable = (position: PlayerPosition | UTILITY.UTIL) => {
		return isAllTrue([
			this.canSwapPlayerPlayPosition(position),
			this.activeSwap.initialSwap.playerId !== 0,
		]);
	};
	// player is incoming, subPlayer is first actioned player in swap
	getIsSwapDisabled = (player: IPlayer, subPlayer: IPlayer, activeSwap: IActiveSwap) => {
		const isSwapStarted = activeSwap.initialSwap.playerId !== 0;
		if (!isSwapStarted) {
			return false;
		}
		const playerPosition = player.position;
		const subPosition = getPlayerDefaultPosition(subPlayer);
		const playerACurrentPosInTeam = this._teamStore.getPlayerPositionInTeam(player.id).position;
		const playerBCurrentPosInTeam = this._teamStore.getPlayerPositionInTeam(
			subPlayer.id
		).position;
		const canPlayerAPlayBPos = player.position.includes(playerBCurrentPosInTeam);
		const canPlayerBPlayAPos = subPlayer.position.includes(playerACurrentPosInTeam);
		const isUtilityAndPositionMatch =
			activeSwap.initialSwap.position === UTILITY.UTIL &&
			playerPosition.includes(subPosition);
		const isPlayerInTradeOut = this.isSubOutgoingTrade;
		if (isPlayerInTradeOut) {
			return !player.position.includes(playerBCurrentPosInTeam);
		}
		if (isUtilityAndPositionMatch) {
			return false;
		}
		const canPlayersPlayBothPositions = canPlayerAPlayBPos && canPlayerBPlayAPos;

		return !canPlayersPlayBothPositions;
	};

	isPlayerLocked = (squadId: number) => {
		const round = this._roundsStore.selectedRound;
		const playerMatch = round?.tournaments.find((match) =>
			[match.awaySquadId, match.homeSquadId].includes(squadId)
		);
		if (!playerMatch && round?.status !== RoundStatus.Scheduled) {
			return true;
		}
		if (playerMatch) {
			return playerMatch.status !== MatchStatus.Scheduled;
		}
		return false;
	};

	isEmergencyScoringPlayer = (playerId: number) => {
		const selectedRound = this._roundsStore.selectedRound?.id || 1;
		const teamSubs = get(this._teamStore.team.substitutions, selectedRound) || [];
		return teamSubs.includes(playerId);
	};

	isPlayerLive = (squadId: number) => {
		const round = this._roundsStore.selectedRound;
		const playerMatch = round?.tournaments.find((match) =>
			[match.awaySquadId, match.homeSquadId].includes(squadId)
		);
		if (playerMatch) {
			return playerMatch.status === MatchStatus.Playing;
		}
		return false;
	};

	isPlayerMatchStarted = (squadId: number) => {
		const round = this._roundsStore.selectedRound;
		const playerMatch = round?.tournaments.find((match) =>
			[match.awaySquadId, match.homeSquadId].includes(squadId)
		);
		if (playerMatch) {
			return playerMatch.status !== MatchStatus.Scheduled;
		}
		return false;
	};

	isPlayerDoubleScore = (playerId: number) => {
		return playerId === this.doubleScoreId;
	};

	swapAction = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const index = Number(e.currentTarget.dataset.index);
		if (this._position || (index === PLAYERS_IN_TEAM && this._position === UTILITY.UTIL)) {
			this.swapPlayer({
				playerId: this._playerId || 0,
				index,
				position: this._position,
				isBench: Boolean(this._isBench),
			});
		}
	};

	tradeSwapAction = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const index = Number(e.currentTarget.dataset.index);
		if (this._playerId && this._position) {
			this.swapPlayer({
				playerId: this._playerId,
				index,
				position: this._position,
				isBench: Boolean(this._isBench),
				isTradeSwap: true,
			});
		}
	};

	tradeOutAction = (e: SyntheticEvent<HTMLButtonElement>) => {
		// if DPP disabled, set sub mode off
		if (IS_DPP_SWAP_DISABLED) {
			this._teamStore.resetSubsitutionToDefault();
		}
		this.setTradeModeActive();
		this.addPlayerOutTrade(e);
	};

	handleFavourites = async (e: SyntheticEvent<HTMLButtonElement>) => {
		const method = this.isFavourite ? this.removeFavouritePlayer : this.setFavouritePlayer;
		await method(e);
	};

	getIsActualOrPreviousId = () => {
		const actualRound = this._roundsStore.currentRound;
		const actualRoundId = get(actualRound, "id", 1);
		const isActualStarted = this._roundsStore.currentRound?.status !== RoundStatus.Scheduled;

		return isActualStarted ? actualRoundId : actualRoundId - 1;
	};

	getStatusDefaultValue = ({
		isRoundIdMoreThanActualRound,
		isPlayerPlayingAndMatchStarted,
		isSeasonStarted,
		isMatchupPageAndPlayerHasntStarted,
		playerNotStartedNotMatchupAndActualIdSelected,
	}: IStatusBoolean) => {
		return isAnyTrue([
			isRoundIdMoreThanActualRound,
			isPlayerPlayingAndMatchStarted,
			!isSeasonStarted,
			isMatchupPageAndPlayerHasntStarted,
			playerNotStartedNotMatchupAndActualIdSelected,
		])
			? "-"
			: "DNP";
	};

	getIsBye(roundIdToUse: number) {
		const round = get(this._roundsStore.roundsById, roundIdToUse);
		if (!round) {
			return false;
		}
		const playerSquad = get(this.player, "squadId", 0);
		return !round.tournaments.filter((match) =>
			[match.homeSquadId, match.awaySquadId].includes(playerSquad)
		).length;
	}

	playerRoundScore = (player: IPlayer, isMatchup?: boolean, skipDoubleScore?: boolean) => {
		const selectedRoundId = get(this._roundsStore.selectedRound, "id", 1);
		/*
			if selected is current, show previous round until current starts
			if selected is not current, show selected round points
		*/

		const isSeasonStarted = this._roundsStore.isRoundOneStarted;

		const actualRound = this._roundsStore.currentRound;
		const actualRoundId = get(actualRound, "id", 1);
		const actualOrPrevious = this.getIsActualOrPreviousId();
		const roundIdToUse = isAnyTrue([selectedRoundId < actualRoundId, Boolean(isMatchup)])
			? selectedRoundId
			: actualOrPrevious;

		const hasPlayerMatchStarted = this._roundsStore.getIsSquadMatchForRoundStarted(
			player.squadId,
			roundIdToUse
		);
		const isRoundIdMoreThanActualRound = getIsFutureRound(roundIdToUse, actualRoundId);
		const isPlayerPlayingAndMatchStarted = getIsPlayerPlayingAndStarted(
			hasPlayerMatchStarted,
			player.status === PlayerStatus.Playing
		);
		const isMatchupPageAndPlayerHasntStarted = isAllTrue([
			Boolean(isMatchup),
			!hasPlayerMatchStarted,
		]);
		const playerNotStartedNotMatchupAndActualIdSelected = isAllTrue([
			!hasPlayerMatchStarted,
			!isMatchup,
			roundIdToUse === actualRoundId,
		]);
		const statusDefaultValue = this.getStatusDefaultValue({
			isRoundIdMoreThanActualRound,
			isPlayerPlayingAndMatchStarted,
			isSeasonStarted,
			isMatchupPageAndPlayerHasntStarted,
			playerNotStartedNotMatchupAndActualIdSelected,
		});
		const seasonStartedAndRoundIdIsPrevious = isAllTrue([
			roundIdToUse < actualRoundId,
			isSeasonStarted,
		]);
		const defaultValue = seasonStartedAndRoundIdIsPrevious ? "DNP" : statusDefaultValue;
		const value = get(player.stats?.scores, roundIdToUse);
		const isBye = this.getIsBye(roundIdToUse);
		if (isBye) {
			return "BYE";
		}
		if (!value || value === null) {
			return defaultValue;
		}

		const sumValue = sumPlayerScoreValue(value) as number;
		return isAllTrue([this.isPlayerDoubleScore(player.id), !isMatchup, !skipDoubleScore])
			? 2 * sumValue
			: sumValue;
	};

	@action
	toggleActionsOpen = () => {
		this._isActionsOpen = !this._isActionsOpen;
	};

	@action
	removeFromTeam = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.addRemoveTeam(e.currentTarget.value, "removePlayerFromTeam");
	};

	@action
	addToTeam = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.addRemoveTeam(e.currentTarget.value, "addPlayerToTeam");
	};

	setCaptain = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const {value} = e.currentTarget;
		this._teamStore.setCaptain(Number(value));
	};

	setPositionFixed = (value: boolean) => {
		this._teamStore.setPositionFixed(value);
	};

	setViceCaptain = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const {value} = e.currentTarget;
		this._teamStore.setViceCaptain(Number(value));
	};

	setPosition = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamBuilderStore.resetPosition();
		this._teamBuilderStore.setPosition(e);
		const indexToAdd = e.currentTarget.dataset.playerindex;
		const isBench = e.currentTarget.dataset.isbench === "true";
		if (indexToAdd !== undefined) {
			this._teamStore.setAddIndex(Number(indexToAdd), isBench);
		}
	};

	resetFilters = () => {
		this._teamBuilderStore.resetFilters();
	};

	removeFavouritePlayer = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		await this._playersStore.removeFavouritePlayer(playerId);
	};

	setFavourite = () => {
		this._teamBuilderStore.setFavourite();
	};

	setFavouritePlayer = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		await this._playersStore.setFavouritePlayer(playerId);
	};

	get lastRoundId() {
		return this._roundsStore.lastCompletedRoundId;
	}

	getStatValue = (prop: string) => {
		if (!this.player) {
			return "- -";
		}

		let value = get(this.player.stats, prop, "- -") as number | string;
		const selectedRoundId = get(this._roundsStore, "selectedRound.id", 1);
		if (value === null) {
			value = "- -";
		}
		if (prop === "cost") {
			return currencyFormat({input: this.player.cost, showDecimal: true});
		}
		if (["roundPoints", "lastRound", `scores[${selectedRoundId}]`].includes(prop)) {
			value = this.roundScore;
		}

		if (isAllTrue([isNumber(value), prop !== "roundPoints"])) {
			const isDouble = this.isPlayerDoubleScore(this.player.id);
			value = isAnyTrue([isAllTrue([isDouble, prop === "roundPoints"])])
				? 2 * Number(value)
				: value;
		}
		return value;
	};

	swapPlayer = ({
		playerId,
		index,
		position,
		isBench,
		isTradeSwap,
	}: {
		playerId: number;
		index: number;
		position: PlayerPosition | UTILITY.UTIL;
		isBench: boolean;
		isTradeSwap?: boolean;
	}) => {
		this._teamStore.swapPlayer({
			playerId,
			index,
			position,
			isBench,
			isTradeSwap: Boolean(isTradeSwap),
		});
	};

	setTradeModeActive = () => {
		this._teamStore.setTradeMode(true);
	};

	removePlayerOutTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		this._teamStore.removePlayerOutTrade(playerId);
	};

	removePlayerInTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		this._teamStore.removePlayerInTrade(playerId);
	};

	addPlayerInTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		this._teamStore.addPlayerInTrade(playerId);
	};

	addPlayerOutTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		this._teamStore.addPlayerOutTrade(playerId);
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		if (this._playerId === param.playerId) {
			return;
		}
		this._playerId = param.playerId;
		this._isBench = param.isBench;
		this._position = param.position;
	}
}
