import styled from "@emotion/styled";
import {ReactComponent as CloseSharpIcon} from "assets/img/icon-close-sharp.svg";
import {Modal, Stack, IconButton, Typography} from "@mui/material";
import {observer} from "mobx-react";
import React, {ReactNode} from "react";
import {ButtonPrimary, ButtonSecondary} from "views/components/buttons/buttons.component";
import {
	ModalBox,
	ModalContent,
	ModalIconCloseButtonPosition,
} from "views/components/modals/modal_common.component";
import {Exist} from "views/components/exist/exist.component";

export const StackWrapper = styled(Stack)`
	flex-flow: column;
	align-items: center;
	gap: 20px;
	max-width: 100%;
	margin: 0 auto;
	text-align: center;
	padding-top: 30px;
`;

export const Heading = styled(Typography)`
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	text-align: center;
	letter-spacing: 0.2px;
	color: var(--aflwModalHeader);
`;
export const SubHeading = styled(Typography)`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 130%;
	max-width: 494px;
`;

export const WhiteModalBox = styled(ModalBox)`
	background: var(--textWhite);
`;

export const ChildWRapper = styled.div`
	background: var(--aflwbkg-light-canvas);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 16px;
	gap: 20px;
	border-radius: 10px;
	strong {
		font-weight: 700;
	}
`;

export const IconWrap = styled.div`
	height: 50px;
	width: 50px;
	circle {
		fill: var(--aflwModalHeader);
	}
`;

export const ModalStack = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	@media (max-width: 860px) {
		flex-direction: column;
		justify-content: center;
		gap: 12px;
	}
`;

export const CancelButton = styled(ButtonSecondary)`
	border: 1px solid var(--aflwPurple);
	color: var(--aflwPurple);
	width: 295px;
	max-width: 45%;
	@media (max-width: 860px) {
		max-width: 98%;
		width: 295px;
	}
`;
export const ConfirmButton = styled(ButtonPrimary)`
	background-color: var(--aflwChargedCoral);
	width: 295px;
	max-width: 45%;
	@media (max-width: 860px) {
		max-width: 98%;
		width: 295px;
	}
`;

interface IProps {
	children?: ReactNode;
	icon: ReactNode;
	heading: string;
	subHeading: string | ReactNode;
	confirmText: string;
	isVisible: boolean;
	extraActionText?: string;
	showCancel: boolean;
	cancelText?: string;
	showOtherFunction?: boolean;
	otherFunctionText?: string;
	onClick: () => void;
	onClose: () => void;
	otherFunction?: () => void;
}

export const ModalGeneral: React.FC<IProps> = observer(
	({
		icon,
		heading,
		children,
		confirmText,
		subHeading,
		showCancel,
		isVisible,
		showOtherFunction,
		otherFunctionText,
		onClose,
		onClick,
		otherFunction,
		cancelText = "Cancel",
	}) => {
		if (!isVisible) {
			return null;
		}

		return (
			<Modal open={true} onClose={onClose}>
				<WhiteModalBox>
					<ModalContent>
						<ModalIconCloseButtonPosition>
							<IconButton data-desc="modal-close" onClick={onClose}>
								<CloseSharpIcon />
							</IconButton>
						</ModalIconCloseButtonPosition>
						<StackWrapper>
							<IconWrap>{icon}</IconWrap>
							<Heading>{heading}</Heading>
							<SubHeading>{subHeading}</SubHeading>
							<Exist when={children}>
								<ChildWRapper>{children}</ChildWRapper>
							</Exist>
							<ModalStack>
								<Exist when={Boolean(showCancel)}>
									<CancelButton data-desc="modal-cancel" onClick={onClose}>
										{cancelText}
									</CancelButton>
								</Exist>
								<Exist when={Boolean(showOtherFunction)}>
									<CancelButton data-desc="modal-cancel" onClick={otherFunction}>
										{otherFunctionText}
									</CancelButton>
								</Exist>
								<Exist when={Boolean(confirmText)}>
									<ConfirmButton data-desc="modal-confirm" onClick={onClick}>
										{confirmText}
									</ConfirmButton>
								</Exist>
							</ModalStack>
						</StackWrapper>
					</ModalContent>
				</WhiteModalBox>
			</Modal>
		);
	}
);
