import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {
	ILeague,
	ILeagueLeaveParams,
	IJoinToLeagueParams,
	ILeagueInviteParams,
	ILeagueDeleteUserParams,
	IGetMyLeaguesParams,
	IGetJoinLeaguesParams,
	IGetLeagueParams,
	IGetLeagueUsersParams,
	ILeagueUser,
	IRemoveLeagueParams,
	IInvitingLeague,
	IDeclineLeagueInviteParams,
	ILeagueAddParams,
	ILeagueUpdateParams,
	IGetMatchupsParams,
	ILeagueUpdateWithAvatarParams,
	IGetMatchupsResponse,
	IGetLeagueRankingParams,
	IGetLeagueRankingResponse,
	IRemoveRegenerateParams,
} from "data/types/leagues";
import {AxiosResponse} from "axios";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {IPagination} from "data/types/common";
import {toNumber} from "lodash";

export interface ILeaguesApiProvider {
	create: (
		variables: FormData | ILeagueAddParams
	) => Promise<AxiosResponse<IApiResponse<ILeague>>>;

	delete: (params: {leagueId: number}) => Promise<AxiosResponse<IApiResponse>>;

	getLeagueByCodeNoAuth: (variables: string) => Promise<AxiosResponse<IApiResponse<ILeague>>>;

	updateLeague: (variables: ILeagueUpdateParams) => Promise<AxiosResponse<IApiResponse<ILeague>>>;

	updateLeagueWithAvatar: (
		variables: ILeagueUpdateWithAvatarParams | FormData
	) => Promise<AxiosResponse<IApiResponse<ILeague>>>;

	leaveLeague: (
		variables: ILeagueLeaveParams
	) => Promise<AxiosResponse<IApiResponse<Record<string, never>>>>;

	joinLeague: (variables: IJoinToLeagueParams) => Promise<AxiosResponse<IApiResponse<ILeague>>>;

	declineInviteLeague: (
		variables: IDeclineLeagueInviteParams
	) => Promise<AxiosResponse<IApiResponse<{invites: IInvitingLeague[]}>>>;

	inviteLeague: (
		variables: ILeagueInviteParams
	) => Promise<AxiosResponse<IApiResponse<Record<string, never>>>>;

	removeUserFromLeague: (
		variables: ILeagueDeleteUserParams
	) => Promise<AxiosResponse<IApiResponse<Record<string, never>>>>;

	removeLeague: (variables: IRemoveLeagueParams) => Promise<AxiosResponse<IApiResponse>>;

	myLeagues: (
		variables: IGetMyLeaguesParams
	) => Promise<AxiosResponse<IApiResponse<{leagues: ILeague[]; nextPage: boolean}>>>;

	invitingLeagues: () => Promise<AxiosResponse<IApiResponse<{invites: IInvitingLeague[]}>>>;

	showForJoinLeague: (
		variables: IGetJoinLeaguesParams
	) => Promise<AxiosResponse<IApiResponse<{leagues: ILeague[]; nextPage: boolean}>>>;

	getLeague: (variables: IGetLeagueParams) => Promise<AxiosResponse<IApiResponse<ILeague>>>;

	getLeagueUsers: (
		variables: IGetLeagueUsersParams
	) => Promise<AxiosResponse<IApiResponse<IPagination<{users: ILeagueUser[]}>>>>;
	getLeagueMatchups: (
		params: IGetMatchupsParams
	) => Promise<AxiosResponse<IApiResponse<IGetMatchupsResponse>>>;
	getLeagueRankings: (
		params: IGetLeagueRankingParams
	) => Promise<AxiosResponse<IApiResponse<IGetLeagueRankingResponse>>>;
	getRegenerateList: () => Promise<AxiosResponse<IApiResponse<{leagues: ILeague[]}>>>;
	removeRegenerateLeague: (
		params: IRemoveRegenerateParams
	) => Promise<AxiosResponse<IApiResponse>>;
	regenerateLeague: (
		variables: FormData | ILeagueAddParams
	) => Promise<AxiosResponse<IApiResponse<ILeague>>>;
}

@injectable()
export class LeaguesApiProvider implements ILeaguesApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	create = (params: FormData | ILeagueAddParams) =>
		this._http.post<IApiResponse<ILeague>>("fantasy/league", params);

	delete = (params: {leagueId: number}) =>
		this._http.post<IApiResponse<Record<string, never>>>(`fantasy/league/delete`, params);

	getLeagueByCodeNoAuth = (code: string) =>
		this._http.get<IApiResponse<ILeague>>(`public/fantasy/league/${code}/details`);

	updateLeague = ({leagueId, ...params}: ILeagueUpdateParams) =>
		this._http.post<IApiResponse<ILeague>>(`fantasy/league/${leagueId}`, params);

	updateLeagueWithAvatar = (params: ILeagueUpdateWithAvatarParams | FormData) => {
		const id = window.location.pathname.split("/")[2];
		const leagueId = toNumber(id);
		return this._http.post<IApiResponse<ILeague>>(`fantasy/league/${leagueId}`, params);
	};
	leaveLeague = ({leagueId}: ILeagueLeaveParams) =>
		this._http.post<IApiResponse<Record<string, never>>>(`fantasy/league/${leagueId}/leave`);

	joinLeague = ({code}: IJoinToLeagueParams) =>
		this._http.post<IApiResponse<ILeague>>(`fantasy/league/${code}/join`);

	inviteLeague = ({leagueId, ...params}: ILeagueInviteParams) =>
		this._http.post<IApiResponse<Record<string, never>>>(
			`fantasy/league/${leagueId}/invite`,
			params
		);

	removeUserFromLeague = ({leagueId, userId}: ILeagueDeleteUserParams) =>
		this._http.post<IApiResponse<Record<string, never>>>(
			`fantasy/league/${leagueId}/user/${userId}`
		);

	declineInviteLeague = ({leagueId}: IDeclineLeagueInviteParams) =>
		this._http.post<IApiResponse<{invites: IInvitingLeague[]}>>(
			`fantasy/league/${leagueId}/decline-invite`
		);

	removeLeague = ({leagueId}: IRemoveLeagueParams) =>
		this._http.post<IApiResponse>(`fantasy/league/${leagueId}/delete`);

	myLeagues = ({...params}: IGetMyLeaguesParams) =>
		this._http.get<IApiResponse<{leagues: ILeague[]; nextPage: boolean}>>(
			"fantasy/leagues",
			params
		);

	invitingLeagues = () =>
		this._http.get<IApiResponse<{invites: IInvitingLeague[]}>>("fantasy/league/user-invites");

	showForJoinLeague = ({...params}: IGetJoinLeaguesParams) =>
		this._http.get<IApiResponse<{leagues: ILeague[]; nextPage: boolean}>>(
			"fantasy/league/show-for-join",
			params
		);

	getLeague = ({leagueId}: IGetLeagueParams) =>
		this._http.get<IApiResponse<ILeague>>(`fantasy/league/${leagueId}`);

	getLeagueUsers = ({leagueId, ...params}: IGetLeagueUsersParams) =>
		this._http.get<IApiResponse<IPagination<{users: ILeagueUser[]}>>>(
			`fantasy/league/${leagueId}/league-users`,
			params
		);
	getLeagueMatchups = (params: IGetMatchupsParams) =>
		this._http.post<IApiResponse<IGetMatchupsResponse>>(`fantasy/league/h2h-match-ups`, params);
	getLeagueRankings = (params: IGetLeagueRankingParams) =>
		this._http.get<IApiResponse<IGetLeagueRankingResponse>>(`fantasy/ranks/h2h-league`, params);
	getRegenerateList = () =>
		this._http.get<IApiResponse<{leagues: ILeague[]}>>("fantasy/league/recover/list");
	removeRegenerateLeague = (params: IRemoveRegenerateParams) =>
		this._http.post<IApiResponse>(`fantasy/league/recover/skip/${params.leagueId}`);
	regenerateLeague = (params: FormData | ILeagueAddParams) =>
		this._http.post<IApiResponse<ILeague>>(`fantasy/league/recover`, params);
}
