import {SHARE_PATH, SITE_URL} from "data/constants";
import {ShareType, SocialNetwork} from "data/enums";
import {eq, identity, set} from "lodash";
import {trackSentryErrors} from "data/utils/index";

interface IContestShare {
	socialNetwork: SocialNetwork;
	type: ShareType.League;
	message?: string;
	contestId?: number;
	leagueId?: number;
	leagueCode?: string;
	roundId?: number;
	code?: string;
}

interface ILeagueShare extends Omit<IContestShare, "type"> {
	type: ShareType.League | ShareType.General | ShareType.Team | ShareType.Trade;
	leagueCode?: string;
}

type IShare = ILeagueShare | IContestShare;

export abstract class ShareUtility {
	private static callbacks = {
		[SocialNetwork.Twitter]: (data: IShare) => ShareUtility.shareTW(data),
		[SocialNetwork.Facebook]: (data: IShare) => ShareUtility.shareFB(data),
		[SocialNetwork.Mobile]: (data: IShare) => ShareUtility.shareMobile(data),
		[SocialNetwork.Link]: (data: IShare) => ShareUtility.shareLink(data),
	} as const;

	public static share(data: IShare): boolean | void | Window | null | string {
		const callback = ShareUtility.callbacks[data.socialNetwork];

		if (!callback || typeof callback !== "function") {
			throw new Error("Invalid share type");
		}

		return callback(data);
	}

	private static shareTW({message = "", ...params}: IShare): WindowProxy | null {
		return window.open(
			"https://twitter.com/share?url=" +
				encodeURIComponent(ShareUtility.getLinkForShare(params)) +
				"&text=" +
				encodeURIComponent(message),
			"twitter-share-dialog",
			"width=626,height=436"
		);
	}

	private static shareLink({...params}: IShare): string | null {
		return ShareUtility.getLinkForShare(params);
	}

	private static shareFB(data: IShare): void {
		const isInvitesToLeague = data.type === ShareType.League;
		const link = ShareUtility.getLinkForShare(data);

		if (!window.FB) {
			const winWidth = 626;
			const winHeight = 436;
			const winTop = window.screen.height / 2 - winHeight / 2;
			const winLeft = window.screen.width / 2 - winWidth / 2;

			window.open(
				`https://www.facebook.com/sharer/sharer.php?display=popup&u=${link}`,
				"_blank",
				`top=${winTop},left=${winLeft},width=${winWidth}, height=${winHeight},toolbar=0,status=0,scrollbars=no,resizable=1`
			);
			return;
		}

		window.FB.ui(
			{
				method: isInvitesToLeague ? "send" : "share",
				display: "popup",
				[isInvitesToLeague ? "link" : "href"]: link,
			},
			identity
		);
	}

	private static shareMobile({message = "", ...params}: IShare) {
		try {
			void navigator.share({
				url: ShareUtility.getLinkForShare(params),
				text: message,
			});
		} catch (err) {
			trackSentryErrors(err, {}, "shareMobile");
			const error = err as Error;
			throw new Error(error.message);
		}
		return true;
	}

	private static getLinkForShare(data: IShare): string {
		const params = {
			time: this.getTimestamp(),
		};

		if (data.type === ShareType.League) {
			set(params, "leagueCode", data.leagueCode);
		}

		let sharePath = eq(data.type, ShareType.League) ? "league" : "team";

		if (data.type === ShareType.Trade) {
			sharePath = "trade";
		}
		if (data.type === ShareType.Team) {
			set(params, "round", data.roundId);
		}

		return `${SITE_URL}/${SHARE_PATH}/en/${sharePath}/${ShareUtility.getEncodedJson(params)}`;
	}

	private static getTimestamp(): string {
		return Date.now().toString().substring(9);
	}

	private static getEncodedJson(object: Partial<IShare> & {time: string}): string {
		return window.btoa(JSON.stringify(object));
	}
}
