import styled from "@emotion/styled";
export const H2HContainer = styled.div`
	width: 550px;
	max-width: 97%;
	background-color: var(--aflwLightPurPleBackground);
	border-radius: 10px;
	padding: 20px;
	margin-top: 10px;
	display: flex;
	flex-direction: column;
`;

export const H2HFormControlContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;
