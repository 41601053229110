import {LeagueStatus, LeagueType, ModalType, RequestState} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, action, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";
import type {ILeague, ILeagueUpdateParams} from "data/types/leagues";
import {AxiosError} from "axios";
import {IRound, type IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";

interface IInit {
	leagueId: number;
}

export interface ISettingsLeagueFormController extends ViewController<IInit> {
	get league(): ILeague | undefined;
	get startRound(): number | undefined;
	get isUpdateDisabled(): boolean;
	get isLoadedContests(): boolean;
	get getUpdateState(): RequestState;
	get getLeaveState(): RequestState;
	get roundList(): IRound[];
	get scheduledrounds(): IRound[];
	get user(): IUser | undefined;
	get isLeagueStarted(): boolean;
	get isPrivacyDisabled(): boolean;

	setStartRound(round: number): void;
	updateLeague(payload: ILeagueUpdateParams): Promise<void>;
	markHasChanges(): void;
}

@injectable()
export class SettingsLeagueFormController implements ISettingsLeagueFormController {
	private _league!: ILeague | undefined;
	private _startRound: number | undefined;

	@observable _hasChanges: boolean = false;
	@observable _requestStateUpdate: RequestState = RequestState.IDLE;
	@observable _requestStateLeave: RequestState = RequestState.IDLE;
	@observable _requestStateContests: RequestState = RequestState.IDLE;
	@observable protected _hasFetchedJSONS: boolean = false;

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get user() {
		return this._userStore.user;
	}

	get league() {
		return this._league;
	}

	get currentRoundId() {
		return this._roundsStore.currentRound?.id || 1;
	}

	get isUpdateDisabled() {
		return !this._hasChanges;
	}

	get getUpdateState(): RequestState {
		return this._requestStateUpdate;
	}

	get isLoadedContests(): boolean {
		return this._requestStateContests === RequestState.SUCCESS;
	}

	get getLeaveState(): RequestState {
		return this._requestStateLeave;
	}

	get startRound() {
		return this._startRound || 1;
	}

	get roundList(): IRound[] {
		return this._roundsStore.list;
	}

	get scheduledrounds(): IRound[] {
		return this._roundsStore.scheduleRounds;
	}

	get isLeagueStarted() {
		return (
			this.league?.status !== LeagueStatus.Scheduled && this.startRound <= this.currentRoundId
		);
	}

	get isPrivacyDisabled() {
		const leagueType = this.league?.privacy;

		if (leagueType === LeagueType.Private) {
			return this.isLeagueStarted;
		}
		return (this.league?.numTeams || 1) > 1;
	}

	@action
	async updateLeague(payload: ILeagueUpdateParams): Promise<void> {
		this._requestStateUpdate = RequestState.PENDING;
		try {
			const formData = new FormData();

			if (payload.avatar) {
				formData.append("avatar", payload.avatar);
				formData.append("startId", JSON.stringify(payload.startId));
				formData.append("privacy", String(payload.privacy));
				formData.append("name", String(payload.name));
				// formData.append("leagueId", String(payload.leagueId));
				// await this._leaguesStore.updateLeagueWithAvatar({leagueId: payload.leagueId, formData: formData});
				await this._leaguesStore.updateLeagueWithAvatar(formData);
			} else {
				await this._leaguesStore.updateLeague(payload);
			}

			runInAction(() => {
				this._requestStateUpdate = RequestState.SUCCESS;
				this._hasChanges = false;
			});
		} catch (err) {
			trackSentryErrors(err, {}, "league update settings");
			const error = err as AxiosError;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
		}
	}

	setStartRound(round: number) {
		this.markHasChanges();
		this._startRound = round;
	}

	markHasChanges() {
		this._hasChanges = true;
		this._requestStateUpdate = RequestState.IDLE;
	}

	init(params: IInit) {
		this._league = this._leaguesStore.getLeagueById(params.leagueId);
		this._startRound = this._league?.startId;

		runInAction(() => {
			this._requestStateContests = RequestState.SUCCESS;
		});
	}

	dispose(): void {
		return;
	}
}
