import placeholderPlayer from "assets/img/placeholderPlayer.png";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const PLAYER_IMAGES_URL = process.env.REACT_APP_PLAYER_IMAGES_URL || "";
export const SITE_URL = window.location.origin || "";
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL || "";
export const REACT_APP_SSO_URL = process.env.REACT_APP_SSO_URL || "";
export const REACT_APP_SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID || "";
export const REACT_APP_SSO_API_KEY = process.env.REACT_APP_SSO_API_KEY || "";
export const REACT_APP_SSO_REDIRECT_URI = process.env.REACT_APP_SSO_REDIRECT_URI || "";
export const STATIC_CONTENT_JSON_URL = process.env.REACT_APP_STATIC_CONTENT_JSON_URL || "";
export const SSO_PROFILE_LINK = process.env.REACT_APP_SSO_PROFILE_LINK || "";
const PLAYER_IMG_PATH = process.env.REACT_APP_PLAYER_IMAGE_URL || "";

export const getPlayerImgPath = (playerID: number) => `${PLAYER_IMG_PATH}/${playerID}_450.webp`;

export const PLAYER_PLACEHOLDER_IMG_URL = placeholderPlayer;

export const PUBLIC_URL = process.env.REACT_SITE_URL || window.location.origin;
export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[-!@#$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const PASSWORD_REQUIREMENTS =
	"Password must include 8 characters, including 1 upper case character, 1 number and 1 special character";

export const PLAYERS_IN_TEAM = 21;
export const DEF_MAX_POSITION = 5;
export const MID_MAX_POSITION = 5;
export const RUC_MAX_POSITION = 1;
export const FWD_MAX_POSITION = 5;
export const BENCH_MAX_POSITION = 1;
export const SALARY_CAP = 10000000;
export const MATCHES_PER_ROUND = 9;
export const AFLW_TRADES_PER_ROUND = 3;
export const AFLW_PLAYERS_ON_FIELD = 15;

export const GRAND_FINAL_WEEK_ID = 10;

export const H2H_SIZE_OPTIONS = [2, 4, 6, 8, 10, 12, 14, 16, 18, 20];

export const SHARE_PATH = "share";

export const IS_DPP_SWAP_DISABLED = true;
