import {RequestState, ModalType, IDirection, LeagueType, RoundStatus, LeagueH2H} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, computed} from "mobx";
import {useLocation, resolvePath} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";
import {ILeague} from "data/types/leagues";
import {AxiosError} from "axios";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import {get, max} from "lodash";
import {isAllTrue} from "data/utils/helpers";

interface IControllerProps {
	leagueId: number;
}

interface IBaseFilters {
	leagueId: number;
	page: number;
	limit: number;
	direction: IDirection;
	roundId?: number;
}

export interface ITab {
	label: string;
	location: ReturnType<typeof resolvePath> | string;
}

export interface ILeagueController extends ViewController<IControllerProps> {
	get currentLeague(): ILeague | undefined;
	get currentTabIndex(): number;
	get leagueTabs(): ITab[];
	get currentTab(): ITab;
	get hasFixture(): boolean;
	get leagueRounds(): IRound[];
	get leagueRound(): number;
	get isLadder(): boolean;
	get isInviteLeague(): boolean;

	setCurrentIndex(tabIndex: number): void;
	setLocation(location: ReturnType<typeof useLocation>): void;
	setLeagueFixtureRound(round: number): void;
	setLeagueLadderRound(round: number): void;
}

@injectable()
export class LeagueController implements ILeagueController {
	@observable private _leagueId!: number;
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _currentLocation!: ReturnType<typeof useLocation>;
	@observable private _currentTabIndex!: number;
	@observable private _leagueFixtureRound: number = 0;
	@observable private _leagueLadderRound: number = 0;

	constructor(
		@inject(Bindings.LeaguesStore) public readonly _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) public readonly _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public readonly _userStore: IUserStore,
		@inject(Bindings.RoundsStore) public _roundStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@computed
	get currentLeague(): ILeague | undefined {
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get currentTabIndex(): ILeagueController["currentTabIndex"] {
		return this._currentTabIndex;
	}

	get currentTab(): ITab {
		return this.leagueTabs[this.currentTabIndex];
	}

	get isCommissioner(): boolean {
		return this._userStore.user?.id === this.currentLeague?.leagueCommissioner?.userId;
	}

	get hasFixture(): boolean {
		return !!this.currentLeague?.fixture;
	}

	get isLadder(): boolean {
		return this._currentTabIndex === 0;
	}

	get isInviteLeague(): boolean {
		const isCommissioner = this.isCommissioner;
		const leagueClass = get(this.currentLeague, "class", "regular");

		const leagueClassRegular = leagueClass.toLocaleLowerCase() === "regular";
		const leagueType = get(this.currentLeague, "privacy", LeagueType.Public).toLowerCase();
		const leagueSize = get(this.currentLeague, "size", 2);
		const leagueNoTeams = get(this.currentLeague, "numTeams", 0);
		const isLeagueNotFull = leagueNoTeams < leagueSize;
		const isPrivateLeague = leagueType.toLowerCase() === LeagueType.Private;
		const isPublicLeague = leagueType.toLowerCase() === LeagueType.Public;
		const isOpen = get(this.currentLeague, "type", "open").toLowerCase() === "open";
		if (isOpen && isCommissioner) {
			return true;
		}
		return (
			isAllTrue([leagueClassRegular, isPrivateLeague, isLeagueNotFull, isCommissioner]) ||
			isAllTrue([isPublicLeague, leagueClassRegular, isLeagueNotFull])
		);
	}

	get leagueTabs(): ILeagueController["leagueTabs"] {
		if (this._currentLocation) {
			const isCommissioner = this.isCommissioner;
			const pathName = this._currentLocation.pathname;
			const rootPath = resolvePath("..", pathName);
			const hasFixture = this.hasFixture;
			const inviteTab = {
				label: "Invites",
				location: resolvePath("invites", rootPath.pathname),
			};
			const leagueTabs = [
				{
					label: "Ladder",
					location: resolvePath("ladder", rootPath.pathname),
				},
				isCommissioner
					? {
							label: "Settings",
							location: resolvePath("about", rootPath.pathname),
					  }
					: {
							label: "About",
							location: resolvePath("about", rootPath.pathname),
					  },
			];
			this.isInviteLeague && leagueTabs.splice(1, 0, inviteTab);
			hasFixture &&
				leagueTabs.splice(1, 0, {
					label: "Fixtures",
					location: resolvePath("fixtures", rootPath.pathname),
				});

			return leagueTabs;
		}

		return [];
	}

	get leagueRounds(): IRound[] {
		const fixtureLength = 10;
		const roundList = this._roundStore.list;
		if (this.isLadder) {
			const returnRounds = roundList.filter(
				(item) =>
					item.id >= Number(this.currentLeague?.startId) &&
					item.status !== RoundStatus.Scheduled
			);
			returnRounds.unshift({
				id: 0,
				status: RoundStatus.Scheduled,
				startDate: "",
				endDate: "",
				tournaments: [],
				byeSquads: [],
			});
			return returnRounds;
		}
		if (!this.isLadder && this.hasFixture) {
			return roundList.filter(
				(item) => item.id >= Number(this.currentLeague?.startId) && item.id <= fixtureLength
			);
		}

		return [];
	}

	get leagueRound(): number {
		if (this.isLadder) {
			return this._leagueLadderRound;
		}
		if (!this.isLadder && this.hasFixture) {
			return Number(
				max([
					this._roundStore.leagueFixtureSelectedRound,
					Number(this.currentLeague?.startId),
				])
			);
		}

		return Number(this.currentLeague?.startId);
	}

	setLeagueFixtureRound(roundId: number) {
		this._roundStore.setLeagueFixtureSelectedRound(roundId);
	}

	@action
	async setLeagueLadderRound(roundId: number) {
		const baseFilters: IBaseFilters = {
			leagueId: this._leagueId,
			page: 1,
			limit: 10,
			direction: IDirection.ASC,
		};
		roundId !== 0 && (baseFilters.roundId = roundId);
		this._leagueLadderRound = roundId;

		this._roundStore.setLeagueLadderSelectedRound(roundId);
		try {
			if (this.currentLeague?.type.toLocaleLowerCase() === LeagueH2H.H2H) {
				const currentRoundId = this._roundStore.currentRound?.id || 1;
				const roundIdPayload = roundId === 0 ? currentRoundId : roundId;
				await this._leaguesStore.fetchLeagueTeamRankings({
					...baseFilters,
					roundId: roundIdPayload,
				});
			} else {
				await this._leaguesStore.fetchLeagueRankings(baseFilters);
			}
		} catch (err) {
			trackSentryErrors(err, {}, "fetch league ranking");
			const error = err as AxiosError;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
		}
	}

	setCurrentIndex(tabIndex: number) {
		this._currentTabIndex = tabIndex;
	}

	dispose(): void {
		return;
	}

	setLocation(location: ReturnType<typeof useLocation>) {
		this._currentLocation = location;
		this._currentTabIndex = this.leagueTabs.findIndex(
			(it) => get(it.location, "pathname", "") === this._currentLocation?.pathname
		);
	}

	@action
	async init({leagueId}: IControllerProps) {
		this._leagueId = leagueId;
		await this._leaguesStore.fetchLeague(leagueId);
		const league = this._leaguesStore.getLeagueById(this._leagueId);
		this._leaguesStore.setSelectedLeagueId(leagueId);
		if (!league) {
			try {
				await this._roundStore.fetchRounds();
			} catch (err) {
				trackSentryErrors(err, {}, "league init");
				const error = err as AxiosError;
				this._modalsStore.showModal(ModalType.ERROR, {
					message: error.message,
					errors: error.response?.data,
				});
			}
		}
	}
}
