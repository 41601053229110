import {action, computed, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {IRound, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {ITrade, type ITeamStore} from "data/stores/team/team.store";
import {IPlayerExtended} from "views/components/team/lineup/team_lineup.controller";
import {IDictionary} from "data/stores/players/players.store";
import {MatchStatus, RequestState, RoundStatus, ShareType, SocialNetwork} from "data/enums";
import {ShareUtility} from "data/utils/social_share/social_share.utility";
import {copyToClipboard, currencyFormat} from "data/utils";

export interface ITradesController extends ViewController {
	get isTradeMode(): boolean;
	get playersById(): IDictionary<IPlayerExtended>;
	get trades(): ITrade[];
	get currentRound(): IRound | undefined;
	get isTeamStartRoundComplete(): boolean;
	get isReverseAllDisabled(): boolean;
	get isSubmitDisabled(): boolean;
	get isTradesMadeFromBE(): boolean;
	get numberOfMadeTrades(): number;
	get showConfirmModal(): number;
	get showSuccessModal(): boolean;
	get teamValue(): number;
	get remainingSalary(): number;
	get showReverseAllModal(): boolean;
	get isRoundLive(): boolean;
	get isAnyTradePartialFilled(): boolean;
	get isShowReverseAll(): boolean;
	get isAllTradesLocked(): boolean;
	get isShowManageTeam(): boolean;
	get isAnyLocalTrade(): boolean;
	get isRollbackAvailable(): boolean;
	get isRollbackOpen(): boolean;
	get nonLockedTrades(): ITrade[];
	get copyLinkState(): RequestState;
	get isSeasonComplete(): boolean;
	toggleReverseAllModal: () => void;
	addPlayerOutTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	addPlayerInTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	removePlayerOutTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	removePlayerInTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	makeTrades: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	resetTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	setTradeViewActive: () => void;
	setTradeViewInactive: () => void;
	reverseAllTrades: () => void;
	toggleShowConfirmModal: (id: number) => void;
	reverseTrade: (tradeId: number) => void;
	shareTrades: () => void;
	handleReverseTrade: (tradeId: number) => void;
	handleReverseAll: () => void;
	clickReverseAll: () => void;
	rollbackTeam: () => void;
	copyLink: () => void;
	shareMobile: () => void;
	shareTwitter: () => void;
	shareFacebook: () => void;
	toggleRollbackModal: () => void;
	handleTradeClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}

@injectable()
export class TradesController implements ITradesController {
	@observable private _showConfirmModal: number = 0;
	@observable private _showReverseAllModal: boolean = false;
	@observable private _copyLinkState: RequestState = RequestState.IDLE;
	@observable private _editTradeId: number = 0;

	@observable private _isRollbackOpen: boolean = false;
	private timeout: ReturnType<typeof setTimeout> | undefined = undefined;
	constructor(
		@inject(Bindings.RoundsStore) public _roundStore: IRoundsStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get isTradeMode() {
		return this._teamStore.isTradeMode;
	}
	get editTradeId() {
		return this._editTradeId;
	}

	get showConfirmModal() {
		return this._showConfirmModal;
	}

	get isSeasonComplete() {
		return this._roundStore.isSeasonComplete;
	}

	get copyLinkState() {
		return this._copyLinkState;
	}
	get showSuccessModal() {
		return this._teamStore.isTradeSuccess;
	}

	get isRollbackOpen() {
		return this._isRollbackOpen;
	}

	get isRollbackAvailable() {
		return this.isTradeMode && this._teamStore.isRollbackAvailable;
	}

	get isRoundLive() {
		return this._roundStore.currentRound?.status === RoundStatus.Playing;
	}

	@action
	toggleRollbackModal = () => {
		this._isRollbackOpen = !this._isRollbackOpen;
	};

	@action
	rollbackTeam = () => {
		this._teamStore.rollbackTeam();
		this.toggleRollbackModal();
	};

	get isReverseAllDisabled() {
		const tradesBE = this.trades.filter((trade) => trade.fromBE);
		const lockedTrades = tradesBE.filter((trade) => {
			const playerInLocked = this.isPlayerLocked(this.playersById[trade.in]?.squadId);
			const playerOutLocked = this.isPlayerLocked(this.playersById[trade.out]?.squadId);
			return playerInLocked || playerOutLocked;
		});
		return tradesBE.length === lockedTrades.length;
	}

	get showReverseAllModal() {
		return this._showReverseAllModal;
	}
	@computed
	get trades() {
		return this._teamStore.trades;
	}

	get isAllTradesLocked() {
		return (
			this.trades.filter((trade) => {
				if (!trade.fromBE) {
					return true;
				}
				const playerInLocked = this.isPlayerLocked(this.playersById[trade.in]?.squadId);
				const playerOutLocked = this.isPlayerLocked(this.playersById[trade.out]?.squadId);
				return playerInLocked || playerOutLocked;
			}).length === 0
		);
	}

	get isTradesMadeFromBE() {
		return Boolean(this.trades.find((trade) => trade.fromBE));
	}

	get isShowReverseAll() {
		return this.trades.filter((trade) => trade.fromBE).length > 0;
	}

	get isShowManageTeam() {
		return this.trades.filter((trade) => trade.fromBE).length === 3;
	}

	get nonLockedTrades() {
		return this.trades.filter((trade) => {
			const isBE = trade.fromBE;
			const playerInLocked = this.isPlayerLocked(this.playersById[trade.in]?.squadId);
			const playerOutLocked = this.isPlayerLocked(this.playersById[trade.out]?.squadId);
			return isBE && !playerInLocked && !playerOutLocked;
		});
	}

	get playersById() {
		return this._teamStore.extendedPlayersById;
	}

	get currentRound() {
		return this._roundStore.currentRound;
	}

	get isTeamStartRoundComplete() {
		return this._teamStore.isTeamStartRoundComplete;
	}

	get numberOfMadeTrades() {
		return this.trades.filter((trade) => trade.fromBE).length;
	}

	get teamValue() {
		return this._teamStore.team.value;
	}

	get remainingSalary() {
		return this._teamStore.remainingSalary;
	}

	get isAnyTradePartialFilled() {
		return this._teamStore.trades.filter((trade) => trade.in || trade.out).length > 0;
	}
	get isAnyLocalTrade() {
		return (
			this._teamStore.trades.filter((trade) => (trade.in || trade.out) && !trade.fromBE)
				.length > 0
		);
	}

	get isSubmitDisabled() {
		const isAllTradesFull =
			this.trades.filter((trade) => {
				return !trade.fromBE && trade.in !== 0 && trade.out !== 0;
			}).length >= 1;
		const isAnyTradesPartialFull =
			this.trades.filter((trade) => {
				return (trade.in && !trade.out) || (!trade.in && trade.out);
			}).length > 0;
		if (this._teamStore.isTradeLoading) {
			return true;
		}
		return this._teamStore.remainingSalary < 0 || !isAllTradesFull || isAnyTradesPartialFull;
	}

	isPlayerLocked = (squadId: number) => {
		const round = this._roundStore.selectedRound;
		const playerMatch = round?.tournaments.find((match) =>
			[match.awaySquadId, match.homeSquadId].includes(squadId)
		);
		if (playerMatch) {
			return playerMatch.status !== MatchStatus.Scheduled;
		}
		return false;
	};

	removePlayerOutTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		this._teamStore.removePlayerOutTrade(playerId);
	};

	removePlayerInTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		this._teamStore.removePlayerInTrade(playerId);
	};

	addPlayerInTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		this._teamStore.addPlayerInTrade(playerId);
	};

	addPlayerOutTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		this._teamStore.addPlayerOutTrade(playerId);
	};

	resetTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		if (!this.isTradeMode) {
			return;
		}
		const trades = this.trades;
		const idValue = Number(e.currentTarget.value);
		const trade = trades.find((trade) => trade.id === idValue);
		const isTradeFromBE = trade?.fromBE;
		if (isTradeFromBE) {
			this.reverseTrade(trade.id);
		} else {
			const handledTradeId = trade?.id || -1;
			this._teamStore.clearTrade(handledTradeId);
		}
	};

	setTradeViewActive = () => {
		this._teamStore.setTradeMode(true);
	};

	setTradeViewInactive = async () => {
		await this._teamStore.requestTeam();
		this._teamStore.restoreTradesToDefault();
		this._teamStore.setTradeMode(false);
		this._teamStore.fetchTrades();
	};

	handleReverseTrade = (tradeId: number) => {
		if (!this.isTradeMode) {
			return;
		}
		this.reverseTrade(tradeId);
		this.toggleShowConfirmModal(0);
	};

	handleReverseAll = () => {
		this.setTradeViewActive();
		this.toggleReverseAllModal();
	};

	clickReverseAll = () => {
		this.reverseAllTrades();
		this.toggleReverseAllModal();
	};

	makeTrades = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.makeTrades();
	};

	reverseTrade = (tradeId: number) => {
		this._teamStore.reverseTradeToBE([tradeId]);
	};

	reverseAllTrades = () => {
		const tradesBE = this.nonLockedTrades.map((trade) => trade.id);
		this._teamStore.reverseTradeToBE(tradesBE);
	};
	toggleShowConfirmModal = (id: number) => {
		this._showConfirmModal = id || 0;
		this._teamStore.clearTradeSuccess();
	};

	toggleReverseAllModal = () => {
		this._showReverseAllModal = !this.showReverseAllModal;
	};

	shareTrades = () => {
		console.log("MY TRADES");
	};

	get tradeInString() {
		const BETrades = this.trades.filter((trade) => trade.fromBE);
		const playersIn = BETrades.map((trade) => trade.in);
		return playersIn
			.map((playerId, index) => {
				const player = this.playersById[playerId];
				if (index === playersIn.length - 1) {
					return `${player.firstName[0]}. ${player.lastName}`;
				}
				return `${player.firstName[0]}. ${player.lastName}, `;
			})
			.join("");
	}

	get tradeOutString() {
		const BETrades = this.trades.filter((trade) => trade.fromBE);
		const playersOut = BETrades.map((trade) => trade.out);
		return playersOut
			.map((playerId, index) => {
				const player = this.playersById[playerId];
				if (index === playersOut.length - 1) {
					return `${player.firstName[0]}. ${player.lastName}`;
				}
				return `${player.firstName[0]}. ${player.lastName}, `;
			})
			.join("");
	}

	copyText = () => {
		const currentRound = this._roundStore.currentRound?.id;

		return (
			`My #AFLWFantasy trades are set for week ${currentRound || 1}!\n\n` +
			`🟢  IN: ${this.tradeInString}\n` +
			`🔴 OUT: ${this.tradeOutString}\n` +
			`💰 ${currencyFormat({
				input: this.remainingSalary,
				showDecimal: true,
			})} remaining salary\n\n` +
			`Play now at https://aflwfantasy.afl/ \n`
		);
	};

	shareFacebook = () => {
		ShareUtility.share({
			message: this.copyText(),
			type: ShareType.Trade,
			socialNetwork: SocialNetwork.Facebook,
		});
	};

	shareTwitter = () => {
		ShareUtility.share({
			message: this.copyText(),
			type: ShareType.Trade,
			socialNetwork: SocialNetwork.Twitter,
		});
	};

	shareMobile = () => {
		ShareUtility.share({
			message: this.copyText(),
			type: ShareType.Trade,
			socialNetwork: SocialNetwork.Mobile,
		});
	};

	copyLink = async () => {
		this.timeout = setTimeout(() => {
			this._copyLinkState = RequestState.IDLE;
		}, 5000);

		await copyToClipboard(this.copyText());
		this._copyLinkState = RequestState.SUCCESS;
	};

	handleTradeClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const tradeId = e.currentTarget.dataset.trade;
		const trade = this.trades.find((t) => t.id === Number(tradeId));
		if (!this.isTradeMode && trade && trade.fromBE) {
			this.setTradeViewActive();
			this.toggleShowConfirmModal(trade.id);
			return;
		}
		if (trade && trade.fromBE) {
			this.toggleShowConfirmModal(trade.id);
		} else {
			this.resetTrade(e);
		}
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
