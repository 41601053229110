import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IPlayersStore, IPoolFilters} from "data/stores/players/players.store";
import {type ITeamStore} from "data/stores/team/team.store";
import {Bindings} from "data/constants/bindings";
// import React, {ChangeEvent} from "react";
import {RequestState, RoundStatus, SortOrder} from "data/enums";
import {get} from "lodash";
// import {SelectChangeEvent} from "@mui/material";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {ISelectOption, type ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {getPreviousRoundId, handleNullValue, sumPlayerScoreValue} from "data/utils/helpers";
import {currencyFormat} from "data/utils";
import {useNavigate} from "react-router-dom";
import {IPlayerExtended} from "views/controllers/player_data/player_data.controller";

export interface IStatsCenterController extends ViewController<IInit> {
	addPlayerOutTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	addPlayerInTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	setStatOrderBy: (stat: string) => void;
	getPlayerStat: (stat: string, player: IPlayerExtended) => string | number;
	toggleTotalPointsSort: () => void;
	toggleTotalPointsOff: () => void;
	isPlayerFavourite: (playerId: number) => boolean;
	get filters(): IPoolFilters;
	get isSortByTotal(): boolean;
	get isTeamLoaded(): boolean;
	get order(): SortOrder;
	get statsOptions(): ISelectOption[];
	get isCurrentRoundIndigenous(): boolean;
	get isTeamFirstRoundComplete(): boolean;
}

interface IInit {
	navigate?: ReturnType<typeof useNavigate>;
	isMobile: boolean;
}

const DISPLAYINITIAL = "Average Pts";

@injectable()
export class StatsCenterController implements IStatsCenterController {
	private _navigate!: IInit["navigate"];
	private _isMobile: boolean = false;
	@observable protected _hasFetchedTeam: boolean = false;
	@observable private _requestState: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}
	// {val: "", label: "", labelShort: ""}
	private _statsOptions = [
		{val: "gamesPlayed", label: "Games Played", labelShort: "GP"},
		{val: "cost", label: "$ Price", labelShort: "Price"},
		{val: "lastRoundPriceChange", label: "Week $ Change", labelShort: "WK $ Change"},
		{val: "seasonPriceChange", label: "Season $ Change", labelShort: "SEA $ Change"},
		{val: "costPerPoint", label: "$ per point", labelShort: "$ / Point"},
		{val: "selections", label: "% Selected", labelShort: "% Sel"},
		{val: "avgPoints", label: DISPLAYINITIAL, labelShort: "Avg"},
		{val: "avgPointsLast3", label: "Last 3 Average", labelShort: "L3 Avg"},
		this._isMobile
			? {val: "roundPoints", label: "Wk Pts", labelShort: "Wk"}
			: {val: "lastRound", label: "Last Wk Pts", labelShort: "Last"},
		{val: "totalPoints", label: "Total Pts", labelShort: "Total"},
	];

	@observable private _isSortByTotal = false;

	get isLoadingOrLoaded() {
		return [RequestState.PENDING, RequestState.SUCCESS].includes(this._requestState);
	}

	get isSortByTotal() {
		return this._isSortByTotal;
	}
	get filters(): IPoolFilters {
		return this._teamBuilderStore.filters;
	}
	get isTeamLoaded() {
		return this._teamStore.isTeamLoaded;
	}

	get isCurrentRoundIndigenous() {
		return this._roundsStore.isCurrentRoundIndigenous;
	}
	isPlayerFavourite = (playerId: number) => {
		return this._playersStore.favouritePlayers.includes(playerId);
	};

	get statsOptions() {
		return [
			{val: "gamesPlayed", label: "Games Played", labelShort: "GP"},
			{val: "cost", label: "$ Price", labelShort: "Price"},
			{val: "lastRoundPriceChange", label: "Week $ Change", labelShort: "WK $ Change"},
			{val: "seasonPriceChange", label: "Season $ Change", labelShort: "SEA $ Change"},
			{val: "costPerPoint", label: "$ per point", labelShort: "$ / Point"},
			{val: "selections", label: "% Selected", labelShort: "% Sel"},
			{val: "avgPoints", label: DISPLAYINITIAL, labelShort: "Avg"},
			{val: "avgPointsLast3", label: "Last 3 Average", labelShort: "L3 Avg"},
			{val: "roundPoints", label: "Wk Pts", labelShort: "Wk"},
			{val: "totalPoints", label: "Total Pts", labelShort: "Total"},
		];
	}

	get order() {
		return this._teamBuilderStore.order;
	}

	get favouritePlayers() {
		return this._playersStore.favouritePlayers;
	}

	get isTeamFirstRoundComplete() {
		return this._teamStore.isTeamStartRoundComplete;
	}

	get isRoundOneComplete() {
		return this._roundsStore.isRoundOneComplete;
	}

	get lastRoundId() {
		const currentRound = this._roundsStore.currentRound;
		const currentRoundId = currentRound?.id;
		if (currentRound?.status !== RoundStatus.Scheduled && currentRoundId) {
			return currentRoundId;
		}
		if (currentRoundId) {
			return getPreviousRoundId(currentRoundId);
		}
		return 1;
	}

	toggleTotalPointsSort = () => {
		this._isSortByTotal = true;
		this._teamBuilderStore.sortPlayers();
	};
	toggleTotalPointsOff = () => {
		this._isSortByTotal = false;
	};

	get roundId() {
		return this._roundsStore.currentRound?.id || 1;
	}

	get filterRoundId() {
		const isCurrentRoundStarted =
			this._roundsStore.currentRound?.status !== RoundStatus.Scheduled;
		if (isCurrentRoundStarted && this._roundsStore.currentRound?.id === 1) {
			return 1;
		}

		return isCurrentRoundStarted && this.roundId !== 1 ? this.roundId : this.roundId - 1;
	}

	getPlayerStat = (stat: string, player: IPlayerExtended) => {
		let value;

		if (stat === "cost") {
			value = currencyFormat({input: player.cost, showDecimal: true});
			return handleNullValue(value);
		}
		if (stat.includes("cost") || stat.includes("Price")) {
			const val = get(player.stats, stat, 0);
			value = currencyFormat({input: val, showDecimal: true});

			return handleNullValue(value);
		}
		if (stat === "selections") {
			value = get(player, "selections", "-");
			return handleNullValue(value);
		}
		if (stat === "lastRound" || stat === "roundPoints") {
			value = get(player?.stats?.scores, this.lastRoundId, "-");

			return sumPlayerScoreValue(value);
		}
		value = get(player.stats, stat, "-");
		return handleNullValue(value);
	};

	@action
	async checkFetchTeam(): Promise<void> {
		if (this._hasFetchedTeam) {
			return;
		}
		this._hasFetchedTeam = true;
		await this._teamStore.requestTeam();
	}

	sortPlayers = () => {
		this._teamBuilderStore.sortPlayers();
	};

	setStatOrderBy = (stat: string) => {
		this.toggleTotalPointsOff();
		this._teamBuilderStore.updateStatField(stat);
	};

	addPlayerInTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.setTradeMode(true);
		const playerId = Number(e.currentTarget.value);
		this._teamStore.addPlayerInTrade(playerId);
		this._navigate!(`/team?from=stats-center`);
	};

	addPlayerOutTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.setTradeMode(true);
		const playerId = Number(e.currentTarget.value);
		this._teamStore.addPlayerOutTrade(playerId);
		this._navigate!(`/team?from=stats-center`);
	};
	dispose(): void {
		return;
	}

	async init({navigate, isMobile}: IInit) {
		this._navigate = navigate;
		this._isMobile = isMobile;
		if (this.isLoadingOrLoaded) {
			return;
		}
		this._requestState = RequestState.PENDING;

		await this._roundsStore.fetchRounds();
		await this._squadsStore.fetchSquads();
		await this._playersStore.safeFetchPlayers();
		await this._playersStore.fetchFavouritePlayers();
		await this.checkFetchTeam();
		/*
		Current decision is to keep filter the same
		both preseason and in season.
		const isSeasonStarted =
			get(this._roundsStore.list, "[0].status", RoundStatus.Scheduled) !==
			RoundStatus.Scheduled;

		if (!isSeasonStarted) {
			this._teamBuilderStore.updateStatField("avgPoints");
		}
		*/
		runInAction(() => {
			this._requestState = RequestState.SUCCESS;
		});
	}
}
