import React from "react";
import styled from "@emotion/styled";
import List from "@mui/material/List";
import RoundListItem from "./round_list_item";
import {useViewController} from "data/services/locator";

import {Bindings} from "data/constants/bindings";

import {IModalsController} from "views/controllers/modals/modals.controller";
import {IPlayerExtended} from "views/components/team/player_pool/player_pool.controller";
import {useMediaQuery} from "@mui/material";
import {Preloader} from "views/components/preloader";
import {Exist} from "views/components/exist/exist.component";
import {observer} from "mobx-react";

const MATCH_STAT_FIELDS = [
	"WK",
	"OPP",
	"TOG",
	"K",
	"H",
	"M",
	"T",
	"FF",
	"FA",
	"HO",
	"G",
	"B",
	"SCORE",
];

const Div = styled.div`
	width: 100%;
	.fav-button {
		width: 303px;
		margin: 20px 0px;
	}
`;

const Wrapper = styled.div`
	background: var(--aflwLightPurPleBackground);
	width: 100%;
	height: 100%;
	padding: 0px 16px 32px 16px;
`;

const StatHeaderRow = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid #fafafa;
	justify-content: space-between;
	background: white;
	height: 50px;
	padding: 0 5px;
	border-radius: 4px;
`;

const StatHeader = styled.div`
	font-size: 14px;
	font-weight: 600;
	color: var(--textBlack);
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
interface IPlayerOverview {
	player: IPlayerExtended;
	loading: boolean;
}

const MatchStats: React.FC<IPlayerOverview> = observer((props) => {
	const {activeOrCompletedRounds, playerStats, isCurrentRoundIndigenous, getSquadById} =
		useViewController<IModalsController>(Bindings.ModalsController, {});

	const isMobile = useMediaQuery("(max-width: 640px)");

	const {player, loading} = props;

	if (loading) {
		return <Preloader />;
	}

	return (
		<Div>
			<Wrapper>
				<Exist when={!isMobile && playerStats.length > 0}>
					<StatHeaderRow>
						{MATCH_STAT_FIELDS.map((stat, key) => {
							return <StatHeader key={key}>{stat}</StatHeader>;
						})}
					</StatHeaderRow>
				</Exist>
				<List>
					{playerStats.length > 0 ? (
						playerStats.map((stat, x) => (
							<RoundListItem
								key={x}
								playerRoundStats={stat}
								isCurrentRoundIndigenous={isCurrentRoundIndigenous}
								activeOrCompletedRounds={activeOrCompletedRounds}
								player={player}
								getSquadById={getSquadById}
							/>
						))
					) : (
						<div>No stats available for {`${player.firstName} ${player.lastName}`}</div>
					)}
				</List>
			</Wrapper>
		</Div>
	);
});

export default MatchStats;
