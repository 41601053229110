import {LeagueStatus, ModalType, RequestState, RoundStatus} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {get, isEmpty, isEqual} from "lodash";
import {trackSentryErrors} from "data/utils";
import type {ILeague, ILeagueUser} from "data/types/leagues";
import {AxiosError} from "axios";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {useNavigate} from "react-router-dom";

interface IInit {
	leagueId: number;
	navigate: ReturnType<typeof useNavigate>;
}

export interface ILeagueUsersController extends ViewController<IInit> {
	get league(): ILeague | undefined;
	get leagueUsers(): ILeagueUser[];
	get isLoadMore(): boolean;
	get isPreloaderVisible(): boolean;
	get isLoading(): boolean;
	get pageNumber(): number;
	get leagueUsersWithoutCommissioner(): ILeagueUser[];
	get leagueCommissioner(): ILeagueUser | undefined;
	get isLeagueStarted(): boolean;

	init(params: IInit): void;
	isOwnUser(userId: number): boolean;
	isUserCanBeRemoved(userId: number): boolean;
	increasePageNumber(): void;
	confirmRemoveUser(user: ILeagueUser): Promise<void>;
	removeUser(userId: ILeagueUser): void;
	deleteLeague: () => void;
	dispose(): void;
}

@injectable()
export class LeagueUsersController implements ILeagueUsersController {
	private _leagueId!: number;
	private _navigate!: IInit["navigate"];
	private _pageSize = 20;
	@observable private _requestState: RequestState = RequestState.PENDING;
	@observable private _pageNumber = 1;

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get league(): ILeague | undefined {
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get isLeagueStarted() {
		const leagueStartRoundId = this.league?.startId || 1;
		return (
			this._roundsStore.list.find((round) => round.id === leagueStartRoundId)?.status !==
			RoundStatus.Scheduled
		);
	}

	@computed
	get leagueUsers(): ILeagueUser[] {
		return this._leaguesStore.leagueUsers.users;
	}

	@computed
	get leagueUsersWithoutCommissioner(): ILeagueUser[] {
		return this._leaguesStore.leagueUsers.users.filter((user) => !this.isOwnUser(user.userId));
	}

	@computed
	get leagueCommissioner(): ILeagueUser | undefined {
		return this._leaguesStore.leagueUsers.users.find((user) => this.isOwnUser(user.userId));
	}

	@computed
	get isLoadMore(): boolean {
		return this._leaguesStore.leagueUsers.nextPage;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isPreloaderVisible() {
		return isEmpty(this.leagueUsers) && this.isLoading;
	}

	get pageNumber(): number {
		return this._pageNumber;
	}

	isOwnUser(userId?: number): boolean {
		return this._userStore.user?.id === userId;
	}

	isUserCanBeRemoved(userId?: number): boolean {
		const leagueStatus = get(this.league, "status", LeagueStatus.Scheduled);
		const hasLeagueStarted = leagueStatus !== LeagueStatus.Scheduled;
		if (hasLeagueStarted) {
			return false;
		}
		return this.league?.leagueCommissioner?.userId !== userId;
	}

	increasePageNumber(): void {
		this._pageNumber = this._pageNumber + 1;
	}

	confirmRemoveUser(user: ILeagueUser): Promise<void> {
		return this._leaguesStore
			.removeLeagueUser({
				leagueId: this._leagueId,
				userId: user.userId,
			})
			.catch((err) => {
				trackSentryErrors(err, {}, "league remove user");
				const error = err as AxiosError;
				this._modalsStore.showModal(ModalType.ERROR, {
					message: error.message,
					errors: error.response?.data,
				});
			});
	}

	async confirmDeleteLeague() {
		await this._leaguesStore.deleteLeague(this._leagueId);
		const isSuccess = this._leaguesStore.isSuccess;
		if (isSuccess) {
			this._navigate("/leagues/join");
		}
	}

	@action removeUser(user: ILeagueUser) {
		this._modalsStore.showModal(ModalType.LEAGUE_REMOVE_USER, {
			title: "Remove User",
			message: "Are you sure you want to remove this user from the league?",
			callback: this.confirmRemoveUser.bind(this, user) as () => void,
		});
	}

	@action deleteLeague = () => {
		this._modalsStore.showModal(ModalType.LEAGUE_REMOVE, {
			title: "Delete League",
			message: this.league?.name || `${this._leagueId}`,
			callback: this.confirmDeleteLeague.bind(this),
		});
	};

	async init({leagueId, navigate}: IInit) {
		this._leagueId = leagueId;
		this._navigate = navigate;
		await this._roundsStore.fetchRounds();

		reaction(
			() => this._pageNumber,
			() => {
				const isNewPage = this._pageNumber > 1;
				runInAction(() => (this._requestState = RequestState.PENDING));

				void this._leaguesStore
					.fetchLeagueUsers(leagueId, this._pageSize, this._pageNumber, isNewPage)
					.catch((err) => {
						trackSentryErrors(err, {}, "league fetch users");
						runInAction(() => (this._requestState = RequestState.ERROR));

						const error = err as AxiosError;
						this._modalsStore.showModal(ModalType.ERROR, {
							message: error.message,
							errors: error.response?.data,
						});
					})
					.then(() => runInAction(() => (this._requestState = RequestState.SUCCESS)));
			},
			{
				fireImmediately: true,
			}
		);
	}

	dispose(): void {
		this._leaguesStore.clearLeagueUsers();
	}
}
