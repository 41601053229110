import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {MatchStatus, PlayerPosition, PlayerStatus, RequestState} from "data/enums";
import {currencyFormat} from "data/utils";
import {first, get, isEmpty, keyBy, last} from "lodash";
import {type ITeamApiProvider} from "data/providers/api/team.api.provider";
import {IRound, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {ISquad, type ISquadsStore} from "data/stores/squads/squads.store";
// import {IS_DPP_SWAP_DISABLED} from "data/constants";

export interface IPlayer {
	id: number;
	firstName: string;
	lastName: string;
	squadId: number;
	status: PlayerStatus;
	cost: number;
	position: PlayerPosition[];
	displayPrice: string;
	locked: boolean;
	birthday?: string;
	stats: {
		avgPoints: number;
		avgPointsLast3: number;
		avgPointsLast5: number;
		costPerPoint: number;
		gamesPlayed: number;
		highestScore: number;
		lastRoundPriceChange: number;
		lowestScore: number;
		prices: IDictionary<number>;
		roundRank: number;
		scores: IDictionary<number[] | null>;
		seasonPriceChange: number;
		seasonRank: number;
		totalPoints: number;
		cost_round_diff?: number;
		cost_season_diff?: number;
		nextOpponent?: (number | undefined)[];
	};
	fixture: {
		isPlayerHome?: boolean;
		homeSquad?: ISquad;
		awaySquad?: ISquad;
		isComplete: boolean;
	}[];
}

export interface IPoolFilters {
	position: PlayerPosition[];
	squad: string;
	price: string;
	favourites: boolean;
	stat: string;
	status: PlayerStatus | string;
	doubleGameWeek: boolean;
}

export interface IDictionary<T> {
	[K: string]: T;
}

export interface IPlayerStats {
	tournamentId: number;
	roundId: number;
	opponentId: number;
	points: number;
	avg: number;
	stats: {
		K: number;
		H: number;
		M: number;
		T: number;
		FF: number;
		FA: number;
		FD: number;
		HO: number;
		G: number;
		B: number;
		TOG: number;
		D: number;
		ED: number;
		IED: number;
		I50: number;
		R50: number;
		CL: number;
		SP: number;
		CP: number;
		UCP: number;
		CM: number;
		GA: number;
		CS: number;
	};
}

export interface IPlayersStore {
	get getIsLoading(): boolean;
	get list(): IPlayer[];
	get currentRound(): IRound | undefined;
	get favouritePlayers(): number[];
	get playersById(): IDictionary<IPlayer>;
	get playerStats(): IPlayerStats[];
	get isLoaded(): boolean;
	getPlayerById(playerId: number): IPlayer | undefined;
	fetchPlayers(): Promise<void>;
	safeFetchPlayers(): Promise<void>;
	getPlayerDisplayPrice(cost: string): string;
	getFilteredPlayers(filters: IPoolFilters, searchFilter: string, maxPrice: number): IPlayer[];
	setFavouritePlayer(playerId: number): Promise<void>;
	removeFavouritePlayer(playerId: number): Promise<void>;
	setFixture(
		player: IPlayer
	): {isPlayerHome?: boolean; awaySquad?: ISquad; homeSquad?: ISquad; isComplete: boolean}[];
	fetchFavouritePlayers(): Promise<void>;
	getExtendedPlayers(): IPlayer[];
	getPlayerSquad(playerId: number): number;
	fetchPlayerStats(playerId: number): void;
}

@injectable()
export class PlayersStore implements IPlayersStore {
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.TeamApiProvider) private _apiTeamProvider: ITeamApiProvider,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _list: IPlayer[] = [];
	@observable private _extendedlist: IPlayer[] = [];
	@observable private _favouritePlayers: number[] = [];
	@observable private _playerStats: IPlayerStats[] = [];
	@observable private _requestState = RequestState.IDLE;

	get currentRound(): IRound | undefined {
		return this._roundsStore.currentRound;
	}

	get selectedRound() {
		return this._roundsStore.selectedRound;
	}

	get list() {
		return this._list;
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	get favouritePlayers(): number[] {
		return this._favouritePlayers;
	}

	get playersById(): IDictionary<IPlayer> {
		const extendedList = this._extendedlist.map((player) => ({
			...player,
			fixture: this.setFixture(player),
		}));
		return keyBy(extendedList, "id");
	}

	get playerStats(): IPlayerStats[] {
		return this._playerStats;
	}

	get isLoaded() {
		return [RequestState.SUCCESS, RequestState.ERROR].includes(this._requestState);
	}

	getPlayerById(playerId: number): IPlayer | undefined {
		return this._list.find((player) => player.id === playerId);
	}

	getPlayerDisplayPrice(cost: string) {
		return currencyFormat({input: Number(cost), showDecimal: true});
	}

	getExtendedPlayers() {
		return this._extendedlist;
	}

	getFilteredPlayers(filters: IPoolFilters, searchFilter: string, maxPrice: number): IPlayer[] {
		/**
		 * Player Pool Filter Logic
		 */
		const players = this.getExtendedPlayers();
		if (searchFilter) {
			return players.filter((player) => this.isInName(searchFilter, player));
		}
		const {position, price, squad, favourites, status, doubleGameWeek} = filters;
		return players.filter(
			(player) =>
				this.isInPosition(position, player) &&
				this.isInSquad(squad, player) &&
				this.isInPrice(price, player, maxPrice) &&
				this.isInFavourites(favourites, player) &&
				this.isInStatus(status, player) &&
				this.isInDoubleGameWeek(doubleGameWeek, player)
		);
	}

	isInName(searchValue: string, player: IPlayer): boolean {
		if (!searchValue.length) {
			return true;
		}
		const fullName = `${player.firstName} ${player.lastName}`.toLowerCase();
		const enteredName = searchValue.toLowerCase();

		return fullName.includes(enteredName);
	}

	isInFavourites(favBoolean: boolean, player: IPlayer): boolean {
		if (!favBoolean) {
			return true;
		}
		return favBoolean && this._favouritePlayers.includes(player.id);
	}

	isInDoubleGameWeek(gwBoolean: boolean, player: IPlayer): boolean {
		if (!gwBoolean) {
			return true;
		}
		const isDoubleGameWeek = this.setFixture(player).length === 2;
		return gwBoolean && isDoubleGameWeek;
	}

	isInPosition(position: PlayerPosition[], player: IPlayer) {
		if (!position.length) {
			return true;
		}
		return player.position.some((pos) => position.includes(pos));
	}

	isInPrice(price: string, player: IPlayer, maxPrice: number) {
		if (price === "0") {
			return true;
		}
		if (price === "afford") {
			return 0 < player.cost && player.cost <= maxPrice;
		}
		const variants = price.split("-").map(Number).sort();

		const [lowest, highest] = [first(variants) || 0, last(variants) || 0];
		const player_cost = player.cost;

		return Boolean(lowest <= player_cost && player_cost <= highest);
	}

	isInSquad(squad: string, player: IPlayer) {
		if (squad === "0") {
			return true;
		}

		return squad && Number(squad) === player.squadId;
	}

	isInStatus(status: string, player: IPlayer) {
		if (["all", "0"].includes(status)) {
			return true;
		}
		if (status.includes("Favourite")) {
			return (
				(status && status === player.status) || this._favouritePlayers.includes(player.id)
			);
		}

		return status && status === player.status;
	}

	setCostChanges(round_id: number, player: IPlayer) {
		const player_price = get(player, `stats.prices[${round_id}]`, player.cost),
			diff = player.cost - player_price;
		if (diff === 0 && round_id === 1) {
			return get(player, "stats.seasonPriceChange", 0);
		}

		return diff;
	}

	setFixture(player: IPlayer) {
		const squadMatches = this.selectedRound?.tournaments.filter((match) =>
			[match.homeSquadId, match.awaySquadId].includes(player.squadId)
		);
		const isSelectedCurrent = this.selectedRound?.id === this.currentRound?.id;
		const currentRoundMatches = this.currentRound?.tournaments.filter((match) =>
			[match.homeSquadId, match.awaySquadId].includes(player.squadId)
		);
		if (!isSelectedCurrent) {
			const matchArr = currentRoundMatches?.map((match) => {
				const isPlayerHome = match.homeSquadId === player.squadId;
				const awaySquad = this._squadsStore.getSquadById(match.awaySquadId);
				const homeSquad = this._squadsStore.getSquadById(match.homeSquadId);
				const isComplete = match.status === MatchStatus.Complete;

				return {
					isPlayerHome,
					awaySquad,
					homeSquad,
					isComplete,
					matchId: match.id,
				};
			});
			return matchArr || [];
		}
		if (squadMatches) {
			return squadMatches.map((match) => {
				const isPlayerHome = match.homeSquadId === player.squadId;
				const awaySquad = this._squadsStore.getSquadById(match.awaySquadId);
				const homeSquad = this._squadsStore.getSquadById(match.homeSquadId);
				const isComplete = match.status === MatchStatus.Complete;

				return {
					isPlayerHome,
					awaySquad,
					homeSquad,
					isComplete,
					matchId: match.id,
				};
			});
		}
		return [];
	}

	onSuccessPlayers(data: IPlayer[]) {
		const selectedRound = get(this._roundsStore.selectedRound, "id", 1);
		const comparisonRound = selectedRound - 1 <= 0 ? 1 : selectedRound - 1;
		// const getSecondaryPos = (playerPos: PlayerPosition) => {
		// 	const playerPosAvail = getPlayerPosAvail(playerPos)

		// 	return playerPosAvail[Math.floor(Math.random() * playerPosAvail.length)];
		// };
		runInAction(() => {
			this._list = data;

			const extendedData = data.map((player, index) => ({
				...player,
				displayPrice: this.getPlayerDisplayPrice(String(player.cost)),
				stats: {
					...player.stats,
					cost_round_diff: this.setCostChanges(comparisonRound, player),
					cost_season_diff: this.setCostChanges(1, player),
				},
				fixture: this.setFixture(player),
				position: player.position,
				// Comment back in for DPP testing if BE is not ready
				// this will randomly add DPP to every second player
				// position:
				// 	index % 2 || IS_DPP_SWAP_DISABLED
				// 		? player.position
				// 		: [player.position[0], getSecondaryPos(player.position[0])],
			}));
			this._extendedlist = extendedData;
		});
	}

	@action
	async fetchPlayers() {
		const {data} = await this._jsonProvider.players();
		this.onSuccessPlayers(data);
	}
	//added a safe call to prevent refetching player.json when livescoring is active
	@action
	async safeFetchPlayers() {
		if (isEmpty(this._list)) {
			const {data} = await this._jsonProvider.players();
			this.onSuccessPlayers(data);
		}
	}
	@action
	async fetchFavouritePlayers() {
		const {data} = await this._apiTeamProvider.getFavouritePlayers();
		runInAction(() => {
			this._favouritePlayers = data.success.favouritePlayers;
		});
	}
	@action
	async setFavouritePlayer(playerId: number) {
		this._favouritePlayers.push(playerId);
		const {data} = await this._apiTeamProvider.setFavouritePlayer({playerId});
		runInAction(() => {
			this._favouritePlayers = [playerId, ...data.success.favouritePlayers];
		});
	}
	@action
	async removeFavouritePlayer(playerId: number) {
		this._favouritePlayers = this.favouritePlayers.filter((id) => id !== playerId);
		const {data} = await this._apiTeamProvider.removeFavouritePlayer({playerId});
		runInAction(() => {
			this._favouritePlayers = data.success.favouritePlayers;
		});
	}

	getPlayerSquad(playerId: number) {
		const player = this.playersById[playerId];
		if (!player) {
			return 0;
		}
		return player.squadId;
	}
	@action
	async fetchPlayerStats(playerId: number) {
		this._requestState = RequestState.PENDING;
		const {data} = await this._jsonProvider.playerStats(playerId);
		if (Array.isArray(data)) {
			return runInAction(() => {
				this._requestState = RequestState.SUCCESS;
				this._playerStats = data;
			});
		}

		runInAction(() => {
			this._requestState = RequestState.SUCCESS;
			this._playerStats = [];
		});
	}
}
