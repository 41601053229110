import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ILeague} from "data/types/leagues";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {useLocation, useNavigate} from "react-router-dom";
import type {ILeaguesToJoinController} from "views/pages/leagues_to_join/leagues_to_join.controller";

interface IInit {
	navigate: ReturnType<typeof useNavigate>;
	location: ReturnType<typeof useLocation>;
}

export interface IJoinCreateWidgetController extends ViewController<IInit> {
	get searchedLeague(): ILeague[] | undefined;
	get leagueCodeOrName(): string;

	setLeagueCodeOrName(input: string): void;

	fetchLeagues(query: string): void;
	fetchAllLeagues(): void;
}

@injectable()
export class JoinCreateWidgetController implements IJoinCreateWidgetController {
	private _joinLeagueUrl = "/leagues/join";

	private _navigate!: IInit["navigate"];
	private _location!: IInit["location"];

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.LeaguesToJoinController)
		private _leaguesToJoinController: ILeaguesToJoinController
	) {
		makeAutoObservable(this);
	}

	get searchedLeague() {
		return this._leaguesToJoinController.leaguesToJoin;
	}

	get leagueCodeOrName() {
		return this._leaguesStore.SearchLeagueCodeOrName;
	}

	setLeagueCodeOrName(input: string) {
		if (input) {
			this._leaguesToJoinController.setIsSearch(true);
		} else {
			this._leaguesToJoinController.setIsSearch(false);
		}
		this._leaguesStore.setSearchLeagueCodeOrName(input);
	}

	public async fetchLeagues(query: string) {
		if (!query) {
			await this.fetchAllLeagues();
		} else {
			const currentPath = window.location.href;
			!currentPath.includes(this._joinLeagueUrl) && this._navigate(this._joinLeagueUrl);
			this._leaguesToJoinController.getLeaguesByQuery(query);
		}
	}

	public async fetchAllLeagues() {
		this._leaguesToJoinController.setIsSearch(false);
		this._leaguesStore.clearJoinLeagues();
		this.setLeagueCodeOrName("");
		const currentPath = window.location.href;
		!currentPath.includes(this._joinLeagueUrl) && this._navigate(this._joinLeagueUrl);
		await this._leaguesToJoinController.fetchLeagues();
	}

	init(params: IInit) {
		this._navigate = params?.navigate;
		this._location = params?.location;
	}

	dispose(): void {
		return;
	}
}
