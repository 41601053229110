import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {action, computed, makeAutoObservable} from "mobx";
import {get} from "lodash";

export interface IHeaderController extends ViewController {
	get isAuthorized(): boolean;
	get isAuthorizedAndRecovered(): boolean;
	get menuItems(): {title: string; path: string; submenu?: {subTitle: string; url: string}[]}[];

	get isMenuOpen(): boolean;

	handleLogout: () => void;
	handleToggleMenu: (isOpen?: boolean) => void;
}

@injectable()
export class HeaderController implements IHeaderController {
	private _isMenuOpen = false;

	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	get isAuthorizedAndRecovered() {
		return this.isAuthorized && Boolean(get(this._userStore.user, "isActive"));
	}

	get isMenuOpen() {
		return this._isMenuOpen;
	}

	@computed get menuItems() {
		if (this.isAuthorizedAndRecovered) {
			return [
				{title: "Manage Team", path: "/team"},
				{
					title: "Leagues",
					path: "/leagues",
					submenu: [
						{subTitle: "My leagues", url: "/leagues"},
						{subTitle: "Create a league", url: "/leagues/create"},
						{subTitle: "Join a league", url: "/leagues/join"},
					],
				},
				{title: "Rankings", path: "/rankings"},
				{title: "Stats Centre", path: "/stats-centre"},
				{title: "Prizes", path: "/help/prizes"},
				{title: "Help", path: "/help"},
			];
		}

		return [
			{title: "Prizes", path: "/help/prizes"},
			{title: "Help", path: "/help"},
		];
	}

	handleLogout = () => {
		void this._userStore.logout();
	};

	@action handleToggleMenu = (isOpen?: boolean) => {
		this._isMenuOpen = isOpen === undefined ? !this._isMenuOpen : isOpen;
		document.body.style.overflow = this._isMenuOpen ? "hidden" : "initial";
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
