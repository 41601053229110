import React, {lazy} from "react";
import {Route, Routes, Navigate} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";
import {LeaguePreloader} from "views/components/leagues/league_preloader/league_preloader.component";

import {
	EmptyLayout,
	FullWidthLayoutTeam,
	FullWidthLayoutHome,
	TwoAdsLayout,
	FullWidthLayoutUserTeam,
} from "views/components/layout/layout.component";

const Home = lazy(retryFailLoad(() => import("views/pages/login/login.page")));
const RecoverUser = lazy(retryFailLoad(() => import("views/pages/recover/recover.page")));
const Initiate = lazy(retryFailLoad(() => import("views/pages/initiate/initiate.page")));
const Team = lazy(retryFailLoad(() => import("views/pages/team/team.page")));
const UserTeam = lazy(retryFailLoad(() => import("views/pages/team/user_team.page")));
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ComponentsReview = lazy(
	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
);
const LoginCallBack = lazy(
	retryFailLoad(() => import("views/pages/login_callback/login_callback.page"))
);

const MyLeagues = lazy(retryFailLoad(() => import("views/pages/my_leagues/my_leagues.page")));
const CreateLeague = lazy(
	retryFailLoad(() => import("views/pages/create_league/create_league.page"))
);
const LeaguesToJoin = lazy(
	retryFailLoad(() => import("views/pages/leagues_to_join/leagues_to_join.page"))
);
const League = lazy(retryFailLoad(() => import("views/pages/league/league.page")));
const LeagueSettings = lazy(
	retryFailLoad(() => import("views/pages/league_settings/league_settings.page"))
);
const LeagueMatchup = lazy(
	retryFailLoad(() => import("views/pages/league_matchup/league_matchup.page"))
);
const LeagueFixtures = lazy(
	retryFailLoad(() => import("views/pages/league_fixtures/league_fixtures.page"))
);
const LeagueLadder = lazy(
	retryFailLoad(() => import("views/pages/league_ladder/league_ladder.page"))
);
const LeagueInvitePage = lazy(
	retryFailLoad(() => import("views/pages/league_invite/league_invite.page"))
);

const Rankings = lazy(retryFailLoad(() => import("views/pages/rankings/rankings.page")));
const StatsCentre = lazy(retryFailLoad(() => import("views/pages/stats_centre/stats_centre.page")));

export const RootRoutes: React.FC = () => (
	<Routes>
		<Route element={<NotAuthOnlyRoute />}>
			<Route element={<FullWidthLayoutHome />}>
				<Route index element={<Home />} />
			</Route>
			<Route path="/" element={<EmptyLayout />}>
				<Route path="/initiate" element={<Initiate />} />
				<Route path="/login/callback" element={<LoginCallBack />} />
			</Route>
		</Route>
		<Route element={<EmptyLayout />}>
			<Route element={<PrivateRoute />}>
				<Route path="/recover-team" element={<RecoverUser />} />
			</Route>
		</Route>
		<Route element={<FullWidthLayoutUserTeam />}>
			<Route element={<PrivateRoute />}>
				<Route path="/user/team/:roundId/:userId" element={<UserTeam />} />
			</Route>
		</Route>

		<Route element={<FullWidthLayoutTeam />}>
			<Route path="stats-centre/*" element={<StatsCentre />} />
			<Route element={<PrivateRoute />}>
				<Route path="team">
					<Route index element={<Team />} />
				</Route>
			</Route>
			<Route path="*" element={<NotFound />} />
		</Route>
		<Route element={<TwoAdsLayout />}>
			<Route element={<PrivateRoute />}>
				<Route path="my-account" element={<MyAccount />} />
				<Route path="leagues">
					<Route index element={<MyLeagues />} />
					<Route path="create" element={<CreateLeague />} />
					<Route path="join" element={<LeaguesToJoin />}>
						<Route path=":code" element={<LeaguesToJoin />} />
					</Route>
					<Route path=":id" element={<League />}>
						<Route element={<LeaguePreloader />}>
							<Route path="ladder" element={<LeagueLadder />} />
							<Route path="fixtures" element={<LeagueFixtures />} />
							<Route path="invites" element={<LeagueInvitePage />} />
							<Route path="about" element={<LeagueSettings />} />
							<Route path="*" element={<Navigate to="about" replace />} />
						</Route>
					</Route>
					<Route path=":id/matchup/:roundId/:matchup" element={<LeagueMatchup />} />
				</Route>
				<Route path="/rankings" element={<Rankings />} />
			</Route>
			<Route path="help/*" element={<Help />} />
		</Route>

		<Route path="components" element={<ComponentsReview />} />
	</Routes>
);

export default RootRoutes;
