import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable} from "mobx";
import {
	type IPlayer,
	type IPlayersStore,
	type IPoolFilters,
} from "data/stores/players/players.store";
import {type ITeamStore} from "data/stores/team/team.store";
import {type ISquadsStore} from "data/stores/squads/squads.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {chain, get} from "lodash";
import {getPlayerimage, handleNullValue, isAllTrue, sumPlayerScoreValue} from "data/utils/helpers";
import {MatchStatus, RoundStatus, SortOrder, TradePlayer} from "data/enums";
import {currencyFormat} from "data/utils";

export interface IPlayerExtended extends IPlayer {
	isInTeam: boolean;
	canAddToTeam: boolean;
	isFavourite?: boolean;
	isLocked?: boolean;
	showAddButton?: boolean;
	playerImgPath?: string;
	displayStat?: number | string;
	showNoButton?: boolean;
	favouriteAction?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	isTradeMode?: boolean;
	isInTrade?: boolean;
	removeTradeAction?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	isPlayerTradeBE?: boolean;
	isPlayerIncomingTrade?: boolean;
	isPlayerOutgoingTrade?: boolean;
	isRoundOneComplete?: boolean;
}

export interface IPlayerDataController extends ViewController {
	get players(): IPlayerExtended[];
	get isTradeInDisabled(): boolean;
	get isTradeOutDisabled(): boolean;
	getPlayerCostChanges(player: IPlayer): {
		cost_round_diff: number;
		cost_season_diff: number;
	};
}

@injectable()
export class PlayerDataController implements IPlayerDataController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	get filters(): IPoolFilters {
		return this._teamBuilderStore.filters;
	}

	get searchFilter(): string {
		return this._teamBuilderStore.searchFilter;
	}

	get favouritePlayers() {
		return this._playersStore.favouritePlayers;
	}

	setCostChanges(round_id: number, player: IPlayer) {
		const player_price = get(player, `stats.prices[${round_id}]`, player.cost),
			diff = player.cost - player_price;
		if (diff === 0 && round_id === 1) {
			return get(player, "stats.seasonPriceChange", 0);
		}

		return diff;
	}

	getPlayerCostChanges(player: IPlayer) {
		const selectedRound = get(this._roundsStore.selectedRound, "id", 1);
		const comparisonRound = selectedRound - 1 <= 0 ? 1 : selectedRound - 1;
		return {
			cost_round_diff: this.setCostChanges(comparisonRound, player),
			cost_season_diff: this.setCostChanges(1, player),
		};
	}

	isPlayerLocked = (squadId: number) => {
		const round = this._roundsStore.selectedRound;
		const playerMatch = round?.tournaments.find((match) =>
			[match.awaySquadId, match.homeSquadId].includes(squadId)
		);
		if (playerMatch) {
			return playerMatch.status !== MatchStatus.Scheduled;
		}
		return false;
	};

	isPlayerInTrade = (playerId: number) => {
		const trades = this._teamStore.trades;
		let isInTrade = false;
		trades.forEach((trade) => {
			if ([trade.in, trade.out].includes(playerId)) {
				isInTrade = true;
			}
		});
		return isInTrade;
	};

	isPlayerTradeBE = (playerId: number) => {
		const trades = this._teamStore.trades;
		const trade = trades.find((trade) => [trade.in, trade.out].includes(playerId));
		if (trade) {
			return trade.fromBE;
		}
		return false;
	};

	isPlayerInOrOutTrade = (playerId: number) => {
		const trades = this._teamStore.trades;
		let inOrOut = "" as TradePlayer;
		trades.forEach((trade) => {
			if (trade.in === playerId) {
				inOrOut = TradePlayer.IN;
			}
			if (trade.out === playerId) {
				inOrOut = TradePlayer.OUT;
			}
		});
		return inOrOut;
	};

	removePlayerOutTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.setTradeMode(true);
		const playerId = Number(e.currentTarget.value);
		this._teamStore.removePlayerOutTrade(playerId);
	};

	removePlayerInTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.setTradeMode(true);
		const playerId = Number(e.currentTarget.value);
		this._teamStore.removePlayerInTrade(playerId);
	};

	setFavouritePlayer = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		await this._playersStore.setFavouritePlayer(playerId);
	};

	removeFavouritePlayer = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		await this._playersStore.removeFavouritePlayer(playerId);
	};

	get players(): IPlayerExtended[] {
		const teamIds = this._teamStore.teamPlayerIds;

		const maxPrice = this._teamStore.remainingSalary;

		const players = this._playersStore.getFilteredPlayers(
			this.filters,
			this.searchFilter,
			maxPrice
		);
		return chain(players)
			.map((player) => {
				const isInTeam = teamIds.includes(player.id);
				const canAddToTeam = this._teamStore.canAddPlayerToTeam(player);
				const isLocked = this.isPlayerLocked(player.squadId);
				const isFavourite = this.favouritePlayers.includes(player.id);
				const isInTrade = this.isPlayerInTrade(player.id);
				const isPlayerTradeBE = this.isPlayerTradeBE(player.id);
				const playerFixtureData = this._playersStore.setFixture(player);
				const playerOpponentIds = playerFixtureData.map((fixture) => {
					return fixture.isPlayerHome ? fixture.awaySquad?.id : fixture.homeSquad?.id;
				});
				const removeTradeAction =
					this.isPlayerInOrOutTrade(player.id) === "in"
						? this.removePlayerInTrade
						: this.removePlayerOutTrade;
				return {
					...player,
					isInTeam,
					isFavourite,
					isLocked: this.isPlayerLocked(player.squadId),
					playerImgPath: getPlayerimage(player.id, 500, player.squadId),
					displayStat: this.getPlayerSelectedStat(player),
					fixture: playerFixtureData,
					showNoButton: isAllTrue([!canAddToTeam, !isInTeam]) || isLocked,
					stats: {
						...player.stats,
						nextOpponent: playerOpponentIds,
						playerOpponentIds,
						...this.getPlayerCostChanges(player),
					},
					favouriteAction: isFavourite
						? this.removeFavouritePlayer
						: this.setFavouritePlayer,
					canAddToTeam,
					showAddButton: !isInTeam && canAddToTeam,
					isTradeMode: this._teamStore.isTradeMode,
					isPlayerIncomingTrade: this.isPlayerInOrOutTrade(player.id) === TradePlayer.IN,
					isPlayerOutgoingTrade: this.isPlayerInOrOutTrade(player.id) === TradePlayer.OUT,
					isInTrade,
					removeTradeAction,
					isPlayerTradeBE,
					isRoundOneComplete: this.isRoundOneComplete,
				};
			})
			.orderBy([this.getPlayerSelectedStatForFilter], [this.order, SortOrder.ASC])
			.value();
	}

	get roundId() {
		return this._roundsStore.currentRound?.id || 1;
	}

	get order() {
		return this._teamBuilderStore.order;
	}

	get isRoundOneComplete() {
		return this._roundsStore.isRoundOneComplete;
	}

	get filterRoundId() {
		const isCurrentRoundStarted =
			this._roundsStore.currentRound?.status !== RoundStatus.Scheduled;
		if (isCurrentRoundStarted && this._roundsStore.currentRound?.id === 1) {
			return 1;
		}

		return isCurrentRoundStarted && this.roundId !== 1 ? this.roundId : this.roundId - 1;
	}

	getPlayerSelectedStatForFilter = (player: IPlayer) => {
		const activeStat = this.filters.stat;

		if (activeStat === "cost") {
			return player.cost;
		}
		if (activeStat === "selections") {
			return handleNullValue(get(player, "selections", 0));
		}
		if (activeStat === "roundPoints") {
			const score = get(player.stats.scores, this.filterRoundId);
			return sumPlayerScoreValue(score);
		}
		if (activeStat === "avgPoints") {
			return handleNullValue(get(player.stats, "avgPoints", 0), true);
		}
		if (activeStat === "totalPoints") {
			return handleNullValue(get(player.stats, "totalPoints", 0), true);
		}
		return handleNullValue(get(player.stats, activeStat, "-"), true);
	};

	getPlayerSelectedStat = (player: IPlayer) => {
		const activeStat = this.filters.stat;
		let selectedStat = get(player, activeStat, "-");

		if (activeStat === "cost") {
			const shouldShowDecimal = player.cost > 1000000;
			selectedStat = currencyFormat({
				input: Number(selectedStat),
				showDecimal: shouldShowDecimal,
			});
			return handleNullValue(selectedStat);
		}
		if (activeStat === "selections") {
			return handleNullValue(get(player, "selections", 0));
		}
		if (activeStat === "roundPoints") {
			const score = get(player.stats.scores, this.filterRoundId);
			return sumPlayerScoreValue(score);
		}
		if (activeStat === "avgPoints") {
			return handleNullValue(get(player.stats, "avgPoints", "-"));
		}
		return handleNullValue(get(player.stats, activeStat, "-"));
	};

	get isTradeOutDisabled() {
		return this._teamStore.isTradeOutDisabled;
	}

	get isTradeInDisabled() {
		return this._teamStore.isTradeInDisabled;
	}

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
