import {Stack} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import {ButtonPrimary, ButtonSecondary} from "views/components/buttons/buttons.component";
import {IPlayerExtended} from "views/components/team/player_pool/player_pool.controller";
import {get, isNumber} from "lodash";
import {currencyFormat} from "data/utils";
import {useViewController} from "data/services/locator";
import {IListPlayerController, IParams} from "views/components/team/lineup/team_player.controller";
import {Bindings} from "data/constants/bindings";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {observer} from "mobx-react";
import {handleNullValue, isAllTrue, sumPlayerScoreValue} from "data/utils/helpers";

const Div = styled.div`
	width: 100%;
	.fav-button {
		width: 292.5px;
		margin: 20px 0px;
		@media (max-width: 1000px) {
			width: 143.5px;
		}
	}
`;

const FavouriteButton = styled(ButtonSecondary)`
	width: 292.5px;
	margin: 20px 0px;
	&.favourite {
		background: var(--aflwPurple);
		color: white;
	}
	@media (max-width: 1000px) {
		width: 143.5px;
	}
`;
const Divider = styled.div`
	width: 1px;
	border-left: 1px solid #e5e5e5;
	height: 100%;
`;

const Wrapper = styled.div`
	background: var(--secondaryDefault);
	width: 100%;
	height: 100%;
`;
const StackWrapper = styled(Stack)`
	flex: 3;
	height: 76px;
	padding: 16px;
`;
const Stat = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	justify-content: space-around;
	.title {
		color: var(--textBlack);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		opacity: 0.5;
	}
	.value {
		color: var(--textBlack);
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		text-transform: uppercase;
	}
`;

const get$ChangeValues = (path: string, isRoundOneComplete: boolean, player: IPlayerExtended) => {
	const defaultValue = isRoundOneComplete ? "HOLD" : "-";
	const val = get(player, path, defaultValue);
	if (isNumber(val) && val !== 0) {
		return currencyFormat({input: val});
	} else {
		return defaultValue;
	}
};
interface IPlayerOverview {
	player: IPlayerExtended;
}
const PlayerOverview: React.FC<IPlayerOverview> = observer((props) => {
	const {player} = props;

	const Iprop: IParams = {
		playerId: player.id,
		isBench: false,
		position: player.position[0],
	};

	const {setFavouritePlayer, favourites} = useViewController<IModalsController>(
		Bindings.ModalsController,
		{}
	);

	const {playerRoundScore, isRoundOneComplete, lastRoundId} =
		useViewController<IListPlayerController>(Bindings.ListPlayerController, Iprop);

	const isPlayerFavourite = favourites.includes(player.id);
	const isPlayerPlayed = player.stats.gamesPlayed > 0;
	const getValues = (path: string) => {
		if (isPlayerPlayed) {
			return handleNullValue(get(player, path, "-"));
		}
		return "-";
	};

	const getPlayerRoundTotal = () => {
		const score = get(player, `stats.scores[${lastRoundId}]`, null);
		if (score === null) {
			return "-";
		}
		return sumPlayerScoreValue(score);
	};

	const getNumberValues = (path: string) => {
		const val = get(player, path, "0");
		if (path === "selections") {
			return Number(val);
		}
		if (isAllTrue([isPlayerPlayed, Boolean(val)])) {
			return Number(val);
		}
		return `-`;
	};

	const get$PerPoint = () => {
		if (!isPlayerPlayed) {
			return "-";
		}
		const value = get(player, "stats.costPerPoint", 0);
		return currencyFormat({input: value});
	};

	return (
		<Div>
			<Wrapper>
				<StackWrapper direction={"row"}>
					<Stat>
						<span className="title">WEEK PTS</span>
						<span className="value">{playerRoundScore(player, false, true)}</span>
					</Stat>
					<Divider />
					<Stat>
						<span className="title">AVERAGE PTS</span>
						<span className="value">{getValues("stats.avgPoints")}</span>
					</Stat>
					<Divider />
					<Stat>
						<span className="title">TOTAL PTS</span>
						<span className="value">{getValues("stats.totalPoints")}</span>
					</Stat>
				</StackWrapper>
				<StackWrapper direction={"row"}>
					<Stat>
						<span className="title">% OWNED</span>
						<span className="value">{getNumberValues("selections")}</span>
					</Stat>
					<Divider />
					<Stat>
						<span className="title">LAST 3 AVG</span>
						<span className="value">{getValues("stats.avgPointsLast3")}</span>
					</Stat>
					<Divider />
					<Stat>
						<span className="title">PRICE</span>
						<span className="value">{getValues("displayPrice")}</span>
					</Stat>
				</StackWrapper>
				<StackWrapper direction={"row"}>
					<Stat>
						<span className="title">HIGHEST PTS</span>
						<span className="value">{getValues("stats.highestScore")}</span>
					</Stat>
					<Divider />
					<Stat>
						<span className="title">LOWEST PTS</span>
						<span className="value">{getValues("stats.lowestScore")}</span>
					</Stat>
					<Divider />
					<Stat>
						<span className="title">LAST WEEK</span>
						<span className="value">{getPlayerRoundTotal()}</span>
					</Stat>
				</StackWrapper>
				<StackWrapper direction={"row"}>
					<Stat>
						<span className="title">WEEK RANK</span>
						<span className="value">{getValues("stats.roundRank")}</span>
					</Stat>
					<Divider />
					<Stat>
						<span className="title">SEASON RANK</span>
						<span className="value">{getValues("stats.seasonRank")}</span>
					</Stat>
					<Divider />
					<Stat>
						<span className="title">GAMES PLAYED</span>
						<span className="value">{getValues("stats.gamesPlayed")}</span>
					</Stat>
				</StackWrapper>
				<StackWrapper direction={"row"}>
					<Stat>
						<span className="title">$ PER POINT</span>
						<span className="value">{get$PerPoint()}</span>
					</Stat>
					<Divider />
					<Stat>
						<span className="title">WK $ CHANGE</span>
						<span className="value">
							{get$ChangeValues(
								"stats.lastRoundPriceChange",
								isRoundOneComplete,
								player
							)}
						</span>
					</Stat>
					<Divider />
					<Stat>
						<span className="title">SEA. $ CHANGE</span>
						<span className="value">
							{get$ChangeValues(
								"stats.seasonPriceChange",
								isRoundOneComplete,
								player
							)}
						</span>
					</Stat>
				</StackWrapper>
			</Wrapper>
			<div style={{display: "flex", justifyContent: "space-around"}}>
				<FavouriteButton
					data-desc="playermodal-favourite"
					className={isPlayerFavourite ? "favourite fav-button" : "fav-button"}
					onClick={() => setFavouritePlayer(player.id)}>
					Favourite{isPlayerFavourite && "d"}
				</FavouriteButton>
				<ButtonPrimary
					data-desc="playermodal-stats"
					className="fav-button"
					href="/stats-centre">
					Stats Centre
				</ButtonPrimary>
			</div>
		</Div>
	);
});

export default PlayerOverview;
