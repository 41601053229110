import {ReactComponent as CheckCircleIcon} from "assets/img/icon-check-circle.svg";
import {ModalType} from "data/enums";
import {useViewController} from "data/services/locator/locator_hook.service";
import {isEqual, noop} from "lodash";
import {observer} from "mobx-react";
import React from "react";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {Bindings} from "data/constants/bindings";
import {ModalGeneral} from "./modal_general.component";

export const ModalLeagueJoinedComponent: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController, {});
	const {visibleModal, close, modalContent} = controller;
	const isVisible = isEqual(visibleModal, ModalType.LEAGUE_JOINED);

	const leagueName = modalContent?.message || "";

	if (!isVisible) {
		return null;
	}

	return (
		<ModalGeneral
			isVisible
			icon={<CheckCircleIcon />}
			heading="You're in!"
			confirmText=""
			showCancel={false}
			onClick={noop}
			onClose={close}
			subHeading={`You have successfully joined ${leagueName}`}
		/>
	);
});
