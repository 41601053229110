import styled from "@emotion/styled";

export const Main = styled.main<{
	isHelpPage?: boolean;
}>`
	display: flex;
	flex: 1;
	background: #f7f6f2;
`;

export const TeamMain = styled.main`
	display: flex;
	flex: 1;
	justify-content: center;
	background: #fafafa;
	@media (max-width: 640px) {
		flex-direction: column;
		flex: unset;
	}
`;
export const Aside = styled.aside`
	width: 422px;
	padding-top: 50px;
	@media (max-width: 1100px) {
		display: none;
	}
`;

export const ContentBox = styled.section<{
	isLadder?: boolean;
	isHelpPage?: boolean;
}>`
	flex: 1;
	width: 100%;
	max-width: 864px;

	@media (max-width: 860px) {
		padding: 0px;
		flex:unset;
		${({isLadder}) =>
			isLadder &&
			`
			padding: 16px 0;
		`}
		${({isHelpPage}) =>
			isHelpPage &&
			`
			padding: 0;
		`}
`;

export const Container = styled.div`
	max-width: var(--maxWidthContent);
	width: 100%;
	padding: 20px;
	margin: 0 auto;
	box-sizing: border-box;
	position: relative;
	justify-content: space-around;

	@media (max-width: 1307px) {
		max-width: 100%;
		padding: 0px;
	}
`;

export const FlexContainer = styled(Container)`
	display: flex;
`;

export const PageTitle = styled.h1`
	@media (max-width: 800px) {
		padding: 0 20px;
	}
	@media (max-width: 640px) {
		font-size: 20px;
	}
`;
