import {ModalType, RequestState, LeagueStatus} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {useNavigate} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";
import {ILeague} from "data/types/leagues";
import {AxiosError} from "axios";

interface IInit {
	navigate: ReturnType<typeof useNavigate>;
	leagueId: number;
}

export interface ILeagueControlsController extends ViewController<IInit> {
	get league(): ILeague | undefined;

	get getLeaveState(): RequestState;

	get getRemoveState(): RequestState;

	get isLeagueCanBeRemoved(): boolean;

	get isCommissioner(): boolean;

	init(params: IInit): void;

	confirmLeaveLeague: () => Promise<void>;

	leaveLeague(): void;

	confirmRemoveLeague: () => Promise<void>;

	removeLeague(): void;
}

@injectable()
export class LeagueControlsController implements ILeagueControlsController {
	private _navigate!: IInit["navigate"];
	private _leagueId: number | undefined;

	@observable _requestStateLeave: RequestState = RequestState.IDLE;
	@observable _requestStateRemove: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	get league(): ILeague | undefined {
		if (this._leagueId) {
			return this._leaguesStore.getLeagueById(this._leagueId);
		}
	}

	get getLeaveState(): RequestState {
		return this._requestStateLeave;
	}

	get getRemoveState(): RequestState {
		return this._requestStateRemove;
	}

	get isCommissioner() {
		const league = this.league;
		const user = this._userStore.user;
		return league?.leagueCommissioner?.userId === user?.id;
	}

	get isLeagueCanBeRemoved(): boolean {
		const league = this.league;
		const user = this._userStore.user;

		if (!league || !user) {
			return false;
		}

		if (league.status !== LeagueStatus.Scheduled) {
			return false;
		}

		return this.isCommissioner;
	}

	confirmLeaveLeague = async () => {
		if (this.league) {
			const leagueId = this.league.id;

			runInAction(() => {
				this._requestStateLeave = RequestState.PENDING;
			});

			return this._leaguesStore
				.leaveLeague(leagueId)
				.then(() => {
					this._goToLeagues();
					this._requestStateLeave = RequestState.SUCCESS;
				})
				.catch((err) => {
					trackSentryErrors(err, {}, "league leave");
					runInAction(() => {
						this._requestStateLeave = RequestState.ERROR;
					});
					const error = err as AxiosError;
					this._modalsStore.showModal(ModalType.ERROR, {
						message: error.message,
						errors: error.response?.data,
					});
				});
		}
	};

	leaveLeague(): void {
		if (this.league) {
			this._modalsStore.showModal(ModalType.LEAGUE_LEAVE, {
				message: this.league.name,
				callback: this.confirmLeaveLeague,
			});
		}
	}

	confirmRemoveLeague = async () => {
		if (this.league) {
			const leagueId = this.league.id;

			runInAction(() => {
				this._requestStateRemove = RequestState.PENDING;
			});

			return this._leaguesStore
				.removeLeague(leagueId)
				.then(() => {
					this._goToLeagues();
					this._requestStateRemove = RequestState.SUCCESS;
				})
				.catch((err) => {
					trackSentryErrors(err, {}, "league remove");
					const error = err as AxiosError;
					this._modalsStore.showModal(ModalType.ERROR, {
						message: error.message,
						errors: error.response?.data,
					});

					runInAction(() => {
						this._requestStateRemove = RequestState.ERROR;
					});
				});
		}
	};

	removeLeague(): void {
		if (this.league) {
			this._modalsStore.showModal(ModalType.LEAGUE_REMOVE, {
				message: this.league.name,
				callback: this.confirmRemoveLeague,
			});
		}
	}

	_goToLeagues() {
		this._navigate("/leagues");
	}

	init(params: IInit): void {
		this._navigate = params.navigate;
		this._leagueId = params.leagueId;
	}

	dispose(): void {
		return;
	}
}
