import styled from "@emotion/styled";
import {Button} from "@mui/material";
import React from "react";

export const ButtonPrimaryStyle = `
	min-width: 120px;
	width: auto;
	font-weight: 700;
	font-size: 16px;
	border-radius: 100px;

	background: var(--aflwChargedCoral);
	color: var(--textWhite);

	&:hover {
		background: var(--aflwPurple);
	}

	&:disabled {
		cursor: default;
		background: rgba(247, 246, 242, 0.20);		
		color: #E6E5D6;
		opacity: 0.8;
	}

	&:disabled::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 100px;
		background: var(--aflwDisabledButtonHoverColor);
	}

	@media (max-width: 640px) {
		min-width: auto;
	}
`;
const ButtonPrimaryBase = styled(Button)`
	${ButtonPrimaryStyle}
`;

ButtonPrimaryBase.defaultProps = {
	variant: "contained",
};

const ButtonSecondaryBase = styled(Button)`
	min-width: 120px;
	font-weight: 700;
	font-size: 16px;
	border-radius: 100px;
	border: 1px solid;
	box-shadow: none;
	background: var(--textWhite);
	border-color: var(--aflwPurple);
	color: var(--aflwPurple);
	&:hover {
		background: var(--aflwPurple);
		border-color: var(--aflwPurple);
		color: var(--textWhite);
	}

	&:disabled {
		background: var(--textWhite);
		color: var(--aflwPurple);
		border-color: var(--aflwPurple);
		opacity: 0.2;
	}
	@media only screen and (max-width: 640px) and (orientation: portrait) {
		max-width: 100%;
	}
	@media (max-width: 640px) {
		min-width: auto;
	}
`;

ButtonSecondaryBase.defaultProps = {
	variant: "contained",
	color: "secondary",
	sx: {
		borderColor: "var(--)",
		color: "var(--aflwChargedCoral)",
	},
};
/**
 * Type casting is required because of MUI issue
 * https://github.com/mui/material-ui/issues/13921#issuecomment-484133463
 */
export const ButtonPrimary = ButtonPrimaryBase as typeof Button;
export const ButtonSecondary = ButtonSecondaryBase as typeof Button;

export const ButtonPure = styled.button`
	padding: 0;
	margin: 0;
	background: none;
	border: none;
	cursor: pointer;
`;

export const TextUnderLineButton = styled(ButtonPure)`
	color: var(--aflwChargedCoral);
	font-size: 16px;
	font-weight: 700;
	text-decoration: underline;
	padding: 12px 16px;
`;

export const ModalCloseButton = styled(ButtonPure)`
	position: absolute;
	right: 20px;
	top: 20px;
`;

const ButtonWithIconWrapper = styled(ButtonSecondary)`
	background: var(--aflwPurple);
	color: var(--textWhite);
	border-color: var(--aflwPurple);

	&:hover {
		path {
			fill: var(--aflwPurple);
		}
	}
	@media only screen and (max-width: 640px) and (orientation: portrait) {
		width: 100% !important;
	}
`;

type IButtonWithIconProps = {
	children: React.ReactNode;
	onClick: () => void;
};

export const ButtonWithIcon = ({children, onClick: compOnClick}: IButtonWithIconProps) => {
	return <ButtonWithIconWrapper onClick={compOnClick}>{children}</ButtonWithIconWrapper>;
};
