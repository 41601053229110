import {LeagueType, MatchStatus, PlayerPosition, RequestState, RoundStatus} from "data/enums";
import type {ILeaguesStore, IMatchupTeam} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {type ILeague, type ILeagueUser} from "data/types/leagues";
import {IMatch, IRound, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {useNavigate} from "react-router-dom";
import {IPagination} from "data/types/common";
import {get, isNull, isUndefined, keyBy, last} from "lodash";
import {POSITION_ARR, type ITeamStore} from "data/stores/team/team.store";
import {IDictionary, IPlayer, type IPlayersStore} from "data/stores/players/players.store";
import {ITab} from "views/pages/league/league.controller";
import {type ISquadsStore} from "data/stores/squads/squads.store";
import {isAllTrue, isAnyTrue} from "data/utils/helpers";
import {AFLW_PLAYERS_ON_FIELD} from "data/constants";

interface IInit {
	leagueId: number;
	navigate?: ReturnType<typeof useNavigate>;
	matchupIndex: number;
	roundId: number;
}

export interface IFixtureTeamData {
	home: {
		id: number;
		statuses: {
			completed: number;
			live: number;
			yetToPlay: number;
			partial: number;
		};
		lineup: {
			field: number[];
			bench: number[];
			position: PlayerPosition;
		}[];
		utility: number;
		uniqueMatchingLineup: {
			field: {matching: number[]; unique: number[]};
			bench: {matching: number[]; unique: number[]};
			position: PlayerPosition;
		}[];
		leaderId: number | undefined;
		captainId: number | undefined;
		viceId: number | undefined;
		isComplete: boolean;
		scoringPlayers: number[];
	};
	away: {
		id: number;
		statuses: {
			completed: number;
			live: number;
			yetToPlay: number;
			partial: number;
		};
		lineup: {
			field: number[];
			bench: number[];
			position: PlayerPosition;
		}[];
		uniqueMatchingLineup: {
			field: {matching: number[]; unique: number[]};
			bench: {matching: number[]; unique: number[]};
			position: PlayerPosition;
		}[];
		utility: number;
		leaderId: number | undefined;
		captainId: number | undefined;
		viceId: number | undefined;
		isComplete: boolean;
		scoringPlayers: number[];
	};
}
export interface IMatchupLeagueController extends ViewController<IInit> {
	get league(): ILeague | undefined;
	get isCommissioner(): boolean;
	get userId(): number | undefined;
	get roundList(): IRound[];
	get scheduledrounds(): IRound[];
	get leagueUser(): IPagination<{users: ILeagueUser[]}>;
	get leagueFixtureSelectedRound(): number;
	get fixture(): number[];
	get fixtureTeamsData(): IFixtureTeamData;
	get matchupDisplayOptions(): {label: string; value: string}[];
	get matchupDisplayValue(): {label: string; value: string};
	get currentLeague(): ILeague | undefined;
	get leagueRound(): number;
	get leagueRounds(): IRound[];
	get leagueTabs(): ITab[];
	get playersById(): IDictionary<IPlayer>;
	get isLoading(): boolean;
	get isLeagueRoundOneStarted(): boolean;
	get isInviteLeague(): boolean;
	get isSelectedRoundStarted(): boolean;
	getleagueUserById: (userId: number) => ILeagueUser | undefined;
	getTeamScore: (userId: number) => number | string;
	setLeagueMatchupType: (val: string) => void;
	setLeagueFixtureRound: (round: number) => void;
	getLeagueUserRankingForm: (userId: number) => {
		rank: number;
		wins: number;
		losses: number;
		draw: number;
	};
}

@injectable()
export class MatchupLeagueController implements IMatchupLeagueController {
	@observable private _league: ILeague | undefined;
	@observable private _leagueId!: number;
	@observable private _navigate: IInit["navigate"];
	@observable private _roundId: number = 0;
	@observable private _isLoading: boolean = false;

	@observable _requestStateLeave: RequestState = RequestState.IDLE;

	@observable protected _hasFetchedJSONS: boolean = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	@action
	async checkFetchJSONs(): Promise<void> {
		if (this._hasFetchedJSONS) {
			return;
		}
		this._hasFetchedJSONS = true;
		await Promise.all([this._roundsStore.fetchRounds()]);
	}

	@computed
	get currentLeague(): ILeague | undefined {
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get isSelectedRoundStarted() {
		const selectedRoundId = this._roundsStore.leagueFixtureSelectedRound;
		const round = this._roundsStore.roundsById[selectedRoundId];
		return round?.status !== RoundStatus.Scheduled;
	}

	get league(): ILeague | undefined {
		return this._league;
	}

	get isLoading() {
		return this._isLoading;
	}

	get leagueUser(): IPagination<{users: ILeagueUser[]}> {
		return this._leaguesStore.leagueUsers;
	}

	get isCommissioner(): boolean {
		return Number(this.userId) === Number(this.league?.leagueCommissioner?.userId);
	}

	get userId(): number | undefined {
		return this._userStore.user?.id;
	}

	get roundList(): IRound[] {
		return this._roundsStore.list;
	}

	get scheduledrounds(): IRound[] {
		return this._roundsStore.scheduleRounds;
	}

	get leagueFixtureSelectedRound(): number {
		return this._roundsStore.leagueFixtureSelectedRound || this.league?.startId || 1;
	}

	get usersTeamId() {
		return this.leagueUser.users.find((user) => user.userId === this.userId)?.teamId || 0;
	}

	get fixture() {
		const leagueFixture = get(this.league?.fixture, this.leagueFixtureSelectedRound);
		const fixture = leagueFixture ? leagueFixture[this._leaguesStore.matchupIndex] : [0, 0];
		const isUserHome = fixture[0] === this.usersTeamId;
		return isUserHome ? fixture : [fixture[1], fixture[0]];
	}

	get hasFixture(): boolean {
		return this.currentLeague?.fixture !== null;
	}

	get leagueRounds(): IRound[] {
		const fixtureLength = 10;

		if (this.hasFixture) {
			return this._roundsStore.list.filter(
				(item) => item.id >= Number(this.currentLeague?.startId) && item.id <= fixtureLength
			);
		}

		return [];
	}

	get leagueRound(): number {
		if (this.hasFixture) {
			return (
				this._roundsStore.leagueFixtureSelectedRound || Number(this.currentLeague?.startId)
			);
		}

		return Number(this.currentLeague?.startId);
	}

	get leagueTeams() {
		return this._leaguesStore.matchupTeams;
	}

	get matchupDisplay() {
		return this._leaguesStore.matchupDisplay;
	}

	get matchupIndex() {
		return this._leaguesStore.matchupIndex;
	}

	setFixtureSelectedRound(player: IPlayer) {
		const squadMatches = this._roundsStore.selectedRound?.tournaments.filter((match) =>
			[match.homeSquadId, match.awaySquadId].includes(player.squadId)
		);
		if (squadMatches) {
			return squadMatches.map((match) => {
				const isPlayerHome = match.homeSquadId === player.squadId;
				const awaySquad = this._squadStore.getSquadById(match.awaySquadId);
				const homeSquad = this._squadStore.getSquadById(match.homeSquadId);
				const isComplete = match.status === MatchStatus.Complete;
				const matchId = match.id;
				return {
					isPlayerHome,
					awaySquad,
					homeSquad,
					isComplete,
					matchId,
				};
			});
		}
		return [];
	}

	get playersById() {
		const playersExtended = this._playersStore.list.map((player) => ({
			...player,
			fixture: this.setFixtureSelectedRound(player),
		}));
		return keyBy(playersExtended, "id");
	}

	get positionsArray(): PlayerPosition[] {
		return this._teamStore.positionsArray;
	}
	// MAIN DATA STRUCTURE FOR PAGE AND TABLE: league_matchup_table.component.tsx
	get fixtureTeamsData() {
		const teamOne = this.fixture[0];
		const teamTwo = this.fixture[1];
		const teamOneObj = this.leagueTeams[this.matchupIndex]?.find(
			(team) => team.teamId === teamOne
		);
		const teamTwoObj = this.leagueTeams[this.matchupIndex]?.find(
			(team) => team.teamId === teamTwo
		);
		const teamOneUtility = this.leagueTeams[this.matchupIndex]?.find(
			(team) => team.teamId === teamOne
		)?.utilityId;
		const teamTwoUtility = this.leagueTeams[this.matchupIndex]?.find(
			(team) => team.teamId === teamTwo
		)?.utilityId;
		const teamOneCaptains = this.getTeamCaptains(teamOne);
		const teamTwoCaptains = this.getTeamCaptains(teamTwo);

		const teamOneData = {
			id: teamOne,
			statuses: this.getTeamPlayerStatus(teamOne),
			lineup: this.getTeamPlayerIds(teamOne),
			utility: teamOneUtility || 0,
			uniqueMatchingLineup: this.getTeamPlayerIdsUniqueMatching(teamOne),
			leaderId: this.getTeamLeader(teamOne),
			captainId: teamOneCaptains.captain,
			viceId: teamOneCaptains.vice,
			isComplete: Boolean(teamOneObj?.isComplete),
			scoringPlayers: this.getTeamScoringPlayers(teamOne),
		};
		const teamTwoData = {
			id: teamTwo,
			statuses: this.getTeamPlayerStatus(teamTwo),
			lineup: this.getTeamPlayerIds(teamTwo),
			utility: teamTwoUtility || 0,
			uniqueMatchingLineup: this.getTeamPlayerIdsUniqueMatching(teamTwo),
			leaderId: this.getTeamLeader(teamTwo),
			captainId: teamTwoCaptains.captain,
			viceId: teamTwoCaptains.vice,
			isComplete: Boolean(teamTwoObj?.isComplete),
			scoringPlayers: this.getTeamScoringPlayers(teamTwo),
		};
		return {
			home: teamOneData,
			away: teamTwoData,
		};
	}

	get uniquePlayersArray() {
		const teamOne = this.fixture[0];
		const teamTwo = this.fixture[1];
		const teamOnePlayerIds = this.getFlattenedTeamPlayerIds(teamOne);
		const teamTwoPlayerIds = this.getFlattenedTeamPlayerIds(teamTwo);
		const combinedIds = [...teamOnePlayerIds, ...teamTwoPlayerIds] as number[];

		const isMatch = (id: number) =>
			combinedIds.filter((playerId) => playerId === id).length === 2;
		return combinedIds.filter((playerId) => {
			return !isMatch(playerId);
		});
	}

	getUniquePlayers = (path: "bench" | "lineup", includeUtility?: boolean) => {
		const teamOne = this.fixture[0];
		const teamTwo = this.fixture[1];
		const teamOnePlayerIds = this.getFlattenedTeamPlayerIds(teamOne, path);
		const teamTwoPlayerIds = this.getFlattenedTeamPlayerIds(teamTwo, path);
		const teamOneUtility = this.leagueTeams[this.matchupIndex]?.find(
			(team) => team.teamId === teamOne
		)?.utilityId;
		const teamTwoUtility = this.leagueTeams[this.matchupIndex]?.find(
			(team) => team.teamId === teamTwo
		)?.utilityId;
		const combinedIds = [...teamOnePlayerIds, ...teamTwoPlayerIds] as number[];
		if (path === "bench" && teamOneUtility && teamTwoUtility) {
			combinedIds.push(teamOneUtility);
			combinedIds.push(teamTwoUtility);
		}

		const isMatch = (id: number) =>
			combinedIds.filter((playerId) => playerId === id).length === 2;
		return combinedIds.filter((playerId) => {
			return !isMatch(playerId);
		});
	};

	getTeamScoringPlayers = (teamId: number) => {
		const team = this.leagueTeams[this.matchupIndex]?.find((team) => team.teamId === teamId);
		const playerIds = this.getFlattenedTeamPlayerIds(teamId) as number[];
		return playerIds.filter((player) => this.isPlayerScoring(player, team));
	};

	getPlayerPositionInTeam(playerId: number, team: IMatchupTeam | undefined) {
		const player = this._playersStore.getPlayerById(playerId);
		if (!team) {
			return {
				isInTeam: false,
				position: player?.position[0],
				isBench: false,
			};
		}
		const {lineup, bench} = team;
		const positionInLineup = POSITION_ARR.find((positionKey) => {
			return lineup[positionKey].includes(playerId);
		});
		const positionInBench = POSITION_ARR.find((positionKey) => {
			return bench[positionKey].includes(playerId);
		});

		const playerDefaultPosition = player?.position[0] as PlayerPosition;
		if (isAllTrue([!positionInLineup, !positionInBench])) {
			return {isInTeam: false, position: playerDefaultPosition, isBench: false};
		}

		if (isAllTrue([!positionInLineup, Boolean(positionInBench)])) {
			return {isInTeam: true, position: positionInBench, isBench: true};
		}
		if (positionInLineup && !positionInBench) {
			return {
				isInTeam: true,
				position: positionInLineup,
				isBench: false,
			};
		}
		const positionHandled = positionInLineup || playerDefaultPosition;
		return {
			isInTeam: true,
			position: positionHandled,
			isBench: Boolean(positionInBench),
		};
	}

	isPlayerScoring = (playerId: number, team: IMatchupTeam | undefined) => {
		const players = this.playersById;
		const player = players[playerId];
		if (!player) {
			return false;
		}
		const playerSquad = get(player, "squadId", 0);
		const isPlayerMatchStarted = this.isPlayerMatchStarted(playerSquad);
		const selectedRound = this._roundsStore.selectedRound?.id || 1;
		const isScoreDNP = get(player, `stats.scores[${selectedRound}]`, "DNP") === "DNP";
		const isDNPAndStartedPlaying = isScoreDNP && isPlayerMatchStarted;
		const isUtilityAndStarted = isAllTrue([playerId === team?.utilityId, isPlayerMatchStarted]);
		const teamSubs = get(team, "substitutions", []) as number[];
		const isBenchScoringPlayer = teamSubs.includes(playerId);
		const playerPosInTeam = this.getPlayerPositionInTeam(playerId, team);
		const isBench = playerPosInTeam.isBench;
		const isBenchNotScoringAndStarted =
			Boolean(isBench) && !isBenchScoringPlayer && isPlayerMatchStarted;
		if (!isPlayerMatchStarted) {
			return true;
		}
		const isNotScoring = isAnyTrue([
			isDNPAndStartedPlaying,
			isUtilityAndStarted,
			isBenchNotScoringAndStarted,
		]);

		return !isNotScoring;
	};

	isPlayerMatchStarted = (squadId: number) => {
		const round = this._roundsStore.selectedRound;
		const playerMatches = round?.tournaments.filter((match) =>
			[match.awaySquadId, match.homeSquadId].includes(squadId)
		);
		if (playerMatches) {
			return playerMatches.every((match) => match.status !== MatchStatus.Scheduled);
		}
		return false;
	};

	getTeamLeader = (teamId: number) => {
		const team = this.leagueTeams[this.matchupIndex]?.find((team) => team.teamId === teamId);

		const captainId = team?.captainId;
		const viceCaptainId = team?.viceCaptainId;

		let shouldDouble = captainId;
		const selectedRound = this._roundsStore.selectedRound;
		const captain = this._playersStore.getPlayerById(captainId || 0);
		const viceCaptain = this._playersStore.getPlayerById(viceCaptainId || 0);
		// Captain is DNP if his score is undefined
		const captainSquad = get(captain, "squadId", 0);
		const selectedRoundId = get(selectedRound, "id", 1);
		const isCaptainGameFinished =
			this._roundsStore.getSquadMatchStatus(captainSquad, selectedRoundId) ===
			MatchStatus.Complete;
		const isCaptainDNP =
			get(captain, ["stats", "scores", selectedRound?.id || 0]) === undefined &&
			isCaptainGameFinished;
		const isViceCaptainDNP =
			get(viceCaptain, ["stats", "scores", selectedRound?.id || 0]) === undefined;
		if (
			isAnyTrue([
				isAllTrue([
					selectedRound?.status !== RoundStatus.Scheduled,
					isCaptainDNP,
					!isViceCaptainDNP,
				]),
				isNull(captainId),
				isUndefined(captainId),
				captainId === 0,
			])
		) {
			shouldDouble = viceCaptainId;
		}
		return shouldDouble;
	};

	getTeamCaptains = (teamId: number) => {
		const team = this.leagueTeams[this.matchupIndex]?.find((team) => team.teamId === teamId);

		return {
			captain: team?.captainId,
			vice: team?.viceCaptainId,
		};
	};

	get matchupDisplayOptions() {
		return [
			{
				label: "Head to Head",
				value: "h2h",
			},
			{
				label: "Unique Scorers",
				value: "unique",
			},
		];
	}

	get matchupDisplayValue() {
		return (
			this.matchupDisplayOptions.find(
				(opt) => opt.value === this._leaguesStore.matchupDisplay
			) || this.matchupDisplayOptions[0]
		);
	}

	get isInviteLeague(): boolean {
		const isCommissioner = this.isCommissioner;
		const leagueClass = get(this.currentLeague, "class", "regular");

		const leagueClassRegular = leagueClass.toLocaleLowerCase() === "regular";
		const leagueType = get(this.currentLeague, "privacy", LeagueType.Public).toLowerCase();
		const leagueSize = get(this.currentLeague, "size", 2);
		const leagueNoTeams = get(this.currentLeague, "numTeams", 0);
		const isLeagueNotFull = leagueNoTeams < leagueSize;
		const isPrivateLeague = leagueType.toLowerCase() === LeagueType.Private;
		const isPublicLeague = leagueType.toLowerCase() === LeagueType.Public;
		return (
			isAllTrue([leagueClassRegular, isPrivateLeague, isLeagueNotFull, isCommissioner]) ||
			isAllTrue([isPublicLeague, leagueClassRegular, isLeagueNotFull])
		);
	}

	get leagueTabs() {
		const leagueId = this._leagueId;

		const settingsOrAbout = this.isCommissioner ? "settings" : "about";
		const settingsOrAboutLabel = this.isCommissioner ? "Settings" : "About";

		const inviteTab = {location: `/leagues/${leagueId}/invites`, label: "Invites"};

		const leagueTabs = [
			{location: `/leagues/${leagueId}/ladder`, label: "Ladder"},
			{location: `/leagues/${leagueId}/fixtures`, label: "Fixtures"},
			{location: `/leagues/${leagueId}/${settingsOrAbout}`, label: settingsOrAboutLabel},
		];

		this.isInviteLeague && leagueTabs.splice(2, 0, inviteTab);

		return leagueTabs;
	}

	get isLeagueRoundOneStarted() {
		const startRoundId = this.league?.startId || 1;
		const roundMatches = get(
			this._roundsStore.roundsById,
			`[${startRoundId}].tournaments`,
			[]
		) as IMatch[];
		const lastMatchStatus = last(roundMatches)?.status;
		return lastMatchStatus !== MatchStatus.Scheduled;
	}

	@action
	setLeagueMatchupType = (val: string) => {
		this._leaguesStore.updateMatchupDisplay(val);
	};

	getTeamPlayerStatus = (teamId: number) => {
		const playerIds = this.getFlattenedTeamPlayerIds(teamId).slice(
			0,
			AFLW_PLAYERS_ON_FIELD
		) as number[];

		return playerIds.reduce(
			(statusObj, playerId) => {
				const player = this.playersById[String(playerId)];
				const playerSquad = get(player, "squadId");
				const status = this._roundsStore.getSquadMatchStatus(playerSquad, this._roundId);
				if (status === MatchStatus.Scheduled) {
					return {
						...statusObj,
						yetToPlay: statusObj.yetToPlay + 1,
					};
				}
				if (status === MatchStatus.Playing) {
					return {
						...statusObj,
						live: statusObj.live + 1,
					};
				}
				if (status === MatchStatus.Partial) {
					return {
						...statusObj,
						partial: statusObj.partial + 1,
					};
				}
				if (status === MatchStatus.Complete) {
					return {
						...statusObj,
						completed: statusObj.completed + 1,
					};
				}
				return statusObj;
			},
			{
				completed: 0,
				live: 0,
				yetToPlay: 0,
				partial: 0,
			}
		);
	};
	// returns player ids in flattened array, use for checking player id presence in team
	getFlattenedTeamPlayerIds = (
		teamId: number,
		path?: "bench" | "lineup",
		includeUtility?: boolean
	) => {
		const team = this.leagueTeams[this.matchupIndex]?.find((team) => team.teamId === teamId);
		const lineup = team?.lineup;
		const bench = team?.bench;
		const utility = team?.utilityId;

		if (!lineup || !bench) {
			return [];
		}

		if (path) {
			const objToReturn = path === "bench" ? bench : lineup;
			const returnArr = [
				...objToReturn.DEF,
				...objToReturn.MID,
				...objToReturn.RUC,
				...objToReturn.FWD,
			];
			if (includeUtility && utility) {
				returnArr.push(utility);
			}
			return returnArr;
		}

		return [
			...lineup.DEF,
			...lineup.FWD,
			...lineup.MID,
			...lineup.RUC,
			...bench.DEF,
			...bench.RUC,
			...bench.MID,
			...bench.FWD,
			utility,
		];
	};
	// return array of player ids keyed by lineup or bench
	getTeamPlayerIds = (teamId: number) => {
		const team = this.leagueTeams[this.matchupIndex]?.find((team) => team.teamId === teamId);

		const lineup = team?.lineup;
		const bench = team?.bench;
		if (lineup && bench) {
			const defenders = {field: lineup.DEF, bench: bench.DEF, position: PlayerPosition.DEF};
			const midfielders = {field: lineup.MID, bench: bench.MID, position: PlayerPosition.MID};
			const rucks = {field: lineup.RUC, bench: bench.RUC, position: PlayerPosition.RUC};
			const forwards = {field: lineup.FWD, bench: bench.FWD, position: PlayerPosition.FWD};
			return [defenders, midfielders, rucks, forwards];
		}
		return [];
	};
	// UNIQUE/MATCHING SORTED DATA
	getTeamPlayerIdsUniqueMatching = (teamId: number) => {
		const team = this.leagueTeams[this.matchupIndex]?.find((team) => team.teamId === teamId);
		const lineup = team?.lineup;
		const bench = team?.bench;
		if (lineup && bench) {
			// keyed unique and matching playerId arrays
			const getUniqueMatchField = (
				lineupArr: number[],
				path: "bench" | "lineup",
				includeUtility?: boolean
			) => {
				const uniquePlayers = this.getUniquePlayers(path, includeUtility);
				const returnObj = {
					unique: [] as number[],
					matching: [] as number[],
				};
				lineupArr.forEach((playerId) => {
					const doesExist = uniquePlayers.indexOf(playerId);

					if (~doesExist) {
						returnObj.unique.push(playerId);
						return;
					}
					returnObj.matching.push(playerId);
				});
				returnObj.matching.sort((numberA, numberB) => {
					return numberA > numberB ? 1 : -1;
				});
				return returnObj;
			};

			const benchFWDIncludeUtility = [...bench.FWD, team.utilityId];

			const defenders = {
				field: getUniqueMatchField(lineup.DEF, "lineup"),
				bench: getUniqueMatchField(bench.DEF, "bench"),
				position: PlayerPosition.DEF,
			};
			const midfielders = {
				field: getUniqueMatchField(lineup.MID, "lineup"),
				bench: getUniqueMatchField(bench.MID, "bench"),
				position: PlayerPosition.MID,
			};
			const rucks = {
				field: getUniqueMatchField(lineup.RUC, "lineup"),
				bench: getUniqueMatchField(bench.RUC, "bench"),
				position: PlayerPosition.RUC,
			};
			const forwards = {
				field: getUniqueMatchField(lineup.FWD, "lineup"),
				bench: getUniqueMatchField(benchFWDIncludeUtility, "bench", true),
				position: PlayerPosition.FWD,
			};
			return [defenders, midfielders, rucks, forwards];
		}
		return [];
	};

	getleagueUserById = (teamId: number): ILeagueUser | undefined => {
		return this.leagueUser.users.find((user) => {
			return user?.teamId === teamId || user.userId === teamId;
		});
	};

	getLeagueUserRankingForm = (teamId: number) => {
		const leagueRankingsArr = this._leaguesStore.leagueTeamRankings;
		const team = leagueRankingsArr.find((team) => team.teamId === teamId);
		return {
			rank: team?.overallRank || 0,
			wins: team?.WDL.win || 0,
			losses: team?.WDL.lose || 0,
			draw: team?.WDL.draw || 0,
		};
	};

	getTeamScore = (teamId: number) => {
		const matchupTeam = this._leaguesStore.matchupTeams[this.matchupIndex]?.find(
			(team) => team.teamId === teamId
		);
		return get(matchupTeam?.scoreFlow, this.leagueFixtureSelectedRound, "-");
	};

	setLeagueFixtureRound = async (roundId: number) => {
		// FETCH DATA AND SYNC STORES
		this._isLoading = true;
		await this._leaguesStore.fetchMatchups(roundId, this._leagueId);
		await this._leaguesStore.fetchLeagueTeamRankings({leagueId: this._leagueId, roundId});
		this._roundsStore.setLeagueFixtureSelectedRound(roundId);
		this._roundsStore.setSelectedRound(roundId);
		const fixtureForRound = get(this.league, `fixture[${roundId}]`) as number[][] | undefined;
		const userTeam = this.leagueUser.users.find((user) => {
			return user.userId === this.userId;
		});
		const userMatchupIndex =
			fixtureForRound?.findIndex((matchup) => matchup.includes(userTeam?.teamId || 0)) || 0;

		this._navigate!(`/leagues/${this._leagueId}/matchup/${roundId}/${userMatchupIndex}`);
		this._leaguesStore.handleMatchupIndexChange(userMatchupIndex);
		this._isLoading = false;
	};

	async init({leagueId, roundId, matchupIndex, navigate}: IInit) {
		this._isLoading = true;
		// DATA FETCHING
		await this._squadStore.fetchSquads();
		await this._roundsStore.fetchRounds();
		await this._playersStore.safeFetchPlayers();
		await this._userStore.requestUser();
		await this._leaguesStore.fetchLeagueUsers(leagueId);
		await this._leaguesStore.fetchLeague(leagueId);
		await this._leaguesStore.fetchLeagueTeamRankings({leagueId, roundId});
		await this._leaguesStore.fetchMatchups(roundId, leagueId);
		// SYNC LEAGUE AND ROUND STORES
		this._roundsStore.setLeagueFixtureSelectedRound(roundId);
		this._roundsStore.setSelectedRound(roundId);
		this._leaguesStore.setSelectedLeagueId(leagueId);
		this._leaguesStore.handleMatchupIndexChange(matchupIndex);
		runInAction(() => {
			this._leagueId = leagueId;
			this._league = this._leaguesStore.getLeagueById(leagueId);
			this._navigate = navigate;
			this._roundId = roundId;
			this._isLoading = false;
		});
	}

	dispose(): void {
		return;
	}
}
