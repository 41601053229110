import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type SelectChangeEvent} from "@mui/material";
import {type ITeamStore} from "data/stores/team/team.store";

export interface IRoundSelectorController extends ViewController {
	get selectedRoundId(): number;
	setRound: (e: SelectChangeEvent | SelectChangeEvent<string | number>) => void;
}

@injectable()
export class RoundSelectorController implements IRoundSelectorController {
	constructor(
		@inject(Bindings.RoundsStore) public _roundStore: IRoundsStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get selectedRoundId() {
		return this._roundStore.selectedRound?.id || 1;
	}

	get actualRoundId() {
		return this._roundStore.currentRound?.id || 1;
	}

	setRound = async (e: SelectChangeEvent | SelectChangeEvent<string | number>) => {
		const {value} = e.target;
		const numberValue = Number(value);
		this._roundStore.setSelectedRound(numberValue);

		if (this.selectedRoundId < this.actualRoundId) {
			this._teamStore.getTeamRank(numberValue);
			this._teamStore.getPastTeam(numberValue);
			return;
		}
		await this._teamStore.requestTeam();
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
