import {ModalType} from "data/enums";
import {useViewController} from "data/services/locator/locator_hook.service";
import {isEqual} from "lodash";
import {observer} from "mobx-react";
import React from "react";
import {ReactComponent as ExclamationIcon} from "assets/img/icon-exclamation.svg";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {Bindings} from "data/constants/bindings";
import {ModalGeneral} from "./modal_general.component";

export const ModalLeagueRemoveUser: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController, {});
	const {visibleModal, close, modalContent} = controller;
	const isVisible = isEqual(visibleModal, ModalType.LEAGUE_REMOVE_USER);

	const onConfirm = () => {
		if (modalContent?.callback) {
			modalContent?.callback();
		}
		close();
	};

	if (!isVisible) {
		return null;
	}

	const title = modalContent?.title || "";
	const message = modalContent?.message || "";

	return (
		<ModalGeneral
			isVisible
			icon={<ExclamationIcon />}
			heading={title}
			confirmText="Confirm"
			showCancel={true}
			onClick={onConfirm}
			onClose={close}
			subHeading={message}
		/>
	);
});
