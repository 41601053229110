/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {createTheme} from "@mui/material/styles";

export const buttonsTheme = createTheme({
	components: {},
});
