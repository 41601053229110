import styled from "@emotion/styled";

export const Container = styled.div`
	max-width: 1307px;
	width: 100%;
	box-sizing: border-box;

	@media (max-width: 1307px) {
		max-width: 100%;
	}
`;

interface IPageHeader {
	size?: "small";
}

export const PageHeader = styled.section<IPageHeader>`
	margin: 0;
`;

export const PageHeaderContainer = styled(Container)``;

export const Sidebar = styled.aside`
	width: 100%;
	max-width: 300px;
	display: flex;
	flex-flow: column;
	gap: 24px;
`;
