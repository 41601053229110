import {ModalType, RequestState, ShareType, SocialNetwork} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import {copyToClipboard, trackSentryErrors} from "data/utils";
import {ShareUtility} from "data/utils/social_share/social_share.utility";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {useNavigate} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {uniqBy} from "lodash";
import {ILeague} from "data/types/leagues";
import {AxiosError} from "axios";

interface IInit {
	navigate?: ReturnType<typeof useNavigate>;
	regenerateParam: string | null;
}

export interface IInviteLeagueController extends ViewController<IInit> {
	inviteCount: number;
	copyCodeState: RequestState;
	isRegenInviteSent: boolean;
	copyLinkState: RequestState;
	isInvalidEmails: boolean;
	invalidEmailsHelperMessage: string;
	league: ILeague | undefined;

	get inviteEmailState(): RequestState;
	get isSendInviteDisabled(): boolean;

	copyCode(): Promise<void>;
	copyLink(): Promise<void>;

	setEmail(email: string): void;

	clearErrorMessage(): void;

	inviteEmails(emailsString: string): Promise<void>;

	updateLeagueId(leagueId: number): void;

	shareTw(): void;

	shareFB(): void;

	shareMobile(): void;

	updateInviteState(): void;

	dispose(): void;
}

@injectable()
export class InviteLeagueController implements IInviteLeagueController {
	private _league: ILeague | undefined;

	@observable private _copyCodeState: RequestState = RequestState.IDLE;
	@observable private _copyLinkState: RequestState = RequestState.IDLE;
	@observable private _inviteEmailsState: RequestState = RequestState.IDLE;
	@observable private _isRegenInvitesSent: boolean = false;
	@observable private _invalidEmails: string[] = [];
	@observable public _invitedEmail: string = "";
	@observable public inviteCount: number = 0;
	private timeout: ReturnType<typeof setTimeout> | undefined = undefined;
	private _navigate: IInit["navigate"];

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	get copyCodeState() {
		return this._copyCodeState;
	}
	get copyLinkState() {
		return this._copyLinkState;
	}

	get league(): ILeague | undefined {
		return this._league;
	}

	get isSendInviteDisabled() {
		return this._invitedEmail === "";
	}

	get isInvalidEmails() {
		return !!this._invalidEmails.length;
	}

	get inviteEmailState() {
		return this._inviteEmailsState;
	}

	get isRegenInviteSent() {
		return this._isRegenInvitesSent;
	}

	get invalidEmailsHelperMessage() {
		if (this.isInvalidEmails) {
			return `Incorrect emails - ${this._invalidEmails.join(", ")}`;
		}

		return "Emails should be separated by commas or new lines";
	}

	setEmail(email: string) {
		this._invitedEmail = email;
	}

	@action
	clearErrorMessage() {
		this._invalidEmails = [];
		this._inviteEmailsState = RequestState.IDLE;
	}

	@action
	async inviteEmails(emailsString: string) {
		const league = this._league;

		if (league) {
			const EMAIL_REGEXP = "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$";
			const emailsByNewLine = emailsString.replace(/\n/g, ",").replace(/,+/, ",");
			const emailsArray = emailsByNewLine.split(",").map((it) => it.trim());
			const invalidEmails = emailsArray.filter(
				(it) => it.length && !new RegExp(EMAIL_REGEXP).exec(it)
			);

			if (invalidEmails.length) {
				this._invalidEmails = invalidEmails;
				return;
			}

			const identityEmails = uniqBy(
				emailsArray.filter(Boolean).map((email) => ({
					email,
				})),
				"email"
			);

			this._inviteEmailsState = RequestState.PENDING;

			return this._leaguesStore
				.inviteLeague({
					leagueId: league.id,
					invites: identityEmails,
				})
				.then((_e) => {
					runInAction(() => {
						this._inviteEmailsState = RequestState.SUCCESS;
					});
				})
				.catch((err) => {
					trackSentryErrors(err, {}, "league invite");
					runInAction(() => {
						this._inviteEmailsState = RequestState.ERROR;
					});

					const error = err as AxiosError;
					this._modalsStore.showModal(ModalType.ERROR, {
						message: error.message,
						errors: error.response?.data,
					});
				});
		}
	}

	copyCode() {
		this.timeout = setTimeout(() => {
			this._copyCodeState = RequestState.IDLE;
		}, 5000);

		if (!this._league?.code) {
			this._copyCodeState = RequestState.ERROR;
			return Promise.resolve();
		}

		return copyToClipboard(this._league.code).then((result) => {
			runInAction(() => {
				if (result) {
					this._copyCodeState = RequestState.SUCCESS;
				} else {
					this._copyCodeState = RequestState.ERROR;
				}
			});
		});
	}

	copyLink() {
		this.timeout = setTimeout(() => {
			this._copyLinkState = RequestState.IDLE;
		}, 5000);

		if (!this._league?.code) {
			this._copyLinkState = RequestState.ERROR;
			return Promise.resolve();
		}

		const shareLink = ShareUtility.share({
			type: ShareType.League,
			leagueCode: String(this._league.code),
			socialNetwork: SocialNetwork.Link,
			code: this._league.code,
		});

		return copyToClipboard(shareLink as string).then((result) => {
			runInAction(() => {
				if (result) {
					this._copyLinkState = RequestState.SUCCESS;
				} else {
					this._copyLinkState = RequestState.ERROR;
				}
			});
		});
	}

	get _shareMessage() {
		const leagueCode = this._league?.code || "";
		return `Come and join my league! Join via the link below or by using the league pin: ${leagueCode}`;
	}

	updateLeagueId(leagueId: number) {
		this._league = this._leaguesStore.getLeagueById(leagueId);
	}

	shareTw() {
		const league = this._league;

		if (league) {
			ShareUtility.share({
				message: this._shareMessage,
				type: ShareType.League,
				leagueCode: String(league.code),
				socialNetwork: SocialNetwork.Twitter,
			});
		}
	}

	shareFB() {
		const league = this._league;
		if (league) {
			ShareUtility.share({
				message: this._shareMessage,
				type: ShareType.League,
				leagueCode: String(league.code),
				socialNetwork: SocialNetwork.Facebook,
			});
		}
	}

	shareMobile() {
		const league = this._league;
		if (league) {
			ShareUtility.share({
				message: this._shareMessage,
				type: ShareType.League,
				leagueCode: String(league.code),
				socialNetwork: SocialNetwork.Mobile,
			});
		}
	}

	updateInviteState() {
		this._inviteEmailsState = RequestState.IDLE;
		this._isRegenInvitesSent = false;
	}

	init({navigate, regenerateParam}: IInit): void {
		this._navigate = navigate;
		if (regenerateParam !== null) {
			this._isRegenInvitesSent = true;
		}
	}

	dispose(): void {
		clearTimeout(this.timeout);
	}
}
