import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable} from "mobx";
import {PUBLIC_URL} from "data/constants";
import {get} from "lodash";

export interface IAuthController extends ViewController {
	get isAuthorized(): boolean;
	get wasLoggedOut(): boolean;
	get isAuthNotActive(): boolean;
	isInviteLink: (url: string) => boolean;
}

@injectable()
export class AuthController implements IAuthController {
	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	get isAuthNotActive() {
		const isActive = get(this._userStore.user, "isActive");
		return this._userStore.isAuthorized && !isActive;
	}

	get wasLoggedOut(): boolean {
		return this._userStore.wasLoggedOut;
	}

	isInviteLink(url: string) {
		const testLink = /^\/leagues\/join\/[A-Z0-9]+$/i;
		return testLink.test(url);
	}

	dispose(): void {
		return;
	}

	init(): void {
		const InvitingLeagueCode = localStorage.getItem("pendingLeague");
		if (this.isAuthorized && InvitingLeagueCode) {
			window.location.href = `${PUBLIC_URL}/leagues/join/${InvitingLeagueCode}`;
			localStorage.removeItem("pendingLeague");
		}
	}
}
