import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {Genders} from "data/types/global";
import {IUpdateUserAccount} from "views/pages/my_account/my_account.controller";

// email / password not needed for site with SSO

export interface IRegistrationPayload {
	fantasyTeamName?: string;
	// email: string;
	// password: string;
}

export interface IFantasyTeamUserUpdate {
	teamName: string;
	isGeneralNotificationsEnabled: boolean;
	isWeeklyResultsNotificationsEnabled: boolean;
	isWeeklyRemindersNotificationsEnabled: boolean;
}

export interface IUpdateUserPayload extends Omit<IRegistrationPayload, "password"> {
	gender: Genders | string;
	state: string;
	isNotificationsEnabled?: boolean;
	sponsorOptIn?: boolean;
	avatar?: File;
}

export interface IUsername {
	teamName: string;
}

export type TUserResponse = IApiResponse<{user: IUser}>;
export interface IFantasyUser {
	teamName: string;
	isGeneralNotificationsEnabled: boolean;
	isWeeklyResultsNotificationsEnabled: boolean;
	isWeeklyRemindersNotificationsEnabled: boolean;
}
export type TUserFantasyResponse = IApiResponse<IFantasyUser>;

export interface IUserApiProvider {
	register: (params: IRegistrationPayload) => Promise<AxiosResponse<TUserResponse>>;
	update: (params: IUpdateUserAccount) => Promise<AxiosResponse<TUserResponse>>;
	updateFormData: (params: FormData) => Promise<AxiosResponse<TUserResponse>>;
	updateFantasyUser: (
		params: IFantasyTeamUserUpdate
	) => Promise<AxiosResponse<TUserFantasyResponse>>;
	user: () => Promise<AxiosResponse<TUserResponse>>;
	userFantasyProfile: () => Promise<AxiosResponse<TUserFantasyResponse>>;
	check_username: (params: IUsername) => Promise<AxiosResponse>;
	check_username_private: (params: IUsername) => Promise<AxiosResponse>;
	deactivate_account: () => Promise<AxiosResponse>;
}

@injectable()
export class UserApiProvider implements IUserApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	check_username = (params: IUsername) =>
		this._http.post("public/fantasy/user-profile/check-team-name", params);
	check_username_private = (params: IUsername) => this._http.post("user/check-team-name", params);

	deactivate_account = () => this._http.post<void>("user/deactivate-account");

	register = (params: IRegistrationPayload) =>
		this._http.post<TUserResponse>("user/register", params);

	update = (params: IUpdateUserAccount) => this._http.post<TUserResponse>("user/update", params);
	updateFormData = (params: FormData) => this._http.post<TUserResponse>("user/update", params);
	updateFantasyUser = (params: IFantasyTeamUserUpdate) =>
		this._http.post<TUserFantasyResponse>("fantasy/user-profile/update", params);

	user = () => this._http.get<TUserResponse>("user");
	userFantasyProfile = () => this._http.get<TUserFantasyResponse>("fantasy/user-profile/show-my");
}
