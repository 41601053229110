import styled from "@emotion/styled";
import {Button, IconButton, Modal, Stack, Typography} from "@mui/material";
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {ModalBox} from "views/components/modals/modal_common.component";
import {useViewController} from "data/services/locator";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {Bindings} from "data/constants/bindings";
import {isEqual} from "lodash";
import {ModalType} from "data/enums";
import {getAge, getPlayerimage, getPositionFullNameJoined, nextOppStr} from "data/utils/helpers";
import {ReactComponent as CloseIcon} from "assets/img/icons/CloseIcon.svg";
import {getClubLogo, getClubPrimaryColor} from "data/utils/helpers/clubLogoGetter";
import PlayerOverview from "views/components/modal_player_sub_component/player_overview";
import MatchStats from "views/components/modal_player_sub_component/match_stats";
import {IPlayerExtended} from "views/components/team/player_pool/player_pool.controller";
import PlayerCostComp from "views/components/team/player_pool/player_cost.component";
import {Exist} from "views/components/exist/exist.component";
import {StatusSection} from "views/components/team/lineup/field/field_player.component";
import {getStatusIcon} from "views/components/team/player_pool/player_card.component";
import {IPlayerDataController} from "views/controllers/player_data/player_data.controller";

export const StackWrapper = styled(Stack)`
	flex-flow: column;
	align-items: center;
	max-width: 100%;
	margin: 0 auto;
	text-align: center;
`;

export const Heading = styled(Typography)`
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	text-align: center;
	letter-spacing: 0.2px;
	text-transform: uppercase;
`;
export const SubHeading = styled(Typography)`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 130%;
`;

const WhiteModalBox = styled(ModalBox)`
	padding: 0px;
	background: none;

	@media (min-width: 415px && max-width: 639px) {
		width: 100% !important;
	}

	@media (max-width: 414px) {
		width: 100% !important;
	}
`;

const ModalContentWrapper = styled.div`
	width: 100%;
	padding: 0px;
	overflow-y: auto;
	min-height: 254px;
	max-height: 85vh;
	background: var(--aflwbkg-light-canvas);
	border-radius: 10px 10px 10px 10px;
`;
export const ChildWRapper = styled.div`
	background: #f4f4f4;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 16px;
	gap: 20px;
	strong {
		font-weight: 700;
	}
`;

const TopWrapper = styled.div<{clubId: number}>`
	border-radius: 10px 10px 0px 0px;
	height: 200px;
	background: ${({clubId}) => getClubPrimaryColor(clubId)};
	display: flex;
	width: 100%;
	padding: 16px 16px 0px 16px;
	align-items: flex-end;
	justify-content: space-between;
	position: relative;
	.icon-close {
		z-index: 12;
		cursor: pointer;
		top: 16px;
		right: 16px;
		position: absolute;
	}
`;
const ClubLogo = styled.div<{clubId: number; isCurrentRoundIndigenous: boolean}>`
	background-image: url(${({clubId, isCurrentRoundIndigenous}) =>
		getClubLogo(clubId, isCurrentRoundIndigenous, true)});
	z-index: 1;
	background-repeat: no-repeat;
	background-size: cover;
	opacity: 0.2;
	display: block;
	position: absolute;
	top: -5px;
	right: 50px;
	height: 198px;
	width: 175px;
	color: #c4d1e5;
`;
const Playerinfo = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 26px;
`;

const PlayerImage = styled.div`
	display: flex;

	.player-img {
		margin-top: -8px;
		margin-right: -8px;
		height: 184px;
		width: auto;
		z-index: 2;
	}
`;
const PlayerName = styled.div`
	font-weight: 600;
	font-size: 16px;
	color: #ffffff;
	align-items: center;
	display: flex;

	div:first-of-type {
		margin-right: 4px;
	}
`;
const PlayerDetails = styled.div`
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	align-items: center;
	display: flex;
	margin-top: 4.5px;

	div {
		margin-right: 4px;
	}
	p {
		margin-right: 4px;
	}
`;

const MidWrapper = styled(Stack)`
	display: flex;
	padding: 12px 16px;
	justify-content: center;
	flex-direction: row;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
	background: var(--aflwbkg-light-canvas);
`;

const Selection = styled(Button)<{selected: boolean}>`
		display: flex;
		height: 30px;
		width:100%;
		padding: 12px 0px;
		align-items: center;
		flex: 1 0 0;
		text-align: center;
		border: unset;
		font-weight: 700;
		font-size: 16px;
		line-heught:30px;
		padding: 5px 0px;
		border-radius: 100px;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
		background :${({selected}) => (selected ? "var(--aflwPurple);" : "var(--textWhite);")}
		color: ${({selected}) => (selected ? " var(--textWhite);" : " var(--textBlack);")}
		&:hover {
			background: var(--aflwDarkPurple);
			color: var(--textWhite);
		}
`;

const ContentCollapse = styled.div`
	width: 100%;
	height: 463px;
	overflow: overlay;
`;

export const ModalPlayer: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController, {});
	const playerStatController = useViewController<IPlayerDataController>(
		Bindings.PlayerDataController
	);
	const {visibleModal, close, modalContent} = controller;
	const isVisible = isEqual(visibleModal, ModalType.MINIPLAYER);

	const [overView, setOverview] = useState(true);
	const [matchStats, setMathStats] = useState(false);
	const [selected, setSelected] = useState("overview");

	const payload = (modalContent?.payload as IPlayerExtended) || {};
	const {isLoaded} = controller;
	useEffect(() => {
		if (isVisible) {
			controller.fetchPlayerStats(payload.id);
		}
	}, [payload.id, isVisible, controller]);

	if (!isVisible) {
		return null;
	}

	const selectOverView = () => {
		setSelected("overview");
		setOverview(true);
		setMathStats(false);
	};

	const selectViewStats = () => {
		setSelected("stats");
		setOverview(false);
		setMathStats(true);
	};

	const playerImgPath = getPlayerimage(payload.id, 500, payload.squadId);

	const opponentSquadsAbbrString = nextOppStr(payload);

	const playerCostChange = playerStatController.getPlayerCostChanges(payload);
	return (
		<Modal sx={{width: 641}} open={true} onClose={close}>
			<WhiteModalBox className="modal-box">
				<ModalContentWrapper className="content-wrapper">
					<StackWrapper>
						<TopWrapper clubId={payload?.squadId || 0}>
							<IconButton
								data-desc="playermodal-close"
								sx={{padding: 0}}
								onClick={close}
								className="icon-close">
								<CloseIcon />
							</IconButton>
							<ClubLogo
								clubId={payload.squadId || 0}
								isCurrentRoundIndigenous={controller.isCurrentRoundIndigenous}
							/>
							<Playerinfo>
								<PlayerName>
									<StatusSection status={payload.status}>
										{getStatusIcon(payload.status)}
									</StatusSection>
									<div> {`${payload.firstName} ${payload.lastName}`} </div>
								</PlayerName>
								<PlayerDetails>Age: {getAge(payload.birthday)}</PlayerDetails>
								<PlayerDetails>
									{getPositionFullNameJoined(payload.position)}
								</PlayerDetails>
								<PlayerDetails>
									<div>{payload.displayPrice}</div>
									<PlayerCostComp
										cost={playerCostChange.cost_season_diff || ""}
										is_diff={true}
									/>
									<PlayerCostComp
										cost={playerCostChange.cost_round_diff || ""}
										is_diff={true}
									/>
								</PlayerDetails>
								<PlayerDetails>Next Opp: {opponentSquadsAbbrString}</PlayerDetails>
							</Playerinfo>
							<PlayerImage>
								<img
									src={playerImgPath}
									className={"player-img"}
									alt="Player Placeholder"
								/>
							</PlayerImage>
						</TopWrapper>
						<MidWrapper>
							<Selection
								data-desc="playermodal-overview"
								selected={selected === "overview"}
								onClick={selectOverView}>
								Overview
							</Selection>
							<Selection
								data-desc="playermodal-matchstats"
								selected={selected === "stats"}
								onClick={selectViewStats}>
								Match Stats
							</Selection>
						</MidWrapper>
						<ContentCollapse>
							<Exist when={overView}>
								<PlayerOverview player={payload} />
							</Exist>
							<Exist when={matchStats}>
								<MatchStats player={payload} loading={!isLoaded} />
							</Exist>
						</ContentCollapse>
					</StackWrapper>
				</ModalContentWrapper>
			</WhiteModalBox>
		</Modal>
	);
});
