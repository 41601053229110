import styled from "@emotion/styled";
import {ClickAwayListener, IconButton, useMediaQuery} from "@mui/material";
import Tooltip, {TooltipProps, tooltipClasses} from "@mui/material/Tooltip";
import {ReactComponent as InfoIcon} from "assets/img/icon-info.svg";
import React, {useState} from "react";
import {Exist} from "views/components/exist/exist.component";

const mobileViewQuery = `max-width: 760px`;

interface ICustomToolTip {
	children?: React.ReactElement;
	title: string;
}

const InfoIconSvg = styled(InfoIcon)`
	margin-left: 10px;
	height: 20px;
	width: auto;
	box-sizing:border-box @media (${mobileViewQuery}) {
		height: 1.3rem;
		width: auto;
	}
`;

const Content = styled.div`
	display: block;
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0.15px;
`;
const StyledTooltip = styled(({className, ...props}: TooltipProps) => (
	<Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "var(--aflwPurple)",
	},

	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "var(--aflwBrightPurple)",
		borderRadius: 10,
		maxWidth: 200,
		fontSize: 12,
		fontWeight: 400,
		letterSpacing: 0.15,
		padding: 12,
	},
}));

export const CustomToolTip = ({children, title}: ICustomToolTip) => {
	const isMobile = useMediaQuery("(max-width: 760px)");
	const [open, setOpen] = useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(!open);
	};
	return (
		<>
			<Exist when={!isMobile}>
				<StyledTooltip arrow title={<Content>{title}</Content>}>
					{children ? children : <InfoIconSvg />}
				</StyledTooltip>
			</Exist>

			<Exist when={isMobile}>
				<ClickAwayListener onClickAway={handleTooltipClose}>
					<StyledTooltip
						arrow
						title={<Content>{title}</Content>}
						PopperProps={{
							disablePortal: true,
						}}
						onClose={handleTooltipClose}
						open={open}
						disableTouchListener>
						{children ? (
							children
						) : (
							<IconButton style={{padding: 0}} onClick={handleTooltipOpen}>
								<InfoIconSvg />{" "}
							</IconButton>
						)}
					</StyledTooltip>
				</ClickAwayListener>
			</Exist>
		</>
	);
};
