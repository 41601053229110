import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {SortOrder, PlayerPosition, PlayerStatus, UTILITY} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {type ITeamApiProvider} from "data/providers/api/team.api.provider";
import {type IUserStore} from "data/stores/user/user.store";
import {type IPlayersStore, type IPoolFilters} from "data/stores/players/players.store";
import React, {ChangeEvent} from "react";
import {SelectChangeEvent} from "@mui/material";
import {get, isArray, xor} from "lodash";
import {isAllTrue} from "data/utils/helpers";

export interface ISelectOption {
	val: string;
	label: string;
	labelShort?: string;
	liveStat?: boolean;
}

export interface IStatusOption {
	val: string | PlayerStatus;
	label: string;
}

/* 
set default to "0" to allow for mui
label to work
*/
export const defaultFilters = {
	position: [],
	squad: "0",
	price: "0",
	stat: "avgPoints",
	favourites: false,
	status: "all",
	doubleGameWeek: false,
};

export interface ITeamBuilderStore {
	get filters(): IPoolFilters;

	get searchFilter(): string;

	get priceOptions(): ISelectOption[];

	get statsOptions(): ISelectOption[];

	get statusOptions(): ISelectOption[];

	get selectedStatLabel(): string;

	get order(): SortOrder;

	setSelectedFieldPosition: (position: PlayerPosition | null) => void;
	resetPosition: () => void;
	setPosition: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	updateFilter: (e: SelectChangeEvent | SelectChangeEvent<string[]>) => void;
	updateSearch: (e: ChangeEvent<HTMLInputElement>) => void;
	updateStatField: (stat: string) => void;
	sortPlayers: () => void;
	resetFilters: () => void;
	resetSearch: () => void;
	setFavourite: () => void;
	updateTeamFilter: (e: SelectChangeEvent | SelectChangeEvent<string[]>) => void;
	updateStatusFilter: (e: SelectChangeEvent | SelectChangeEvent<string | PlayerStatus>) => void;
	forcePositionFilterChange: (position: PlayerPosition | null) => void;
	toggleDoubleGameWeek: () => void;
}

const DISPLAYINITIAL = "Average Pts";

@injectable()
export class TeamBuilderStore implements ITeamBuilderStore {
	get isPlayerPoolVisible(): boolean {
		return this._isPlayerPoolVisible;
	}
	@observable private _isPlayerPoolVisible: boolean = false;
	@observable private _selectedFieldPosition: null | PlayerPosition = null;
	@observable private _order: SortOrder = SortOrder.DESC;

	@observable private _filters: IPoolFilters = {
		...defaultFilters,
	};
	@observable private _searchFilter: string = "";

	@observable _selectedStatLabel: string = DISPLAYINITIAL;

	private _priceOptions = [
		{val: "0", label: "All Prices"},
		{val: "afford", label: "Players I can afford"},
		{val: "300000-399000", label: "$300K - $399K"},
		{val: "400000-499000", label: "$400K - $499K"},
		{val: "500000-599000", label: "$500K - $599K"},
		{val: "600000-699000", label: "$600K - $699K"},
		{val: "700000-799000", label: "$700K - $799K"},
		{val: "800000-899000", label: "$800K - $899K"},
		{val: "900000-999999", label: "$900K - $999K"},
		{val: "1000000-3000000", label: "$1M+"},
	];

	// const prices = [
	// 	{ key: "200000-299000", value: "$200K - $299K" },
	// 	{ key: "300000-399000", value: "$300K - $399K" },
	// 	{ key: "400000-499000", value: "$400K - $499K" },
	// 	{ key: "500000-599000", value: "$500K - $599K" },
	// 	{ key: "600000-699000", value: "$600K - $699K" },
	// 	{ key: "700000-799000", value: "$700K - $799K" },
	// 	{ key: "800000-899000", value: "$800K - $899K" },
	// 	{ key: "900000-9999999", value: "$900K+"}
	// ];

	private _statsOptions = [
		{val: "avgPoints", label: DISPLAYINITIAL, labelShort: "Avg"},
		{val: "cost", label: "$ Price", labelShort: "Price"},
		{val: "selections", label: "% Selected", labelShort: "% Sel"},
		{val: "totalPoints", label: "Total Pts", labelShort: "Total"},
		{val: "roundPoints", label: "Week Pts", labelShort: "Week"},
	];

	private _statusOptions = [
		{val: "all", label: "All Statuses"},
		{val: PlayerStatus.Playing, label: "Playing"},
		{val: PlayerStatus.Emergency, label: "Emergency"},
		{val: PlayerStatus.Injured, label: "Injured"},
		{val: PlayerStatus.NotPlaying, label: "Not Playing"},
		{val: PlayerStatus.Uncertain, label: "Uncertain"},
		{val: PlayerStatus.UncertainGame2, label: "Uncertain G2"},
		{val: "Favourite", label: "Favourites"},
	];

	constructor(
		@inject(Bindings.TeamApiProvider) private _teamApiProvider: ITeamApiProvider,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore
	) {
		makeAutoObservable(this);
	}

	get order() {
		return this._order;
	}

	get statusOptions() {
		return this._statusOptions;
	}

	get statsOptions() {
		return this._statsOptions;
	}

	get filters(): IPoolFilters {
		return this._filters;
	}

	get searchFilter(): string {
		return this._searchFilter;
	}

	get priceOptions() {
		return this._priceOptions;
	}

	get selectedStatLabel(): string {
		return this._selectedStatLabel;
	}

	@action
	setSelectedFieldPosition = (position: PlayerPosition | null) => {
		if (position) {
			this._filters = {
				...this._filters,
				position: xor(this._filters.position, [position]),
			};
		}
	};

	@action
	toggleDoubleGameWeek = () => {
		const currentValue = this._filters.doubleGameWeek;
		runInAction(() => {
			this._filters.doubleGameWeek = !currentValue;
		});
	};

	@action
	updateStatField = (stat: string) => {
		if (this._filters.stat === stat) {
			const newOrder = this._order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
			this._order = newOrder;
			return;
		}
		this._filters.stat = stat;
		this._order = SortOrder.DESC;
	};

	@action
	sortPlayers = () => {
		this._order = this._order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
	};

	@action
	resetFilters = () => {
		this._searchFilter = "";
		this._filters = {
			...defaultFilters,
		};
		this._order = SortOrder.DESC;
		this._selectedStatLabel = DISPLAYINITIAL;
	};

	@action
	resetSearch = () => {
		this._searchFilter = "";
	};

	@action
	setFavourite = () => {
		this._filters.favourites = !this._filters.favourites;
	};

	@action
	resetPosition = () => {
		this._filters = {
			...this._filters,
			position: [],
		};
	};

	@action
	setPosition = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const position = (e.currentTarget.value as PlayerPosition | UTILITY.UTIL) || "";
		if (position === UTILITY.UTIL) {
			this._filters = {
				...this._filters,
				position: [],
			};
			return;
		}
		this._filters = {
			...this._filters,
			position: xor(this._filters.position, [position]),
		};
	};

	@action
	updateFilter = (
		e: SelectChangeEvent | SelectChangeEvent<string[]>,
		...rest: {props: {value: string}}[]
	) => {
		const {value, name} = e.target;
		const val = rest ? rest[0]?.props?.value : undefined;
		const isAllSelected = val === "null";
		const isAffordSelected = val === "afford";
		let newValue = isAllSelected ? ["null"] : value;

		if (isAffordSelected) {
			newValue = ["afford"];
		} else if (
			isAllTrue([Boolean(value.length), value.includes("afford")]) &&
			isArray(newValue)
		) {
			newValue = newValue.filter((val) => val !== "afford");
		}

		this._filters = {
			...this._filters,
			[name]: newValue,
		};

		if (name === "stat") {
			this._selectedStatLabel = get(
				this._statsOptions.find(({val}) => val === value),
				"label",
				""
			);
			this._order = SortOrder.DESC;
		}
	};

	@action
	updateTeamFilter = (e: SelectChangeEvent | SelectChangeEvent<string[]>) => {
		const {value} = e.target;
		const val = Array.isArray(value) ? value[0] : value;
		this._filters = {
			...this._filters,
			squad: val,
		};
	};

	@action
	updateStatusFilter = (e: SelectChangeEvent | SelectChangeEvent<string | PlayerStatus>) => {
		const {value} = e.target;
		this._filters = {
			...this._filters,
			status: value,
		};
	};

	@action
	forcePositionFilterChange = (position: PlayerPosition | null) => {
		if (position === null) {
			this._filters = {
				...this.filters,
				position: [],
			};
			return;
		}
		this._filters = {
			...this.filters,
			position: [position],
		};
	};

	updateSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const {value} = e.target;

		this._searchFilter = value;
	};
}
