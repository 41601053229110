import React from "react";
import {NavLink} from "react-router-dom";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ILoginController} from "views/pages/login/login.controller";
import telstra from "assets/img/telstra.png";
import {ReactComponent as Account} from "assets/img/icons/account.svg";
import {ReactComponent as OpenNewRounded} from "assets/img/OpenInNewRoundedSmall.svg";
import {IAuthController} from "views/controllers/auth/auth.controller";
import {ButtonPure} from "views/components/buttons/buttons.component";
import {ISecretGateController} from "views/controllers/secrete_gate/secret_gate.controller";
import {Exist} from "views/components/exist/exist.component";

const Wrapper = styled.div`
	background-color: var(--aflwDarkPurple);
	color: var(--textWhite);
	height: 40px;
	display: flex;
	justify-content: space-between;
`;

const TelstraWrap = styled.div`
	display: flex;
	height: 24px;
	margin: 8px 20px;
	img {
		width: 22px;
		height: 24px;
	}
`;
const TelstraTextWrap = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 10px;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	margin-left: 12px;
`;
const TelstraTextTop = styled.div`
	opacity: 0.5;
	line-height: 100%;
	letter-spacing: 1.5px;
	margin-bottom: 2px;
	margin-top: 2px;
`;
const TelstraTextBottom = styled.div`
	font-size: 11px;
`;
const Left = styled.div`
	display: flex;
`;

const baseLinkStyle = `
color: var(--textWhite);
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 700;
text-decoration: none;
text-transform: uppercase;`;

const SiteLinks = styled.div`
	display: flex;
	height: 32px;
	margin-top: auto;
	a {
		padding: 8px 12px 12px;
	}
	@media (max-width: 380px) {
		display: none;
	}
`;

const CurrentSite = styled.div`
	border-radius: 4px 4px 0px 0px;
	background: var(--aflwPurple);
	padding: 8px 12px 12px;
	${baseLinkStyle}
`;
const Right = styled.div`
	margin-right: 20px;
	gap: 32px;
	display: flex;
	a {
		height: 14px;
		margin: auto;
		gap: 10px;
		display: flex;
	}
	@media (max-width: 768px) {
		display: none;
	}
`;

const InternalLink = styled(NavLink)`
	${baseLinkStyle}
`;

const ExternalLink = styled.a`
	${baseLinkStyle}
`;

const LoginButton = styled(ButtonPure)`
	${baseLinkStyle};
	height: 14px;
	margin: auto;
	gap: 10px;
	display: flex;
	line-height: 12px;
`;

export const HeaderAFL: React.FC = observer(() => {
	const {isSecretPassed} = useViewController<ISecretGateController>(
		Bindings.SecretGateController
	);
	const {getLoginLink} = useViewController<ILoginController>(Bindings.LoginController);
	const {isAuthorized} = useViewController<IAuthController>(Bindings.AuthController);
	return (
		<Wrapper>
			<Left>
				<TelstraWrap>
					<img src={telstra} alt="Telstra Logo" />
					<TelstraTextWrap>
						<TelstraTextTop>Created By</TelstraTextTop>
						<TelstraTextBottom>Telstra</TelstraTextBottom>
					</TelstraTextWrap>
				</TelstraWrap>
				<SiteLinks>
					<CurrentSite>AFLW Fantasy</CurrentSite>
					<ExternalLink href={"https://aflwtipping.afl/"} target="_blank">
						{`AFLW Tipping`}
					</ExternalLink>
				</SiteLinks>
			</Left>
			<Right>
				<Exist when={isSecretPassed}>
					{isAuthorized ? (
						<InternalLink data-desc="account-top-nav" to="/my-account">
							<span>Account</span>
							<Account />
						</InternalLink>
					) : (
						<LoginButton data-desc="login-top-nav" onClick={getLoginLink}>
							<span>Login</span>
							<Account />
						</LoginButton>
					)}
					<ExternalLink href="https://www.womens.afl/" target="_blank">
						<span>womens.afl</span>
						<OpenNewRounded />
					</ExternalLink>
				</Exist>
			</Right>
		</Wrapper>
	);
});
