import styled from "@emotion/styled";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import {ReactComponent as CodeIcon} from "assets/img/icon-code.svg";
import {ReactComponent as LinkIcon} from "assets/img/icon-link.svg";
import {ReactComponent as FacebookIcon} from "assets/img/icon-facebook.svg";
import {ReactComponent as Twitter} from "assets/img/icon-twitter.svg";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ShareIcon from "@mui/icons-material/Share";
import {
	FormControl,
	CircularProgress,
	useMediaQuery,
	Snackbar,
	IconButton,
	Stack,
} from "@mui/material";
import {RequestState} from "data/enums";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import React, {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {ButtonSecondary, ButtonWithIcon} from "views/components/buttons/buttons.component";
import {IInviteLeagueController} from "views/components/leagues/league_invite_form/league.invite_form.controller";
import {Bindings} from "data/constants/bindings";
import {
	borderColor,
	StepTitle,
	TextFieldExtended,
} from "views/pages/create_league/create_league.page";
import {Exist} from "views/components/exist/exist.component";

const mobileViewQuery = `max-width: 730px`;

const SendInviteButton = styled(ButtonSecondary)`
	padding: 7px 40px;

	@media (${mobileViewQuery}) {
		width: 100%;
		max-width: 100%;
	}
`;

const Buttons = styled.div`
	display: flex;
	padding-bottom: 10px;
	gap: 20px;
	max-width: 535px;

	button {
		margin-top: 0;
	}

	@media (${mobileViewQuery}) {
		flex-flow: column;
		max-width: 100%;
	}
`;

const CodeIconSvg = styled(CodeIcon)`
	margin-left: 10px;
`;

const LinkIconSvg = styled(LinkIcon)`
	margin-left: 10px;
`;

const FacebookIconSvg = styled(FacebookIcon)`
	margin-left: 10px;
`;

const TwitterIconSvg = styled(Twitter)`
	margin-left: 10px;
	path {
		fill: rgb(106, 21, 209);
	}
`;

interface IProps {
	leagueId?: number;
	regenerateParam: string | null;
}

export const LeagueInviteForm: React.FC<IProps> = observer(({leagueId = 0, regenerateParam}) => {
	const controller = useViewController<IInviteLeagueController>(Bindings.InviteLeagueController, {
		navigate: useNavigate(),
		regenerateParam,
	});

	useEffect(() => {
		controller.updateLeagueId(leagueId);
	}, [leagueId, controller]);

	const isMobile = useMediaQuery(`(${mobileViewQuery})`);

	const isSupportMobileShare = "share" in navigator && isMobile;

	const copyCodeHandler = useCallback(() => {
		void controller.copyCode();
	}, [controller]);

	const copyLinkHandler = useCallback(() => {
		void controller.copyLink();
	}, [controller]);

	return (
		<div>
			<StepTitle>Invite friends to your league</StepTitle>
			<Buttons>
				<FormControl>
					<ButtonWithIcon onClick={copyCodeHandler}>
						{
							{
								[RequestState.IDLE]: "Copy Code",
								[RequestState.SUCCESS]: "Copied!",
								[RequestState.ERROR]: "Failed to copy",
								[RequestState.PENDING]: "Copying...",
							}[controller.copyCodeState]
						}
						<CodeIconSvg />
					</ButtonWithIcon>
				</FormControl>

				<FormControl>
					<ButtonWithIcon onClick={copyLinkHandler}>
						{
							{
								[RequestState.IDLE]: "Copy League Link",
								[RequestState.SUCCESS]: "Copied!",
								[RequestState.ERROR]: "Failed to copy",
								[RequestState.PENDING]: "Copying...",
							}[controller.copyLinkState]
						}
						<LinkIconSvg />
					</ButtonWithIcon>
				</FormControl>
			</Buttons>

			<form
				onSubmit={(evt: React.SyntheticEvent<HTMLFormElement>) => {
					evt.preventDefault();
					const {emails} = evt.currentTarget as unknown as {
						emails: HTMLInputElement;
					};

					void controller.inviteEmails(emails.value);

					return false;
				}}
				onChange={(evt) => {
					const {emails} = evt.currentTarget as unknown as {
						emails: HTMLInputElement;
					};

					controller.inviteCount = emails.value.length;
					controller.clearErrorMessage();
				}}>
				<Exist when={!isMobile}>
					<FormControl fullWidth>
						<StepTitle>Invite via email</StepTitle>

						<TextFieldExtended
							id="league-name"
							name="emails"
							label="Email Addresses"
							variant="standard"
							multiline
							placeholder="Enter email address separated by ‘,’ or press 'enter' for multiple"
							onChange={(event) => {
								controller.setEmail(event.target.value);
							}}
							sx={{
								"& .MuiInput-underline:before": {
									borderBottomColor: borderColor,
								},
								"& .MuiInput-underline:after": {
									borderBottomColor: borderColor,
								},
							}}
							required={true}
							error={controller.isInvalidEmails}
						/>

						<ButtonSecondary
							style={{height: "44px"}}
							type="submit"
							disabled={controller.isSendInviteDisabled}
							startIcon={
								controller.inviteEmailState === RequestState.PENDING ? (
									<CircularProgress />
								) : null
							}>
							{
								{
									[RequestState.SUCCESS]: "Invites Sent",
									[RequestState.ERROR]: "Failed",
									[RequestState.IDLE]: "Send Invites",
									[RequestState.PENDING]: "Sending...",
								}[controller.inviteEmailState]
							}
						</ButtonSecondary>
					</FormControl>
				</Exist>
			</form>

			<FormControl fullWidth>
				<StepTitle>Invite via social</StepTitle>
				{isSupportMobileShare ? (
					<SendInviteButton fullWidth onClick={() => controller.shareMobile()}>
						Share &nbsp;
						<ShareIcon />
					</SendInviteButton>
				) : (
					<Buttons>
						<SendInviteButton onClick={() => controller.shareFB()}>
							Facebook
							<FacebookIconSvg />
						</SendInviteButton>

						<SendInviteButton onClick={() => controller.shareTw()}>
							Twitter
							<TwitterIconSvg />
						</SendInviteButton>
					</Buttons>
				)}
			</FormControl>

			<Snackbar
				open={
					controller.inviteEmailState === RequestState.SUCCESS ||
					(Boolean(regenerateParam) && controller.isRegenInviteSent)
				}
				onClose={() => controller.updateInviteState()}
				message={
					<Stack direction={"row"} alignItems={"center"} spacing={2}>
						<CheckCircleSharpIcon color={"success"} />
						<span>
							{Boolean(regenerateParam) && "Previous season members "}Invites Sent
							Successfully!
						</span>
					</Stack>
				}
				action={
					<React.Fragment>
						<IconButton
							aria-label="close"
							color="inherit"
							sx={{p: 0.5}}
							onClick={() => controller.updateInviteState()}>
							<CloseSharpIcon color={"action"} />
						</IconButton>
					</React.Fragment>
				}
			/>
		</div>
	);
});
