import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, action, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IGetRankingsParams, IUserRanking} from "data/types/rankings";
import type {IPagination} from "data/types/common";
import {ModalType, RequestState} from "data/enums";
import type {IRankingsApiProvider} from "data/providers/api/rankings.api.provider";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {trackSentryErrors} from "data/utils";
import {AxiosError} from "axios";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {clone, isEmpty} from "lodash";
import {isAllTrue, isErrorStatusError} from "data/utils/helpers";
import {IApiResponse} from "data/services/http";

type IRankingsPagination = IPagination<{
	rankings: IUserRanking[];
	currentUserRanking?: IUserRanking | undefined | null;
}>;

export interface IRankingsStore {
	get isLoaded(): boolean;
	get rankings(): IRankingsPagination;
	get errorMsg(): string;
	fetchRankings(props: IGetRankingsParams): Promise<void>;
}

@injectable()
export class RankingsStore implements IRankingsStore {
	@observable protected _hasFetchedRankings: boolean = false;
	@observable private _requestState = RequestState.IDLE;
	@observable private _rankings: IRankingsPagination = {
		rankings: [],
		nextPage: false,
		currentUserRanking: null,
	};
	@observable private _errorMessage: string = "";

	get errorMsg(): string {
		return this._errorMessage;
	}
	get isLoaded() {
		return [RequestState.SUCCESS, RequestState.ERROR].includes(this._requestState);
	}

	get rankings() {
		return this._rankings;
	}

	constructor(
		@inject(Bindings.RankingsApiProvider) private _rankingsApiProvider: IRankingsApiProvider,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		const isSentryError = isErrorStatusError(error);
		if (!isSentryError) {
			trackSentryErrors(error, {}, "loadRankings");
		}
		this._errorMessage = error.message;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: error.message,
			errors: error.response?.data,
		});
		if (!isSentryError) {
			trackSentryErrors(error, {}, "user store API error");
		}
		this._modalsStore.showModal(ModalType.ERROR, {
			message: error.response?.data.errors[0].message || "",
		});
	};

	@action
	async fetchRankings(params: IGetRankingsParams): Promise<void> {
		if (this._requestState !== RequestState.PENDING) {
			this._requestState = RequestState.PENDING;
			const requestParams = clone(params);
			delete requestParams.nextPage;
			try {
				const {data} = await this._rankingsApiProvider.rankings(requestParams);
				if (data) {
					const rankings = data.success;
					let mergedArray: Array<IUserRanking> = [];
					let filteredRanking: IUserRanking[] = [];

					if (!isEmpty(rankings.currentUserRanking)) {
						filteredRanking = rankings.rankings.map((item) => {
							item.isCurrentLeagueUser =
								item.userId === rankings.currentUserRanking.userId;
							return item;
						});
					} else {
						filteredRanking = rankings.rankings;
					}

					if (isAllTrue([Boolean(params.nextPage), params.page !== 1])) {
						mergedArray = [...this._rankings.rankings, ...filteredRanking];
					} else {
						mergedArray = filteredRanking;
					}

					await this._roundsStore.fetchRounds();
					await this._squadsStore.fetchSquads();
					runInAction(() => {
						this._rankings = {
							...rankings,
							rankings: [...mergedArray],
						};
						this._requestState = RequestState.SUCCESS;
					});
				}
			} catch (err) {
				const error = err as AxiosError<IApiResponse>;
				this.onError(error);
			}
		}
	}
}
