import React from "react";
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {FormHelperText, Input, InputProps, useMediaQuery} from "@mui/material";

const StyledFormControl = styled(FormControl)<{width?: string}>`
	border: 1px solid #f2f2f7;
	border-radius: 4px 4px 4px 0px;
	width: ${({width}) => (width ? width : `100%`)};
	margin-top: 20px;
	input ::before {
		border-color: var(--aflwBrightPurple);
	}
	input ::after {
		border-color: var(--aflwBrightPurple);
	}
`;

const InputExtended = styled(Input)`
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	padding-top: 3px;
	& label.Mui-focused {
		color: var(--textLightGrey);
		border-color: var(--aflwPurple);
	}
`;

const TextHelper = styled(FormHelperText)`
	position: absolute;
	bottom: -20px;
`;

interface IInputProps extends InputProps {
	helperText?: string;
	width?: string;
	label?: string;
	errorText?: string;
}

const borderColor = "var(--aflwBrightPurple)";
const textColor = "var(--textLightGrey)";

export const AFLWInput: React.FC<IInputProps> = (props: IInputProps) => {
	const {width = "", label, errorText} = props;
	const isMobile = useMediaQuery("(max-width: 1000px)");

	const sizeW = isMobile ? "100%" : width;
	return (
		<StyledFormControl variant="filled" width={sizeW}>
			<InputLabel
				id="start-round-label"
				sx={{
					fontWeight: 400,
					fontSize: 16,
					"&.Mui-focused": {
						color: textColor,
					},
				}}>
				{label}
			</InputLabel>
			<InputExtended
				sx={{
					padding: "0px 12px",
					":before": {borderBottomColor: borderColor},
					":after": {borderBottomColor: borderColor},
				}}
				{...props}
			/>
			<TextHelper>{errorText}</TextHelper>
		</StyledFormControl>
	);
};
