import {PlayerPosition, UTILITY} from "data/enums";
import React from "react";
import styled from "@emotion/styled";
import {Captain} from "views/components/team/icons/captain.component";
import {ViceCaptain} from "views/components/team/icons/vice_captain.component";
import {ReactComponent as RemovePlayer} from "assets/img/icons/trash.svg";
import {ReactComponent as Locked} from "assets/img/icons/lock.svg";
import {ReactComponent as Trade} from "assets/img/icons/trade.svg";
import {ReactComponent as Swap} from "assets/img/icons/swap.svg";
import {ReactComponent as Reset} from "assets/img/icons/reset_trade.svg";
import {ReactComponent as More} from "assets/img/icons/more.svg";
import {ButtonPure} from "views/components/buttons/buttons.component";
import {Exist} from "views/components/exist/exist.component";
import {useViewController} from "data/services/locator";
import {IListPlayerController} from "views/components/team/lineup/team_player.controller";
import {Bindings} from "data/constants/bindings";
import {observer} from "mobx-react";
import {isAllTrue} from "data/utils/helpers";

const ActionWrapper = styled.div<{
	isLocked?: boolean;
	isTradeMode?: boolean;
}>`
	display: flex;
	flex-direction: column;
	height: 100%;
	position: absolute;
	left: 10px;
	justify-content: space-evenly;
	align-items: flex-start;
	transition: all 0.3s ease;
	gap: 0px;
	${({isLocked}) =>
		isLocked &&
		`
		top: 5px;
		left: 5px;
		height: fit-content;
		gap: 5px;
		
	`}
	${({isTradeMode}) =>
		isTradeMode &&
		`
		left: 6px;
		top: 6px;
		justify-content: flex-start;
	`}
`;

const MultipleActionRow = styled.div`
	display: flex;
	gap: 6px;
	min-height: 24px;
`;

const ActionButton = styled(ButtonPure)<{
	isLocked?: boolean;
	small?: boolean;
	reset?: boolean;
}>`
	width: 24px;
	height: 24px;
	background: #4a4a4a;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		width: ${({small}) => (small ? "15px" : "100%")};
		height: ${({small}) => (small ? "15px" : "100%")};
	}
	${({isLocked}) =>
		isLocked &&
		`
		background: black;
		svg {
			width: 11px;
			height: 13px;
			border-radius: 0;
			margin-left: 0px;
			margin-top: 0px;
		}
	`}
	&:disabled {
		opacity: 0.4;
		cursor: disabled;
	}
	${({reset}) =>
		reset &&
		`
		background-color: var(--removeRed);
	`}
`;

const MoreButton = styled(ActionButton)<{
	isOpen?: boolean;
}>`
	padding: 0;
	background: var(--aflwChargedCoral);
	svg {
		margin-right: 1px;
	}
	transition: all 0.3s ease;
	${({isOpen}) =>
		isOpen &&
		`
		transform: rotate(-90deg);
	`}
`;

interface IPlayerActions {
	playerId: number;
	position: PlayerPosition | UTILITY.UTIL;
	isBench: boolean;
	isUtility: boolean | undefined;
	index: number;
	isSwapDisabled: boolean;
	isPlayerIncomingTrade: boolean;
	isPlayerOutgoingTrade: boolean;
	isPlayerIncomingFromBE: boolean;
	isTradeOutDisabled: boolean;
	toggleActionsOpen: () => void;
	actionsOpen: boolean;
}

export const PlayerActions: React.FC<IPlayerActions> = observer(
	({
		playerId,
		position,
		isBench,
		isUtility,
		index,
		isSwapDisabled,
		isPlayerIncomingTrade,
		isPlayerOutgoingTrade,
		isPlayerIncomingFromBE,
		isTradeOutDisabled,
		toggleActionsOpen,
		actionsOpen,
	}) => {
		const {
			setViceCaptain,
			setCaptain,
			removeFromTeam,
			isLocked,
			isTeamStartRoundComplete,
			isPlayerAvailableForTrade,
			isCaptain,
			isTradeMode,
			removePlayerInTrade,
			removePlayerOutTrade,
			addPlayerOutTrade,
			isViceCaptain,
			isPlayerTradeSwap,
			swapAction,
			tradeSwapAction,
			tradeOutAction,
			isCaptainLocked,
			isViceCaptainLocked,
		} = useViewController<IListPlayerController>(Bindings.ListPlayerController, {
			playerId,
			isBench,
			position,
		});
		// LOCKED PLAYER ACTIONS
		if (isLocked) {
			return (
				<ActionWrapper isLocked={true}>
					<ActionButton isLocked={true}>
						<Locked />
					</ActionButton>
					<Exist when={isCaptain}>
						<ActionButton>
							<Captain isCaptain={isCaptain} />
						</ActionButton>
					</Exist>
					<Exist when={isViceCaptain}>
						<ActionButton>
							<ViceCaptain isViceCaptain={isViceCaptain} />
						</ActionButton>
					</Exist>
				</ActionWrapper>
			);
		}
		// TRADE MODE ACTIONS
		if (isTradeMode) {
			return (
				<Exist when={!isPlayerIncomingFromBE}>
					<ActionWrapper isTradeMode={true}>
						<MultipleActionRow>
							<Exist when={!isPlayerOutgoingTrade && !isPlayerIncomingTrade}>
								<ActionButton
									data-desc="field-trade"
									onClick={addPlayerOutTrade}
									value={playerId}
									disabled={isTradeOutDisabled}>
									<Trade />
								</ActionButton>
							</Exist>
							<Exist when={isPlayerOutgoingTrade}>
								<ActionButton
									data-desc="field-reset-trade-out"
									onClick={removePlayerOutTrade}
									value={playerId}
									reset={true}
									small={true}>
									<Reset />
								</ActionButton>
							</Exist>
							<Exist when={isPlayerIncomingTrade}>
								<ActionButton
									data-desc="field-reset-trade-in"
									onClick={removePlayerInTrade}
									value={playerId}
									reset={true}
									small={true}>
									<Reset />
								</ActionButton>
							</Exist>
							<Exist when={isPlayerTradeSwap}>
								<ActionButton
									data-desc="field-swap-player"
									value={playerId}
									data-index={index}
									onClick={tradeSwapAction}
									disabled={isSwapDisabled}
									aria-label="Swap Players position">
									<Swap />
								</ActionButton>
							</Exist>
						</MultipleActionRow>
						<MultipleActionRow />
					</ActionWrapper>
				</Exist>
			);
		}
		// DEFAULT ACTIONS
		return (
			<ActionWrapper>
				<MultipleActionRow>
					<MoreButton
						aria-label="Open player actions"
						data-desc="field-more-actions"
						onClick={toggleActionsOpen}
						isOpen={actionsOpen}>
						<More />
					</MoreButton>
				</MultipleActionRow>

				<MultipleActionRow>
					<Exist when={actionsOpen}>
						<Exist when={!isBench}>
							<Exist when={!isUtility}>
								<Exist
									when={isAllTrue([!isCaptain, !isCaptainLocked]) || isCaptain}>
									<ActionButton
										data-desc="field-captain"
										aria-label="Set Player as Captain"
										onClick={setCaptain}
										value={playerId}>
										<Captain isCaptain={isCaptain} />
									</ActionButton>
								</Exist>
								<Exist
									when={
										isAllTrue([!isViceCaptain, !isViceCaptainLocked]) ||
										isViceCaptain
									}>
									<ActionButton
										data-desc="field-vc"
										aria-label="Set Player as Vice Captain"
										onClick={setViceCaptain}
										value={playerId}>
										<ViceCaptain isViceCaptain={isViceCaptain} />
									</ActionButton>
								</Exist>
							</Exist>
						</Exist>
						<Exist when={isPlayerAvailableForTrade && !isPlayerIncomingFromBE}>
							<ActionButton
								data-desc="field-trade"
								onClick={tradeOutAction}
								value={playerId}
								aria-label="Trade out player">
								<Trade />
							</ActionButton>
						</Exist>
						<Exist when={!isTeamStartRoundComplete}>
							<ActionButton
								data-desc="field-remove-player"
								onClick={removeFromTeam}
								value={playerId}
								aria-label="Remove Player from team">
								<RemovePlayer />
							</ActionButton>
						</Exist>
						<ActionButton
							data-desc="field-swap-player"
							value={playerId}
							data-index={index}
							onClick={swapAction}
							disabled={isSwapDisabled}
							aria-label="Swap Players position">
							<Swap />
						</ActionButton>
					</Exist>
				</MultipleActionRow>
			</ActionWrapper>
		);
	}
);
