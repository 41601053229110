import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {Bindings} from "data/constants/bindings";
import {MatchStatus, RoundStatus} from "data/enums";
import {first} from "lodash";

export interface IRoundTimerController extends ViewController {
	get isRoundLive(): boolean;
	get isRoundScheduled(): boolean;
	get isRoundComplete(): boolean;
	get isRoundOneStarted(): boolean;
	get nextRoundStartDate(): string;
	get actualRoundId(): number | undefined;
	get isSeasonComplete(): boolean;
	fetchRoundsWithDelay: () => void;
}

@injectable()
export class RoundTimerController implements IRoundTimerController {
	constructor(@inject(Bindings.RoundsStore) public _roundStore: IRoundsStore) {
		makeAutoObservable(this);
	}

	@observable private _isSoftLock: boolean = false;

	get isRoundLive() {
		const currentRound = this._roundStore.currentRound;
		return currentRound?.status === RoundStatus.Playing;
	}

	get isSeasonComplete() {
		return this._roundStore.isSeasonComplete;
	}

	get isRoundScheduled() {
		const currentRound = this._roundStore.currentRound;
		return currentRound?.status === RoundStatus.Scheduled;
	}

	get isRoundComplete() {
		const currentRoundMatches = this._roundStore.currentRound?.tournaments;
		const roundMatchesLength = currentRoundMatches?.length;
		return (
			currentRoundMatches?.filter((match) => match.status === MatchStatus.Complete).length ===
			roundMatchesLength
		);
	}

	get isRoundOneStarted() {
		const roundOne = this._roundStore.list.find((round) => round.id === 1);
		return roundOne?.status !== RoundStatus.Scheduled;
	}

	get isSoftLock(): boolean {
		return this._isSoftLock;
	}

	get isCompleteState(): boolean {
		return this._roundStore.selectedRound?.status === RoundStatus.Complete;
	}

	get nextRoundStartDate(): string {
		return first(this._roundStore.scheduleRounds)?.startDate || new Date().toISOString();
	}

	get actualRoundId() {
		return this._roundStore.currentRound?.id;
	}

	@action
	fetchRoundsWithDelay = () => {
		void this._roundStore.fetchRounds();
		runInAction(() => {
			this._isSoftLock = true;
		});

		setTimeout(() => {
			void this._roundStore.fetchRounds();
		}, 10000);
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
