export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum PlayerPosition {
	DEF = "DEF",
	MID = "MID",
	RUC = "RUC",
	FWD = "FWD",
}

export enum UTILITY {
	UTIL = "utility",
}

export enum PlayerStatus {
	Playing = "playing",
	Emergency = "emergency",
	Uncertain = "uncertain",
	UncertainGame2 = "uncertain_game_2",
	NotPlaying = "not-playing",
	Injured = "injured",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum MatchStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
	Partial = "partial",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
	Link,
}

export enum ShareType {
	General = "general",
	League = "league",
	Team = "team",
	Trade = "trade",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum RankingType {
	All = "all",
	State = "state",
	Club = "club",
}

export enum LeagueClass {
	Regular = "regular",
	Overall = "overall",
}

export enum LeagueStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum LeagueType {
	All = "all",
	Private = "private",
	Public = "public",
}

export enum ModalType {
	ERROR,
	LEAGUE_JOINED,
	LEAGUE_REMOVE_USER,
	LEAGUE_LEAVE,
	LEAGUE_REMOVE,
	GENERAL,
	MINIPLAYER,
	ERROR_USER_TEAM,
}

export enum LeagueH2H {
	Open = "open",
	H2H = "h2h",
}

export enum IDirection {
	ASC = "ASC",
	DESC = "DESC",
}

export enum TradePlayer {
	IN = "in",
	OUT = "out",
}
