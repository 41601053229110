import {LeagueH2H, RequestState, RoundStatus} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {ILeague, ILeagueUser} from "data/types/leagues";
import {IRound, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {useNavigate} from "react-router-dom";
import {IPagination} from "data/types/common";
import {get, max} from "lodash";

interface IInit {
	leagueId: number;
	navigate?: ReturnType<typeof useNavigate>;
}

export interface IFixturesLeagueController extends ViewController<IInit> {
	get league(): ILeague | undefined;
	get isCommissioner(): boolean;
	get userId(): number | undefined;
	get roundList(): IRound[];
	get scheduledrounds(): IRound[];
	get leagueUser(): IPagination<{users: ILeagueUser[]}>;
	get leagueFixtureSelectedRound(): number;
	get orderedFixture(): {fixture: number[]; matchupIndex: number}[];
	get isSelectedRoundStarted(): boolean;

	getTeamScore: (userId: number) => number | string;

	getleagueUserById: (teamId: number) => ILeagueUser | undefined;
	getLeagueUserRankingForm: (userId: number) => {
		rank: number;
		wins: number;
		losses: number;
		draw: number;
	};
}

@injectable()
export class FixturesLeagueController implements IFixturesLeagueController {
	private _league: ILeague | undefined;
	private _navigate: IInit["navigate"];

	@observable _requestStateLeave: RequestState = RequestState.IDLE;

	@observable protected _hasFetchedJSONS: boolean = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@action
	async checkFetchJSONs(): Promise<void> {
		if (this._hasFetchedJSONS) {
			return;
		}
		this._hasFetchedJSONS = true;
		await Promise.all([this._roundsStore.fetchRounds()]);
	}

	get league(): ILeague | undefined {
		return this._league;
	}

	get leagueUser(): IPagination<{users: ILeagueUser[]}> {
		return this._leaguesStore.leagueUsers;
	}

	get isSelectedRoundStarted() {
		const selectedRoundId = this._roundsStore.leagueFixtureSelectedRound;
		const round = this._roundsStore.roundsById[selectedRoundId];
		return round?.status !== RoundStatus.Scheduled;
	}

	get orderedFixture() {
		const selectedRound = this.leagueFixtureSelectedRound;
		const roundFixture = get(this._league?.fixture, selectedRound, []);
		return roundFixture
			.map((fixture, index) => {
				if (fixture.includes(this.usersTeamId)) {
					const isHome = fixture[0] === this.usersTeamId;
					const opponentId = isHome ? fixture[1] : fixture[0];
					return {fixture: [this.usersTeamId, opponentId], matchupIndex: index};
				}
				return {fixture, matchupIndex: index};
			})
			.sort((matchupA, matchupB) => {
				if (matchupA.fixture.includes(this.usersTeamId)) {
					return -1;
				}
				if (matchupB.fixture.includes(this.usersTeamId)) {
					return 1;
				}
				return 1;
			});
	}

	get isCommissioner(): boolean {
		return Number(this.userId) === Number(this.league?.leagueCommissioner?.userId);
	}

	get userId(): number | undefined {
		return this._userStore.user?.id;
	}

	get roundList(): IRound[] {
		return this._roundsStore.list;
	}
	get usersTeamId() {
		return this.leagueUser.users.find((user) => user.userId === this.userId)?.teamId || 0;
	}

	get scheduledrounds(): IRound[] {
		return this._roundsStore.scheduleRounds;
	}

	get leagueFixtureSelectedRound(): number {
		return max([this._roundsStore.leagueFixtureSelectedRound, this.league?.startId]) || 1;
	}

	getleagueUserById = (teamId: number): ILeagueUser | undefined => {
		return this.leagueUser.users.find((user) => {
			return user.teamId === teamId;
		});
	};

	getTeamScore = (teamId: number) => {
		const teams = this._leaguesStore.leagueTeamRankings;
		const selectedRound = this.leagueFixtureSelectedRound;
		const teamForScore = teams.find((team) => team.teamId === teamId);
		const h2hTeamScore = this._leaguesStore.matchupTeams
			.flat()
			.find((team) => team.teamId === teamForScore?.teamId);
		if (h2hTeamScore) {
			return get(h2hTeamScore, ["scoreFlow", selectedRound], "-");
		}
		return "-";
	};

	getLeagueUserRankingForm = (teamId: number) => {
		const leagueRankingsArr = this._leaguesStore.leagueTeamRankings;
		const team = leagueRankingsArr.find((team) => team.teamId === teamId);
		return {
			rank: team?.overallRank || 0,
			wins: team?.WDL.win || 0,
			losses: team?.WDL.lose || 0,
			draw: team?.WDL.draw || 0,
		};
	};

	async init(params: IInit) {
		await this._roundsStore.fetchRounds();
		const league = this._leaguesStore.getLeagueById(params.leagueId);
		this._league = league;
		const currentRound = this._roundsStore.currentRound?.id || 1;
		const round =
			currentRound < Number(league?.startId)
				? Number(league?.startId)
				: this._roundsStore.leagueFixtureSelectedRound;

		if (league?.type.toLowerCase() === LeagueH2H.H2H.toLowerCase()) {
			await this._leaguesStore.fetchLeagueTeamRankings({
				leagueId: params.leagueId,
				roundId: round,
			});
			await this._leaguesStore.fetchMatchups(round, params.leagueId);
		} else {
			await this._leaguesStore.fetchLeagueRankings({leagueId: params.leagueId});
		}

		this._navigate = params.navigate;
		await this._leaguesStore.fetchLeagueUsers(params.leagueId);
	}

	dispose(): void {
		return;
	}
}
