import {RequestState} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {ILeague} from "data/types/leagues";
import {IRound, type IRoundsStore} from "data/stores/rounds/rounds.store";

interface IInit {
	leagueId: number;
}

export interface ISettingsLeagueController extends ViewController<IInit> {
	get league(): ILeague | undefined;
	get leagueName(): string | undefined;
	get isCommissioner(): boolean;
	get userId(): number | undefined;
	get roundList(): IRound[];
	get scheduledrounds(): IRound[];
}

@injectable()
export class SettingsLeagueController implements ISettingsLeagueController {
	private _league: ILeague | undefined;

	@observable _requestStateLeave: RequestState = RequestState.IDLE;

	@observable protected _hasFetchedJSONS: boolean = false;
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@action
	async checkFetchJSONs(): Promise<void> {
		if (this._hasFetchedJSONS) {
			return;
		}
		this._hasFetchedJSONS = true;
		await Promise.all([this._roundsStore.fetchRounds()]);
	}

	get league(): ILeague | undefined {
		return this._league;
	}

	get leagueName(): string | undefined {
		return this._league?.name || "";
	}

	get isCommissioner(): boolean {
		return Number(this.userId) === Number(this.league?.leagueCommissioner?.userId);
	}

	get userId(): number | undefined {
		return this._userStore.user?.id;
	}

	get roundList(): IRound[] {
		return this._roundsStore.list;
	}

	get scheduledrounds(): IRound[] {
		return this._roundsStore.scheduleRounds;
	}
	async init(params: IInit) {
		await this.checkFetchJSONs();
		this._league = this._leaguesStore.getLeagueById(params.leagueId);
	}

	dispose(): void {
		return;
	}
}
