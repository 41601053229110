import React, {CSSProperties, Fragment} from "react";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import {type IPlayerExtended} from "./player_pool.controller";
import {Exist} from "views/components/exist/exist.component";
import {ButtonPure} from "views/components/buttons/buttons.component";
import PlayerCostComp from "./player_cost.component";
import {ReactComponent as AddPlayer} from "assets/img/icons/add_player.svg";
import {ReactComponent as RemovePlayer} from "assets/img/icons/trash.svg";
import {ReactComponent as Injured} from "assets/img/icons/injured.svg";
import {ReactComponent as NotSelected} from "assets/img/icons/not_selected.svg";
import {ReactComponent as SelectedEmg} from "assets/img/icons/selected_emergency.svg";
import {ReactComponent as Selected} from "assets/img/icons/selected.svg";
import {ReactComponent as Uncertain} from "assets/img/icons/uncertain.svg";
import {ReactComponent as UncertainG2} from "assets/img/icons/uncertainG2.svg";
import {ReactComponent as Locked} from "assets/img/icons/lock.svg";
import {PlayerStatus} from "data/enums";
import {ReactComponent as Trade} from "assets/img/icons/trade.svg";
import {ReactComponent as Reset} from "assets/img/icons/reset_trade.svg";
import {ReactComponent as ArrowRight} from "assets/img/icons/arrow_right.svg";
import {ReactComponent as ArrowLeft} from "assets/img/icons/arrow_left.svg";

import {IPlayer} from "data/stores/players/players.store";
import {
	getPlayerName,
	getPlayerPositionText,
	getPlayerStatusColors,
	isAllTrue,
	isAnyTrue,
} from "data/utils/helpers";
import {getClubLogo} from "data/utils/helpers/clubLogoGetter";
import {useViewController} from "data/services/locator";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {Bindings} from "data/constants/bindings";
import {PlayerIcon} from "views/components/team/lineup/field/field_player.component";
import Separator from "views/components/separator/separator.component";
import {PLAYER_PLACEHOLDER_IMG_URL} from "data/constants";
import {get} from "lodash";
import {useMediaQuery} from "@mui/material";
import {IPlayerDataController} from "views/controllers/player_data/player_data.controller";

const Wrapper = styled.div<{
	isInTeam: boolean;
	isPlayerIncomingTrade?: boolean;
	isPlayerOutgoingTrade?: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	border-bottom: 1px solid #e9ebed;
	background: #fff;
	padding: 0 4px 0 0px;
	width: 100%;
	${({isInTeam}) =>
		isInTeam &&
		`
		background: rgba(255, 94, 68, 0.05);
	`}
	${({isPlayerOutgoingTrade}) =>
		isPlayerOutgoingTrade &&
		`
		background: linear-gradient(90deg, rgba(227, 37, 37, 0.2) 0%, rgba(255, 255, 255, 0) 98.37%), #FFFFFF;
	`}
	${({isPlayerIncomingTrade}) =>
		isPlayerIncomingTrade &&
		`
		background: linear-gradient(90deg, rgba(32, 183, 65, 0.2) 0%, rgba(255, 255, 255, 0) 98.37%), #FFFFFF;
	`}
	@media(max-width: 825px) {
		padding-right: 0px;
	}
	@media (max-width: 640px) {
		padding-right: 18px;
	}
`;

export const StatusSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	position: absolute;
	left: 10px;
	top: calc(50% - 12px);
	width: 24px;
	padding-left: 10px;
	height: 100%;
	@media (max-width: 825px) {
		width: 0;
	}
`;

export const StatusIcon = styled.div`
	height: 24px;
	width: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #383a36;
	border-radius: 50%;
	svg {
		width: 18px;
		height: 18px;
		path {
			fill: white;
		}
	}
`;

export const SmallStatusIcon = styled(StatusIcon)<{
	status: PlayerStatus;
	isPlayerIncoming?: boolean;
}>`
	height: 16px;
	width: 16px;
	margin-right: 5px;
	background: ${({status}) => getPlayerStatusColors(status)};
	${({isPlayerIncoming}) =>
		isPlayerIncoming &&
		`
		margin-right: 0;
		margin-left: 5px;
	`}
`;

export const PlayerHeadshot = styled.div<{
	fromPool?: boolean;
	empty?: boolean;
}>`
	width: 77px;
	height: 100%;

	z-index: 1;
	img {
		width: 75px;
		height: auto;
		position: relative;
		${({fromPool}) =>
			fromPool &&
			`
			top: -2px;
		`}
	}
	${({empty}) =>
		empty &&
		`
		img{
			width: 64px;
			top: 7px;
		}
	`}
`;

const FlexCol = styled.div<{
	reverseInfoSection: boolean;
	isMatchupView: boolean;
}>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-evenly;
	gap: 2px;
	${({reverseInfoSection}) =>
		reverseInfoSection &&
		`
		align-items: flex-end;
	`}
	${({isMatchupView}) =>
		isMatchupView &&
		`
		gap: 0;
		justify-content: center;
		padding-top: 5px;
	`}
`;

const PlayerName = styled(ButtonPure)<{
	reverseInfoSection: boolean;
	isMatchupView?: boolean;
}>`
	font-size: 14px;
	font-weight: 700;
	color: var(--textBlack);
	margin-bottom: 8px;
	line-height: 14px;
	width: 135px;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: left;
	overflow: hidden;
	${({reverseInfoSection}) =>
		reverseInfoSection &&
		`
		width: unset;
		text-align:  right;
	`}
	@media(max-width: 825px) {
		width: 75px;
	}
	@media (max-width: 640px) {
		width: 135px;
		${({isMatchupView}) =>
			isMatchupView &&
			`
			width: unset;
		`}
	}
`;

const FlexRow = styled.div<{
	reverseInfoSection?: boolean;
	hidePaddingRight?: boolean;
}>`
	display: flex;
	align-items: center;
	padding-right: 18px;
	${({reverseInfoSection}) => reverseInfoSection && "padding-right: 0;"}
	${({hidePaddingRight}) => hidePaddingRight && `padding-right: 0;`}
`;

const StatActionRow = styled.div`
	display: flex;
	align-items: center;
	width: 25%;
	@media (max-width: 825px) {
		width: 32%;
	}
	@media (max-width: 640px) {
		width: 25%;
	}
`;

const FlexRowGap = styled(FlexRow)<{
	clubId: number;
	isCurrentRoundIndigenous: boolean;
}>`
	gap: 5px;
	width: 75%;
	position: relative;

	&::before {
		content: " ";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100px;
		height: 100%;
		opacity: 0.2;
		background: url(${({clubId, isCurrentRoundIndigenous}) =>
				getClubLogo(clubId, isCurrentRoundIndigenous)})
			no-repeat;
		background-size: 120px auto;
		background-position: -40px;
	}
	@media (max-width: 825px) {
		width: 50%;
	}
	@media (max-width: 640px) {
		width: 75%;
	}
`;

const FlexRowMarginBottom = styled(FlexRow)<{
	isMatchupView: boolean;
}>`
	margin-bottom: 5px;
	@media (max-width: 640px) {
		${({isMatchupView}) =>
			isMatchupView &&
			`
			width: 120px;
			z-index: 1;
			

		`}
		${({reverseInfoSection}) =>
			reverseInfoSection &&
			`
			justify-content: flex-end;
		`}
	}
`;

const Position = styled.div<{
	isMatchupView?: boolean;
}>`
	font-size: 14px;
	font-weight: 700;
	line-height: 14px;
	color: var(--textBlack);
	display: flex;
	margin-bottom: 5px;
	white-space: nowrap;
	${({isMatchupView}) =>
		isMatchupView &&
		`
		margin-bottom: 0;
	`}
	@media (max-width: 825px) {
		font-size: 10px;
	}
	@media (max-width: 640px) {
		font-size: 14px;
		${({isMatchupView}) =>
			isMatchupView &&
			`
			margin-bottom: 0px;
			font-size: 12px;
			p,div,span{
				font-size: 12px;
			}
		`}
	}
`;

const Fixture = styled(Position)<{
	isBye?: boolean;
	small?: boolean;
}>`
	font-weight: 400;
	margin-bottom: 0;
	${({small}) =>
		small &&
		`
		font-size: 12px;
	`}
	span {
		margin: 0 2px;
	}
	${({isBye}) =>
		isBye &&
		`
		margin-left: 4px;
	`}
	@media(max-width: 640px) {
		${({small}) =>
			small &&
			`
			font-size: 12px;
			font-weight: 400;
			display: flex;
			align-items: center;
		`}
	}
`;

const PlayerCost = styled.p`
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 700;
	color: var(--textBlack);
	line-height: 14px;
`;

const SelectedStat = styled.div`
	min-width: 50px;
	height: 100%;
	font-size: 16px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ActionButton = styled(ButtonPure)`
	margin-right: 5px;
	margin-left: 15px;
	min-height: 28px;
	min-width: 28px;
	svg {
		border-radius: 50%;
	}
	&:disabled {
		opacity: 0.4;
		cursor: disabled;
	}
	@media (max-width: 825px) {
		margin-left: 0;
	}
	@media (max-width: 640px) {
		margin-left: 15px;
	}
`;

const LockedButton = styled(ActionButton)`
	background: black;
	border-radius: 50%;
	svg {
		width: 11.2px;
		height: 14.7px;
		border-radius: 0;
		margin-left: 1px;
		margin-top: 2px;
	}
`;

const ResetButton = styled(ActionButton)`
	background: var(--removeRed);
	border-radius: 50%;
`;

export const FavouriteButton = styled.div`
	height: 24px;
	width: 24px;
	z-index: 1;
	display: flex;
	justify-content: center;
	svg {
		height: 22px;
		width: 22px;
	}
`;

const VenueRow = styled.p`
	font-size: 12px;
	font-weight: 400;
	color: var(--textBlack);
`;

const SeperatorAnd = styled.div<{
	isVenueSection?: boolean;
}>`
	margin: 0 1px;
	font-weight: 700;
	font-size: 14px;
	${({isVenueSection}) =>
		isVenueSection &&
		`
		font-size: 12px;
	`}
`;

export const getStatusIcon = (status: PlayerStatus) => {
	switch (status) {
		case PlayerStatus.Uncertain:
			return <Uncertain />;
		case PlayerStatus.UncertainGame2:
			return <UncertainG2 />;
		case PlayerStatus.Emergency:
			return <SelectedEmg />;
		case PlayerStatus.NotPlaying:
			return <NotSelected />;
		case PlayerStatus.Playing:
			return <Selected />;
		case PlayerStatus.Injured:
			return <Injured />;
		default:
			return <Uncertain />;
	}
};

interface IFixtureElementProps {
	player: IPlayerExtended;
	isTeamView?: boolean;
	isStatCenter?: boolean;
}
export const FixtureElement: React.FC<IFixtureElementProps> = ({
	player,
	isStatCenter,
	isTeamView,
}) => {
	const isMobile = useMediaQuery("(max-width: 640px)");
	const isMobileAndStatCenter = isStatCenter && isMobile;
	const isLengthTwo = player.fixture.length === 2;
	const isIndexFirstAndLength2 = (index: number) => index === 0 && isLengthTwo;
	const isListView = localStorage.getItem("team_view");
	const isTeamAndListView = isTeamView && isListView;

	if (player.fixture.length === 0) {
		return (
			<Fixture isBye={true}>
				<strong>BYE</strong>
			</Fixture>
		);
	}
	if (isMobileAndStatCenter) {
		return player.fixture.map((fixture, index) => {
			const opponentSquad = fixture.isPlayerHome ? fixture.awaySquad : fixture.homeSquad;
			return (
				<Fragment key={index}>
					<Fixture small={isMobileAndStatCenter}>
						{isIndexFirstAndLength2(index) && <span>{"-"}</span>}
						{index === 0 && !isLengthTwo && (
							<span>{fixture.isPlayerHome ? "V" : "@"}</span>
						)}
						{opponentSquad?.abbreviation}
					</Fixture>
					{isIndexFirstAndLength2(index) && ","}
				</Fragment>
			);
		});
	}
	return (
		<Fragment>
			{player.fixture.map((fixture, index) => {
				const opponentSquad = fixture.isPlayerHome ? fixture.awaySquad : fixture.homeSquad;
				return (
					<Fragment key={index}>
						<Fixture small={Boolean(isTeamAndListView)}>
							<span>{fixture.isPlayerHome ? "V" : "@"}</span>
							{opponentSquad?.abbreviation}
						</Fixture>
						{index === 0 && isLengthTwo && (
							<SeperatorAnd>{isMobileAndStatCenter ? "," : "&"}</SeperatorAnd>
						)}
					</Fragment>
				);
			})}
		</Fragment>
	);
};

const getVenueSection = (player: IPlayerExtended, getMatchDayTime: (squadId: number) => string) => {
	const isDualWeek = player.fixture.length === 2;
	if (player.fixture.length === 0) {
		return (
			<VenueRow>
				<strong>BYE</strong>
			</VenueRow>
		);
	}

	return player.fixture.map((fixture, index) => {
		const isHome = fixture.isPlayerHome;
		const opponentSquad = isHome ? fixture.awaySquad : fixture.homeSquad;
		const fixtureMatchId = get(fixture, "matchId", 0);
		const time = getMatchDayTime(fixtureMatchId); // get via tournamentId
		let text = isHome
			? `${time} v ${opponentSquad?.abbreviation || ""}`
			: `${time} @${opponentSquad?.abbreviation || ""}`;
		if (time === "BYE") {
			text = "BYE";
		}
		return (
			<Fragment key={index}>
				<VenueRow>{text}</VenueRow>
				{isAllTrue([isDualWeek, index === 0]) && (
					<SeperatorAnd isVenueSection={true}>&</SeperatorAnd>
				)}
			</Fragment>
		);
	});
};

interface IPlayerInfo {
	player: IPlayerExtended;
	isTeamView?: boolean;
	isRoundOneComplete?: boolean;
	isMatchupView?: boolean;
	isAway?: boolean;
	isStatCenter?: boolean;
	isMatchupBench?: boolean;
	hidePaddingRight?: boolean;
	isH2HMatchup?: boolean;
}

export const PlayerInfo: React.FC<IPlayerInfo> = ({
	player,
	isTeamView,
	isMatchupView,
	isAway,
	isStatCenter,
	isMatchupBench,
	hidePaddingRight,
	isH2HMatchup,
}) => {
	const modalController = useViewController<IModalsController>(Bindings.ModalsController, {});
	const playerDataController = useViewController<IPlayerDataController>(
		Bindings.PlayerDataController
	);
	const {showMiniPlayer, getMatchDayTime} = modalController;

	const reverseInfoSection = isMatchupView && isAway;
	const showDetailInfo = Boolean(!isMatchupView) || isMatchupBench;

	const playerSquad = get(player, "squad.abbreviation", "");
	const playerCostChanges = playerDataController.getPlayerCostChanges(player);
	return (
		<FlexCol
			reverseInfoSection={Boolean(reverseInfoSection)}
			isMatchupView={Boolean(isMatchupView)}>
			<FlexRowMarginBottom
				reverseInfoSection={Boolean(reverseInfoSection)}
				hidePaddingRight={hidePaddingRight}
				isMatchupView={Boolean(isMatchupView)}>
				<SmallStatusIcon status={player.status}>
					{getStatusIcon(player.status)}
				</SmallStatusIcon>
				<PlayerName
					data-desc="open-playermodal"
					isMatchupView={isMatchupView}
					className="player-name"
					reverseInfoSection={Boolean(reverseInfoSection)}
					style={{marginBottom: 0}}
					onClick={() => showMiniPlayer(player)}>
					{getPlayerName(player)}
				</PlayerName>
			</FlexRowMarginBottom>

			<Exist when={Boolean(isMatchupView)}>
				<Position isMatchupView={Boolean(isMatchupView)}>
					<Exist when={isH2HMatchup}>
						<Exist when={!isMatchupBench}>
							<strong>{playerSquad}</strong>
						</Exist>
						<Exist when={isMatchupBench}>
							<strong>{getPlayerPositionText(player)}</strong>

							<Separator />
							<strong>{playerSquad}</strong>
						</Exist>
					</Exist>
					<Exist when={!isH2HMatchup}>
						<strong>{getPlayerPositionText(player)}</strong>

						<Separator />
						<strong>{playerSquad}</strong>
					</Exist>
				</Position>
			</Exist>
			<Exist when={showDetailInfo}>
				<Position isMatchupView={Boolean(isMatchupView)}>
					<Exist when={isAnyTrue([!isMatchupView])}>
						{getPlayerPositionText(player)}
						<Exist when={!isMatchupBench}>
							<Separator />
							<strong>{playerSquad}</strong>
							<FixtureElement
								player={player}
								isTeamView={isTeamView}
								isStatCenter={isStatCenter}
							/>
						</Exist>
					</Exist>
				</Position>
			</Exist>

			<FlexRow
				reverseInfoSection={Boolean(reverseInfoSection)}
				hidePaddingRight={hidePaddingRight}>
				<Exist when={!isMatchupView}>
					<PlayerCost>{player.displayPrice}</PlayerCost>
					<Exist when={modalController.isRoundOneComplete && !isStatCenter}>
						<PlayerCostComp
							cost={playerCostChanges.cost_season_diff || ""}
							is_diff={true}
						/>
						<PlayerCostComp
							cost={playerCostChanges.cost_round_diff || ""}
							is_diff={true}
						/>
					</Exist>
				</Exist>
				<Exist when={isMatchupView}>{getVenueSection(player, getMatchDayTime)}</Exist>
			</FlexRow>
		</FlexCol>
	);
};

interface IProps {
	player: IPlayerExtended;
	style: CSSProperties;
	displayedStat: string;
	addToTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	removeFromTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	setFavouritePlayer: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	removeFavouritePlayer: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	favouritePlayers: number[];
	canAddPlayerToTeam: (player: IPlayer) => boolean;
	handlePlayerPool: () => void;
	addPlayerOutTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	addPlayerInTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	removePlayerInTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	removePlayerOutTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	isPlayerLocked: (squadId: number) => boolean;
	isTradeOutDisabled: boolean;
	isTradeInDisabled: boolean;
	isCurrentRoundIndigenous: boolean;
	isTeamStarted: boolean;
	setPositionFixed: (value: boolean) => void;
	focusOnField?: () => void;
	isLocked: boolean;
	handleAddToTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	handleRemoveFromTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}
export const PlayerPoolCard: React.FC<IProps> = observer(
	({
		player,
		style,
		canAddPlayerToTeam,
		addPlayerInTrade,
		addPlayerOutTrade,
		isTradeOutDisabled,
		isCurrentRoundIndigenous,
		isTradeInDisabled,
		isTeamStarted,
		isLocked,
		handleAddToTeam,
		handleRemoveFromTeam,
	}) => {
		return (
			<Wrapper
				style={style}
				isInTeam={player.isInTeam}
				isPlayerIncomingTrade={player.isPlayerIncomingTrade}
				isPlayerOutgoingTrade={player.isPlayerOutgoingTrade}>
				<FlexRowGap
					clubId={player.squadId}
					isCurrentRoundIndigenous={isCurrentRoundIndigenous}>
					<Exist when={player.isPlayerIncomingTrade}>
						<PlayerIcon isPlayerIncoming={true} isFromPool={true}>
							<ArrowLeft />
						</PlayerIcon>
					</Exist>
					<Exist when={player.isPlayerOutgoingTrade}>
						<PlayerIcon isPlayerIncoming={false} isFromPool={true}>
							<ArrowRight />
						</PlayerIcon>
					</Exist>
					{/* <Exist when={Boolean(player.isFavourite)}>
							<FavouriteButton>
								<Favourite isFavourite={true} />
							</FavouriteButton>
						</Exist> */}

					<PlayerHeadshot fromPool={true}>
						<img
							src={player.playerImgPath}
							alt={getPlayerName(player)}
							onError={({currentTarget}) => {
								currentTarget.onerror = null;
								currentTarget.src = PLAYER_PLACEHOLDER_IMG_URL;
							}}
						/>
					</PlayerHeadshot>
					<PlayerInfo
						player={player}
						isRoundOneComplete={Boolean(player.isRoundOneComplete)}
					/>
				</FlexRowGap>

				<StatActionRow>
					<SelectedStat>{player.displayStat}</SelectedStat>
					<Exist when={isLocked}>
						<LockedButton>
							<Locked />
						</LockedButton>
					</Exist>
					<Exist when={!isLocked}>
						<Exist when={isTeamStarted}>
							{/* Add to trade in/out or if in trade reset in/out */}
							<Exist when={player.isInTrade}>
								<ResetButton
									data-desc="pool-remove-player-from-trade"
									aria-label="Remove Player From Trade - Player Pool"
									onClick={player.removeTradeAction}
									value={player.id}
									disabled={player.isPlayerTradeBE}>
									<Reset />
								</ResetButton>
							</Exist>
							<Exist when={player.isInTeam && !player.isInTrade}>
								<ActionButton
									data-desc="pool-add-player-to-trade"
									aria-label="Add Player Out Trade - Player Pool"
									onClick={addPlayerOutTrade}
									value={player.id}
									disabled={isTradeOutDisabled}>
									<Trade />
								</ActionButton>
							</Exist>
							<Exist when={!player.isInTeam && !player.isInTrade}>
								<ActionButton
									data-desc="pool-add-player-in-trade"
									aria-label="Add Player In Trade - Player Pool"
									onClick={addPlayerInTrade}
									value={player.id}
									disabled={isTradeInDisabled}>
									<AddPlayer />
								</ActionButton>
							</Exist>
						</Exist>
						<Exist when={!isTeamStarted}>
							<Exist when={!player.isLocked}>
								<Exist when={player.isInTeam}>
									<ActionButton
										data-desc="pool-remove-player"
										aria-label="Remove Player From team - Player Pool"
										onClick={handleRemoveFromTeam}
										value={player.id}>
										<RemovePlayer />
									</ActionButton>
								</Exist>
								<Exist when={player.showAddButton && canAddPlayerToTeam(player)}>
									<ActionButton
										data-desc="pool-add-player"
										aria-label="Add Player To Team - Player Pool"
										onClick={handleAddToTeam}
										value={player.id}>
										<AddPlayer />
									</ActionButton>
								</Exist>
							</Exist>
							<Exist when={player.showNoButton}>
								<ActionButton />
							</Exist>
						</Exist>
					</Exist>
				</StatActionRow>
			</Wrapper>
		);
	}
);
