import React from "react";
import styled from "@emotion/styled";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import {IPlayer, IPlayerStats} from "data/stores/players/players.store";
import {get} from "lodash";
import {IRound} from "data/stores/rounds/rounds.store";
import {getClubLogo} from "data/utils/helpers/clubLogoGetter";
import {IDynamicObject, fantasyStatsPerPoint, getFantasyStatsEquivalent} from "data/utils/helpers";
import {useMediaQuery} from "@mui/material";
import {ISquad} from "data/stores/squads/squads.store";

const Div = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
`;
const CustomListItem = styled(ListItemButton)`
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	min-height: 50px;
	padding: 0px;
	margin: 8px 0px;
	background: #fff;
`;

const RoundDetails = styled.div`
	display: flex;
	height: 50px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px;
	color: var(--inputLabelActive);
	.round-name-details {
		flex-direction: column;
		.round-title {
			display: flex;
			align-items: center;
			font-weight: 700;
			font-size: 14px;
		}
		.round-subtitle {
			font-weight: 400;
			font-size: 12px;
			margin-top: 3px;
		}
		.club-logo {
			height: 24px;
			width: 24px;
			margin-left: 6px;
		}
	}
	.round-points {
		font-weight: 700;
		font-size: 14px;
	}
`;

const CustomCollapse = styled(Collapse)`
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 0px 0px 10px 10px;
	bacground: var(--aflw-primary-200);
`;
const StatsKeyVal = styled.div<{withVal: boolean}>`
	flex-direction: row;
	background: ${({withVal}) => (withVal ? `var(--aflw-canvas)` : "#EFEEE4")};
	opacity: ${({withVal}) => (withVal ? 9 : 0.5)};
	display: flex;
	height: 35px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	color: var(--inputLabelActive);
	padding: 0px 20px;
	.value {
		font-weight: 700;
	}
	span {
		font-weight: 400;
		margin-left: 4px;
	}
`;

const RoundRow = styled.div<{
	isEven: boolean;
}>`
	display: flex;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid #fafafa;
	justify-content: space-between;
	background: white;
	height: 50px;
	padding: 0 5px;
	border-radius: 4px;
	margin-bottom: 2px;
`;

const StatItem = styled.div`
	font-size: 14px;
	font-weight: 400;
	color: var(--textMidGrey);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 50px;
	img {
		width: 32px;
		max-height: 32px;
	}
	span {
		margin-top: 4px;
	}
`;

interface IRoundStat {
	playerRoundStats: IPlayerStats;
	round?: IRound;
	activeOrCompletedRounds: IRound[];
	player: IPlayer;
	isCurrentRoundIndigenous: boolean;
	getSquadById: (squadId: number) => ISquad | undefined;
}

const RoundListItem: React.FC<IRoundStat> = (props) => {
	const isMobile = useMediaQuery("(max-width: 640px)");
	const {playerRoundStats, isCurrentRoundIndigenous, getSquadById} = props;
	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	const opponent = getSquadById(get(playerRoundStats, "opponentId", 0));
	const converted = getFantasyStatsEquivalent(playerRoundStats.stats as IDynamicObject);
	const isEven = playerRoundStats.roundId % 2 === 0;

	if (!isMobile) {
		return (
			<RoundRow isEven={isEven}>
				<StatItem>{playerRoundStats.roundId}</StatItem>
				<StatItem>
					<img
						className="club-logo"
						src={getClubLogo(playerRoundStats.opponentId, isCurrentRoundIndigenous)}
						alt={"club-logo"}></img>
				</StatItem>
				<StatItem>{Number(playerRoundStats.stats.TOG)}%</StatItem>
				<StatItem>
					{Number(playerRoundStats.stats.K)}
					<span>({playerRoundStats.stats.K * fantasyStatsPerPoint.K.multiplier})</span>
				</StatItem>
				<StatItem>
					{Number(playerRoundStats.stats.H)}
					<span>({playerRoundStats.stats.H * fantasyStatsPerPoint.H.multiplier})</span>
				</StatItem>
				<StatItem>
					{Number(playerRoundStats.stats.M)}
					<span>({playerRoundStats.stats.M * fantasyStatsPerPoint.M.multiplier})</span>
				</StatItem>
				<StatItem>
					{Number(playerRoundStats.stats.T)}
					<span>({playerRoundStats.stats.T * fantasyStatsPerPoint.T.multiplier})</span>
				</StatItem>
				<StatItem>
					{Number(playerRoundStats.stats.FF)}
					<span>({playerRoundStats.stats.FF * fantasyStatsPerPoint.FF.multiplier})</span>
				</StatItem>
				<StatItem>
					{Number(playerRoundStats.stats.FA)}
					<span>({playerRoundStats.stats.FA * fantasyStatsPerPoint.FA.multiplier})</span>
				</StatItem>
				<StatItem>
					{Number(playerRoundStats.stats.HO)}
					<span>({playerRoundStats.stats.HO * fantasyStatsPerPoint.HO.multiplier})</span>
				</StatItem>
				<StatItem>
					{Number(playerRoundStats.stats.G)}
					<span>({playerRoundStats.stats.G * fantasyStatsPerPoint.G.multiplier})</span>
				</StatItem>
				<StatItem>
					{Number(playerRoundStats.stats.B)}
					<span>({playerRoundStats.stats.B * fantasyStatsPerPoint.B.multiplier})</span>
				</StatItem>
				<StatItem>{Number(playerRoundStats.points)}</StatItem>
			</RoundRow>
		);
	}
	return (
		<Div>
			<CustomListItem onClick={handleClick}>
				<RoundDetails>
					<div className="round-name-details">
						<div className="round-title">
							{" "}
							Week {playerRoundStats.roundId} vs {get(opponent, "abbreviation")}{" "}
							<img
								className="club-logo"
								src={getClubLogo(
									playerRoundStats.opponentId,
									isCurrentRoundIndigenous
								)}
								alt={"club-logo"}></img>
						</div>
						<div className="round-subtitle">
							Time on ground: {Number(playerRoundStats.stats.TOG)}%
						</div>
					</div>
					<div className="round-points">{Number(playerRoundStats.points)} Pts</div>
				</RoundDetails>
				<CustomCollapse in={open} unmountOnExit timeout={1}>
					<StatsKeyVal withVal={converted?.K?.value !== 0}>
						<div>{converted?.K?.label}</div>
						<div className="value">
							{Number(converted?.K?.value)}
							<span>({converted?.K?.value * fantasyStatsPerPoint.K.multiplier})</span>
						</div>
					</StatsKeyVal>
					<StatsKeyVal withVal={playerRoundStats.stats.H !== 0}>
						<div>Handballs</div>
						<div className="value">
							{Number(playerRoundStats.stats.H)}
							<span>
								({playerRoundStats.stats.H * fantasyStatsPerPoint.H.multiplier})
							</span>
						</div>
					</StatsKeyVal>
					<StatsKeyVal withVal={playerRoundStats.stats.M !== 0}>
						<div>Marks</div>
						<div className="value">
							{Number(playerRoundStats.stats.M)}
							<span>
								({playerRoundStats.stats.M * fantasyStatsPerPoint.M.multiplier})
							</span>
						</div>
					</StatsKeyVal>
					<StatsKeyVal withVal={playerRoundStats.stats.T !== 0}>
						<div>Tackles</div>
						<div className="value">
							{Number(playerRoundStats.stats.T)}
							<span>
								({playerRoundStats.stats.T * fantasyStatsPerPoint.T.multiplier})
							</span>
						</div>
					</StatsKeyVal>
					<StatsKeyVal withVal={playerRoundStats.stats.FF !== 0}>
						<div>Free Kicks For</div>
						<div className="value">
							{Number(playerRoundStats.stats.FF)}
							<span>
								({playerRoundStats.stats.FF * fantasyStatsPerPoint.FF.multiplier})
							</span>
						</div>
					</StatsKeyVal>
					<StatsKeyVal withVal={playerRoundStats.stats.FA !== 0}>
						<div>Free Kicks Against</div>
						<div className="value">
							{Number(playerRoundStats.stats.FA)}
							<span>
								({playerRoundStats.stats.FA * fantasyStatsPerPoint.FA.multiplier})
							</span>
						</div>
					</StatsKeyVal>
					<StatsKeyVal withVal={playerRoundStats.stats.G !== 0}>
						<div>Goals</div>
						<div className="value">
							{Number(playerRoundStats.stats.G)}
							<span>
								({playerRoundStats.stats.G * fantasyStatsPerPoint.G.multiplier})
							</span>
						</div>
					</StatsKeyVal>
					<StatsKeyVal withVal={playerRoundStats.stats.B !== 0}>
						<div>Behinds</div>
						<div className="value">
							{Number(playerRoundStats.stats.B)}
							<span>
								({playerRoundStats.stats.B * fantasyStatsPerPoint.B.multiplier})
							</span>
						</div>
					</StatsKeyVal>
					<StatsKeyVal withVal={playerRoundStats.stats.HO !== 0}>
						<div>Hitouts</div>
						<div className="value">
							{Number(playerRoundStats.stats.HO)}
							<span>
								({playerRoundStats.stats.HO * fantasyStatsPerPoint.HO.multiplier})
							</span>
						</div>
					</StatsKeyVal>
				</CustomCollapse>
			</CustomListItem>
		</Div>
	);
};

export default RoundListItem;
