import React from "react";
import styled from "@emotion/styled";
import * as _ from "lodash";
import {currencyFormat} from "data/utils";

const PlayerCostStyled = styled.div`
	font-size: 12px;
	color: #7f8a90;
	font-weight: 400;
	margin-bottom: -3px;
`;
const PlayerCostDiffStyled = styled.p<{
	is_positive?: boolean;
	is_even?: boolean;
	is_bracketed?: boolean;
}>`
	background: ${({is_positive, is_even}) => (is_even ? "grey" : is_positive ? "green" : "red")};
	color: var(--textWhite) !important;
	font-size: 10px;
	height: 19px;
	font-weight: 600;
	min-width: 30px;
	padding: 6px;
	line-height: 120%;
	border-radius: 4px;
	display: flex;
	margin-left: 2px;
	align-items: center;
	justify-content: center;
	white-space: nowrap;

	${({is_bracketed, is_positive, is_even}) =>
		is_bracketed &&
		`
		background: unset;
		color: ${is_even ? "grey" : is_positive ? "green" : "red"}
		&:before {
			content: '(';
		}
		&:after {
			content: ')';
		}
	`}
`;
type Props = {
	cost: string | number;
	is_diff?: boolean;
	is_bracketed?: boolean;
};
export const PlayerCostComp = ({cost, is_diff, is_bracketed = false}: Props) => {
	if (!cost) {
		return <PlayerCostDiffStyled is_even>HOLD</PlayerCostDiffStyled>;
	}
	return is_diff ? (
		<PlayerCostDiffStyled
			is_bracketed={is_bracketed}
			is_positive={_.isNumber(cost) && cost >= 0}>
			{currencyFormat({input: Number(cost)})}
		</PlayerCostDiffStyled>
	) : (
		<PlayerCostStyled>
			{currencyFormat({input: Number(cost), showDecimal: true})}
		</PlayerCostStyled>
	);
};

export default PlayerCostComp;
