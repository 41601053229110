import {computed, action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IPlayersStore, type IPlayer, IPoolFilters} from "data/stores/players/players.store";
import {type ITeamStore} from "data/stores/team/team.store";
import {Bindings} from "data/constants/bindings";
// import React, {ChangeEvent} from "react";
import {MatchStatus, PlayerPosition, PlayerStatus, RoundStatus, SortOrder} from "data/enums";
import {get} from "lodash";
// import {SelectChangeEvent} from "@mui/material";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {
	ISelectOption,
	defaultFilters,
	type ITeamBuilderStore,
} from "data/stores/team_builder/team_builder.store";
import {SelectChangeEvent} from "@mui/material";
import {ChangeEvent} from "react";

export interface IPlayerExtended extends IPlayer {
	isInTeam: boolean;
	canAddToTeam: boolean;
	isFavourite?: boolean;
	isLocked?: boolean;
	showAddButton?: boolean;
	playerImgPath?: string;
	displayStat?: number | string;
	showNoButton?: boolean;
	favouriteAction?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	isTradeMode?: boolean;
	isInTrade?: boolean;
	removeTradeAction?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	isPlayerTradeBE?: boolean;
	isPlayerIncomingTrade?: boolean;
	isPlayerOutgoingTrade?: boolean;
	isRoundOneComplete?: boolean;
}

interface IParams {
	focusOnField?: () => void;
}

export interface IPlayerPoolController extends ViewController<IParams> {
	removeFromTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	addToTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	resetPosition: () => void;
	setPosition: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	updateFilter: (e: SelectChangeEvent | SelectChangeEvent<string>) => void;
	updateTeamFilter: (e: SelectChangeEvent | SelectChangeEvent<string>) => void;
	updateStatusFilter: (e: SelectChangeEvent | SelectChangeEvent<string | PlayerStatus>) => void;
	updateSearch: (e: ChangeEvent<HTMLInputElement>) => void;
	sortPlayers: () => void;
	resetFilters: () => void;
	resetSearch: () => void;
	isPositionFixed: boolean;
	setFavouritePlayer: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	removeFavouritePlayer: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	canAddPlayerToTeam: (player: IPlayer) => boolean;
	isPlayerLocked: (squadId: number) => boolean;
	addPlayerOutTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	addPlayerInTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	removePlayerOutTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	removePlayerInTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	handleAddToTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	handleRemoveFromTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	setPositionFixed: (value: boolean) => void;
	toggleDoubleGameWeek: () => void;

	get filters(): IPoolFilters;
	get playerPositionStr(): string;
	get isCurrentRoundIndigenous(): boolean;
	get searchFilter(): string;
	get selectedFiltersLength(): number;
	get disableResetButton(): boolean;

	get isTradeMode(): boolean;
	get order(): SortOrder;

	get squads(): ISquad[];

	get positionsArray(): PlayerPosition[];

	get statsOptions(): ISelectOption[];
	get priceOptions(): ISelectOption[];
	get statusOptions(): ISelectOption[];
	get favouritePlayers(): number[];
	get isTeamFirstRoundComplete(): boolean;
	get doubleGameWeekFilter(): boolean;
	get isDoubleGameWeek(): boolean;
}

@injectable()
export class PlayerPoolController implements IPlayerPoolController {
	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	@observable protected _hasFetchedJSONS: boolean = false;
	@observable private _focusOnField: () => void | null = () => null;

	// Count of filters that includes at least 1 property, except "All" property
	@computed
	get selectedFiltersLength(): number {
		const searchValue = this.searchFilter.trim().length > 0 ? 1 : 0;
		let numChanged = 0;
		Object.keys(this.filters).forEach((key) => {
			const currentValue = get(this.filters, key, "") as string;
			const defaultValue = get(defaultFilters, key, "") as string;

			if (key === "position" && currentValue.length !== defaultValue.length) {
				const incrementNum = currentValue.length;
				numChanged += incrementNum;
			}

			if (
				["squad", "status", "price", "stat"].includes(key) &&
				currentValue !== defaultValue
			) {
				numChanged += 1;
			}
		});
		return numChanged + searchValue;
	}

	get disableResetButton(): boolean {
		return this.selectedFiltersLength === 0 || this.isPositionFixed;
	}

	get squads(): ISquad[] {
		return this._squadsStore.list;
	}

	get isCurrentRoundIndigenous() {
		return this._roundsStore.isCurrentRoundIndigenous;
	}

	get filters(): IPoolFilters {
		return this._teamBuilderStore.filters;
	}

	get playerPositionStr(): string {
		return get(this._teamBuilderStore.filters, "position", "") as string;
	}

	get searchFilter(): string {
		return this._teamBuilderStore.searchFilter;
	}

	get doubleGameWeekFilter(): boolean {
		return this._teamBuilderStore.filters.doubleGameWeek;
	}

	get isDoubleGameWeek(): boolean {
		const tournaments = this._roundsStore.currentRound?.tournaments;
		if (tournaments) {
			return Boolean(tournaments.length > 9);
		}
		return false;
	}

	get isPositionFixed() {
		return this._teamStore.isPositionFixed;
	}

	get statsOptions() {
		return this._teamBuilderStore.statsOptions;
	}

	get priceOptions() {
		return this._teamBuilderStore.priceOptions;
	}

	get order() {
		return this._teamBuilderStore.order;
	}

	get isTradeMode() {
		return this._teamStore.isTradeMode;
	}

	get favouritePlayers() {
		return this._playersStore.favouritePlayers;
	}

	get statusOptions() {
		return this._teamBuilderStore.statusOptions;
	}

	get isTeamFirstRoundComplete() {
		return this._teamStore.isTeamStartRoundComplete;
	}

	get isRoundOneComplete() {
		return this._roundsStore.isRoundOneComplete;
	}

	setPositionFixed = (value: boolean) => {
		this._teamStore.setPositionFixed(value);
	};

	get positionsArray(): PlayerPosition[] {
		return this._teamStore.positionsArray;
	}

	get roundId() {
		return this._roundsStore.currentRound?.id || 1;
	}

	get filterRoundId() {
		const isCurrentRoundStarted =
			this._roundsStore.currentRound?.status !== RoundStatus.Scheduled;
		if (isCurrentRoundStarted && this._roundsStore.currentRound?.id === 1) {
			return 1;
		}

		return isCurrentRoundStarted && this.roundId !== 1 ? this.roundId : this.roundId - 1;
	}

	isPlayerLocked = (squadId: number) => {
		const round = this._roundsStore.selectedRound;
		const playerMatch = round?.tournaments.find((match) =>
			[match.awaySquadId, match.homeSquadId].includes(squadId)
		);
		if (playerMatch) {
			return playerMatch.status !== MatchStatus.Scheduled;
		}
		return false;
	};

	@action
	removeFromTeam = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.addRemoveTeam(e.currentTarget.value, "removePlayerFromTeam");
	};

	@action
	addToTeam = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.addRemoveTeam(e.currentTarget.value, "addPlayerToTeam");
	};

	@action
	handleAddToTeam = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		if (this.isTeamFirstRoundComplete) {
			this.addPlayerInTrade(e);
			return;
		}
		this.addToTeam(e);
		this.setPositionFixed(false);
		this._focusOnField();
	};

	@action
	handleRemoveFromTeam = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		if (this.isTeamFirstRoundComplete) {
			this.addPlayerOutTrade(e);
			return;
		}
		this.removeFromTeam(e);
	};

	@action
	async checkFetchJSONs(): Promise<void> {
		if (this._hasFetchedJSONS) {
			return;
		}
		this._hasFetchedJSONS = true;
		await Promise.all([this._playersStore.safeFetchPlayers()]);
	}

	sortPlayers = () => {
		this._teamBuilderStore.sortPlayers();
	};
	resetFilters = () => {
		this._teamBuilderStore.resetFilters();
	};
	resetSearch = () => {
		this._teamBuilderStore.resetSearch();
	};

	setFavouritePlayer = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		await this._playersStore.setFavouritePlayer(playerId);
	};

	removeFavouritePlayer = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		await this._playersStore.removeFavouritePlayer(playerId);
	};

	resetPosition = () => {
		this._teamBuilderStore.resetPosition();
	};

	setPosition = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamBuilderStore.setPosition(e);
	};
	updateFilter = (e: SelectChangeEvent | SelectChangeEvent<string[]>) => {
		this._teamBuilderStore.updateFilter(e);
	};
	updateSearch = (e: ChangeEvent<HTMLInputElement>) => {
		this._teamBuilderStore.updateSearch(e);
	};

	toggleDoubleGameWeek = () => {
		this._teamBuilderStore.toggleDoubleGameWeek();
	};

	updateTeamFilter = (e: SelectChangeEvent | SelectChangeEvent<string[]>) => {
		this._teamBuilderStore.updateTeamFilter(e);
	};

	updateStatusFilter = (e: SelectChangeEvent | SelectChangeEvent<string | PlayerStatus>) => {
		this._teamBuilderStore.updateStatusFilter(e);
	};

	canAddPlayerToTeam = (player: IPlayer) => {
		return this._teamStore.canAddPlayerToTeam(player);
	};

	removePlayerOutTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.setTradeMode(true);
		const playerId = Number(e.currentTarget.value);
		this._teamStore.removePlayerOutTrade(playerId);
	};

	removePlayerInTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.setTradeMode(true);
		const playerId = Number(e.currentTarget.value);
		this._teamStore.removePlayerInTrade(playerId);
	};

	addPlayerInTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.setTradeMode(true);
		const playerId = Number(e.currentTarget.value);
		this._teamStore.addPlayerInTrade(playerId);
	};

	addPlayerOutTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.setTradeMode(true);
		const playerId = Number(e.currentTarget.value);
		this._teamStore.addPlayerOutTrade(playerId);
	};
	dispose(): void {
		return;
	}

	async init(param: IParams): Promise<void> {
		this.resetFilters();
		if (param.focusOnField) {
			this._focusOnField = param.focusOnField;
		}
		await this.checkFetchJSONs();
		// const isSeasonStarted = this._roundsStore.list[0].status !== RoundStatus.Scheduled;
		//this._teamBuilderStore.updateStatField("cost");
	}
}
