import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type ITeamStore} from "data/stores/team/team.store";
import {Bindings} from "data/constants/bindings";
import {type IPlayersStore} from "data/stores/players/players.store";
import {IRound, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {RoundStatus} from "data/enums";
import {get} from "lodash";
import {currencyFormat} from "data/utils";
import {isAllTrue} from "data/utils/helpers";
import {type IUserStore} from "data/stores/user/user.store";

interface IInit {
	isOtherUser: boolean;
}

export interface ITeamControlsController extends ViewController<IInit> {
	get remainingSalary(): number;
	get teamCount(): number;
	get isTeamValid(): boolean;
	get teamError(): string | null;
	get hasUnsavedChanges(): boolean;
	get isAutofillAvailable(): boolean;
	get isClearTeamHidden(): boolean;
	get teamValue(): number | string;
	get isTeamRoundOneStarted(): boolean;
	get roundPoints(): number | string;
	get totalPoints(): number;
	get tradesRemaining(): number;
	get isShowClearAll(): boolean;
	get isNegative(): boolean;
	get isClearDisabled(): boolean;
	get roundOptions(): IRound[];
	get isRollbackAvailable(): boolean;
	get isTradeMode(): boolean;
	get teamRank(): number;
	get roundRank(): number;
	get selectedRoundId(): number;
	get isRollbackOpen(): boolean;
	get isTeamOnceComplete(): boolean;
	get isTeamRoundOneComplete(): boolean;
	get isPastRoundSelected(): boolean;
	get isAutosavePending(): boolean;
	get isCurrentRoundLive(): boolean;
	get isSelectedRoundScheduled(): boolean;
	get selectedRound(): IRound | undefined;
	get currentRound(): IRound | undefined;
	get currency(): string;
	clearTeam: () => void;
	autoFillTeam: () => void;
	saveTeam: () => void;
	clearError: () => void;
	rollbackTeam: () => void;
	setClearOff: () => void;
	setClearOn: () => void;
	onClearTeam: () => void;
	toggleRollbackModal: () => void;
}

@injectable()
export class TeamControlsController implements ITeamControlsController {
	@observable private _isRollbackOpen: boolean = false;
	@observable private _isOtherUser: boolean = false;
	@observable private _isRankFetched: boolean = false;
	@observable private _isShowClearAll: boolean = false;

	constructor(
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore,
		@inject(Bindings.PlayersStore) public _playersStore: IPlayersStore,
		@inject(Bindings.RoundsStore) public _roundStore: IRoundsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isShowClearAll() {
		return this._isShowClearAll;
	}

	get currency() {
		return currencyFormat({input: this.remainingSalary, showDecimal: true});
	}

	get isNegative() {
		return this.currency.includes("-");
	}

	get isClearDisabled() {
		return this.teamCount < 1;
	}

	get isRollbackOpen() {
		return this._isRollbackOpen;
	}

	get isSelectedRoundScheduled() {
		return this._roundStore.selectedRound?.status === RoundStatus.Scheduled;
	}

	get selectedRound() {
		return this._roundStore.selectedRound;
	}

	get currentRound() {
		return this._roundStore.currentRound;
	}

	get isCurrentRoundLive() {
		return this._roundStore.isCurrentRoundLive;
	}

	get isTeamOnceComplete() {
		return this._teamStore.team.isComplete;
	}

	get remainingSalary() {
		return this._teamStore.remainingSalary;
	}
	get selectedRoundId() {
		return this._roundStore.selectedRound?.id || 1;
	}

	get teamCount() {
		return this._teamStore.teamPlayerIds.filter((playerID) => playerID !== 0).length;
	}

	get teamError() {
		return this._teamStore.teamError;
	}

	get isTeamValid() {
		return this._teamStore.isTeamFullfilled() && this._teamStore.remainingSalary >= 0;
	}

	get hasUnsavedChanges() {
		return this._teamStore.hasUnsavedChanges;
	}

	get teamValue() {
		return currencyFormat({input: this._teamStore.team.value, showDecimal: true});
	}

	get isTradeMode() {
		return this._teamStore.isTradeMode;
	}

	get teamRank() {
		return this._teamStore.teamRankData?.rank.overallRank || 0;
	}
	get roundRank() {
		return this._teamStore.teamRankData?.rank.roundRank || 0;
	}

	get roundPoints() {
		if (this._isOtherUser) {
			return this._teamStore.teamRankData?.rank.roundPoints || 0;
		}
		const selectedRoundId = this._roundStore.selectedRound?.id || 1;
		const currentRoundId = this.currentRound?.id || 1;
		let roundIdToUse = selectedRoundId < currentRoundId ? selectedRoundId : currentRoundId;
		if (
			isAllTrue([
				selectedRoundId === currentRoundId,
				this.currentRound?.status === RoundStatus.Scheduled,
			])
		) {
			roundIdToUse -= 1;
		}
		const teamScoreflow = this._teamStore.team.scoreflow;
		return get(teamScoreflow, roundIdToUse, "-");
	}

	get totalPoints() {
		return this._teamStore.teamRankData?.rank.overallPoints || 0;
	}

	get tradesRemaining() {
		return this._teamStore.tradesRemaining;
	}

	get isAutosavePending() {
		return this._teamStore.isAutosavePending;
	}

	get roundOptions() {
		return this._roundStore.activeOrCompletedRounds.filter(
			(round) => round.id >= this._teamStore.team.startRoundId
		);
	}

	get isTeamRoundOneStarted() {
		const teamStartRound = this._teamStore.team.startRoundId || 1;
		const round = this._roundStore.roundsById[teamStartRound];
		const roundStatus = get(round, "status", RoundStatus.Scheduled);
		return roundStatus !== RoundStatus.Scheduled && this._teamStore.team.isComplete;
	}

	get isPastRoundSelected() {
		const selectedRound = this._roundStore.selectedRound?.id || 1;
		const actualRound = this._roundStore.currentRound?.id || 1;
		return selectedRound < actualRound;
	}

	get isTeamRoundOneComplete() {
		if (this._isOtherUser) {
			return true;
		}
		const teamStartRound = this._teamStore.team.startRoundId || 1;
		const round = this._roundStore.roundsById[teamStartRound];
		const roundStatus = get(round, "status", RoundStatus.Scheduled);
		return roundStatus === RoundStatus.Complete && this._teamStore.team.isComplete;
	}

	get isStartRoundComplete() {
		return this._teamStore.isTeamStartRoundComplete;
	}

	get isRollbackAvailable() {
		return this.isTradeMode && this._teamStore.isRollbackAvailable;
	}

	get isAutofillAvailable() {
		const isTeamFull = this._teamStore.isTeamFullfilled(); // false
		const currentRoundId = this._roundStore.currentRound?.id || 1;
		const isStartRoundAfterCurrent = this._teamStore.team.startRoundId > currentRoundId;
		const isFullLockout =
			this._roundStore.isCurrentRoundMatchesStarted && isStartRoundAfterCurrent;
		return !isTeamFull && !this.isTeamRoundOneComplete && !isFullLockout;
	}

	get isClearTeamHidden() {
		const teamStartRound = this._teamStore.team.startRoundId || 1;
		const round = this._roundStore.roundsById[teamStartRound];
		if (!round) {
			return false;
		}
		return round.status !== RoundStatus.Scheduled;
	}

	@action
	setClearOff = () => {
		this._isShowClearAll = false;
	};
	@action
	setClearOn = () => {
		this._isShowClearAll = true;
	};

	@action
	onClearTeam = () => {
		this.clearTeam();
		this.setClearOff();
	};
	@action
	toggleRollbackModal = () => {
		this._isRollbackOpen = !this._isRollbackOpen;
	};

	@action
	clearTeam = () => {
		this._teamStore.clearTeam();
	};

	@action
	autoFillTeam = () => {
		this._teamStore.autoFillTeam();
	};
	@action
	saveTeam = () => {
		this._teamStore.saveTeam();
	};

	@action
	clearError = () => {
		this._teamStore.clearTeamError();
	};

	@action
	rollbackTeam = () => {
		this._teamStore.rollbackTeam();
		this.toggleRollbackModal();
	};

	@action
	checkFetchRank() {
		if (this._isRankFetched) {
			return;
		}
		this._isRankFetched = true;
		const selectedRound = this._roundStore.selectedRound;
		const isRoundStarted = selectedRound?.status !== RoundStatus.Scheduled;

		if (isRoundStarted) {
			this._teamStore.getTeamRank(selectedRound?.id);
		} else {
			this._teamStore.getTeamRank();
		}
	}

	dispose(): void {
		return;
	}

	init(param: IInit) {
		this._isRankFetched = false;
		const isAuth = this._userStore.isAuthorized;
		if (!isAuth) {
			return;
		}
		const isViewUser =
			window.location.pathname.includes("team") && window.location.pathname.includes("user");
		if (param.isOtherUser || isViewUser) {
			this._isOtherUser = param.isOtherUser;
			return;
		}
		this.checkFetchRank();
	}
}
