import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {MatchStatus, ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IModalMessage, TModalArguments} from "data/types/modals";
import {Bindings} from "data/constants/bindings";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import type {IPlayer, IPlayerStats, IPlayersStore} from "data/stores/players/players.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IPlayerExtended} from "views/components/team/lineup/team_lineup.controller";
import {ISquad, type ISquadsStore} from "data/stores/squads/squads.store";
import {useNavigate} from "react-router-dom";
import {DateTime} from "luxon";

interface IInit {
	navigate?: ReturnType<typeof useNavigate>;
}

export interface IModalsController extends ViewController<IInit> {
	show: (...args: TModalArguments) => void;
	close: () => void;
	onCloseUserTeamModal: () => void;
	showMiniPlayer: (player: IPlayerExtended) => void;
	getPlayer: (playerId: number) => IPlayerExtended | undefined;
	fetchPlayerStats: (playerId: number) => void;
	setFavouritePlayer: (e: number) => void;
	getMatchDayTime: (matchId: number) => string;
	getPlayerOpponent: (squadId: number) => ISquad | undefined;
	getSquadById: (squadId: number) => ISquad | undefined;
	get user(): IUser | undefined;
	get visibleModal(): ModalType | null;
	get modalContent(): IModalMessage | null;
	get scheduledRounds(): IRound[];
	get isCurrentRoundIndigenous(): boolean;
	get team(): ITeamStore;
	get opponentSquad(): ISquad | undefined;
	get activeOrCompletedRounds(): IRound[];
	get playerStats(): IPlayerStats[];
	get isLoaded(): boolean;
	get isRoundOneComplete(): boolean;
	get favourites(): number[];
}

@injectable()
export class ModalsController implements IModalsController {
	@observable _player: IPlayer | null = null;
	private _navigate!: IInit["navigate"];
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.SquadsStore) private _squadStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	get team() {
		return this._teamStore;
	}

	get scheduledRounds() {
		return this._roundsStore.scheduleRounds;
	}
	get user(): IUser | undefined {
		return this._userStore.user;
	}

	get isCurrentRoundIndigenous() {
		return this._roundsStore.isCurrentRoundIndigenous;
	}

	get visibleModal() {
		return this._modalsStore.modal;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get opponentSquad() {
		return this._player?.fixture[0].isPlayerHome
			? this._player?.fixture[0].awaySquad
			: this._player?.fixture[0].homeSquad;
	}

	get activeOrCompletedRounds() {
		return this._roundsStore.activeOrCompletedRounds;
	}

	get playerStats() {
		return this._playersStore.playerStats;
	}

	get favourites() {
		return this._playersStore.favouritePlayers;
	}

	get isRoundOneComplete() {
		return this._roundsStore.isRoundOneComplete;
	}

	get isLoaded() {
		return this._playersStore.isLoaded;
	}

	getSquadById = (squadId: number) => {
		return this._squadStore.getSquadById(squadId || 0);
	};

	getPlayer = (playerId: number): IPlayerExtended | undefined => {
		return this._playersStore.getPlayerById(playerId) as IPlayerExtended;
	};

	getPlayerOpponent = (squadId: number) => {
		const selectedRoundId = this._roundsStore.leagueFixtureSelectedRound;
		const round = this._roundsStore.roundsById[selectedRoundId];
		const match = round.tournaments.find((match) =>
			[match.homeSquadId, match.awaySquadId].includes(squadId)
		);
		const opponentId = match?.homeSquadId === squadId ? match.homeSquadId : match?.awaySquadId;
		return this._squadStore.getSquadById(opponentId || 0);
	};
	fetchPlayerStats = (playerId: number) => {
		//hard coded while BE dont have data
		this._playersStore.fetchPlayerStats(playerId);
	};

	showMiniPlayer = (player: IPlayerExtended) => {
		this._player = player;
		const fixture = this.setFixture(player);
		player.fixture = fixture;
		this._modalsStore.showModal(ModalType.MINIPLAYER, {
			message: player.id.toString(),
			payload: player,
		});
	};

	setFixture(player: IPlayer) {
		const squadMatches = this._roundsStore.selectedRound?.tournaments.filter((match) =>
			[match.homeSquadId, match.awaySquadId].includes(player.squadId)
		);
		const isSelectedCurrent =
			this._roundsStore.selectedRound?.id === this._roundsStore.currentRound?.id;
		const nextWeekRound = this._roundsStore.list.find(
			(round) => round.id === (this._roundsStore.currentRound?.id || 1) + 1
		);
		const currentRoundMatches = this._roundsStore.currentRound?.tournaments.filter((match) =>
			[match.homeSquadId, match.awaySquadId].includes(player.squadId)
		);
		if (!isSelectedCurrent) {
			const matchArr = currentRoundMatches?.map((match) => {
				const isPlayerHome = match.homeSquadId === player.squadId;
				const awaySquad = this._squadStore.getSquadById(match.awaySquadId);
				const homeSquad = this._squadStore.getSquadById(match.homeSquadId);
				const isComplete = match.status === MatchStatus.Complete;

				return {
					isPlayerHome,
					awaySquad,
					homeSquad,
					isComplete,
					matchId: match.id,
				};
			});
			return matchArr || [];
		}
		if (squadMatches) {
			const matches = squadMatches.map((match) => {
				const isPlayerHome = match.homeSquadId === player.squadId;
				const awaySquad = this._squadStore.getSquadById(match.awaySquadId);
				const homeSquad = this._squadStore.getSquadById(match.homeSquadId);
				const isComplete = match.status === MatchStatus.Complete;

				return {
					isPlayerHome,
					awaySquad,
					homeSquad,
					isComplete,
					matchId: match.id,
				};
			});

			const nextWeekSquadMatches = nextWeekRound?.tournaments.filter((match) =>
				[match.homeSquadId, match.awaySquadId].includes(player.squadId)
			);
			if (
				matches.every((match) => match.isComplete) &&
				isSelectedCurrent &&
				nextWeekSquadMatches
			) {
				return nextWeekSquadMatches.map((match) => {
					const isPlayerHome = match.homeSquadId === player.squadId;
					const awaySquad = this._squadStore.getSquadById(match.awaySquadId);
					const homeSquad = this._squadStore.getSquadById(match.homeSquadId);
					const isComplete = match.status === MatchStatus.Complete;

					return {
						isPlayerHome,
						awaySquad,
						homeSquad,
						isComplete,
						matchId: match.id,
					};
				});
			}
			return matches;
		}
		return [];
	}

	setFavouritePlayer = async (e: number) => {
		if (!this._playersStore.favouritePlayers.includes(e)) {
			await this._playersStore.setFavouritePlayer(e);
		} else {
			await this._playersStore.removeFavouritePlayer(e);
		}
	};

	onCloseUserTeamModal = async () => {
		this._navigate!("/team");
		await this._teamStore.requestTeam();
		this._modalsStore.hideModal();
	};

	getMatchDayTime = (matchId: number) => {
		const selectedRound = this._roundsStore.leagueFixtureSelectedRound || 1;
		const match = this._roundsStore.roundsById[selectedRound]?.tournaments.find(
			(match) => match.id === matchId
		);
		if (match) {
			// this is the format for matchup page Wed 8am. Need to format two seperately.
			return `${DateTime.fromISO(match.date).toFormat("EEE")} ${DateTime.fromISO(match.date)
				.toFormat("ha")
				.toLowerCase()}`;
		}
		return "BYE";
	};

	show = (...args: TModalArguments) => {
		this._modalsStore.showModal(...args);
	};

	close = () => this._modalsStore.hideModal();

	dispose(): void {
		return;
	}

	init(params: IInit): void {
		if (params.navigate) {
			this._navigate = params.navigate;
		}
	}
}
