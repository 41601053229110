import React, {Fragment} from "react";
import styled from "@emotion/styled";
import {Stack, Typography, useMediaQuery} from "@mui/material";
import {ReactComponent as GeniusSportsLogo} from "assets/img/genius-logo.svg";
import {Bindings} from "data/constants/bindings";
import {NavLink} from "react-router-dom";
import {Container} from "views/components/composition/composition.component";
import {ExternalLink} from "views/components/links/links.component";
import {Exist} from "views/components/exist/exist.component";
import {useViewController} from "data/services/locator";
import {ISecretGateController} from "views/controllers/secrete_gate/secret_gate.controller";

const mobileViewQuery = `max-width: 640px`;

const GeniusLogo = styled(GeniusSportsLogo)`
	color: currentColor;
	opacity: 0.7;
`;

const Wrapper = styled.footer`
	background: var(--textWhite);
	text-align: center;
	padding: 30px 0 0;
	border-bottom: 4px solid var(--aflwChargedCoral);
	.footer-sitemap-item {
		font-weight: 700;
		font-size: 16px;
		color: #6a758b;
		display: flex;
		flex-flow: column wrap;
		height: 100px;
		padding: unset;
		@media (${mobileViewQuery}) {
			height: unset;
		}
	}
	.footer-sitemap-item li {
		color: #6a758b;
		padding: unset;
		display: inline-block;
		width: 276px;
		margin-top: 12px;
		font-weight: 700;
		font-size: 16px;
		line-height: unset;
		text-align: start;
	}
	.horizontal-line {
		border: 1px solid #e9ebed;
	}
	@media (max-width: 1290px) {
		padding: 30px 20px;
	}
`;

const FooterTop = styled(Stack)`
	padding-bottom: 20px;
	border-bottom: 1px solid #e9ebed;
	margin-bottom: 10px;
`;

const Nav = styled.nav`
	font-size: 12px;
	line-height: 16px;
	font-weight: 700;

	a {
		color: currentColor;
		font-weight: 500;
		font-size: 16px;
		text-decoration: none;
	}
`;

const Sponsor = styled(ExternalLink)`
	color: var(--textBlack);
	display: inline-flex;
	gap: 8px;
	align-items: center;
	background: none;
	transition: opacity 0.5s;

	&:hover {
		opacity: 0.5;
	}
	@media (${mobileViewQuery}) {
		margin-top: 24px;
		margin-bottom: 24px;
	}
`;
const FooterMenus = styled(NavLink)`
	color: var(--textBlack);
	a {
		font-size: 16px;
		font-weight: 700;
	}
`;

const External = styled(ExternalLink)`
	color: var(--textBlack);
	font-size: 16px;
	font-weight: 700;
`;

const CopyrightText = styled(Typography)`
	color: var(--textLightGrey);
	font-weight: 500;
	font-size: 16px;
`;
const DesktopFootNote = styled.div`
	display: flex;
	align-items: center;
	color: #6a758b;
	justify-content: space-between;

	@media (${mobileViewQuery}) {
		flex-direction: column;
	}
`;

export const Footer: React.FC = () => {
	const isMobile = useMediaQuery("(max-width: 640px)");
	const {isSecretPassed} = useViewController<ISecretGateController>(
		Bindings.SecretGateController
	);
	/*** commenting for reference in final layout usage **/
	// const isSupportShareAPI = "share" in navigator && isMobile;

	// const shareMobile = useCallback(
	// 	() =>
	// 		share({
	// 			type: ShareType.General,
	// 			socialNetwork: SocialNetwork.Mobile,
	// 			message: shareMessage,
	// 		}),
	// 	[]
	// );

	return (
		<Wrapper>
			<Container>
				<FooterTop
					sx={{mb: 2.5}}
					direction={isMobile ? "column" : "row"}
					gap={isMobile ? 4 : 16}
					justifyContent="space-between"
					alignItems="center">
					<Stack
						direction={isMobile ? "column" : "row"}
						justifyContent="flex-start"
						alignItems={"center"}
						gap={isMobile ? 2 : 5}>
						<CopyrightText variant="body2">
							Copyright @2024 AFL. All Rights Reserved
						</CopyrightText>
					</Stack>

					<Nav>
						<Exist when={isSecretPassed}>
							<FooterMenus to="/help/contact-us">Contact Us</FooterMenus>
							<Fragment>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</Fragment>
							<FooterMenus to="/help/game-rules">Game Guidelines</FooterMenus>
							<Fragment>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</Fragment>
							<FooterMenus to="/help/faqs">FAQs</FooterMenus>
						</Exist>
					</Nav>
				</FooterTop>

				<DesktopFootNote>
					<Nav>
						<Exist when={isSecretPassed}>
							<External
								href="https://www.telstra.com.au/privacy?ref=Net-Footer-Corp-Privacy"
								target="_blank">
								Privacy Policy
							</External>
							<Fragment>&nbsp;&nbsp;|&nbsp;&nbsp;</Fragment>
							<FooterMenus to="/help/terms">Terms and Conditions</FooterMenus>
							<Fragment>&nbsp;&nbsp;|&nbsp;&nbsp;</Fragment>
							<External
								href=" https://www.telstra.com.au/cyber-security-and-safety"
								target="_blank">
								Online Security
							</External>
						</Exist>
					</Nav>
					<Sponsor href="https://geniussports.com/">
						<Typography variant="body3">Powered by:</Typography>
						<GeniusLogo fill="red" />
					</Sponsor>
				</DesktopFootNote>
			</Container>
		</Wrapper>
	);
};
