/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 */
import {createTheme, PaletteColorOptions} from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface CustomPalette {
		apple: PaletteColorOptions;
	}

	interface Palette extends CustomPalette {}

	interface PaletteOptions extends CustomPalette {}
}

export const paletteTheme = createTheme({
	palette: {
		apple: {
			main: "#FFF",
		},
		primary: {
			main: "#0052cc",
		},
		secondary: {
			main: "#edf2ff",
		},
	},
});
