import {IDirection, ModalType, RequestState, RoundStatus} from "data/enums";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";
import {AxiosError} from "axios";
import {IUserRanking} from "data/types/rankings";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {SelectChangeEvent} from "@mui/material";
import {getPreviousRoundId} from "data/utils/helpers";

export interface IRankingsController extends ViewController {
	isLoaded: boolean;
	isEmptyRankings: boolean;
	rankings: IUserRanking[];
	currentUserRanking: IUserRanking | undefined | null;
	isLoadMore: boolean;
	selectedSquad: string;
	selectedRound: string;
	selectedState: string;
	statDisplay: string;
	teamLinkRound: number;
	rankingsFilters: {
		state?: string;
		roundId?: number;
		page: number;
		limit: number;
		nextPage: boolean;
	};
	get getUpdateState(): RequestState;
	get errorMessage(): string;
	handleSelectedRound: (e: SelectChangeEvent) => void;
	handleSelectedSquad: (e: SelectChangeEvent) => void;
	handleSelectedState: (e: SelectChangeEvent) => void;
	handleStatDisplay: (e: SelectChangeEvent) => void;
	handleStatDisplayClick: (val: string) => void;
	handleLoadMore: () => void;
	get pageNumber(): number;
	get squads(): ISquad[];
	get isRoundOneStarted(): boolean;
	get isRoundOneComplete(): boolean;
	get direction(): IDirection;
}

@injectable()
export class RankingsController implements IRankingsController {
	@observable private _selectedSquad: string = "";
	@observable private _selectedRound: string | number = 0;
	@observable private _selectedState: string = "";
	@observable private _pageNumber: number = 1;
	@observable private _nextPage: boolean = false;
	@observable private _statDisplay: string = "totalPoints";
	@observable _requestStateUpdate: RequestState = RequestState.IDLE;
	@observable private _direction: IDirection = IDirection.DESC;

	// @observable private _filterByType: RankingType = RankingType.All;

	//private _pageNumber: number = 1;
	private _limitSize: number = 10;

	constructor(
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get rankingsFilters() {
		const baseFilters = {
			page: this._pageNumber,
			limit: this._limitSize,
			nextPage: this._nextPage,
			order: this._statDisplay,
			direction: this._direction,
		};
		const clubFilters = this._selectedSquad === "" ? {} : {squadId: this._selectedSquad};
		const roundFilters =
			this._selectedRound === 0 ? {} : {roundId: Number(this._selectedRound)};
		const stateFilters = this._selectedState === "" ? {} : {state: this._selectedState};
		const final = {
			...baseFilters,
			...clubFilters,
			...stateFilters,
			...roundFilters,
		};
		if (this._selectedRound === 0) {
			delete final.roundId;
		}
		return final;
	}

	get direction() {
		return this._direction;
	}

	get teamLinkRound() {
		const currentRound = this._roundsStore.currentRound;
		const currentRoundId = currentRound?.id || 1;
		const previousRoundId = getPreviousRoundId(currentRoundId);
		const isCurrentRoundScheduledOrLive =
			currentRound?.status === RoundStatus.Scheduled ||
			currentRound?.status === RoundStatus.Playing;
		const roundToLink = isCurrentRoundScheduledOrLive ? previousRoundId : currentRoundId;
		if (currentRoundId === 1) {
			return 1;
		}
		return Number(this._selectedRound) === 0 || Number(this._selectedRound) >= currentRoundId
			? roundToLink
			: Number(this._selectedRound);
	}

	get selectedSquad() {
		return this._selectedSquad;
	}

	get squads(): ISquad[] {
		return this._squadsStore.list;
	}

	get selectedRound() {
		return this._selectedRound.toString();
	}

	get selectedState() {
		return this._selectedState;
	}

	get statDisplay() {
		return this._statDisplay;
	}

	get isLoaded() {
		return this._rankingsStore.isLoaded;
	}

	get rankings() {
		return this._rankingsStore.rankings.rankings;
	}

	get currentUserRanking() {
		return this._rankingsStore.rankings.currentUserRanking;
	}

	get pageNumber() {
		return this._pageNumber;
	}
	get isLoadMore() {
		return this._rankingsStore.rankings.nextPage;
	}

	get isEmptyRankings() {
		return !this._rankingsStore.rankings.rankings.length;
	}

	get getUpdateState(): RequestState {
		return this._requestStateUpdate;
	}

	get errorMessage(): string {
		return this._rankingsStore.errorMsg;
	}

	get isRoundOneStarted() {
		return this._roundsStore.isRoundOneStarted;
	}

	get isRoundOneComplete() {
		return this._roundsStore.isRoundOneComplete;
	}

	@action
	handleLoadMore = () => {
		runInAction(() => {
			const page = this._pageNumber + 1;
			this._pageNumber = page;
			this._nextPage = true;
			this.refetchList();
		});
	};

	@action
	handleStatDisplayClick = (statValue: string) => {
		if (this._statDisplay !== statValue) {
			this._pageNumber = 1;
		}
		if (this._statDisplay === statValue) {
			this._pageNumber = 1;
			const directionToApply =
				this._direction === IDirection.ASC ? IDirection.DESC : IDirection.ASC;
			this._direction = directionToApply;
		}
		this._statDisplay = statValue;
		this.refetchList();
	};

	@action
	handleSelectedSquad = (e: SelectChangeEvent) => {
		// refactor to call api in F2P1-26727
		runInAction(() => {
			const item = e.target.value;
			this._selectedState = "";
			this._selectedSquad = item;
			this._nextPage = false;
			this._pageNumber = 1;
			this.refetchList();
		});
	};

	@action
	handleSelectedRound = (e: SelectChangeEvent) => {
		// refactor to call api in F2P1-26727
		runInAction(() => {
			const item = e.target.value;
			this._selectedRound = item;
			this._nextPage = false;
			this.refetchList();
		});
	};

	@action
	handleSelectedState = (e: SelectChangeEvent) => {
		// refactor to call api in F2P1-26727
		runInAction(() => {
			const item = e.target.value;
			this._selectedState = item;
			this._selectedSquad = "";
			this._pageNumber = 1;
			this._nextPage = false;
			this.refetchList();
		});
	};

	@action
	handleStatDisplay = (e: SelectChangeEvent) => {
		this._requestStateUpdate = RequestState.PENDING;

		runInAction(() => {
			const item = e.target.value;
			this._statDisplay = item;
			this._requestStateUpdate = RequestState.SUCCESS;
		});
	};

	@action
	refetchList = () => {
		this._requestStateUpdate = RequestState.PENDING;
		try {
			this._rankingsStore
				.fetchRankings(this.rankingsFilters)
				.then(() => (this._requestStateUpdate = RequestState.SUCCESS))
				.catch((err) => {
					this._requestStateUpdate = RequestState.ERROR;
					console.log(err);
				});
		} catch (err) {
			trackSentryErrors(err, {}, "loadRankings");
			const error = err as AxiosError;

			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
		}
	};

	async init() {
		this._requestStateUpdate = RequestState.PENDING;
		try {
			await this._rankingsStore.fetchRankings(this.rankingsFilters);
			this._requestStateUpdate = RequestState.SUCCESS;
		} catch (err) {
			trackSentryErrors(err, {}, "loadRankings");
			const error = err as AxiosError;

			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
		}
	}

	dispose(): void {
		return;
	}
}
