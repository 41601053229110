import React from "react";
import styled from "@emotion/styled";

const VerticalSeparator = styled.span`
	display: inline-block;
	width: 1px;
	height: 9px;
	opacity: 0.7;
	background-color: black;
	margin: auto 3px;
`;

export const Separator: React.FC = () => {
	return <VerticalSeparator />;
};

export default Separator;
