export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	ApiHTTPClientFormData: Symbol("ApiHTTPClientFormData"),
	UserApiProvider: Symbol("UserApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	TeamStore: Symbol("TeamStore"),
	TeamBuilderStore: Symbol("TeamBuilderStore"),
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	ModalsStore: Symbol("ModalsStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	AuthController: Symbol("AuthController"),
	HeaderController: Symbol("HeaderController"),
	LoginController: Symbol("LoginController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	LeagueController: Symbol("LeagueController"),
	ModalsController: Symbol("ModalsController"),
	SettingsLeagueController: Symbol("SettingsLeagueController"),
	LadderLeagueController: Symbol("LadderLeagueController"),
	LeagueUsersController: Symbol("LeagueUsersController"),
	InviteLeagueController: Symbol("InviteLeagueController"),
	LoginCallbackController: Symbol("LoginCallbackController"),
	RegistrationController: Symbol("RegistrationController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	LeagueControlsController: Symbol("LeagueControlsController"),
	LiveScoreController: Symbol("LiveScoreController"),
	ForgotPasswordController: Symbol("ForgotPasswordController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	LeaguesStore: Symbol("LeaguesStore"),
	TeamApiProvider: Symbol("TeamApiProvider"),
	LeaguesToJoinController: Symbol("LeaguesToJoinController"),
	MyLeaguesController: Symbol("MyLeaguesController"),
	RankingsController: Symbol("RankingsController"),
	RankingsStore: Symbol("RankingsStore"),
	RankingsApiProvider: Symbol("RankingsApiProvider"),
	FixturesLeagueController: Symbol("FixturesLeagueController"),
	MatchupLeagueController: Symbol("MatchupLeagueController"),
	SettingsLeagueFormController: Symbol("SettingsLeagueFormController"),
	BootstrapController: Symbol("BootstrapController"),
	PlayerPoolController: Symbol("PlayerPoolController"),
	TeamController: Symbol("TeamController"),
	TeamLineupController: Symbol("TeamLineupController"),
	TeamControlsController: Symbol("TeamControlsController"),
	RoundTimerController: Symbol("RoundTimerController"),
	TeamFilledModalController: Symbol("TeamFilledModalController"),
	JoinCreateWidgetController: Symbol("JoinCreateWidgetController"),
	ListPlayerController: Symbol("ListPlayerController"),
	InvitingLeaguesListController: Symbol("InvitingLeaguesListController"),
	RoundSelectorController: Symbol("RoundSelectorController"),
	TradesController: Symbol("TradesController"),
	StatsCenterController: Symbol("StatsCenterController"),
	LeagueMatchupButtonsController: Symbol("LeagueMatchupButtonsController"),
	InitiateController: Symbol("InitiateController"),
	TradeController: Symbol("TradeController"),
	PlayerDataController: Symbol("PlayerDataController"),
	RecoverUserController: Symbol("RecoverUserController"),
	RegenerateLeagueController: Symbol("RegenerateLeagueController"),
};
