import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type ITeamStore} from "data/stores/team/team.store";
import {Bindings} from "data/constants/bindings";
import {useNavigate} from "react-router-dom";
import {RequestState, ShareType, SocialNetwork} from "data/enums";
import {copyToClipboard, currencyFormat} from "data/utils";
import {
	getIsCurrentScheduledAndLastComplete,
	getPlayerName,
	getPreviousRoundId,
	isAllTrue,
} from "data/utils/helpers";
import {PUBLIC_URL} from "data/constants";
import {ShareUtility} from "data/utils/social_share/social_share.utility";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";

interface IInit {
	navigate: ReturnType<typeof useNavigate>;
}
export interface ITeamFilledController extends ViewController<IInit> {
	get isTeamFilledSuccess(): boolean;
	get copyLinkState(): RequestState;
	get isTeamFilled(): boolean;
	clearIsTeamFilledSuccess: () => void;
	goToMyLeague: () => void;
	copyLink: () => void;
	shareMobile: () => void;
	shareTwitter: () => void;
	shareFacebook: () => void;
}

@injectable()
export class TeamFilledModalController implements ITeamFilledController {
	private _navigate!: IInit["navigate"];
	@observable private _copyLinkState: RequestState = RequestState.IDLE;
	private timeout: ReturnType<typeof setTimeout> | undefined = undefined;

	constructor(
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore,
		@inject(Bindings.RoundsStore) public _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get isTeamFilledSuccess() {
		const isOnceCleared = localStorage.getItem("TEAM_ONCE_SAVED") === "true";
		return this._teamStore.isAutoFillSuccess && !isOnceCleared;
	}

	get copyLinkState() {
		return this._copyLinkState;
	}

	get isTeamFilled() {
		return this._teamStore.team.isComplete && this._teamStore.isTeamFullfilled();
	}

	get teamName() {
		return this._teamStore.teamName;
	}

	get currentRound() {
		return this._roundsStore.currentRound;
	}

	shareFacebook = () => {
		ShareUtility.share({
			message: this.copyText(false),
			type: ShareType.Team,
			socialNetwork: SocialNetwork.Facebook,
			roundId: this.currentRound?.id,
		});
	};

	shareTwitter = () => {
		ShareUtility.share({
			message: this.copyText(false),
			type: ShareType.Team,
			socialNetwork: SocialNetwork.Twitter,
			roundId: this.currentRound?.id,
		});
	};

	shareMobile = () => {
		ShareUtility.share({
			message: this.copyText(false),
			type: ShareType.Team,
			socialNetwork: SocialNetwork.Mobile,
			roundId: this.currentRound?.id,
		});
	};

	@action
	clearIsTeamFilledSuccess = () => {
		this._teamStore.setAutoFillSuccess(false);
		localStorage.setItem("TEAM_ONCE_SAVED", "true");
	};
	get top5PlayersString() {
		const playersById = this._teamStore.extendedPlayersById;
		const flattenedTeamPlayerIds = this._teamStore.teamPlayerIds;
		let copyString = "";
		const top5PlayersId = flattenedTeamPlayerIds
			.sort((playerIdA, playerIdB) => {
				const playerA = playersById[playerIdA];
				const playerB = playersById[playerIdB];
				return playerA.cost > playerB.cost ? -1 : 1;
			})
			.slice(0, 5);
		top5PlayersId.forEach((playerId) => {
			const player = playersById[playerId];
			const playerName = `${getPlayerName(player)}`;
			const playerCost = currencyFormat({input: player.cost, showDecimal: true});
			copyString += `${playerName} - ${playerCost}\n`;
		});
		return copyString;
	}

	public goToMyLeague = () => this._navigate(`/leagues`);
	copyLink = async () => {
		this.timeout = setTimeout(() => {
			this._copyLinkState = RequestState.IDLE;
		}, 5000);

		await copyToClipboard(this.copyText(true));
		this._copyLinkState = RequestState.SUCCESS;
	};

	copyText(isFull: boolean) {
		const isTeamFirstRoundComplete = this._teamStore.isTeamStartRoundComplete;
		const currentRound = this._roundsStore.currentRound;
		const currentRoundId = currentRound?.id || 0;
		const lastRound = this._roundsStore.list.find(
			(round) => round.id === getPreviousRoundId(currentRoundId)
		);
		const isCurrentScheduledAndLastComplete = getIsCurrentScheduledAndLastComplete(
			lastRound,
			currentRound
		);
		const roundId = this._roundsStore.currentRound?.id || 1;

		const roundScore = this._teamStore.team.scoreflow[roundId - 1] || 0;
		if (isAllTrue([isTeamFirstRoundComplete, isCurrentScheduledAndLastComplete])) {
			return `I scored ${roundScore} in Week ${
				roundId - 1
			} of AFLW Fantasy! How well did you score? #AFLWFantasy`;
		}
		const finalLine = isFull ? `\nCreate yours now at ${PUBLIC_URL}` : "";
		return (
			`I've created my team ${this.teamName} on the official AFLW Fantasy game.\n\n` +
			`My top 5 players are:\n` +
			`${this.top5PlayersString}` +
			finalLine
		);
	}

	dispose(): void {
		return;
	}

	init(params: IInit): void {
		this._navigate = params?.navigate;
	}
}
