import React, {Fragment, useEffect} from "react";
import {NavLink, useLocation} from "react-router-dom";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {IHeaderController} from "views/components/header/header.controller";
import {Bindings} from "data/constants/bindings";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {observer} from "mobx-react";
import {IconButton, useMediaQuery} from "@mui/material";
import {ReactComponent as AflFantasy} from "assets/img/afl-fantasy.svg";
import {ReactComponent as DownArrow} from "assets/img/ArrowDropDownRounded.svg";
import {ReactComponent as OpenNewRounded} from "assets/img/OpenInNewRounded.svg";
import {clone} from "lodash";
import {HeaderAFL} from "views/components/header/header.afl.component";
import {Exist} from "views/components/exist/exist.component";
import {ISecretGateController} from "views/controllers/secrete_gate/secret_gate.controller";
import {ReactComponent as ToyotaSponsor} from "assets/img/presented-toyota.svg";

const Wrapper = styled.div`
	background-color: var(--aflwPurple);
	height: 72px;
	padding: 12px 12px;
	position: relative;
	z-index: 8;
	transition: background-color 0.5s;
	&.noshow {
		display: none;
	}
	@media (max-width: 768px) {
		&.noshow {
			display: block;
		}
		padding: 12px 20px;
	}
	&.open {
		background-color: var(--aflwPurple);
		svg {
			color: var(--aflwLightGreyPurple);
		}
	}
`;

const HeaderContent = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: var(--maxWidthHeader);

	@media (max-width: 1467px) {
		width: 100%;
		justify-content: space-between;
	}
`;

const LogoMobileMenu = styled.div`
	width: 90%;
	display: flex;
	align-items: center;
	background-color: var(--aflwPurple);
	padding: 8px 12px;
	position: relative;
`;

const Nav = styled.nav`
	display: flex;
	height: 48px;
	margin-left: 12px;
	align-items: center;
	@media (max-width: 1000px) {
		display: none;
	}

	.main-menu {
		display: flex;
		flex-direction: row;
	}

	a {
		display: flex;
		align-items: center;
	}

	ul li ul {
		visibility: hidden;
		position: absolute;
		background: var(--textWhite);
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
		border-radius: 0px 0px 8px 8px;
		transition: all 0.5s ease;
		margin-left: -8px;
		display: none;
		padding: 12px;
		top: 60px;
	}

	ul li:hover > ul,
	ul li:focus-within > ul,
	ul li ul:hover,
	ul li ul:focus {
		visibility: visible;
		opacity: 1;
		display: block;
	}

	a ul li {
		clear: both;
		margin: 2px 0px;
	}
	.sub-menu {
		border-radius: 4px;
		z-index: 10;
	}
`;

const NavItem = styled(NavLink)`
	font-size: 16px;
	padding: 0px 20px;
	font-weight: 700;
	height: 48px;
	line-height: 48px;
	transition: color 0.5s;
	color: var(--textWhite);
	text-decoration: none;
	display: block;
	&:hover,
	&.active {
		background-color: var(--textWhite);
		border-radius: 100px;
		color: var(--aflwPurple);
		@media (max-width: 1000px) {
			color: var(--aflwPurple);
		}
		svg path {
			fill: var(--aflwPurple);
		}
	}

	&.active {
		opacity: 1;
		cursor: default;
	}
`;
const SubNavItem = styled(NavLink)`
	font-size: 16px;
	padding: 0px 20px;
	font-weight: 700;
	height: 48px;
	line-height: 48px;
	transition: color 0.5s;
	color: var(--textBlack);
	text-decoration: none;
	display: block;
	// &:hover,
	// &.active {
	// 	background-color: var(--aflwPurple);
	// 	opacity: 1;
	// 	border-radius: 6px;
	// 	color: var(--textWhite);
	// 	@media (max-width: 1000px) {
	// 		color: var(--textWhite);
	// 	}
	// }
	&:hover {
		background-color: var(--aflwPurple);
		opacity: 1;
		border-radius: 6px;
		color: var(--textWhite);
		@media (max-width: 1000px) {
			color: var(--textWhite);
		}
	}

	&.active {
		opacity: 1;
		cursor: default;
	}
`;

const MNavItem = styled(NavLink)`
	font-size: 16px;
	padding: 0px 20px;
	font-weight: 700;
	height: 48px;
	line-height: 48px;
	transition: color 0.5s;
	color: var(--textWhite);
	text-decoration: none;
	display: block;
`;

const ExternalLink = styled.a`
	font-size: 16px;
	padding: 0px 20px;
	font-weight: 700;
	height: 48px;
	line-height: 48px;
	transition: color 0.5s;
	color: var(--textBlack);
	text-decoration: none;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
`;
// const LogoutButton = styled(NavItem.withComponent(ButtonSecondary))``;
// const LoginLink = LogoutButton.withComponent(NavLink);

const MenuWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;
const MenuButton = styled.button`
	color: var(--textWhite);
	border: 0;
	background: transparent;
	appearance: none;
	outline: none;
	cursor: pointer;
	padding: 10px 0;
	display: none;

	@media (max-width: 1000px) {
		display: inline-block;
		float: right;
	}

	.open & {
		color: var(--textWhite);
	}
`;

const CloseButton = styled(IconButton)`
	height: 32px;
	width: 32px;
	color: var(--aflwPurple);
	background: var(--textWhite);
	position: absolute;
	right: -16px;
	z-index: 12;
`;

const MobileMenu = styled.div`
	width: 100%;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 12;
	transform: translateX(-100%);
	transition: transform 0.5s;
	&.open {
		transform: translateX(0);
	}
	@media (min-width: 768px) {
		display: none;
	}
`;

const MobileMenuScroll = styled.div`
	width: 90%;
	height: 100%;
	overflow: scroll;
	background-color: var(--aflwLightPurPleBackground);
`;

const MobileMenuInner = styled.div`
	display: flex;
	flex-flow: column;
	align-items: flex-start;
`;

const MobileMenuNav = styled.nav`
	display: flex;
	height: 48px;
	line-height: 48px;
	flex-flow: column;
	align-items: flex-start;
	flex: 1;
	width: 100%;
	margin-bottom: 15px;
	border-bottom: 1px solid #e5e5e5;
	padding-bottom: 15px;
	a {
		color: #000;
	}
`;

const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.8);
	display: none;
	z-index: 8;
	&.open {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
`;
const PresentedToyotaWrapper = styled.div`
	width: 80px;
	height: 100%;
	display: flex;
	align-items: center;
	margin-left: 10px;
	svg {
		width: 100%;
		height: 28px;
	}
`;

const StyledNavLink = styled(NavLink)`
	margin-left: 4px;
	svg {
		height: 44px;
		width: 44px;
	}
`;
// interface IProps {
// 	isAuthorized: boolean;
// 	handleLogout: () => void;
// }

// const AuthButton: React.FC<IProps> = ({isAuthorized, handleLogout}) => (
// 	<Fragment>
// 		<Exist when={isAuthorized}>
// 			<ButtonPrimary onClick={handleLogout} style={{marginLeft: 18}}>
// 				Logout
// 			</ButtonPrimary>
// 		</Exist>
// 		<Exist when={!isAuthorized}>
// 			<LoginLink to="/login">Login</LoginLink>
// 		</Exist>
// 	</Fragment>
// );

export const Header: React.FC = observer(() => {
	const location = useLocation();
	const isDesktop = useMediaQuery("(max-width: 1000px)");
	const {isMenuOpen, menuItems, handleToggleMenu, isAuthorizedAndRecovered} =
		useViewController<IHeaderController>(Bindings.HeaderController);
	const isLoginPage = location.pathname === "/";
	const isPrizesPage = location.pathname === "/help/prizes";
	useEffect(() => {
		handleToggleMenu(false);
	}, [location, handleToggleMenu]);

	useEffect(() => {
		handleToggleMenu(false);
	}, [isDesktop, handleToggleMenu]);

	const menuClassName = isMenuOpen ? "open" : "";
	const headerClassName = isLoginPage ? "noshow" : "";
	const aflwLink = "https://www.womens.afl";

	const mobileMenuItems = clone(menuItems);
	if (isAuthorizedAndRecovered) {
		mobileMenuItems.push({title: "My Account", path: "/my-account"});
	}
	const {isSecretPassed} = useViewController<ISecretGateController>(
		Bindings.SecretGateController
	);
	return (
		<Fragment>
			<HeaderAFL />
			<Wrapper className={`${menuClassName} ${headerClassName}`}>
				<HeaderContent>
					<MenuWrapper>
						<StyledNavLink to={"/"} aria-label="Go to the first page">
							<AflFantasy />
						</StyledNavLink>
						<PresentedToyotaWrapper>
							<ToyotaSponsor />
						</PresentedToyotaWrapper>
						<Nav>
							<ul className="main-menu">
								{menuItems.map((itm, x) => {
									if (itm.title === "Help" && isPrizesPage) {
										return (
											<li key={itm.title}>
												<NavItem to={itm.path} end>
													{itm.title}
												</NavItem>
											</li>
										);
									}
									return (
										<li
											key={itm.title}
											data-desc={`mobile-menu-navitem-${itm.title}`}>
											<NavItem to={itm.path}>
												{itm.submenu ? (
													<>
														{itm.title} <DownArrow />{" "}
													</>
												) : (
													itm.title
												)}
											</NavItem>
											{itm.submenu && (
												<ul className="sub-menu">
													{itm.submenu.map((sub, y) => (
														<li
															key={sub.subTitle}
															data-desc={`mobile-menu-navitem-${sub.subTitle}`}>
															<SubNavItem to={sub.url}>
																{sub.subTitle}
															</SubNavItem>
														</li>
													))}
												</ul>
											)}
										</li>
									);
								})}
							</ul>
						</Nav>
					</MenuWrapper>
					<Exist when={isSecretPassed}>
						<MenuButton
							onClick={() => handleToggleMenu()}
							data-desc="mobile-open-menu"
							aria-label="Open Mobile Menu">
							{isMenuOpen ? <CloseIcon /> : <MenuIcon />}
						</MenuButton>
					</Exist>
				</HeaderContent>
			</Wrapper>
			<Overlay className={menuClassName} />
			<MobileMenu id={"mobile-menu"} className={menuClassName}>
				<LogoMobileMenu>
					<StyledNavLink
						data-desc="mobile-menu-aflw-icon"
						to={"/"}
						aria-label="Go to the first page">
						<AflFantasy />
					</StyledNavLink>
					<PresentedToyotaWrapper>
						<ToyotaSponsor />
					</PresentedToyotaWrapper>
					<CloseButton
						data-desc="mobile-menu-close"
						aria-label="Close Menu"
						onClick={() => handleToggleMenu()}>
						<CloseIcon />
					</CloseButton>
				</LogoMobileMenu>
				<MobileMenuScroll className={menuClassName}>
					<MobileMenuInner>
						<MobileMenuNav>
							<ul className="main-menu">
								{mobileMenuItems.map((itm) => {
									return (
										<li
											data-desc={`mobile-menu-navitem-${itm.title}`}
											key={itm.title}>
											<MNavItem to={itm.path}>{itm.title}</MNavItem>
											{itm.submenu && (
												<ul>
													{itm.submenu.map((sub, y) => (
														<li
															data-desc={`mobile-menu-navitem-${sub.subTitle}`}
															key={sub.subTitle}>
															<MNavItem to={sub.url}>
																{sub.subTitle}
															</MNavItem>
														</li>
													))}
												</ul>
											)}
										</li>
									);
								})}
							</ul>
							{/* <AuthButton isAuthorized={isAuthorized} handleLogout={handleLogout} /> */}
						</MobileMenuNav>
						<ExternalLink href={aflwLink} target="_blank">
							{`AFLW Women's`} <OpenNewRounded />
						</ExternalLink>
						<ExternalLink href={"https://aflwtipping.afl/"} target="_blank">
							{`AFLW Tipping`} <OpenNewRounded />
						</ExternalLink>
					</MobileMenuInner>
				</MobileMenuScroll>
			</MobileMenu>
		</Fragment>
	);
});
