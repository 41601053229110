import {RequestState, ModalType} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {useNavigate} from "react-router-dom";
import {RefObject} from "react";
import {throttle} from "lodash";
import {Bindings} from "data/constants/bindings";
import {getIsInViewPort} from "data/utils/scroll";
import {trackSentryErrors} from "data/utils";
import {ILeague} from "data/types/leagues";
import {AxiosError} from "axios";
import type {IAuthController} from "views/controllers/auth/auth.controller";

interface IInit {
	navigate: ReturnType<typeof useNavigate>;
	loadMoreAnchorRef?: RefObject<HTMLDivElement>;
	code?: string;
}

export interface ILeaguesToJoinController extends ViewController<IInit> {
	get isLoaded(): boolean;
	get isEmptyLeagues(): boolean;
	get leaguesToJoin(): ILeague[];
	get isLoadMore(): boolean;
	get isSearch(): boolean;
	get isAuthorized(): boolean;
	get isPending(): boolean;

	leagueName(code: string): string | undefined;
	joinWhenNotJoined(leagueCode: string, leagueId: number): Promise<void>;
	declineInvite(leagueId: number): Promise<void>;
	goToCreateLeague(): void;
	goToLeague(leagueId: number): void;
	isLeagueJoined(leagueId: number): boolean;
	getLeaguesByQuery(value: string): void;
	getLeagueByCode(code: string): ILeague | undefined;
	getLeagueByCodeNoAuth(): ILeague | undefined;
	goToMyLeague(): void;
	increasePageNumber: () => void;
	clearCodeFromQuery(): void;
	setIsSearch(search: boolean): void;

	calculatePageNumber(): void;
	fetchLeagues(query?: string): Promise<void>;
	fetchLeagueByCodeNoAuth(code: string): Promise<void>;
}

@injectable()
export class LeaguesToJoinController implements ILeaguesToJoinController {
	@observable private _requestState = RequestState.IDLE;
	private _pageNumber = 1;

	private _pageSize = 20;

	private _isSearch: boolean = false;
	private _query = "";
	private _searchLeagueNoAuth: ILeague | undefined;

	private _navigate!: IInit["navigate"];
	private _loadMoreAnchorRef: IInit["loadMoreAnchorRef"];

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.AuthController) private _authController: IAuthController
	) {
		makeAutoObservable(this);
	}

	get isAuthorized(): boolean {
		return this._authController.isAuthorized;
	}

	get isLoaded() {
		return [RequestState.SUCCESS, RequestState.ERROR].includes(this._requestState);
	}

	get leaguesToJoin() {
		return this.isSearch
			? this._leaguesStore.joinSearchLeagues.leagues
			: this._leaguesStore.joinLeagues.leagues;
	}

	get isEmptyLeagues() {
		return (
			(this._isSearch
				? !this._leaguesStore.joinSearchLeagues.leagues.length
				: !this._leaguesStore.joinLeagues.leagues.length) &&
			this.isLoaded &&
			this._leaguesStore.isLoaded
		);
	}

	get isLoadMore(): boolean {
		return !!this._leaguesStore.joinLeagues?.nextPage && this.isLoaded;
	}

	get isPending() {
		return this._requestState === RequestState.PENDING;
	}

	get isSearch(): boolean {
		return this._isSearch;
	}

	setIsSearch(search: boolean) {
		this._isSearch = search;
	}

	leagueName(code: string): string | undefined {
		const league = this.getLeagueByCode(code);
		return league?.name;
	}

	public increasePageNumber = () => {
		this._pageNumber = this._pageNumber + 1;
		void this.fetchLeagues(this._query);
	};

	public async fetchLeagues(query?: string) {
		try {
			await this._leaguesStore.fetchLeaguesToJoin({
				search: query || "",
				page: this._pageNumber,
				limit: this._pageSize,
			});
		} catch (err) {
			trackSentryErrors(err, {}, "leagues to join - fetch leagues");
			const error = err as AxiosError;
			this._requestState = RequestState.ERROR;
			if (this.isAuthorized) {
				this._modalsStore.showModal(ModalType.ERROR, {
					message: error.message,
					errors: error.response?.data,
				});
			}
		}
	}

	async _join(leagueCode: string) {
		try {
			const league = await this._leaguesStore.joinLeague(leagueCode);
			if (league?.id) {
				this._modalsStore.showModal(ModalType.LEAGUE_JOINED, {
					message: league?.name || "",
				});
			}
		} catch (err) {
			trackSentryErrors(err, {}, "leagues to join - join");
			const error = err as AxiosError;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
		}
	}

	public async getLeaguesByQuery(value: string) {
		this._leaguesStore.clearJoinLeagues();
		await this.fetchLeagues(value);
	}

	public isLeagueJoined(id: number): boolean {
		return this._leaguesStore.isLeagueJoined(id);
	}

	public goToCreateLeague() {
		this._navigate("/leagues/create");
	}

	public goToLeague(leagueId: number) {
		this._navigate(`/leagues/${leagueId}/about`);
	}

	public goToMyLeague() {
		this._navigate(`/leagues`);
	}

	async joinWhenNotJoined(leagueCode: string, leagueId: number) {
		if (!this.isLeagueJoined(leagueId) && this.isAuthorized) {
			await this._join(leagueCode);
		}
	}

	async declineInvite(leagueId: number) {
		await this._leaguesStore.declineInviteLeague(leagueId);
	}

	public getLeagueByCode(leagueCode: string) {
		return this.leaguesToJoin.find((league) => league.code === leagueCode);
	}

	public getLeagueByCodeNoAuth() {
		return this._searchLeagueNoAuth;
	}

	async fetchLeagueByCodeNoAuth(leagueCode: string) {
		try {
			const searchLeagueNoAuth = await this._leaguesStore.getLeagueByCodeNoAuth(leagueCode);

			runInAction(() => {
				this._searchLeagueNoAuth = searchLeagueNoAuth;
			});
		} catch (err) {
			trackSentryErrors(err, {}, "leagues to join - fetch leagues no auth");
			const error = err as AxiosError;
			this._requestState = RequestState.ERROR;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
		}
	}

	public clearCodeFromQuery() {
		this._navigate("/leagues");
	}

	calculatePageNumber() {
		const anchor = this._loadMoreAnchorRef?.current;
		if (this.isLoadMore) {
			const isInViewPort = getIsInViewPort(anchor);
			if (isInViewPort) {
				this.increasePageNumber();
			}
		}
	}

	get _loadMoreDebounce() {
		return throttle(() => this.calculatePageNumber(), 500);
	}

	_addScrollEvent() {
		window.addEventListener("scroll", this._loadMoreDebounce);
	}

	_removeScrollEvent() {
		window.removeEventListener("scroll", this._loadMoreDebounce);
	}

	async init(params: IInit) {
		this._navigate = params?.navigate;
		params?.code && (await this.fetchLeagueByCodeNoAuth(params.code));
		if (this._requestState === RequestState.PENDING) {
			return;
		}
		this._requestState = RequestState.PENDING;
		try {
			await this.fetchLeagues(params.code);
			if (this.isAuthorized) {
				await this._leaguesStore.fetchInvitingLeagues();
			}
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (err) {
			trackSentryErrors(err, {}, "join leagues init");
			const error = err as AxiosError;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
			this._requestState = RequestState.ERROR;
		}
		this._addScrollEvent();
	}

	dispose(): void {
		this._leaguesStore.clearLeagueUsers();
		this._leaguesStore.clearJoinLeagues();
		this._removeScrollEvent();
	}
}
