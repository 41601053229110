import React, {Fragment} from "react";
import styled from "@emotion/styled";
import {useMediaQuery} from "@mui/material";
import {Outlet, useLocation} from "react-router-dom";
import {Advert} from "views/components/adverts";
import {Header} from "views/components/header/header.component";
import {Footer} from "views/components/footer/footer.component";
import {
	Aside,
	ContentBox,
	FlexContainer,
	Main,
	TeamMain,
} from "views/components/composition/composition.component";
import {Exist} from "views/components/exist/exist.component";
import {LeagueSidebar} from "views/components/leagues/league_sidebar/league_sidebar.component";
import {JoinCreateWidget} from "views/components/leagues/widgets/join_create_widget/join_create_widget.component";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {ITeamControlsController} from "views/components/team/team_controls/team_controls.controller";
import {Bindings} from "data/constants/bindings";

const WEB_AD_PATH = `${window.location.origin}/ads/top-lb.html`;
const MOBILE_AD_PATH = `${window.location.origin}/ads/top-lb-mob.html`;

const Container = styled(FlexContainer)`
	align-items: flex-start;
	height: -webkit-fill-available;
	box-sizing: border-box;
	gap: 20px;

	@media (max-width: 1200px) {
		padding: 20px 0;
	}
`;

const TwoColumnLayout = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
	margin-bottom: 50px;
`;

const AdPadding = styled.div`
	background: var(--textWhite);
	padding: 24px 0px;
	text-align: center;
	@media (max-width: 640px) {
		padding: 12px 16px;
		background: var(--aflwPurple);
	}
`;

const AdPaddingTeam = styled(AdPadding)`
	@media (max-width: 640px) {
		padding: 0;
	}
`;

const AdPaddingNoBottom = styled.div`
	background: var(--textWhite);
	padding: 24px 0 0;
	text-align: center;
	@media (max-height: 700px) {
		.ad {
			display: none;
		}
	}
`;

const IFrameWrap = styled.iframe`
	height: 90px;
	width: 728px;
	@media (max-width: 728px) {
		height: 50px;
		width: 100%;
	}
`;

export const MainWithContainer: React.FC = () => {
	return (
		<Fragment>
			<Header />
			<Main>
				<TwoColumnLayout>
					<Container>
						<ContentBox>
							<Outlet />
						</ContentBox>
					</Container>
				</TwoColumnLayout>
			</Main>
			<Footer />
		</Fragment>
	);
};

const maxWidth1000 = "(max-width: 1000px)";
export const TwoAdsLayout: React.FC = () => {
	const isMobile = useMediaQuery(maxWidth1000);
	const location = useLocation();
	const currentPath = location.pathname;
	const isLadder = currentPath.includes("ladder") || currentPath.includes("matchup");

	const showJoinCreateWidget =
		currentPath === "/leagues" || currentPath.startsWith("/leagues/join");

	const containerSelector = ["/rankings"].includes(currentPath)
		? "fullwidth-container"
		: "inner-container";
	const isHelpPage = currentPath.includes("help") || currentPath.includes("account");
	return (
		<Fragment>
			<Header />
			<Main isHelpPage={isHelpPage}>
				<TwoColumnLayout>
					<AdPadding>
						<Exist when={!isMobile}>
							<IFrameWrap title="top-leaderboard" src="/ads/top-lb.html" />
						</Exist>
						<Exist when={isMobile}>
							<IFrameWrap title="top-leaderboard" src="/ads/top-lb-mob.html" />
						</Exist>
					</AdPadding>
					<Container className={containerSelector}>
						<ContentBox isLadder={isLadder} isHelpPage={isHelpPage}>
							<Outlet />
						</ContentBox>
						<Aside>
							<Exist when={showJoinCreateWidget}>
								<LeagueSidebar>
									<JoinCreateWidget />
								</LeagueSidebar>
							</Exist>
							<Exist when={!isMobile}>
								<Advert $id="fg-mpu" index={2} isHelpPage={isHelpPage} />
							</Exist>
						</Aside>
					</Container>
					<AdPadding>
						<Exist when={!isMobile}>
							<IFrameWrap title="bottom-leaderboard" src="/ads/bottom-lb.html" />
						</Exist>
						<Exist when={isMobile}>
							<IFrameWrap title="bottom-leaderboard" src="/ads/bottom-lb-mob.html" />
						</Exist>
					</AdPadding>
				</TwoColumnLayout>
			</Main>
			<Footer />
		</Fragment>
	);
};

export const WithFooterLayout: React.FC = () => {
	return (
		<Fragment>
			<Main>
				<Outlet />
			</Main>
			<Footer />
		</Fragment>
	);
};

export const FullWidthLayout: React.FC = () => {
	return (
		<Fragment>
			<Header />
			<Main>
				<Outlet />
			</Main>
			<Footer />
		</Fragment>
	);
};

export const FullWidthLayoutHome: React.FC = () => {
	const isMobile = useMediaQuery(maxWidth1000);
	return (
		<Fragment>
			<Header />
			<TeamMain>
				<Outlet />
			</TeamMain>
			<AdPaddingNoBottom>
				<Exist when={!isMobile}>
					<IFrameWrap
						className="ad"
						title="bottom-leaderboard"
						src="ads/bottom-lb.html"
					/>
				</Exist>
				<Exist when={isMobile}>
					<IFrameWrap
						className="ad-mob"
						title="bottom-leaderboard"
						src="ads/bottom-lb-mob.html"
					/>
				</Exist>
			</AdPaddingNoBottom>
			<Footer />
		</Fragment>
	);
};

export const FullWidthLayoutTeam: React.FC = observer(() => {
	const isMobile = useMediaQuery(maxWidth1000);
	const {isTeamOnceComplete} = useViewController<ITeamControlsController>(
		Bindings.TeamControlsController,
		{isOtherUser: false}
	);

	return (
		<Fragment>
			<Header />
			<Exist when={isTeamOnceComplete}>
				<AdPaddingTeam>
					<Exist when={!isMobile}>
						<IFrameWrap title="top-leaderboard" src={WEB_AD_PATH} />
					</Exist>
					<Exist when={isMobile}>
						<IFrameWrap title="top-leaderboard" src={MOBILE_AD_PATH} />
					</Exist>
				</AdPaddingTeam>
			</Exist>
			<TeamMain>
				<Outlet />
			</TeamMain>
			<Footer />
		</Fragment>
	);
});

export const FullWidthLayoutUserTeam: React.FC = observer(() => {
	const isMobile = useMediaQuery(maxWidth1000);
	const {isTeamOnceComplete} = useViewController<ITeamControlsController>(
		Bindings.TeamControlsController,
		{isOtherUser: true}
	);

	return (
		<Fragment>
			<Header />
			<Exist when={isTeamOnceComplete}>
				<AdPaddingTeam>
					<Exist when={!isMobile}>
						<IFrameWrap title="top-leaderboard" src={WEB_AD_PATH} />
					</Exist>
					<Exist when={isMobile}>
						<IFrameWrap title="top-leaderboard" src={MOBILE_AD_PATH} />
					</Exist>
				</AdPaddingTeam>
			</Exist>
			<TeamMain>
				<Outlet />
			</TeamMain>
			<Footer />
		</Fragment>
	);
});

export const EmptyLayout: React.FC = () => {
	return (
		<Main>
			<Outlet />
		</Main>
	);
};
