import {RequestState, ModalType, LeagueType} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {useNavigate} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";
import {ILeague} from "data/types/leagues";
import {AxiosError} from "axios";
import {get} from "lodash";
interface IInit {
	navigate?: ReturnType<typeof useNavigate>;
}

export interface IMyLeaguesController extends ViewController<IInit> {
	isLoaded: boolean;
	isEmptyLeagues: boolean;
	myLeagues: ILeague[];
	isLoadMore: boolean;
	currentRoundId: number;

	filterByType(type: LeagueType): void;
	goToCreateLeague(): void;
	goToJoinLeague(): void;
	loadMoreLeague(): Promise<void>;
}

@injectable()
export class MyLeaguesController implements IMyLeaguesController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _filterByType: LeagueType = LeagueType.All;

	private _pageNumber: number = 1;
	private _limitSize: number = 10;
	private _navigate: IInit["navigate"];

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isLoaded() {
		return [RequestState.SUCCESS, RequestState.ERROR].includes(this._requestState);
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get myLeagues() {
		if (this._filterByType === LeagueType.All) {
			return this._leaguesStore.userLeagues.leagues;
		}

		return this._leaguesStore.userLeagues.leagues.filter((it) => {
			return it.privacy === this._filterByType;
		});
	}

	get currentRoundId() {
		return get(this._roundsStore.currentRound, "id", 1);
	}

	get isLoadMore() {
		return this._leaguesStore.userLeagues.nextPage;
	}

	get isEmptyLeagues() {
		return !this._leaguesStore.userLeagues.leagues.length;
	}

	filterByType(type: LeagueType) {
		this._filterByType = type;
	}

	goToCreateLeague() {
		this._navigate!("/leagues/create");
	}

	goToJoinLeague() {
		this._navigate!("/leagues/join");
	}

	async loadMoreLeague() {
		this._pageNumber++;
		try {
			await this._leaguesStore.fetchLeagues({page: this._pageNumber, limit: this._limitSize});
		} catch (err) {
			trackSentryErrors(err, {}, "my leagues load more");
			const error = err as AxiosError;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
			this._requestState = RequestState.ERROR;
		}
	}

	async init(params: IInit) {
		this._navigate = params.navigate;
		if (this.isLoaded || this.isLoading) {
			return;
		}
		this._requestState = RequestState.PENDING;

		try {
			await this._leaguesStore.fetchLeagues({page: this._pageNumber, limit: this._limitSize});
			await this._leaguesStore.fetchInvitingLeagues();
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (err) {
			trackSentryErrors(err, {}, "my leagues init");
			const error = err as AxiosError;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
			this._requestState = RequestState.ERROR;
		}
	}

	dispose(): void {
		return;
	}
}
