import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {DEFAULT_SINGLE_TRADE, type ITrade, type ITeamStore} from "data/stores/team/team.store";

interface IParams {
	trade: ITrade;
}

export interface ITradeController extends ViewController<IParams> {
	get isReversable(): boolean;
}

@injectable()
export class TradeController implements ITradeController {
	@observable private _trade: ITrade = {id: 1, ...DEFAULT_SINGLE_TRADE};
	constructor(
		@inject(Bindings.RoundsStore) public _roundStore: IRoundsStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get isReversable() {
		return Boolean(this._trade.in && this._trade.out && !this._trade.fromBE);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._trade = param.trade;
	}
}
