import React, {useState} from "react";
import styled from "@emotion/styled";
// import { IPlayerExtended } from "./team_field.controller";
import {PlayerPosition, PlayerStatus, UTILITY} from "data/enums";
import {ButtonPure} from "views/components/buttons/buttons.component";
import {ReactComponent as AddPlayer} from "assets/img/icons/add_player.svg";
import {getStatusIcon} from "views/components/team/player_pool/player_card.component";
import {ReactComponent as Swap} from "assets/img/icons/swap.svg";
import {ReactComponent as ArrowRight} from "assets/img/icons/arrow_right.svg";
import {ReactComponent as ArrowLeft} from "assets/img/icons/arrow_left.svg";
import {Exist} from "views/components/exist/exist.component";
import {
	getPlayerName,
	getPlayerPositionText,
	getPlayerStatusColors,
	getPositionFullName,
	isAllTrue,
} from "data/utils/helpers";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {IListPlayerController} from "views/components/team/lineup/team_player.controller";
import {Bindings} from "data/constants/bindings";
import {getClubLogo} from "data/utils/helpers/clubLogoGetter";
import {PlayerActions} from "./field_player_actions.component";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {ITeamLineupController} from "views/components/team/lineup/team_lineup.controller";
import Separator from "views/components/separator/separator.component";
import {PLAYER_PLACEHOLDER_IMG_URL} from "data/constants";

const Wrapper = styled.div<{
	isSwapDisabled: boolean;
	clubId: number;
	isCaptain: boolean;
	isViceCaptain: boolean;
	isPlayerIncomingTrade: boolean;
	isPlayerOutgoingTrade: boolean;
	fadeForScoring: boolean;
	isCurrentRoundIndigenous: boolean;
	isPlayerInitialSwap: boolean;
}>`
	width: 184px;
	height: 116px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: var(--aflwLightGreyPurple);
	position: relative;
	overflow: hidden;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
	&::before {
		content: " ";
		display: block;
		position: absolute;
		right: -10px;
		width: 150px;
		height: 100%;
		top: -40px;
		opacity: 0.2;
		background: url(${({clubId, isCurrentRoundIndigenous}) =>
				getClubLogo(clubId, isCurrentRoundIndigenous)})
			no-repeat;
		background-size: 180px auto;
	}

	${({isSwapDisabled}) =>
		isSwapDisabled &&
		`
        opacity: 0.4;
    `}

	${({isCaptain}) =>
		isCaptain &&
		`
		background: linear-gradient(0deg, rgba(0, 212, 231, 0.15) 0%, rgba(0, 212, 231, 0.15) 100%), #FFF;
	`};

	${({isViceCaptain}) =>
		isViceCaptain &&
		`
		background: linear-gradient(0deg, rgba(254, 86, 222, 0.15) 0%, rgba(254, 86, 222, 0.15) 100%), #FFF;
	`};

	${({fadeForScoring}) =>
		fadeForScoring &&
		`
		opacity: 0.6;
	`}

	${({isPlayerOutgoingTrade}) =>
		isPlayerOutgoingTrade &&
		`
		background: linear-gradient(270deg, rgba(227, 37, 37, 0.2) 0%, rgba(255, 255, 255, 0) 98.37%), #FFFFFF;
		opacity: 0.6;
	`}
	${({isPlayerIncomingTrade}) =>
		isPlayerIncomingTrade &&
		`
		background: linear-gradient(270deg, rgba(32, 183, 65, 0.2) 0%, rgba(255, 255, 255, 0) 98.37%), #FFFFFF;
	`}
	${({isPlayerInitialSwap}) =>
		isPlayerInitialSwap &&
		`
		box-shadow: 0px 0px 20px 0px #6C3FCC;
	`}
`;

const EmptyWrapper = styled(ButtonPure)<{
	isSwapDisabled: boolean;
}>`
	width: 184px;
	height: 116px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
	align-items: center;
	background: var(--aflwLightGreyPurple);
	${({isSwapDisabled}) =>
		isSwapDisabled &&
		`
        opacity: 0.4;
    `}
`;

const EmptyPlayerSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: 68px;
	width: 100%;
	background: url(${PLAYER_PLACEHOLDER_IMG_URL}) no-repeat;
	background-position: center;
	background-size: 75px auto;
	position: relative;
	svg {
		width: 24px;
		border-radius: 50%;
	}
`;

const AddPlayerIcon = styled(AddPlayer)`
	position: absolute;
	top: 56px;
`;

const SwapIcon = styled(Swap)`
	position: absolute;
	top: 56px;
`;

const FullPlayerSection = styled(EmptyPlayerSection)`
	background-image: unset;
	position: relative;
	background-size: auto 100px;
	background-position: 45px;
`;

const PlayerImageBackground = styled.img<{
	playerImg?: string;
}>`
	background-repeat: no-repeat;
	background-size: cover;
	height: 78px;
	width: 78px;
	position: absolute;
	top: -10px;
`;

const RoundScore = styled.div<{
	isLive: boolean;
	isDNP: boolean;
}>`
	width: 36px;
	height: 36px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	font-family: var(--fontFamilyBase);
	align-items: center;
	background: var(--aflwPurple);
	padding: 6px;
	position: absolute;
	right: 6px;
	top: 7px;
	font-weight: 700;
	color: var(--textWhite);
	font-size: 14px;
	letter-spacing: 1px;
	${({isLive}) =>
		isLive &&
		`
		background: var(--statusLivePlaying);
	`}

	${({isDNP}) =>
		isDNP &&
		`
		font-size: 12px
	`}
`;

const AddRow = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25px;
	font-size: 14px;
	font-weight: 700;
	color: var(--textWhite);
	background: #4a4a4a;
	border-radius: 0 0 10px 10px;
`;

const PositionRow = styled(AddRow)`
	color: var(--textBlack);
	background: var(--textWhite);
	font-weight: 600;
`;

const EmptyPositionRow = styled(AddRow)`
	height: 48px;
	background: var(--textWhite);
	color: var(--textBlack);
`;

const PlayerInfoRow = styled(AddRow)<{
	isCaptain: boolean;
	isViceCaptain: boolean;
	isBench: boolean;
	isTradeMode: boolean;
}>`
	justify-content: space-between;
	padding-right: 5px;
	z-index: 1;
	background-color: var(--aflwDarkPurple);
	border-radius: 0;
	${({isBench, isTradeMode}) =>
		(isBench || isTradeMode) &&
		`
		background-color: var(--aflCoreDarkNavy);
	`}
	${({isCaptain}) =>
		isCaptain &&
		`
		background-color: var(--aflwBlue);
		button,div {
			color: var(--aflwPrimaryPurple);
		}

	`}
	${({isViceCaptain}) =>
		isViceCaptain &&
		`
		background-color: var(--aflwPink);
		button,div {
			color: var(--aflwPrimaryPurple);
		}
	`}
`;

const FilledPositionRow = styled(PositionRow)`
	justify-content: space-between;
	padding: 0 5px;
	background-color: var(--aflw-primary-200);
	padding-left: 0;
`;

export const StatusSection = styled.div<{
	status: PlayerStatus;
}>`
	width: 24px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		width: 100%;
		height: 100%;
	}
	background: ${({status}) => status && getPlayerStatusColors(status)};
`;

const StatusName = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;
	justify-content: space-between;
	width: 100%;
	.field-player-name {
		padding-left: 5px;
		min-width: 68px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 14px;
		font-weight: 600;
	}
`;

const PlayerName = styled(ButtonPure)`
	color: #ffffff;
	max-width: 130px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	text-align: left;
`;

const OpponentInfo = styled.div`
	font-size: 12px;
	font-weight: 500;
	display: flex;
	align-items: center;
	strong {
		font-size: 12px;
		margin: 0px 3px 0 0;
	}
`;

export const PlayerIcon = styled.div<{
	isPlayerIncoming: boolean;
	isFromPool?: boolean;
}>`
	min-width: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 24px;
	border-radius: 50%;
	position: absolute;
	right: 6px;
	top: 6px;
	background: ${({isPlayerIncoming}) =>
		isPlayerIncoming ? `var(--statusLivePlaying)` : `#E32525`};
	svg {
		height: 10px;
		width: 10px;
	}
	${({isFromPool}) =>
		isFromPool &&
		`
		position: absolute;
		right: unset;
		top: calc(50% - 12px);
		left: 2px;
	`}
`;

interface IFieldPlayer {
	playerId: number;
	position: PlayerPosition | UTILITY.UTIL;
	index: number;
	isBench: boolean;
	fieldStat: string;
	isUtility?: boolean;
	isOtherUser: boolean;
	onFocus?: () => void;
}

export const FieldPlayer: React.FC<IFieldPlayer> = observer(
	({playerId, position, isBench, isUtility, index, fieldStat, isOtherUser, onFocus}) => {
		const {statsOptions} = useViewController<ITeamLineupController>(
			Bindings.TeamLineupController
		);
		const {
			setPosition,
			swapPlayer,
			fadeForScoring,
			isSelectedRoundComplete,
			getStatValue,
			player,
			isSwapPlayerEmpty,
			isSwapDisabled,
			isCaptain,
			isPlayerIncomingTrade,
			isPlayerOutgoingTrade,
			playerImgPath,
			isViceCaptain,
			isSwapButtonNowAvailable,
			isLive,
			roundScore,
			isSwapDisabledEmptyPlayer,
			isLiveOrHasScore,
			isPlayerIncomingFromBE,
			playerStatus,
			isPlayerInitialSwap,
			isTradeOutDisabled,
			isCurrentRoundIndigenous,
			isTradeMode,
			opponentInfoComponent,
		} = useViewController<IListPlayerController>(Bindings.ListPlayerController, {
			playerId,
			isBench,
			position,
		});
		const [actionsOpen, setActionsOpen] = useState(false);
		const toggleActionsOpen = () => {
			setActionsOpen(!actionsOpen);
		};
		const openActions = () => {
			setActionsOpen(true);
		};
		const closeActions = () => {
			setActionsOpen(false);
		};
		const activeFieldStatOption = statsOptions.find((option) => option.val === fieldStat);
		const {showMiniPlayer} = useViewController<IModalsController>(
			Bindings.ModalsController,
			{}
		);
		const addAction = (e: React.SyntheticEvent<HTMLButtonElement>) => {
			onFocus && onFocus();
			setPosition(e);
		};
		const emptyPlayerAction = (e: React.SyntheticEvent<HTMLButtonElement>) => {
			if (isSwapButtonNowAvailable) {
				swapPlayer({
					playerId,
					index,
					position,
					isBench,
				});
				return;
			}
			addAction(e);
		};

		if (!playerId || !player) {
			// disable it when sub is not empty, the position doesnt match and its not utility
			return (
				<EmptyWrapper
					data-desc="field-add"
					isSwapDisabled={isSwapDisabledEmptyPlayer}
					onClick={emptyPlayerAction}
					value={position}
					data-playerindex={index}
					data-isbench={isBench}>
					<EmptyPlayerSection>
						<Exist when={isSwapButtonNowAvailable}>
							<SwapIcon aria-label="Remove a player from team" />
						</Exist>
						<Exist when={isSwapPlayerEmpty}>
							<AddPlayerIcon aria-label="Add a player to team" />
						</Exist>
					</EmptyPlayerSection>
					<EmptyPositionRow>Add {getPositionFullName(position)}</EmptyPositionRow>
				</EmptyWrapper>
			);
		}

		return (
			<Wrapper
				isSwapDisabled={isSwapDisabled}
				clubId={player.squadId}
				isPlayerIncomingTrade={isPlayerIncomingTrade}
				isCurrentRoundIndigenous={isCurrentRoundIndigenous}
				isPlayerOutgoingTrade={isPlayerOutgoingTrade}
				isCaptain={isCaptain}
				isViceCaptain={isViceCaptain}
				onMouseOver={openActions}
				isPlayerInitialSwap={isPlayerInitialSwap}
				onMouseOut={closeActions}
				fadeForScoring={fadeForScoring}>
				<Exist when={isPlayerIncomingTrade}>
					<PlayerIcon isPlayerIncoming={true}>
						<ArrowLeft />
					</PlayerIcon>
				</Exist>
				<Exist when={isPlayerOutgoingTrade}>
					<PlayerIcon isPlayerIncoming={false}>
						<ArrowRight />
					</PlayerIcon>
				</Exist>
				<FullPlayerSection>
					<PlayerImageBackground
						src={playerImgPath}
						onError={({currentTarget}) => {
							currentTarget.onerror = null;
							currentTarget.src = PLAYER_PLACEHOLDER_IMG_URL;
						}}
						alt={`${player.firstName}-${player.lastName}`}
					/>
					<Exist when={isAllTrue([!isSelectedRoundComplete, !isOtherUser])}>
						<PlayerActions
							playerId={playerId}
							isBench={isBench}
							isUtility={isUtility}
							index={index}
							position={position}
							isSwapDisabled={isSwapDisabled}
							isPlayerOutgoingTrade={isPlayerOutgoingTrade}
							isPlayerIncomingFromBE={isPlayerIncomingFromBE}
							isTradeOutDisabled={isTradeOutDisabled}
							isPlayerIncomingTrade={isPlayerIncomingTrade}
							toggleActionsOpen={toggleActionsOpen}
							actionsOpen={actionsOpen}
						/>
					</Exist>

					<Exist when={isLiveOrHasScore}>
						<RoundScore isLive={isLive} isDNP={roundScore === "DNP"}>
							{roundScore}
						</RoundScore>
					</Exist>
				</FullPlayerSection>
				<PlayerInfoRow
					isCaptain={isCaptain}
					isViceCaptain={isViceCaptain}
					isTradeMode={isTradeMode}
					isBench={isBench}>
					<StatusName>
						<PlayerName
							data-desc="open-playermodal-field"
							className="field-player-name"
							onClick={() => showMiniPlayer(player)}>
							{getPlayerName(player)}
						</PlayerName>
						<OpponentInfo>{opponentInfoComponent}</OpponentInfo>
					</StatusName>
				</PlayerInfoRow>
				<FilledPositionRow>
					<StatusSection status={playerStatus}>
						{getStatusIcon(playerStatus)}
					</StatusSection>
					<div>
						{getPlayerPositionText(player)}
						<Separator />
						{player?.displayPrice}
					</div>
					<div>
						<strong className="stat-val">
							{getStatValue(activeFieldStatOption?.val || "")}
						</strong>
					</div>
				</FilledPositionRow>
			</Wrapper>
		);
	}
);
