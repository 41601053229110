import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {get} from "lodash";
import {RequestState} from "data/enums";
import {
	IFantasyTeamUserUpdate,
	IFantasyUser,
	IUpdateUserPayload,
	type IUserApiProvider,
	IUsername,
} from "data/providers/api/user.api.provider";
import {parseDob} from "data/utils/helpers";
import {Genders} from "data/types/global";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";

export interface IMyAccountController extends ViewController {
	get fantasyTeamName(): string;
	get user(): IUser | undefined;
	get userFantasyData(): IFantasyUser | undefined;
	// handleFormSubmit: (event: SyntheticEvent<IUpdateUserFormElement>) => void;
	logout: () => void;
	get getUpdateState(): RequestState;
	handleUserUpdate: (payload: IUpdateUserAccount) => void;
	handleUserUpdateFormData: (payload: IUpdateUserAccount) => void;
	handleFantasyUserUpdate: (payload: IFantasyTeamUserUpdate) => void;
	updateDOB: (event: string) => void;
	checkTeamName: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	get error(): Record<string, string> | null;
	get validDate(): boolean;
	get teamName(): string;
}

//userFantasyProfile
export interface IUpdateUserAccount extends IUpdateUserPayload {
	gender: Genders | string;
	state: string;
	avatar?: File;
}

export interface IUpdateTeamAccount extends Omit<IUpdateUserPayload, "birthday"> {
	fantasyTeamName: string;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable _requestStateUpdate: RequestState = RequestState.IDLE;
	@observable _requestStateLeave: RequestState = RequestState.IDLE;
	@observable _requestStateContests: RequestState = RequestState.IDLE;
	@observable _birthdate: string = "";
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable _isValidDate = true;
	@observable protected _hasFetchedTeam: boolean = false;
	@observable private _teamNameCheck: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider
	) {
		makeAutoObservable(this);
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get getUpdateState(): RequestState {
		return this._requestStateUpdate;
	}

	get fantasyTeamName() {
		return get(this._userStore.user, "fantasyTeamName", "");
	}

	get user() {
		return this._userStore.user;
	}

	get userFantasyData() {
		return this._userStore.userFantasyData;
	}

	get validDate() {
		return this._isValidDate;
	}
	@action checkTeamName = async (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		this._teamNameCheck = RequestState.PENDING;
		const payload: IUsername = {
			teamName: event.target.value,
		};
		try {
			await this._userApi.check_username_private(payload);
			this._errorMsg = "";
			this._teamNameCheck = RequestState.SUCCESS;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this._teamNameCheck = RequestState.ERROR;
			const msg = err.response?.data.errors[0].message || "";
			this._errorPlace = "fantasyTeamName";
			this._errorMsg = msg;
		}
	};
	// see how handleFormSubmit works in
	// src/views/pages/registration/registration.controller.ts
	// to add in loading and set data on complete
	// update catch like in this file as well
	// @action public handleFormSubmit = (event: SyntheticEvent<IUpdateUserFormElement>) => {

	// 	this._requestStateUpdate = RequestState.PENDING;
	// 	const {username, gender, isNotificationsEnabled, avatar, birthday, state} =
	// 		event.currentTarget;
	// 	const payload: IUpdateUserPayload = {
	// 		username: username.value,
	// 		gender: gender.value,
	// 		birthday: birthday.value,
	// 		state: state.value,
	// 	};

	// 	this._userStore
	// 		.update(payload)
	// 		.then(() => (this._requestStateUpdate = RequestState.SUCCESS))
	// 		.catch((err) => {
	// 			this._requestStateUpdate = RequestState.ERROR;
	// 			console.log(err);
	// 		});
	// };

	@action updateDOB = (dob: string) => {
		const res = parseDob(dob);
		this._errorMsg = res.errorMsg;
		this._isValidDate = res.isValidDate;
		this._birthdate = res.birthdate;
		this._errorPlace = res.errorPlace;
	};

	@action public handleFantasyUserUpdate = (payload: IFantasyTeamUserUpdate) => {
		this._requestStateUpdate = RequestState.PENDING;

		this._userStore
			.updateFantasyUser(payload)
			.then(() => (this._requestStateUpdate = RequestState.SUCCESS))
			.catch((err) => {
				this._requestStateUpdate = RequestState.ERROR;
				console.log(err);
			});
	};

	@action public handleUserUpdate = (payload: IUpdateUserAccount) => {
		this._requestStateUpdate = RequestState.PENDING;

		this._userStore
			.update(payload)
			.then(() => (this._requestStateUpdate = RequestState.SUCCESS))
			.catch((err) => {
				this._requestStateUpdate = RequestState.ERROR;
				console.log(err);
			});
	};

	@action public handleUserUpdateFormData = (payload: IUpdateUserAccount) => {
		this._requestStateUpdate = RequestState.PENDING;
		// const dobStr = this._birthdate ? this._birthdate : this._userStore.user?.birthday;
		const formData = new FormData();
		if (payload.avatar) {
			formData.append("avatar", payload.avatar);
		}
		formData.append("gender", payload.gender);
		formData.append("birthday", this._birthdate);
		formData.append("state", payload.state);
		this._userStore
			.updateFormData(formData)
			.then(() => (this._requestStateUpdate = RequestState.SUCCESS))
			.catch((err) => {
				this._requestStateUpdate = RequestState.ERROR;
				console.log(err);
			});
	};

	get teamName() {
		return get(this.userFantasyData, "teamName", "");
	}

	logout = () => void this._userStore.logout();

	dispose(): void {
		return;
	}

	async init() {
		this._birthdate = this._userStore.user?.birthday ? this._userStore.user?.birthday : "";
		await this._userStore.requestUser();
	}
}
