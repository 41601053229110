import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";

export interface IInitiateController extends ViewController {
	getLoginLink: () => void;
}

@injectable()
export class InitiateController implements IInitiateController {
	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	@action getLoginLink = () => this._userStore.getLoginLink();

	dispose(): void {
		return;
	}

	init(): void {
		this.getLoginLink();
	}
}
