import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {RequestState} from "data/enums";

export interface ISessionController extends ViewController {
	get isSessionChecked(): boolean;
}

@injectable()
export class SessionController implements ISessionController {
	@observable _isSessionChecked = false;

	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	get isSessionChecked(): boolean {
		return this._isSessionChecked;
	}
	get isLoadingOrLoaded() {
		return [RequestState.PENDING, RequestState.SUCCESS].includes(
			this._userStore.userLoadingStatus
		);
	}

	dispose(): void {
		return;
	}

	@action init() {
		if (this.isLoadingOrLoaded) {
			return;
		}
		void this._userStore
			.requestUser()
			.catch(() => {
				// Do nothing as the error is expected when a user isn't authorized
			})
			.then(() =>
				runInAction(() => {
					this._isSessionChecked = true;
				})
			);
	}
}
