import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IAFLIDProfile, IAFLIDUser, IUser, IUserStore} from "data/stores/user/user.store";
import {action, observable, makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {RequestState} from "data/enums";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {get} from "lodash";

import type {IFantasyUser, IUserApiProvider, IUsername} from "data/providers/api/user.api.provider";
import {useNavigate} from "react-router-dom";

interface IRecoverForm extends HTMLFormElement {
	fantasyTeamName: HTMLInputElement;
	sponsorOptIn: HTMLInputElement;
	terms: HTMLInputElement;
}

interface IInit {
	navigate: ReturnType<typeof useNavigate>;
}

export interface IRecoverUserController extends ViewController<IInit> {
	get user(): IUser | undefined;

	handleFormSubmit: (event: React.SyntheticEvent<IRecoverForm>) => void;
	checkTeamName: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	aflUserData: IAFLIDProfile | null;
	get error(): Record<string, string> | null;
	get statusCode(): number;
	get isFormDisabled(): boolean;
	get teamNameChecked(): boolean;
	get teamNameChecking(): boolean;
	get userFantasyData(): IFantasyUser | undefined;
}

@injectable()
export class RecoverUserController implements IRecoverUserController {
	private _navigate!: IInit["navigate"];
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable private _teamNameCheck: RequestState = RequestState.IDLE;
	@observable _status: number = 100;
	@observable _aflIDUserData: IAFLIDUser | null = null;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable protected _hasCheckedLogin: boolean = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider
	) {
		makeAutoObservable(this);
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return this._requestState === RequestState.PENDING;
	}

	get statusCode() {
		return this._status;
	}

	get aflUserData() {
		return get(this._aflIDUserData, "aflIdProfile", null);
	}

	get teamNameChecking() {
		return [RequestState.PENDING].includes(this._teamNameCheck);
	}

	get teamNameChecked() {
		return [RequestState.SUCCESS, RequestState.IDLE].includes(this._teamNameCheck);
	}
	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action checkTeamName = async (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		this._teamNameCheck = RequestState.PENDING;
		const payload: IUsername = {
			teamName: event.target.value,
		};
		try {
			await this._userApi.check_username_private(payload);
			this._errorMsg = "";
			this._teamNameCheck = RequestState.SUCCESS;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this._teamNameCheck = RequestState.ERROR;
			const msg = err.response?.data.errors[0].message || "";
			this._errorPlace = "fantasyTeamName";
			this._errorMsg = msg;
		}
	};
	@action handleFormSubmit = async (event: React.SyntheticEvent<IRecoverForm>) => {
		event.preventDefault();
		/*
			set state to allow for correcting errors
			eg if they put in bad name for team name.
		*/

		this._requestState = RequestState.IDLE;

		const {terms, sponsorOptIn} = event.currentTarget;

		const validateList = [
			{
				error: "Please provide your team name",
				place: "fantasyTeamName",
			},
			{field: terms, error: "Please accept the Terms & Conditions", place: "terms"},
		];

		const hasError = validateList.find(({field, error, place}) => {
			if (!field) {
				return false;
			}
			return field.checkValidity() ? false : this.reportError(error, place);
		});

		if (hasError) {
			return;
		}

		const payload = {
			terms: terms.checked,
			sponsorOptIn: sponsorOptIn.checked,
		};
		await this._userStore.recoverUser(payload);
		if (this._userStore.isNowRecovered) {
			this._navigate("/team");
		}
	};

	get user() {
		return this._userStore.user;
	}

	get userFantasyData() {
		return this._userStore.userFantasyData;
	}

	dispose(): void {
		return;
	}

	init(params: IInit): void {
		this._navigate = params.navigate;
	}
}
