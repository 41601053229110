import React from "react";
import {isEqual, first, noop} from "lodash";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {ModalType} from "data/enums";
import {observer} from "mobx-react";
import {Bindings} from "data/constants/bindings";
import {ModalGeneral} from "./modal_general.component";
import {ReactComponent as ErrorIcon} from "assets/img/icon-error.svg";

export const ModalError: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController, {});
	const {visibleModal, modalContent, close} = controller;
	const isVisible = isEqual(visibleModal, ModalType.ERROR);

	if (!isVisible) {
		return null;
	}

	const title = modalContent?.title || "";
	const message = modalContent?.message || "";
	const errorsContent = modalContent?.errors as {errors: {message: string}[]};
	const firstError = first(errorsContent?.errors)?.message;

	return (
		<ModalGeneral
			isVisible
			icon={<ErrorIcon />}
			heading={title}
			confirmText=""
			showCancel={true}
			onClick={noop}
			onClose={close}
			subHeading={firstError || message}
		/>
	);
});
