import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IAFLIDProfile, IAFLIDUser, IUser, IUserStore} from "data/stores/user/user.store";
import {action, observable, makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {REACT_APP_SSO_REDIRECT_URI} from "data/constants";
import {RequestState} from "data/enums";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage, extractErrorStatus} from "data/utils";
import type {IRegisterAFLIdPayload} from "data/providers/api/auth.api.provider";
import {get} from "lodash";
import {ISquad, type ISquadsStore} from "data/stores/squads/squads.store";
import {ICountry, type ICountriesStore} from "data/stores/countries/countries.store";
import {parseDob} from "data/utils/helpers";
import type {IUserApiProvider, IUsername} from "data/providers/api/user.api.provider";
import {SelectChangeEvent} from "@mui/material";

const params = window.location.search;
const searchParams = new URLSearchParams(params);

interface IRegisterForm extends HTMLFormElement {
	fantasyTeamName: HTMLInputElement;
	country: HTMLSelectElement;
	state: HTMLSelectElement;
	gender: HTMLSelectElement;
	birthday: HTMLInputElement;
	sponsorOptIn: HTMLInputElement;
	supportedSquadId: HTMLSelectElement;
	terms: HTMLInputElement;
}

export interface ILoginCallbackController extends ViewController {
	get user(): IUser | undefined;
	get countryList(): ICountry[];
	get squadList(): ISquad[];
	get countryFromForm(): string;
	logout: () => void;
	handleFormOnChange: () => void;
	handleCountry: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
	updateDOB: (event: string) => void;
	handleFormSubmit: (event: React.SyntheticEvent<IRegisterForm>) => void;
	checkTeamName: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	aflUserData: IAFLIDProfile | null;
	get error(): Record<string, string> | null;
	get statusCode(): number;
	get statusCodeLogin(): number;
	get isFormDisabled(): boolean;
	get validDate(): boolean;
	get teamNameChecked(): boolean;
	get teamNameChecking(): boolean;
}

@injectable()
export class LoginCallbackController implements ILoginCallbackController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable private _teamNameCheck: RequestState = RequestState.IDLE;
	@observable _status: number = 100;
	@observable _statusLogin: number = 100;
	@observable _birthdate: string = "";
	@observable _countryFromForm: string = "";
	@observable _aflIDUserData: IAFLIDUser | null = null;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable protected _hasCheckedAuthCode: boolean = false;
	@observable protected _hasCheckedLogin: boolean = false;
	@observable protected _checkNameError: string = "";
	@observable _isValidDate = true;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.CountriesStore) private _countriesStore: ICountriesStore,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider
	) {
		makeAutoObservable(this);
	}

	get validDate() {
		return this._isValidDate;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return this._requestState === RequestState.PENDING;
	}

	get statusCode() {
		return this._status;
	}

	get statusCodeLogin() {
		return this._statusLogin;
	}

	get aflUserData() {
		return get(this._aflIDUserData, "aflIdProfile", null);
	}

	get countryList() {
		return this._countriesStore.list;
	}

	get countryFromForm() {
		return this._countryFromForm;
	}

	get squadList() {
		return this._squadsStore.list;
	}

	get teamNameChecking() {
		return [RequestState.PENDING].includes(this._teamNameCheck);
	}

	get teamNameChecked() {
		return [RequestState.SUCCESS, RequestState.IDLE].includes(this._teamNameCheck);
	}
	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action registerAFLID(payload: IRegisterAFLIdPayload) {
		if (this._hasCheckedAuthCode) {
			return;
		}
		this._hasCheckedAuthCode = true;

		const compulsoryFields = {
			code: payload.code,
			codeVerifier: payload.codeVerifier,
			redirectUrl: payload.redirectUrl,
			fantasyTeamName: payload.fantasyTeamName,
			gender: payload.gender,
			birthday: payload.birthday,
			sponsorOptIn: payload.sponsorOptIn,
			terms: true,
		};
		const countryField = payload.country
			? {
					country: payload.country,
			  }
			: {};
		const stateField = payload.state
			? {
					state: payload.state,
			  }
			: {};
		const supportedSquadField = payload.supportedSquadId
			? {
					supportedSquadId: payload.supportedSquadId,
			  }
			: {};
		return this._userStore
			.registerAFLID({
				...compulsoryFields,
				...countryField,
				...stateField,
				...supportedSquadField,
			})
			.catch(this.onError);
	}

	@action handleCountry = (event: SelectChangeEvent<unknown>, _child: React.ReactNode) => {
		event.preventDefault();
		this._countryFromForm = get(event.target, "value", "") as string;
	};

	@action handleFormOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._requestState = RequestState.IDLE;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this.reportError(extractErrorMessage(error));
		this._status = extractErrorStatus(error);
		this._hasCheckedAuthCode = true;
	};

	@action private onErrorLogin = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._statusLogin = extractErrorStatus(error);
		this._aflIDUserData = error.response?.data.success as IAFLIDUser;
	};

	@action updateDOB = (dob: string) => {
		const res = parseDob(dob);
		this._errorMsg = res.errorMsg;
		this._isValidDate = res.isValidDate;
		this._birthdate = res.birthdate;
		this._errorPlace = res?.errorPlace || "";
	};

	@action checkTeamName = async (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		this._teamNameCheck = RequestState.PENDING;
		const payload: IUsername = {
			teamName: event.target.value,
		};
		try {
			await this._userApi.check_username(payload);
			this._errorMsg = "";
			this._teamNameCheck = RequestState.SUCCESS;
			this._checkNameError = "";
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this._teamNameCheck = RequestState.ERROR;
			const msg = err.response?.data.errors[0].message || "";
			this._errorPlace = "fantasyTeamName";
			this._checkNameError = msg;
			this._errorMsg = msg;
		}
	};
	@action handleFormSubmit = (event: React.SyntheticEvent<IRegisterForm>) => {
		event.preventDefault();
		/*
			set state to allow for correcting errors
			eg if they put in bad name for team name.
		*/

		this._requestState = RequestState.IDLE;
		this._hasCheckedAuthCode = false;

		const {fantasyTeamName, country, state, gender, sponsorOptIn, terms, supportedSquadId} =
			event.currentTarget;

		const code = searchParams.get("code") || "";
		const codeVerifier = localStorage.getItem("code_verifier") || "";
		const validateList = [
			{
				field: fantasyTeamName,
				error: "Please provide your team name",
				place: "fantasyTeamName",
			},
			{field: state, error: "Please select one of the options", place: "state"},
			{field: gender, error: "Please fill in your gender", place: "gender"},
			{field: country, error: "Please provide your country"},
			{field: terms, error: "Please accept the Terms & Conditions", place: "terms"},
			{field: supportedSquadId, error: "Please add your team!", place: "supportedSquadId"},
		];

		const hasError = validateList.find(({field, error, place}) => {
			if (!this.teamNameChecked) {
				return this.reportError(this._checkNameError, "fantasyTeamName");
			}
			if (!field) {
				return false;
			}

			return field.checkValidity() ? false : this.reportError(error, place);
		});

		if (hasError) {
			return;
		}
		const countryField = country ? {country: country.value} : {};
		const stateField = state ? {state: state.value} : {};
		const supportedSquadField = supportedSquadId
			? {supportedSquadId: supportedSquadId.value}
			: {};

		void this.registerAFLID({
			code,
			codeVerifier,
			redirectUrl: REACT_APP_SSO_REDIRECT_URI,
			fantasyTeamName: fantasyTeamName.value,
			...countryField,
			...stateField,
			...supportedSquadField,
			gender: gender.value,
			birthday: this._birthdate,
			sponsorOptIn: sponsorOptIn.checked,
			terms: true,
		});
	};

	@action
	async loginAFLID(): Promise<void> {
		if (this._hasCheckedLogin) {
			return;
		}

		this._hasCheckedLogin = true;

		const code = searchParams.get("code") || "";
		const codeVerifier = localStorage.getItem("code_verifier") || "";

		await Promise.allSettled([
			this._countriesStore.fetchCountries(),
			this._squadsStore.fetchSquads(),
		]);

		return this._userStore.loginAFLID({
			code,
			codeVerifier,
			redirectUrl: REACT_APP_SSO_REDIRECT_URI,
		});
	}

	get user() {
		return this._userStore.user;
	}

	logout = () => void this._userStore.logout();

	dispose(): void {
		return;
	}

	async init() {
		await this.loginAFLID().catch(this.onErrorLogin);
	}
}
