import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {IUserRanking, IGetRankingsParams} from "data/types/rankings";
import {AxiosResponse} from "axios";
import type {IApiResponse, IHttpClientService} from "data/services/http";

export interface IRankingsApiProvider {
	rankings: (
		variables: IGetRankingsParams
	) => Promise<
		AxiosResponse<
			IApiResponse<{
				rankings: IUserRanking[];
				nextPage: boolean;
				currentUserRanking: IUserRanking;
			}>
		>
	>;
}

@injectable()
export class RankingsApiProvider implements IRankingsApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	rankings = ({...params}: IGetRankingsParams) =>
		this._http.get<
			IApiResponse<{
				currentUserRanking: IUserRanking;
				rankings: IUserRanking[];
				nextPage: boolean;
			}>
		>("fantasy/ranks/open-league", params);
}
