import styled from "@emotion/styled";
import {InputAdornment} from "@mui/material";
import React from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {ButtonSecondary} from "views/components/buttons/buttons.component";
import {
	borderColor,
	FormControlExtended,
	TextFieldExtended,
} from "views/pages/create_league/create_league.page";
import {ReactComponent as SearchIcon} from "assets/img/icon-search.svg";
import {observer} from "mobx-react";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {IJoinCreateWidgetController} from "./join_create_widget.controller";

const mobileViewQuery = `max-width: 860px`;

const Wrapper = styled.div`
	padding: 16px 20px;
	width: 100%;
	margin-bottom: 1px;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
	line-height: 24px;
	border-radius: 10px;

	transition: all 0.3s ease;

	h4 {
		font-family: inherit;
		padding-bottom: 6px;
		font-weight: 700;
		font-size: 18px;
		line-height: 20px;
	}

	p {
		font-family: inherit;
		margin-bottom: 20px;
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
	}

	@media (${mobileViewQuery}) {
		width: 100%;
		margin: 12px 0 20px 0;
		line-height: 20px;
		h4 {
			font-size: 16px;
		}

		p {
			font-size: 14px;
		}
	}

	@media (max-width: 1100px) {
		background-color: var(--textWhite);
		border-radius: 10px;
		margin-top: 10px;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 10px;
	height: 42px;
`;

const LeagueLink = styled(NavLink)`
	width: 100%;
	text-decoration: none;
`;

const WidgetTitle = styled.div`
	font-size: 16px;
	font-weight: 700;
	line-height: 21px;
	@media (max-width: 640px) {
		margin-bottom: 16px;
	}
`;

const CreateLeagueButton = styled(ButtonSecondary)`
	max-width: 382px;
`;

const CreateLeagueText = styled.p`
	margin-top: 0;
	font-size: 16px;
	font-weight: 700;
`;

export const JoinCreateWidget: React.FC = observer(() => {
	const controller = useViewController<IJoinCreateWidgetController>(
		Bindings.JoinCreateWidgetController,
		{
			navigate: useNavigate(),
			location: useLocation(),
		}
	);

	return (
		<Wrapper>
			<WidgetTitle>Invited to a league?</WidgetTitle>
			<FormControlExtended>
				<TextFieldExtended
					id="league-name"
					label="Enter League PIN or League Name"
					name="leagueName"
					variant="standard"
					placeholder="Enter Your League PIN"
					required={true}
					value={controller.leagueCodeOrName}
					onChange={(event) => {
						controller.setLeagueCodeOrName(event.target.value);
						controller.fetchLeagues(event.target.value);
					}}
					sx={{
						"& .MuiInput-underline:before": {
							borderBottomColor: borderColor,
						},
						"& .MuiInput-underline:after": {
							borderBottomColor: borderColor,
						},
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</FormControlExtended>

			<CreateLeagueText>Or Create your own league</CreateLeagueText>

			<ButtonWrapper>
				<LeagueLink to={`/leagues/create`}>
					<CreateLeagueButton fullWidth>Create League</CreateLeagueButton>
				</LeagueLink>
			</ButtonWrapper>
		</Wrapper>
	);
});
