import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {useNavigate} from "react-router-dom";
import {isEqual, sortBy, orderBy, toLower, deburr, lowerCase} from "lodash";
import type {
	IStaticContentStore,
	TArticle,
	ISections,
} from "data/stores/static_content/static_content.store";
import {RequestState} from "data/enums";

interface IControllerProps {
	navigate: ReturnType<typeof useNavigate>;
	pathname: string;
}

export interface IHelpController extends ViewController<IControllerProps> {
	updatePathname: (pathname: string) => void;
	buildPathName: (pathname: string) => string;
	isActiveTab: (tabId: number) => boolean;
	isContactUs: (tabId: number) => boolean;

	get tabs(): ISections["sections"];

	get contents(): TArticle[];

	get currentTab(): number | null;

	findSectionsById(sectionId: number): TArticle[];
}

@injectable()
export class HelpController implements IHelpController {
	private _currentTab: number | null = null;
	private _navigate!: IControllerProps["navigate"];
	@observable private _requestState: RequestState = RequestState.IDLE;

	private static rootPath = "/help";

	get tabs() {
		return sortBy(this._staticContentStore.sections, "position");
	}

	get isLoadedOrLoading() {
		return [RequestState.PENDING, RequestState.SUCCESS].includes(this._requestState);
	}

	get contents() {
		return this._staticContentStore.articles;
	}

	get currentTab() {
		return this._currentTab;
	}

	constructor(
		@inject(Bindings.StaticContentStore)
		private readonly _staticContentStore: IStaticContentStore
	) {
		makeAutoObservable(this);
	}

	public findSectionsById(sectionId: number) {
		const sections = this.contents?.filter((article) => article.section_id === sectionId) ?? [];

		return orderBy(sections, ["position"], ["asc"]);
	}

	public updatePathname = (pathname: string) => {
		const newTab = this.tabs.find((it) => pathname.includes(this.buildPathName(it.name)))?.id;

		if (newTab) {
			this.setCurrentTab(newTab);
		}
	};

	public setCurrentTab = (value: number) => {
		this._currentTab = value;
	};

	public isActiveTab = (tab: number) => {
		return isEqual(this._currentTab, tab);
	};

	public isContactUs = (tabId: number) => {
		const sections = this.findSectionsById(tabId);

		return Boolean(
			sections.find(
				({label_names, title}) =>
					label_names.includes("contact-us") || lowerCase(title).includes("contact us")
			)
		);
	};

	public buildPathName = (name: string) => {
		return toLower(deburr(name)).split(" ").join("-");
	};

	async init({navigate, pathname}: IControllerProps) {
		if (this.isLoadedOrLoading) {
			return;
		}
		this._requestState = RequestState.PENDING;
		await this._staticContentStore.fetchStaticContent();
		this._requestState = RequestState.SUCCESS;
		this._navigate = navigate;

		pathname = pathname.endsWith("/") ? pathname.slice(0, -1) : pathname;

		if (isEqual(pathname, HelpController.rootPath)) {
			return navigate(this.buildPathName(this.tabs[0].name), {replace: true});
		}

		this.updatePathname(pathname);
	}

	dispose() {
		return;
	}
}
