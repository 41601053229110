import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable} from "mobx";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IUserStore} from "data/stores/user/user.store";

export interface ILoginController extends ViewController {
	getLoginLink: () => void;
	showInfo: () => void;
}

@injectable()
export class LoginController implements ILoginController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action getLoginLink = () => this._userStore.getLoginLink();

	showInfo(): void {
		//To DO showmodal
		this._modalsStore.showModal(ModalType.GENERAL, {
			message: "",
			errors: "",
		});
	}

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
