import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {ILineup, IPastTeam, ITeam} from "data/stores/team/team.store";

export interface ISaveTeamPayload {
	lineup: ILineup;
	bench: ILineup;
	utilityId: number;
	captainId: number;
	viceCaptainId: number;
	emergency: number[];
}

export interface IAutoFillPayload {
	lineup: ILineup;
	bench: ILineup;
	utilityId?: number;
	captainId?: number;
	viceCaptainId?: number;
}

export interface ISaveCaptainPayload {
	type: "captain" | "viceCaptain";
	newId: number;
}

export interface ISetFavouritePayload {
	playerId: number;
}

export interface ISwapPlayersPayload {
	swapsPairs: number[][];
}

export interface IShowPastTeamPayload {
	roundId: number;
}

export interface IShowUserTeamPayload {
	roundId: number;
	userId: number;
}

export interface IReverseTradePayload {
	tradeIds: number[];
}

export interface IGetTradesPayload {
	roundId?: number;
}
export interface IMakeTradePayload {
	tradePairs: {in: number; out: number}[];
}

export interface IExistingTrade {
	id: number;
	roundId: number;
	playerOutId: number;
	playerInId: number;
}

export type ITeamResponse = IApiResponse<{team: ITeam}>;

export type IShowMyTeamResponse = IApiResponse<{team: ITeam; rollbackAvailable: boolean}>;

export type IPastTeamResponse = IApiResponse<{teamHistory: IPastTeam}>;

export type IFavouriteResponse = IApiResponse<{favouritePlayers: number[]}>;

export type ITradesResponse = IApiResponse<{trades: IExistingTrade[]}>;

export type IMakeTradeResponse = IApiResponse<{trades: IExistingTrade[]; team: ITeam}>;

export interface IGetUserRankingPayload {
	roundId?: number;
	teamId?: number;
}

export interface IRankData {
	rank: {
		leagueId: number;
		roundId: number;
		teamId: number;
		overallRank: number;
		roundRank: number;
		overallPoints: number;
		roundPoints: number;
	};
}

export type ITeamRankResponse = IApiResponse<IRankData>;

const getTradesRoundPath = (params: IGetTradesPayload) => {
	return params.roundId ? `/${params.roundId}` : "";
};

export interface ITeamApiProvider {
	getTeam: () => Promise<AxiosResponse<IShowMyTeamResponse>>;
	getPastTeam: (params: IShowPastTeamPayload) => Promise<AxiosResponse<IPastTeamResponse>>;
	saveTeam: (params: Required<ISaveTeamPayload>) => Promise<AxiosResponse<ITeamResponse>>;
	autoFillTeam: (params: IAutoFillPayload) => Promise<AxiosResponse<ITeamResponse>>;
	changeCaptain: (params: ISaveCaptainPayload) => Promise<AxiosResponse<IShowMyTeamResponse>>;
	getFavouritePlayers: () => Promise<AxiosResponse<IFavouriteResponse>>;
	rollbackTeam: () => Promise<AxiosResponse<ITeamResponse>>;
	setFavouritePlayer: (
		params: ISetFavouritePayload
	) => Promise<AxiosResponse<IFavouriteResponse>>;
	removeFavouritePlayer: (
		params: ISetFavouritePayload
	) => Promise<AxiosResponse<IFavouriteResponse>>;
	swapPlayers: (params: ISwapPlayersPayload) => Promise<AxiosResponse<IShowMyTeamResponse>>;
	getTrades: (params: IGetTradesPayload) => Promise<AxiosResponse<ITradesResponse>>;
	makeTrade: (params: IMakeTradePayload) => Promise<AxiosResponse<IMakeTradeResponse>>;
	reverseTrade: (params: IReverseTradePayload) => Promise<AxiosResponse<ITeamResponse>>;
	getUserRanking: (params: IGetUserRankingPayload) => Promise<AxiosResponse<ITeamRankResponse>>;
	getUserPastTeam: (params: IShowUserTeamPayload) => Promise<AxiosResponse<IPastTeamResponse>>;
}

@injectable()
export class TeamApiProvider implements ITeamApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	getTeam = () => this._http.get<IShowMyTeamResponse>(`fantasy/team/show-my`);

	getPastTeam = (params: IShowPastTeamPayload) =>
		this._http.get<IPastTeamResponse>(`fantasy/team-history/get-by-round/${params.roundId}`);
	getUserPastTeam = (params: IShowUserTeamPayload) =>
		this._http.get<IPastTeamResponse>(
			`fantasy/team-history/get-by-round-and-user/${params.roundId}/${params.userId}`
		);

	saveTeam = (params: ISaveTeamPayload) =>
		this._http.post<ITeamResponse>("fantasy/team/update", params);
	autoFillTeam = (params: IAutoFillPayload) =>
		this._http.post<ITeamResponse>("fantasy/team/autopick", params);
	changeCaptain = (params: ISaveCaptainPayload) =>
		this._http.post<IShowMyTeamResponse>("fantasy/team/change-captain", params);
	rollbackTeam = () => this._http.post<ITeamResponse>("fantasy/team/rollback");

	getFavouritePlayers = () =>
		this._http.get<IFavouriteResponse>(`fantasy/favourite-player/show-favourites`);
	setFavouritePlayer = (params: ISetFavouritePayload) =>
		this._http.post<IFavouriteResponse>("fantasy/favourite-player/add", params);
	removeFavouritePlayer = (params: ISetFavouritePayload) =>
		this._http.post<IFavouriteResponse>("fantasy/favourite-player/remove", params);
	swapPlayers = (params: ISwapPlayersPayload) =>
		this._http.post<IShowMyTeamResponse>("fantasy/team/swap", params);
	makeTrade = (params: IMakeTradePayload) =>
		this._http.post<IMakeTradeResponse>("fantasy/trade/make", params);
	getTrades = (params: IGetTradesPayload) =>
		this._http.get<ITradesResponse>(`fantasy/trade/list${getTradesRoundPath(params)}`);
	reverseTrade = (params: IReverseTradePayload) =>
		this._http.post<ITeamResponse>(`fantasy/trade/reverse`, params);
	getUserRanking = (params: IGetUserRankingPayload) =>
		this._http.get<ITeamRankResponse>(`fantasy/team/overall-rank`, params);
}
