import {IDirection, LeagueH2H, ModalType, RequestState, RoundStatus} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {ILeague, ILeagueUser, IRankingTeamObject} from "data/types/leagues";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {trackSentryErrors} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import {IUserRanking} from "data/types/rankings";
import {get} from "lodash";
import {getPreviousRoundId} from "data/utils/helpers";

interface IInit {
	leagueId: number;
}

export interface ILadderLeagueController extends ViewController<IInit> {
	get league(): ILeague | undefined;
	get isCommissioner(): boolean;
	get userId(): number | undefined;
	get totalPointsDirection(): IDirection;
	get isStartRoundStarted(): boolean;
	get isStartRoundComplete(): boolean;
	get leagueUsers(): ILeagueUser[];
	get teamLinkRound(): number;
	rankingsFilters: {state?: string; roundId?: number; page: number; limit: number};
	selectedRound: number;
	leagueRankings: IUserRanking[] | IRankingTeamObject[];
	isEmptyLeagueRankings: boolean;
	isLoaded: boolean;
	statDisplay: string;
	isLoadMore: boolean;

	handleLoadMore: () => void;
	handleChangeTotalPointsDirection: () => void;
	handleStatDisplayClick: (val: string) => void;
}

@injectable()
export class LadderLeagueController implements ILadderLeagueController {
	private _league: ILeague | undefined;
	private _pageNumber: number = 1;
	private _limitSize: number = 10;
	private _nextPage: boolean = false;
	private _totalPointsDirection: IDirection = IDirection.DESC;

	@observable protected _hasFetchedJSONS: boolean = false;
	@observable private _statDisplay: string = "totalPoints";
	@observable _requestStateUpdate: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action
	async checkFetchJSONs(): Promise<void> {
		if (this._hasFetchedJSONS) {
			return;
		}
		this._hasFetchedJSONS = true;
		await Promise.all([this._roundsStore.fetchRounds()]);
	}

	@action
	handleLoadMore = () => {
		runInAction(() => {
			const page = this._pageNumber + 1;
			this._pageNumber = page;
			this._nextPage = true;
			this.refetchList();
		});
	};

	@action
	handleStatDisplayClick = (statValue: string) => {
		if (this._statDisplay !== statValue) {
			this._pageNumber = 1;
		}
		if (this._statDisplay === statValue) {
			this._pageNumber = 1;
			const directionToApply =
				this._totalPointsDirection === IDirection.ASC ? IDirection.DESC : IDirection.ASC;
			this._totalPointsDirection = directionToApply;
		}
		this._statDisplay = statValue;
		this.refetchList();
	};

	@action
	handleChangeTotalPointsDirection = () => {
		this._totalPointsDirection =
			this._totalPointsDirection === IDirection.ASC ? IDirection.DESC : IDirection.ASC;
		runInAction(() => {
			this.refetchList();
		});
	};

	@action
	refetchList = () => {
		this._requestStateUpdate = RequestState.PENDING;
		try {
			this._leaguesStore
				.fetchLeagueRankings(this.rankingsFilters)
				.then(() => (this._requestStateUpdate = RequestState.SUCCESS))
				.catch((err) => {
					this._requestStateUpdate = RequestState.ERROR;
					console.log(err);
				});
		} catch (err) {
			trackSentryErrors(err, {}, "loadLeagueRankings");
			const error = err as AxiosError;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
		}
	};

	get league(): ILeague | undefined {
		return this._league;
	}

	get teamLinkRound() {
		const currentRound = this._roundsStore.currentRound;
		const currentRoundId = currentRound?.id || 1;
		const previousRoundId = getPreviousRoundId(currentRoundId);
		const isCurrentRoundScheduledOrLive =
			currentRound?.status === RoundStatus.Scheduled ||
			currentRound?.status === RoundStatus.Playing;
		const roundToLink = isCurrentRoundScheduledOrLive ? previousRoundId : currentRoundId;
		if (currentRoundId === 1) {
			return 1;
		}
		return Number(this.selectedRound) === 0 || Number(this.selectedRound) >= currentRoundId
			? roundToLink
			: Number(this.selectedRound);
	}

	get isCommissioner(): boolean {
		return Number(this.userId) === Number(this.league?.leagueCommissioner?.userId);
	}

	get userId(): number | undefined {
		return this._userStore.user?.id;
	}

	get totalPointsDirection(): IDirection {
		return this._totalPointsDirection;
	}

	get roundForPayload() {
		const selectedRoundId = this.selectedRound;
		const lastCompletedRound = this._roundsStore.lastCompletedRoundId;
		const currentRoundId = this._roundsStore.currentRound?.id || 1;
		return selectedRoundId < currentRoundId ? selectedRoundId : lastCompletedRound;
	}

	get rankingsFilters() {
		const lastCompletedRound = this._roundsStore.lastCompletedRoundId;
		const baseFilters = {
			// roundId: 1,
			leagueId: this._league?.id,
			page: this._pageNumber,
			limit: this._limitSize,
			direction: this._totalPointsDirection,
			order: this._statDisplay,
			roundId: this.roundForPayload,
		};
		const roundFilter =
			this.selectedRound === 0
				? {roundId: lastCompletedRound}
				: {roundId: Number(this.selectedRound)};
		return {
			...baseFilters,
			...roundFilter,
		};
	}

	get isStartRoundStarted() {
		const roundsById = this._roundsStore.roundsById;
		const startRound = this.league?.startId || 1;
		const round = roundsById[startRound];
		const roundStatus = get(round, "status", RoundStatus.Scheduled);
		return roundStatus !== RoundStatus.Scheduled;
	}

	get isStartRoundComplete() {
		const roundsById = this._roundsStore.roundsById;
		const startRound = this.league?.startId || 1;
		const round = roundsById[startRound];
		const roundStatus = get(round, "status", RoundStatus.Scheduled);
		return roundStatus === RoundStatus.Complete;
	}

	get selectedRound() {
		return this._roundsStore.leagueLadderSelectedRound;
	}

	get leagueRankings() {
		if (this._league?.type.toLowerCase() === LeagueH2H.H2H.toLowerCase()) {
			return this._leaguesStore.leagueTeamRankings;
		}
		return this._leaguesStore.leagueRankings.rankings;
	}

	get statDisplay() {
		return this._statDisplay;
	}

	get isEmptyLeagueRankings() {
		return !this._leaguesStore.leagueRankings.rankings.length;
	}

	get isLoaded() {
		return this._leaguesStore.isLoaded;
	}

	get leagueUsers() {
		return this._leaguesStore.leagueUsers.users;
	}

	get isLoadMore() {
		return this._leaguesStore.leagueRankings.nextPage;
	}

	async init(params: IInit) {
		try {
			this._leaguesStore.clearRankings();
			await this.checkFetchJSONs();
			const roundId = this._roundsStore.leagueLadderSelectedRound || 0;
			const lastCompletedRound = this._roundsStore.lastCompletedRoundId;
			this._league = this._leaguesStore.getLeagueById(params.leagueId);
			await this._leaguesStore.fetchLeagueUsers(params.leagueId);
			if (this._league?.type.toLowerCase() === LeagueH2H.Open.toLowerCase()) {
				await this._leaguesStore.fetchLeagueRankings({
					...this.rankingsFilters,
					roundId: lastCompletedRound,
				});
			}
			const roundIfZero =
				lastCompletedRound < Number(this._league?.startId)
					? Number(this._league?.startId)
					: lastCompletedRound;
			const h2hRoundKeyPair = roundId === 0 ? {roundId: roundIfZero} : {roundId};
			if (this._league?.type.toLowerCase() === LeagueH2H.H2H.toLowerCase()) {
				await this._leaguesStore.fetchLeagueTeamRankings({
					leagueId: params.leagueId,
					...h2hRoundKeyPair,
				});
			}
		} catch (err) {
			trackSentryErrors(err, {}, "loadRankings");
			const error = err as AxiosError;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
		}
	}

	dispose(): void {
		return;
	}
}
