import styled from "@emotion/styled";
import {observer} from "mobx-react";
import React, {Suspense} from "react";
import {Outlet, useOutletContext} from "react-router-dom";
import {PagePreloader} from "views/components/preloader";

const Center = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 300px;
	width: 100%;
	flex: 1 1 100%;
`;

export const LeaguePreloader: React.FC = observer(() => {
	const context = useOutletContext();

	return (
		<Suspense
			fallback={
				<Center>
					<PagePreloader />
				</Center>
			}>
			<Outlet context={context} />
		</Suspense>
	);
});
