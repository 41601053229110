import React from "react";
import styled from "@emotion/styled";
import {FormControl, InputLabel, Select, SelectProps, useMediaQuery} from "@mui/material";

const StyledFormControl = styled(FormControl)<{width?: string}>`
	border: 1px solid var(--aflwLightGreyPurple);
	border-radius: 4px 4px 4px 0px;
	width: ${({width}) => (width ? width : `100%`)};
`;
const StyleSelect = styled(Select)``;

interface ISelectProps extends SelectProps {
	helperText?: string;
	width?: string;
}

const borderColor = "var(--aflwBrightPurple)";
const textColor = "var(--textLightGrey)";

export const AFLWSelect: React.FC<ISelectProps> = (props: ISelectProps) => {
	const {width = "", label} = props;
	const isMobile = useMediaQuery("(max-width: 1000px)");

	const sizeW = isMobile ? "100%" : width;
	return (
		<StyledFormControl variant="filled" width={sizeW}>
			<InputLabel
				id={props.labelId}
				sx={{
					fontWeight: 400,
					fontSize: 16,
					"&.Mui-focused": {
						color: textColor,
					},
				}}>
				{label}
			</InputLabel>
			<StyleSelect
				sx={{
					background: "unset !important",
					fontSize: 16,
					":before": {borderBottomColor: borderColor},
					":after": {borderBottomColor: borderColor},
					".MuiSvgIcon-root ": {
						fill: borderColor,
					},
					"& .MuiInputBase-input": {
						"&:focus": {
							background: "none",
						},
					},
				}}
				{...props}>
				{props.children}
			</StyleSelect>
		</StyledFormControl>
	);
};
