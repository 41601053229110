import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {useNavigate} from "react-router-dom";

interface IInit {
	navigate?: ReturnType<typeof useNavigate>;
}

export interface ILeagueMatchupButtonsController extends ViewController<IInit> {
	get isShowNext(): boolean;
	get isShowPrevious(): boolean;
	handleMatchupClick: (isNext: boolean) => void;
}

@injectable()
export class LeagueMatchupButtonsController implements ILeagueMatchupButtonsController {
	@observable private _navigate: IInit["navigate"];

	constructor(@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore) {
		makeAutoObservable(this);
	}
	@observable
	get isShowNext() {
		return this._leaguesStore.isShowNext;
	}
	@observable
	get isShowPrevious() {
		return this._leaguesStore.isShowPrevious;
	}

	get currentMatchupIndex() {
		return this._leaguesStore.matchupIndex;
	}

	handleMatchupClick = (isNext: boolean) => {
		const newURL = this._leaguesStore.getMatchupURL(isNext);
		this._navigate!(newURL);
		const newIndex = isNext ? this.currentMatchupIndex + 1 : this.currentMatchupIndex - 1;
		this._leaguesStore.handleMatchupIndexChange(newIndex);
	};

	init({navigate}: IInit) {
		this._navigate = navigate;
	}

	dispose(): void {
		return;
	}
}
