import {retryFailLoad} from "data/utils";
import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";
import {FullWidthLayoutHome} from "views/components/layout/layout.component";

// const Offseason = lazy(retryFailLoad(() => import("views/pages/offseason/offseason.page")));
const Login = lazy(retryFailLoad(() => import("views/pages/login/login.page")));

export const SecretGateRoutes: React.FC = () => (
	<Routes>
		<Route element={<FullWidthLayoutHome />}>
			<Route path="*" element={<Login />} />
		</Route>
	</Routes>
);

export default SecretGateRoutes;
