import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {type IChecksums, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type IPlayersStore} from "data/stores/players/players.store";
import {ViewController} from "data/types/structure";
import {RoundStatus} from "data/enums";
import {type ILeaguesStore} from "data/stores/leagues/leagues.store";

/**
 * Constant for determine update frequency.
 */
const LIVE_SCORING_FETCH_TIMING = 1000 * 60;

type IChecksumAction = Record<keyof IChecksums, () => Promise<unknown>>;

interface IProps {
	roundId?: number;
	leagueId?: number;
}

export interface ILiveScoreController extends ViewController<IProps> {
	subscribeLiveScoring: (location?: string) => void;
	unsubscribeLiveScoring: () => void;
}

@injectable()
export class LiveScoreController implements ILiveScoreController {
	@observable protected _interval?: ReturnType<typeof setInterval>;
	@observable protected _liveScoreDisposer?: ReturnType<typeof reaction>;
	@observable protected _changesDisposer?: ReturnType<typeof reaction>;
	@observable protected _isSubscribed: boolean = false;
	@observable protected _roundId?: number;
	@observable protected _leagueId?: number;

	constructor(
		@inject(Bindings.ChecksumStore) private _checksumStore: IChecksumStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.LeaguesStore) private _leagueStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	/**
	 * Provide object of files you want to update
	 * for example: rounds
	 */
	private get actions(): IChecksumAction {
		const id = this._roundId;

		if (!id) return {};

		if (this._leagueId) {
			const leagueId = this._leagueId;
			return {
				players: () => this._playersStore.fetchPlayers(),
				rounds: () => this._roundsStore.fetchRounds(),
				league: () => this._leagueStore.fetchMatchups(this._roundId || 1, leagueId),
			};
		}

		return {
			players: () => this._playersStore.fetchPlayers(),
			rounds: () => this._roundsStore.fetchRounds(),
		};
	}

	@computed private get changes() {
		return Object.keys(this._checksumStore.changedChecksums);
	}

	@computed private get round() {
		if (!this._roundId) return;
		return this._roundsStore.roundsById[this._roundId];
	}

	@computed private get isLive() {
		return this.round?.status === RoundStatus.Playing;
	}

	@action
	public subscribeLiveScoring() {
		if (this._isSubscribed) return;

		this._isSubscribed = true;
		void this._checksumStore.fetchChecksums();

		this._interval = setInterval(() => {
			void this._checksumStore.fetchChecksums();
		}, LIVE_SCORING_FETCH_TIMING);
	}

	/**
	 * Stop checking changes
	 * called on dispose
	 * or you can call it when you want to stop listen checksums, for example on the end of the game match/round/etc.
	 */
	@action
	public unsubscribeLiveScoring() {
		this._isSubscribed = false;

		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	/**
	 * Check changed checksums and call actions
	 */
	private callActions = () => {
		this.changes.forEach((key) => {
			const action = this.actions[key];
			if (key === "players" && this._leagueId) {
				void this.actions["league"]();
				void action();
			}
			if (action && typeof action === "function") {
				void action();
			}
		});
	};

	dispose(): void {
		this._liveScoreDisposer?.();
		this._changesDisposer?.();
		this.unsubscribeLiveScoring();
	}

	@action onChange({roundId}: IProps) {
		this._roundId = roundId ? Number(roundId) : undefined;
	}

	init({roundId, leagueId}: IProps): void {
		if (!roundId) return;
		this._roundId = Number(roundId);
		if (leagueId) {
			this._leagueId = leagueId;
		}

		this._liveScoreDisposer = reaction(
			() => this.isLive,
			() => {
				this.unsubscribeLiveScoring();

				if (this.isLive) {
					this.subscribeLiveScoring();
				}
			},
			{fireImmediately: true}
		);

		this._changesDisposer = reaction(() => this.changes, this.callActions, {
			fireImmediately: true,
		});
	}
}
