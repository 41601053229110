import styled from "@emotion/styled";
import React, {useEffect} from "react";
import {useMediaQuery} from "@mui/material";
const AdRectangle = styled.div`
	background-color: var(--aflwBKGPurple);
	width: 320px;
	height: 100px;
	margin: 50px auto;
	position: relative;
`;

const AdRectangleBig = styled.div`
	width: 970px;
	height: 250px;
	margin: 40px auto;
	position: relative;
`;

const AdSquareBig = styled.div`
	width: 422px;
	min-height: 250px;
	margin: 0 auto;
	position: relative;

	@media (max-width: 1100px) {
		display: none;
	}
`;

const AdSquare = styled.div<{
	isHelpPage?: boolean;
}>`
	width: 300px;
	height: 250px;
	background-color: #f7f6f2;
	margin: 0 auto;
	position: relative;

	@media (max-width: 1100px) {
		width: 320px;
		height: 50px;
	}
`;

const AdLeaderboard = styled.div`
	width: 728px;
	height: 90px;
	background-color: #f7f6f2;
	margin: 0 auto;
	position: relative;
`;

export const Advert: React.FC<{$id: string; index: number; isHelpPage?: boolean}> = ({
	$id,
	index,
	isHelpPage,
}) => {
	const isMobile = useMediaQuery("(max-width: 640px)");
	const AREA = "AFLWFantasyHome";
	useEffect(() => {
		const targeting = isMobile ? "mobile" : "desktop";
		const create_ads = [$id];

		if ($id === "fg-lb") {
			create_ads.push(`${$id}-mobile`);
		}

		setTimeout(() => {
			window._telstra = window._telstra || {};
			window._telstra.after = window._telstra.after || [];
			window._telstra.after.push((lib) => {
				lib.publisher("aflw");
				lib.area = AREA;
				lib.targeting("device", targeting);
				lib.createAds(create_ads);
			});
		}, 2000);
	}, [$id, isMobile]);

	switch ($id) {
		case "fg-bb":
			return <AdRectangleBig id={$id} />;
		case "fg-bb-btf":
			return <AdRectangleBig id={$id} />;
		case "fg-m-bb":
			return <AdRectangle id={$id} />;
		case "fg-dmpu":
			return <AdSquareBig id={$id} />;
		case "fg-mpu":
			return (
				<AdSquare id={$id} isHelpPage={isHelpPage}>
					<span className="tmframe" ad-index={index} title="320x50"></span>
				</AdSquare>
			);
		case "fg-lb":
			return (
				<AdLeaderboard id={$id}>
					<span className="tmframe" ad-index={index} title="728x90"></span>
				</AdLeaderboard>
			);
		default:
			return null;
	}
};
