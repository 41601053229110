import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {Genders} from "data/types/global";

export interface IBaseAFLIdParams {
	code: string;
	codeVerifier: string;
	redirectUrl: string;
}

export interface IRegisterAFLIdPayload extends IBaseAFLIdParams {
	fantasyTeamName: string;
	country?: string;
	state?: string;
	gender: Genders | string;
	birthday: string;
	sponsorOptIn: boolean;
	// identityToken: string;
	avatar?: string;
	supportedSquadId?: string;
	terms: boolean;
}

export interface IRecoverUserPayload {
	fantasyTeamName?: string;
	sponsorOptIn: boolean;
	terms: boolean;
}

type TAFLIdResponse = IApiResponse<{user: IUser; sessionId: string}>;
type TLoginResponse = IApiResponse<{user: IUser; identityToken: string; sessionId: string}>;

export interface ILoginPayload {
	email: string;
	password: string;
}

export interface IAuthApiProvider {
	loginAFLID: (params: IBaseAFLIdParams) => Promise<AxiosResponse<TLoginResponse>>;
	registerAFLID: (params: IRegisterAFLIdPayload) => Promise<AxiosResponse<TAFLIdResponse>>;
	login: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	recoverUser: (params: IRecoverUserPayload) => Promise<AxiosResponse<TLoginResponse>>;
	logout: () => Promise<AxiosResponse>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	loginAFLID = (params: IBaseAFLIdParams) =>
		this._http.post<TLoginResponse>(`afl-id/login`, params);
	registerAFLID = (params: IRegisterAFLIdPayload) =>
		this._http.post<TAFLIdResponse>(`afl-id/register`, params);

	login = (params: ILoginPayload) => this._http.post<TLoginResponse>(`auth/login`, params);
	recoverUser = (params: IRecoverUserPayload) =>
		this._http.post<TLoginResponse>("user/returning-user", params);
	logout = () => this._http.post(`auth/logout`);
}
