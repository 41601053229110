import React, {Fragment} from "react";
import {ModalError} from "views/components/modals/modal_error.component";
import {ModalLeagueJoinedComponent} from "views/components/modals/modal_league_joined.component";
import {ModalLeagueLeave} from "views/components/modals/modal_league_leave.component";
import {ModalLeagueRemove} from "views/components/modals/modal_league_remove.component";
import {ModalLeagueRemoveUser} from "views/components/modals/modal_league_remove_user.component";
import {ModalPlayer} from "./modal_player_component";
import {ModalErrorUserTeam} from "./modal_error_user_team.component";

export const Modals: React.FC = () => (
	<Fragment>
		<ModalError />
		<ModalLeagueRemoveUser />
		<ModalLeagueJoinedComponent />
		<ModalLeagueLeave />
		<ModalLeagueRemove />
		<ModalPlayer />
		<ModalErrorUserTeam />
	</Fragment>
);
