import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {
	IExistingTrade,
	IRankData,
	IShowMyTeamResponse,
	ITeamApiProvider,
} from "data/providers/api/team.api.provider";
import {
	AFLW_TRADES_PER_ROUND,
	BENCH_MAX_POSITION,
	DEF_MAX_POSITION,
	FWD_MAX_POSITION,
	MID_MAX_POSITION,
	PLAYERS_IN_TEAM,
	RUC_MAX_POSITION,
	SALARY_CAP,
} from "data/constants";
import {
	MatchStatus,
	ModalType,
	PlayerPosition,
	RequestState,
	RoundStatus,
	UTILITY,
} from "data/enums";
import {IDictionary, type IPlayer, type IPlayersStore} from "data/stores/players/players.store";
import {
	getPlayerDefaultPosition,
	getPreviousRoundId,
	handleNullValue,
	isAllTrue,
	isAnyTrue,
	isErrorStatusError,
} from "data/utils/helpers";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {chain, get, indexOf, isEmpty, isNull, isUndefined, keyBy} from "lodash";
import {type ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {trackSentryErrors} from "data/utils";
import {AxiosError, AxiosResponse} from "axios";
import {IApiResponse} from "data/services/http";
import {IPlayerExtended} from "views/components/team/lineup/team_lineup.controller";
import {type IModalsStore} from "data/stores/modals/modals.store";

export interface ILineup {
	[PlayerPosition.DEF]: number[];
	[PlayerPosition.MID]: number[];
	[PlayerPosition.RUC]: number[];
	[PlayerPosition.FWD]: number[];
}

export interface ISubstitutions {
	[roundId: number]: number[];
}

interface IFunctionType {
	boolValue: boolean;
	idOne: number | ISwapPayloadValue;
	idTwo: number | ISwapPayloadValue;
}
const ifBooleanReturnFirst = ({boolValue, idOne, idTwo}: IFunctionType) => {
	return boolValue ? idOne : idTwo;
};

const MAX_DEFENDERS = 6;
const MAX_MIDS = 6;
const MAX_RUCKS = 2;
const MAX_FWDS = 6;

export const POSITION_ARR = [
	PlayerPosition.DEF,
	PlayerPosition.MID,
	PlayerPosition.RUC,
	PlayerPosition.FWD,
];

const MAX_POS_OBJ = {
	[PlayerPosition.DEF]: MAX_DEFENDERS,
	[PlayerPosition.MID]: MAX_MIDS,
	[PlayerPosition.RUC]: MAX_RUCKS,
	[PlayerPosition.FWD]: MAX_FWDS,
};

const LINEUP_SIZE = PLAYERS_IN_TEAM;

export interface ITeam {
	id: number;
	userId: number;
	name: string;
	lineup: ILineup;
	bench: ILineup;
	emergency: number[];
	utilityId: number | null;
	captainId: number | null;
	viceCaptainId: number | null;
	value: number;
	salaryCap: number;
	points: number;
	scoreflow: Record<number, number>;
	startRoundId: number;
	isComplete: boolean;
	substitutions: ISubstitutions;
	avatarVersion?: number;
	supportedSquadId?: number;
}

export interface IPastTeam {
	lineup: ILineup;
	bench: ILineup;
	emergency: number[];
	utilityId: number | null;
	captain: number | null;
	viceCaptain: number | null;
	value: number;
	salaryCap: number;
	teamId: number;
	teamName: string;
	userId: number;
	avatarVersion: number;
	substitutions: number[];
	startRoundId: number;
	supportedTeamId: number;
}

export interface ITeamStore {
	get hasUnsavedChanges(): boolean;
	get captainId(): number | null;
	get viceCaptainId(): number | null;
	get utilityId(): number | null;
	get positionsArray(): PlayerPosition[];
	get teamName(): string;
	get teamPlayerIds(): number[];
	get team(): ITeam;
	get activeSwap(): IActiveSwap;
	get teamError(): string | null;
	get emergency(): number[] | null;
	get remainingSalary(): number;
	get captainOrViceDouble(): number | null;
	get isAutoFillSuccess(): boolean;
	get isInitialTeamLoad(): boolean;
	get isTradeLoading(): boolean;
	get isTeamStartRoundComplete(): boolean;
	get extendedPlayersById(): IDictionary<IPlayerExtended>;
	get isTradeMode(): boolean;
	get tradesRemaining(): number;
	get trades(): ITrade[];
	get isTradeSuccess(): boolean;
	get isRollbackAvailable(): boolean;
	get isPositionFixed(): boolean;
	get fieldStat(): string;
	get isAutosavePending(): boolean;
	get teamRankData(): IRankData | null;
	get isTeamLoaded(): boolean;
	get isTradeOutDisabled(): boolean;
	get isTradeInDisabled(): boolean;
	get pastRoundCaptain(): number;
	setAutoFillSuccess: (value: boolean) => void;
	clearTeamError: () => void;
	setTeamLoading: () => void;
	requestTeam(): Promise<void>;
	getPastTeam(roundId: number): void;
	getUserPastTeam(roundId: number, userId: number): void;
	rollbackTeam(): void;
	saveTeam: () => void;
	resetSubsitutionToDefault: () => void;
	isTeamFullfilled(): boolean;
	addPlayerToTeam: (playerID: number, position: PlayerPosition, isBench?: boolean) => void;
	removePlayerFromTeam(playerID: number, position: PlayerPosition): void;
	clearTeam: () => void;
	getTeamSize(team: number[]): number;
	setCaptain(playerID: number | null): void;
	setEmergency(playerID: number): void;
	setViceCaptain(playerID: number | null): void;
	canAddPlayerToTeam(player: IPlayer): boolean;
	autoFillTeam: () => void;
	setAddIndex(index: number, isBench: boolean): void;
	reverseTradeToBE: (tradeIds: number[]) => void;
	getPlayerPositionInTeam(playerId: number): {
		isInTeam: boolean;
		position: PlayerPosition;
		isBench: boolean;
	};
	getValidEmptyPositionInTeam(positions: PlayerPosition[]): PlayerPosition | false;
	swapPlayer: ({
		playerId,
		index,
		position,
		isBench,
		isTradeSwap,
	}: {
		playerId: number;
		index: number;
		position: PlayerPosition | UTILITY.UTIL;
		isBench: boolean;
		isTradeSwap?: boolean;
	}) => void;
	addRemoveTeam(strId: string, methodName: "removePlayerFromTeam" | "addPlayerToTeam"): void;
	setTradeMode(isTradeMode: boolean): void;
	addPlayerInTrade(playerId: number): void;
	addPlayerOutTrade(playerId: number): void;
	removePlayerInTrade(playerId: number): void;
	removePlayerOutTrade(playerId: number): void;
	fetchTrades: () => void;
	makeTrades: () => void;
	clearTrade: (index: number) => void;
	restoreTradesToDefault: () => void;
	clearTradeSuccess: () => void;
	setPositionFixed: (value: boolean) => void;
	setStatOption: (val: string) => void;
	getTeamRank: (roundId?: number) => void;
}

const populateDefaultPosition = (position: number) => Array.from({length: position}, () => 0);
const DEFAULT_LINEUP = {
	lineup: {
		DEF: populateDefaultPosition(DEF_MAX_POSITION),
		FWD: populateDefaultPosition(FWD_MAX_POSITION),
		MID: populateDefaultPosition(MID_MAX_POSITION),
		RUC: populateDefaultPosition(RUC_MAX_POSITION),
	},
	bench: {
		DEF: populateDefaultPosition(BENCH_MAX_POSITION),
		FWD: populateDefaultPosition(BENCH_MAX_POSITION),
		MID: populateDefaultPosition(BENCH_MAX_POSITION),
		RUC: populateDefaultPosition(BENCH_MAX_POSITION),
	},
};

const getBenchOrLineup = (benchOrLineup: boolean) => {
	return benchOrLineup ? "bench" : "lineup";
};
export const DEFAULT_TEAM = {
	id: 0,
	userId: 0,
	name: "",
	...DEFAULT_LINEUP,
	utilityId: null,
	emergency: [],
	captainId: null,
	viceCaptainId: null,
	value: 0,
	salaryCap: SALARY_CAP,
	points: 0,
	scoreflow: [],
	startRoundId: 1,
	isComplete: false,
	substitutions: {},
};

export interface IActiveSwap {
	initialSwap: {
		position: PlayerPosition | UTILITY.UTIL | null;
		index: number;
		isBench: boolean;
		playerId: number;
	};
	toSwap: {
		position: PlayerPosition | UTILITY.UTIL | null;
		index: number;
		isBench: boolean;
		playerId: number;
	};
}

interface ISwapPayloadValue {
	position: PlayerPosition | UTILITY.UTIL | null;
	index: number;
	isBench: boolean;
	playerId: number;
}

export interface ITrade {
	in: number;
	out: number;
	id: number;
	fromBE?: boolean;
	isLocked?: boolean;
	swapIds?: number[];
}

export const DEFAULT_SINGLE_TRADE = {
	in: 0,
	out: 0,
};

const mapDefaultTrade = (id: number) => {
	return {
		id,
		in: 0,
		out: 0,
		fromBE: false,
	};
};

const DEFAULT_TRADE_IDS = [1, 2, 3];

const DEFAULT_TRADES = DEFAULT_TRADE_IDS.map((id) => {
	return {
		id,
		...DEFAULT_SINGLE_TRADE,
	};
});
const DEFAULT_SWAP = {
	initialSwap: {
		position: null,
		index: -1,
		isBench: false,
		playerId: 0,
	},
	toSwap: {
		position: null,
		index: -1,
		isBench: false,
		playerId: 0,
	},
};

@injectable()
export class TeamStore implements ITeamStore {
	@observable private _requestTeamState = RequestState.IDLE;
	@observable private _isTradeLoading = RequestState.IDLE;
	@observable private _team: ITeam = DEFAULT_TEAM;
	@observable private _hasUnsavedChanges = false;
	@observable private _captainId: number | null = DEFAULT_TEAM.captainId;
	@observable private _viceCaptainId: number | null = DEFAULT_TEAM.viceCaptainId;
	@observable private _utilityId: number | null = DEFAULT_TEAM.utilityId;
	@observable private _emergency: number[] | null = DEFAULT_TEAM.emergency;
	@observable private _activeSwap: IActiveSwap = DEFAULT_SWAP;
	@observable private _error: string | null = null;
	@observable private _autoFillSuccess: boolean = false;
	@observable private _addIndex: number | null = null;
	@observable private _isAddIndexBench: boolean = false;
	@observable private _initialTeamLoad: boolean = false;
	@observable private _isTradeModeEnabled: boolean = false;
	@observable private _trades: ITrade[] = DEFAULT_TRADES;
	@observable private _tradesRemaining: number = 3;
	@observable private _isLoading: boolean = false;
	@observable private _isTradeSuccess: boolean = false;
	@observable private _mismatchPlayerIds: {in: number; out: number}[] = [];
	@observable private _isRollbackAvailable: boolean = false;
	@observable private _isPositionFixed: boolean = false;
	@observable private _activeFieldStat: string = "avgPoints";
	@observable private _isAutoSavePending: boolean = false;
	@observable private _teamRankData: IRankData | null = null;
	@observable private _pastRoundCaptain: number = 0;
	@observable private _isCaptainForReplace = false;
	constructor(
		@inject(Bindings.TeamApiProvider) private _teamApi: ITeamApiProvider,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	get isAutoFillSuccess() {
		return this._autoFillSuccess;
	}

	get teamRankData() {
		return this._teamRankData;
	}

	get pastRoundCaptain() {
		return this._pastRoundCaptain;
	}

	get fieldStat() {
		return this._activeFieldStat;
	}

	get isPositionFixed() {
		return this._isPositionFixed;
	}

	get isRollbackAvailable() {
		return this._isRollbackAvailable;
	}

	get isAddIndexBench() {
		return this._isAddIndexBench;
	}

	get hasUnsavedChanges() {
		return this._hasUnsavedChanges;
	}

	get captainId() {
		return this._captainId;
	}

	get viceCaptainId() {
		return this._viceCaptainId;
	}

	get utilityId() {
		return this._utilityId || 0;
	}

	get teamName() {
		return this._team.name;
	}

	get emergency() {
		return this._emergency || [];
	}

	get activeSwap() {
		return this._activeSwap;
	}
	get isTeamLoading() {
		return this._isLoading;
	}

	get isTradeLoading() {
		return this._isTradeLoading === RequestState.PENDING;
	}

	get isTeamLoaded() {
		return [RequestState.SUCCESS, RequestState.ERROR].includes(this._requestTeamState);
	}
	@action
	setTeamLoading = () => {
		this._initialTeamLoad = false;
	};

	get addIndex() {
		return this._addIndex;
	}

	get isInitialTeamLoad() {
		return this._initialTeamLoad;
	}

	get isTeamValidToBeSaved() {
		return this.isTeamFullfilled() && !this.isTeamStartRoundComplete;
	}

	get extendedPlayersById() {
		const teamIds = this.teamPlayerIds;

		const players = this._playersStore.getExtendedPlayers();
		const playersArr = chain(players)
			.map((player) => {
				return {
					...player,
					isInTeam: teamIds.includes(player.id),
					displayPrice: this._playersStore.getPlayerDisplayPrice(String(player.cost)),
					canAddToTeam: this.canAddPlayerToTeam(player),
					fixture: this._playersStore.setFixture(player),
				};
			})
			.value();
		return keyBy(playersArr, "id");
	}

	get captainOrViceDouble() {
		let shouldDouble = this._captainId;
		const selectedRound = this._roundsStore.selectedRound;
		const captain = this._playersStore.getPlayerById(this._captainId || 0);
		const viceCaptain = this._playersStore.getPlayerById(this._viceCaptainId || 0);
		// Captain is DNP if his score is undefined
		const captainSquad = get(captain, "squadId", 0);
		const selectedRoundId = get(selectedRound, "id", 1);
		const isCaptainGameFinished =
			this._roundsStore.getSquadMatchStatus(captainSquad, selectedRoundId) ===
			MatchStatus.Complete;
		const isCaptainDNP =
			get(captain, ["stats", "scores", selectedRound?.id || 0]) === undefined &&
			isCaptainGameFinished;
		const isViceDNP =
			get(viceCaptain, ["stats", "scores", selectedRound?.id || 0]) === undefined;
		if (
			isAnyTrue([
				isAllTrue([
					selectedRound?.status !== RoundStatus.Scheduled,
					isCaptainDNP,
					!isViceDNP,
				]),
				isNull(this._captainId),
				isUndefined(this._captainId),
				this._captainId === 0,
			])
		) {
			shouldDouble = this._viceCaptainId;
		}

		return shouldDouble;
	}
	@computed
	get teamPlayerIds() {
		const {DEF, MID, FWD, RUC} = this._team.lineup;
		const {DEF: benchDEF, MID: benchMID, RUC: benchRUC, FWD: benchFWD} = this._team.bench;
		return [
			...DEF,
			...MID,
			...FWD,
			...RUC,
			...benchDEF,
			...benchMID,
			...benchFWD,
			...benchRUC,
			this.utilityId,
		];
	}

	get teamPlayerIdsLineup() {
		const {DEF, MID, FWD, RUC} = this._team.lineup;
		return [...DEF, ...MID, ...FWD, ...RUC];
	}

	get positionsArray(): PlayerPosition[] {
		return [PlayerPosition.DEF, PlayerPosition.MID, PlayerPosition.RUC, PlayerPosition.FWD];
	}

	get teamError() {
		return this._error;
	}

	get team() {
		return this._team;
	}

	get positionMismatchValue() {
		// HAVENT ACCOUNTED FOR PRICE DIFFERENCE IN PARTIAL 'TRADE' AS THE OUTGOING PLAYER STILL IN LINEUP UNTIL POSITIONS MATCH
		const mismatchPlayerIds = this._mismatchPlayerIds;
		const playersById = this._playersStore.playersById;
		return mismatchPlayerIds.reduce((totalValue, mismatchObj) => {
			const inCost = playersById[mismatchObj.in].cost;
			const outCost = playersById[mismatchObj.out].cost;
			const netCost = outCost - inCost;
			return (totalValue += netCost);
		}, 0);
	}

	get isAutosavePending() {
		return this._isAutoSavePending;
	}
	get teamValue() {
		const playersInTeam = this.teamPlayerIds;
		const playersById = this._playersStore.playersById;
		// account for trades WHERE (player trade out without player in -> subtract out player from value)
		let tradeValues = this._trades.reduce((value, currentTrade) => {
			if (!currentTrade.in && currentTrade.out) {
				const playerOutCost = playersById[currentTrade.out].cost;
				return (value += playerOutCost);
			}
			if (currentTrade.in && !currentTrade.out) {
				const playerInCost = playersById[currentTrade.in].cost;
				return (value -= playerInCost);
			}
			return value;
		}, 0);

		if (this.positionMismatchValue) {
			tradeValues += this.positionMismatchValue;
		}

		const teamPlayersTotalValue = playersInTeam.reduce((value, currentPlayer) => {
			const player = playersById[currentPlayer];
			let playerCost = 0;
			if (player) {
				playerCost = player.cost;
			}
			return (value += playerCost);
		}, 0);
		return teamPlayersTotalValue - tradeValues;
	}

	get remainingSalary() {
		const nonZeroPlayers = this.teamPlayerIds.filter((id) => id !== 0);
		if (!this.teamValue) {
			return this.team.salaryCap;
		}
		if (
			nonZeroPlayers.length < PLAYERS_IN_TEAM ||
			(nonZeroPlayers.length === PLAYERS_IN_TEAM && this.teamValue)
		) {
			return this.team.salaryCap - this.teamValue;
		}
		return this.team.salaryCap - this.teamValue;
	}

	get isTeamStartRoundComplete() {
		const teamStartRoundId = this.team.startRoundId;
		const startRound = this._roundsStore.roundsById[teamStartRoundId];
		if (!startRound) {
			return false;
		}
		return startRound?.status === RoundStatus.Complete;
	}

	getCanGetPastTeam(isCurrentRoundStarted: boolean, isCurrentRoundOverOne: boolean) {
		return !isCurrentRoundStarted && isCurrentRoundOverOne && this.isTeamStartRoundComplete;
	}

	checkForErrors() {
		const team = this.team;
		if (!team) throw Error("Team is not found");
		// if (!this._captainId) {
		// 	throw Error("You must select a captain via the 'C' icon");
		// }
		// if (!this._viceCaptainId) throw Error("You must select a vice captain via the 'VC' icon");
		// if (!this._utilityId) throw Error("You must select a utility on your bench");
		// if (!this._emergency) throw Error("You must select at least one emergency");
	}

	// TEAM UPDATE LOGIC
	@action async saveTeam() {
		const team = this.team;
		try {
			this.checkForErrors();
		} catch (error: unknown) {
			runInAction(() => {
				const strError = String(error);
				this._error = strError;
			});
			return;
		}
		try {
			const response = await this._teamApi.saveTeam({
				lineup: team.lineup,
				bench: team.bench,
				captainId: this._captainId as number,
				viceCaptainId: this._viceCaptainId as number,
				utilityId: this._utilityId as number,
				emergency: this._emergency as number[],
			});
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}

			if (!response.data.errors.length) {
				this.onSuccessReceiveTeam(response.data.success.team);
			}
			this.setAutoFillSuccess(true);
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
			if (team.isComplete) {
				await this.requestTeam();
			}
		}
	}
	@action
	async addRemoveTeam(strId: string, methodName: "removePlayerFromTeam" | "addPlayerToTeam") {
		const id = Number(strId);

		if (this.isCaptain(id)) {
			this._isCaptainForReplace = true;
		}

		const player = this._playersStore.playersById[id];
		if (!player) return;

		const isAdd = methodName.includes("add");
		const positionForUse = this.getPositionForUse(id, isAdd);
		if (!positionForUse) {
			this._utilityId = parseInt(strId);
			await this.autoSaveTeam();
			return;
		}
		if (isAdd) {
			await this.addPlayerToTeam(id, positionForUse);
		} else {
			this.removePlayerFromTeam(id, positionForUse);
		}
	}

	isCaptain = (id: number) => {
		return Number(this._captainId) === id;
	};

	getPositionForUse(id: number, isAdd: boolean): PlayerPosition | false {
		const player = this._playersStore.playersById[id];
		const playerPos = get(player, "position", [PlayerPosition.FWD]);
		if (isAdd) {
			return this.getValidEmptyPositionInTeam(playerPos);
		} else {
			const positionInTeam = this.getPlayerPositionInTeam(id);
			return positionInTeam.position;
		}
	}

	@action async autoSaveTeam() {
		this._isAutoSavePending = true;
		if (this.isTeamValidToBeSaved) {
			await this.saveTeam();
		}
		this._isAutoSavePending = false;
	}

	@action async autoFillTeam() {
		const team = this.team;
		const captainId = this._captainId || 0;
		const viceCaptainId = this._viceCaptainId || 0;
		const utilityId = this._utilityId || 0;
		const emergency = this._emergency || [];
		const payload = {
			lineup: team.lineup,
			bench: team.bench,
			captainId,
			viceCaptainId,
			utilityId,
			emergency,
		};
		try {
			const response = await this._teamApi.autoFillTeam(payload);
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			this.onSuccessReceiveTeam(response.data.success.team);
			this.setAutoFillSuccess(true);
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action async setCaptain(playerID: number) {
		const isCaptainLocked = this.checkCanPlayerBeActioned(this._captainId as number);
		const isPlayerLocked = this.checkCanPlayerBeActioned(playerID);
		if (isCaptainLocked || isPlayerLocked) {
			return;
		}
		const isViceCaptain = this._viceCaptainId === playerID;
		if (isViceCaptain) {
			this._viceCaptainId = this._captainId;
		}
		this._captainId = playerID;
		if (this.isTeamStartRoundComplete) {
			try {
				const {data} = await this._teamApi.changeCaptain({
					type: "captain",
					newId: playerID,
				});
				if (data.errors[0]) {
					const error = data.errors[0].message;
					throw new Error(error);
				}
				this._isRollbackAvailable = data.success.rollbackAvailable;
			} catch (error) {
				const err = error as AxiosError<IApiResponse>;
				this.onError(err);
				return;
			}
		}
		await this.autoSaveTeam();

		this._hasUnsavedChanges = true;
	}

	@action setAddIndex(index: number, isBench: boolean) {
		this._addIndex = index;
		this._isAddIndexBench = isBench;
	}

	@action
	setStatOption = (val: string) => {
		runInAction(() => {
			this._activeFieldStat = val;
		});
	};

	@action async setViceCaptain(playerID: number) {
		const isViceCaptainLocked = this.checkCanPlayerBeActioned(this._viceCaptainId as number);
		const isPlayerLocked = this.checkCanPlayerBeActioned(playerID);
		if (isViceCaptainLocked || isPlayerLocked) {
			return;
		}
		const isCaptain = this._captainId === playerID;
		const currentID = this._viceCaptainId;
		if (isCaptain) {
			this._captainId = this._viceCaptainId;
		}
		this._viceCaptainId = playerID;
		if (this.isTeamStartRoundComplete) {
			try {
				const {data} = await this._teamApi.changeCaptain({
					type: "viceCaptain",
					newId: playerID,
				});
				if (data.errors[0]) {
					const error = data.errors[0].message;
					throw new Error(error);
				}
				this._isRollbackAvailable = data.success.rollbackAvailable;
			} catch (error) {
				this._viceCaptainId = currentID;
				const err = error as AxiosError<IApiResponse>;
				this.onError(err);
				return;
			}
		}
		await this.autoSaveTeam();

		this._hasUnsavedChanges = true;
	}
	@action setAutoFillSuccess(value: boolean) {
		runInAction(() => {
			this._autoFillSuccess = value;
		});
	}

	@action setEmergency(playerID: number) {
		this.checkCanPlayerBeActioned(playerID);

		const existingEmg = this._emergency || [];
		let newEmg = [...existingEmg, playerID];
		if (existingEmg.includes(playerID)) {
			newEmg = newEmg.filter((id) => id !== playerID);
		}
		this._emergency = newEmg;
		this._hasUnsavedChanges = true;
	}

	@action async requestTeam() {
		try {
			this._requestTeamState = RequestState.PENDING;
			/**commented out to prevent captain empty when clicking cancel trade. 
			** please indicate usage why it should be cleared?
			//this._captainId = null;
			**/
			const response = await this._teamApi.getTeam();
			const currentRound = this._roundsStore.currentRound;
			const isCurrentRoundStarted = currentRound?.status !== RoundStatus.Scheduled;
			const isCurrentRoundOverOne = get(currentRound, "id", 1) > 1;
			const currentRoundId = get(currentRound, "id", 1);
			const players = this._playersStore.playersById;
			const teamStartRoundId = get(response.data.success.team, "startRoundId", 1);
			runInAction(() => {
				this._team.startRoundId = teamStartRoundId;
			});
			// if (!isCurrentRoundStarted && isCurrentRoundOverOne && ) {
			if (this.getCanGetPastTeam(isCurrentRoundStarted, isCurrentRoundOverOne)) {
				const previousRoundId = getPreviousRoundId(currentRoundId);
				const pastTeamResponse = await this._teamApi.getPastTeam({
					roundId: previousRoundId,
				});
				const pastCaptain = pastTeamResponse.data.success.teamHistory.captain;
				const pastVice = pastTeamResponse.data.success.teamHistory.viceCaptain;
				const didCaptainPlay = get(
					players,
					`${pastCaptain as number}.stats.scores[${previousRoundId}]`
				);
				const leaderId = didCaptainPlay ? pastCaptain : pastVice;
				this._pastRoundCaptain = Number(handleNullValue(leaderId, true));
			}
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			if (response.data.success.rollbackAvailable) {
				this._isRollbackAvailable = true;
			}
			this._requestTeamState = RequestState.SUCCESS;
			this.onSuccessReceiveTeam(response.data.success.team);

			this._initialTeamLoad = true;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
			this._initialTeamLoad = true;
		}
	}

	@action async getPastTeam(roundId: number) {
		try {
			this._initialTeamLoad = false;
			const response = await this._teamApi.getPastTeam({roundId});
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			this.onSuccessReceivePastTeam(response.data.success.teamHistory);
			this._initialTeamLoad = true;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
			this._initialTeamLoad = true;
		}
	}
	@action
	clearPastTeam() {
		runInAction(() => {
			this._team = {
				...this._team,
				...DEFAULT_LINEUP,
				value: 0,
			};
			this._emergency = [];
			this._utilityId = null;
			this._viceCaptainId = 0;
			this._captainId = 0;
		});
	}

	@action async getUserPastTeam(roundId: number, userId: number) {
		try {
			this.clearPastTeam();
			this._initialTeamLoad = false;
			const response = await this._teamApi.getUserPastTeam({roundId, userId});
			await this.getTeamRank(roundId, response.data.success.teamHistory.teamId);
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			this.onSuccessReceivePastTeam(response.data.success.teamHistory, true);
			this._initialTeamLoad = true;
			this._requestTeamState = RequestState.SUCCESS;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onErrorUserTeam(err);
			this._initialTeamLoad = true;
		}
	}

	@action async getTeamRank(roundId?: number, payloadTeamId?: number) {
		try {
			const teamId = payloadTeamId;

			const response = await this._teamApi.getUserRanking({roundId, teamId});
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			this._teamRankData = response.data.success;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action async rollbackTeam() {
		try {
			this._initialTeamLoad = false;
			const response = await this._teamApi.rollbackTeam();
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			if (response.data.success) {
				this._isRollbackAvailable = false;
			}
			await this.fetchTrades();
			this.onSuccessReceiveTeam(response.data.success.team);
			this._isTradeModeEnabled = false;
			this._initialTeamLoad = true;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
			this._initialTeamLoad = true;
		}
	}

	@action private onSuccessReceiveTeam = (team: ITeam) => {
		runInAction(() => {
			this._hasUnsavedChanges = false;
			this._team = team;
			this._captainId = team.captainId;
			this._viceCaptainId = team.viceCaptainId;
			this._utilityId = team.utilityId;
			this._emergency = team.emergency;
			this._team.captainId = team.captainId;
			this._team.viceCaptainId = team.viceCaptainId;
			this._team.startRoundId = team.startRoundId;
		});
	};

	@action private onSuccessReceivePastTeam = (team: IPastTeam, isOtherUser?: boolean) => {
		runInAction(() => {
			this._hasUnsavedChanges = false;
			const roundKey = this._roundsStore.selectedRound?.id || 1;
			if (isOtherUser) {
				this._team = {
					...this._team,
					captainId: team.captain,
					viceCaptainId: team.viceCaptain,
					isComplete: true,
					name: team.teamName,
					...team,
					userId: team.userId,
					avatarVersion: team.avatarVersion,
					supportedSquadId: team.supportedTeamId,
					substitutions: {
						[roundKey]: team.substitutions,
					},
				};
			} else {
				this._team = {
					...this._team,
					captainId: team.captain,
					viceCaptainId: team.viceCaptain,
					isComplete: true,
					...team,
					startRoundId: team.startRoundId || 1,
					substitutions: {
						[roundKey]: team.substitutions,
					},
				};
			}

			this._captainId = team.captain;
			this._viceCaptainId = team.viceCaptain;
			this._utilityId = team.utilityId;
			this._emergency = team.emergency;
		});
	};

	potentiallyChangePositionFilter(position: PlayerPosition) {
		const nonZeroArray = (arr: number[]) => arr.filter((num) => num !== 0);
		const isPositionFull =
			nonZeroArray(this.team.lineup[position]).length +
				nonZeroArray(this.team.bench[position]).length ===
			MAX_POS_OBJ[position];
		if (!isPositionFull) {
			return;
		}
		const nextUnfilledPosition = POSITION_ARR.find(
			(thisPosition) =>
				nonZeroArray(this.team.lineup[thisPosition]).length +
					nonZeroArray(this.team.bench[thisPosition]).length <
				MAX_POS_OBJ[thisPosition]
		);
		if (nextUnfilledPosition) {
			this._teamBuilderStore.forcePositionFilterChange(nextUnfilledPosition);
		}
		this._teamBuilderStore.forcePositionFilterChange(null);
	}

	getHighestValueNonLeader() {
		const playersById = this.extendedPlayersById;
		const playerIdsSorted = this.teamPlayerIdsLineup.sort((playerA, playerB) => {
			const costA = get(playersById[playerA], "cost");
			const costB = get(playersById[playerB], "cost");
			return costA > costB ? -1 : 1;
		});
		return playerIdsSorted.find((player) => player !== this._viceCaptainId) || 0;
	}
	@action
	checkAddPlayerForMissingCaptain() {
		const captainToUse = this.getHighestValueNonLeader();

		if (
			(this._team.captainId === null || this._captainId === null) &&
			!this.isAddIndexBench &&
			this.team.isComplete
		) {
			runInAction(() => {
				this._team.captainId = captainToUse;
				this._captainId = captainToUse;
			});
		}
	}
	/**
	 *
	 * refactored to reduce complexity
	 * commented out for future refference in case for bugs
	 */
	// @action async addPlayerToTeam(playerID: number, position: PlayerPosition, isBench?: boolean) {
	// 	this.checkCanPlayerBeActioned(playerID);
	// 	const team = this.team;
	// 	const emptySpot = this.addIndex || team.lineup[position].indexOf(0);
	// 	if(this._isCaptainForReplace){
	// 		this._team.captainId = playerID;
	// 		this._captainId = playerID;
	// 	}else{
	// 	this.checkAddPlayerForMissingCaptain();
	// 	}
	// 	// UTILITY INDEX = NUM PLAYERS IN TEAM
	// 	if (this.addIndex === PLAYERS_IN_TEAM) {
	// 		runInAction(() => {
	// 			this._utilityId = playerID;
	// 			this._team.utilityId = playerID;
	// 			this._addIndex = null;
	// 			this._hasUnsavedChanges = true;
	// 		});
	// 		await this.autoSaveTeam();
	// 		return;
	// 	}

	// 	if (this.isAddIndexBench) {
	// 		team.bench[position][0] = playerID;
	// 		runInAction(() => {
	// 			this._addIndex = null;
	// 			this._isAddIndexBench = false;
	// 			this._team = team;
	// 			this._hasUnsavedChanges = true;
	// 		});
	// 		this.potentiallyChangePositionFilter(position);

	// 		await this.autoSaveTeam();
	// 		return;
	// 	}
	// 	if (~emptySpot) {
	// 		team.lineup[position][emptySpot] = playerID;
	// 	} else {
	// 		const isBenchEmpty = team.bench[position][0] === 0;
	// 		const benchPlayerId = isBenchEmpty ? playerID : team.bench[position][0];
	// 		team.bench[position][0] = benchPlayerId;
	// 		const isUtilityEmpty = isEmpty(this._utilityId);
	// 		const utilityId = isAllTrue([!isBenchEmpty, isUtilityEmpty])
	// 			? playerID
	// 			: this._utilityId;
	// 		this._utilityId = utilityId;
	// 	}
	// 	runInAction(() => {
	// 		this._team = team;
	// 		this._hasUnsavedChanges = true;
	// 		this._addIndex = null;
	// 	});
	// 	this.potentiallyChangePositionFilter(position);
	// 	await this.autoSaveTeam();
	// }

	@action async addPlayerToTeam(playerID: number, position: PlayerPosition, isBench?: boolean) {
		this.checkCanPlayerBeActioned(playerID);
		const team = this.team;
		const emptySpot = this.addIndex || team.lineup[position].indexOf(0);

		if (this._isCaptainForReplace) {
			this._team.captainId = playerID;
			this._captainId = playerID;
			this._isCaptainForReplace = false;
		} else {
			this.checkAddPlayerForMissingCaptain();
		}

		if (this.addIndex === PLAYERS_IN_TEAM) {
			this.assignUtilityPlayer(playerID);
			await this.autoSaveTeam();
			return;
		}

		if (this.isAddIndexBench) {
			this.assignBenchPlayer(team, position, playerID);
			await this.autoSaveTeam();
			return;
		}

		if (~emptySpot) {
			team.lineup[position][emptySpot] = playerID;
		} else {
			this.assignToBenchOrUtility(team, position, playerID);
		}

		runInAction(() => {
			this._team = team;
			this._hasUnsavedChanges = true;
			this._addIndex = null;
		});

		this.potentiallyChangePositionFilter(position);
		await this.autoSaveTeam();
	}

	private assignUtilityPlayer(playerID: number) {
		runInAction(() => {
			this._utilityId = playerID;
			this._team.utilityId = playerID;
			this._addIndex = null;
			this._hasUnsavedChanges = true;
		});
	}

	private assignBenchPlayer(team: ITeam, position: PlayerPosition, playerID: number) {
		team.bench[position][0] = playerID;
		runInAction(() => {
			this._addIndex = null;
			this._isAddIndexBench = false;
			this._team = team;
			this._hasUnsavedChanges = true;
		});
		this.potentiallyChangePositionFilter(position);
	}

	private assignToBenchOrUtility(team: ITeam, position: PlayerPosition, playerID: number) {
		const isBenchEmpty = team.bench[position][0] === 0;
		const benchPlayerId = isBenchEmpty ? playerID : team.bench[position][0];
		team.bench[position][0] = benchPlayerId;

		const isUtilityEmpty = isEmpty(this._utilityId);
		const utilityId = !isBenchEmpty && isUtilityEmpty ? playerID : this._utilityId;
		this._utilityId = utilityId;
	}

	getPlayerPositionInTeam(playerId: number) {
		const team = this.team;
		const {lineup, bench} = team;
		const positionInLineup = POSITION_ARR.find((positionKey) => {
			return lineup[positionKey].includes(playerId);
		});
		const positionInBench = POSITION_ARR.find((positionKey) => {
			return bench[positionKey].includes(playerId);
		});
		const player = this._playersStore.getPlayerById(playerId);
		const playerDefaultPosition = player?.position[0] as PlayerPosition;
		if (isAllTrue([!positionInLineup, !positionInBench])) {
			return {isInTeam: false, position: playerDefaultPosition, isBench: false};
		}

		if (!positionInLineup && positionInBench) {
			return {isInTeam: true, position: positionInBench, isBench: true};
		}
		if (positionInLineup && !positionInBench) {
			return {
				isInTeam: true,
				position: positionInLineup,
				isBench: false,
			};
		}
		const positionHandled = positionInLineup || playerDefaultPosition;
		return {
			isInTeam: true,
			position: positionHandled,
			isBench: Boolean(positionInBench),
		};
	}
	/*
		This function checks if the first and second positions provided 
		in the positions array are empty in the lineup or bench objects of the team.
		It returns the first empty position if found, 
		or false if no empty position is available.
	*/

	getValidEmptyPositionInTeam(positions: PlayerPosition[]) {
		const {lineup, bench} = this.team;
		const isDPP = positions.length === 2;
		const firstPosLineupEmpty = lineup[positions[0]].includes(0);
		const firstPosBenchEmpty = bench[positions[0]].includes(0);
		if (firstPosBenchEmpty || firstPosLineupEmpty) {
			return positions[0];
		}
		if (!isDPP) {
			return false;
		}
		const secondPosLineupEmpty = lineup[positions[1]].includes(0);
		const secondPosBenchEmpty = bench[positions[1]].includes(0);
		if (secondPosBenchEmpty || secondPosLineupEmpty) {
			return positions[1];
		}
		return false;
	}

	@action removePlayerFromTeam(playerID: number, position: PlayerPosition): void {
		this.checkCanPlayerBeActioned(playerID);
		const removePosition = this.getPlayerPositionInTeam(playerID).position;
		const team = this.team;

		if (!team) return;
		const teamLineupPositionWithPlayerRemoved = team.lineup[removePosition].map((id) =>
			id === playerID ? 0 : id
		);
		const newLineup = {
			...team.lineup,
			[position]: teamLineupPositionWithPlayerRemoved,
		};
		const teamBenchPositionWithPlayerRemoved = team.bench[position].map((id) =>
			id === playerID ? 0 : id
		);
		const newBench = {
			...team.bench,
			[position]: teamBenchPositionWithPlayerRemoved,
		};
		this._hasUnsavedChanges = true;

		if (this._captainId === playerID) {
			this._captainId = null;
		}
		if (this._viceCaptainId === playerID) {
			this._viceCaptainId = null;
		}
		const utilityHandled = playerID === this._utilityId ? 0 : this._utilityId;
		this._team.bench = newBench;
		this._team.lineup = newLineup;
		this._utilityId = utilityHandled;
	}

	@action clearTeam() {
		const team = this.team;

		if (!team) return;
		// if after round one and team exists then we dont allow
		if (this.isTeamStartRoundComplete) {
			return;
		}

		this._team = {
			...this._team,
			...DEFAULT_LINEUP,
			value: 0,
		};
		this._emergency = [];
		this._utilityId = null;
		this._viceCaptainId = 0;
		this._captainId = 0;
		this._hasUnsavedChanges = true;
	}

	handlePosition = (playerId: number, position: PlayerPosition | UTILITY.UTIL) => {
		const player = this._playersStore.playersById[playerId];
		const playerPosition = getPlayerDefaultPosition(player) || (position as PlayerPosition);
		return position === UTILITY.UTIL ? playerPosition : position;
	};

	getCaptainFromBoolean = (condition: boolean, playerIdOne: number, playerIdTwo: number) => {
		return condition ? playerIdTwo : playerIdOne;
	};

	getPositionOfPlayerInLineup = (playerId: number) => {
		const lineup = this.team.lineup;
		const bench = this.team.bench;
		const utility = this.utilityId;

		let playerPosition = "";

		POSITION_ARR.forEach((position) => {
			const isInLineup = lineup[position].includes(playerId);
			const isInBench = bench[position].includes(playerId);
			if (isInLineup || isInBench) {
				playerPosition = position;
			}
		});
		if (utility === playerId) {
			playerPosition = UTILITY.UTIL;
		}
		return playerPosition;
	};
	@action
	resetSubsitutionToDefault = () => {
		runInAction(() => {
			this._activeSwap = DEFAULT_SWAP;
		});
	};
	// CHECKS NEED FOR SWAPPING CAPTAIN STATUS IF SUB IS ON BENCH
	leaderEmergencyCheck = (
		initialSwap: number,
		newSwap: number,
		positionOne: PlayerPosition,
		positionTwo: PlayerPosition
	) => {
		let captain = this._captainId;
		let viceCaptain = this._viceCaptainId;
		let emergency = this.emergency;
		const utility = this._utilityId;
		const {bench} = this._team;
		const positionOneHandled = this.handlePosition(initialSwap, positionOne);
		const positionTwoHandled = this.handlePosition(newSwap, positionTwo) as
			| PlayerPosition
			| UTILITY.UTIL;
		const isInitialCaptain = initialSwap === captain;
		const isNewSwapCaptain = newSwap === captain;
		const isInitialVice = initialSwap === viceCaptain;
		const isNewSwapVice = newSwap === viceCaptain;
		const isInitialEmergency = emergency.includes(initialSwap);
		const isNewSwapEmergency = emergency.includes(newSwap);
		const isInitialBench =
			utility === initialSwap || bench[positionOneHandled].includes(initialSwap);
		const isNewSwapBench =
			positionTwoHandled === UTILITY.UTIL || bench[positionTwoHandled].includes(newSwap);
		if (
			isAnyTrue([
				isAllTrue([isInitialCaptain, !isInitialBench, isNewSwapBench]),
				isAllTrue([isNewSwapCaptain, !isNewSwapBench, isInitialBench]),
			])
		) {
			const whicheverWasntCaptain = this.getCaptainFromBoolean(
				isInitialCaptain,
				initialSwap,
				newSwap
			);
			captain = whicheverWasntCaptain;
		}
		if (
			isAnyTrue([
				isAllTrue([isInitialVice, !isInitialBench, isNewSwapBench]),
				isAllTrue([isNewSwapVice, !isNewSwapBench, isInitialBench]),
			])
		) {
			const whicheverWasntVice = this.getCaptainFromBoolean(
				isInitialVice,
				initialSwap,
				newSwap
			);
			viceCaptain = whicheverWasntVice;
		}
		if (isAllTrue([Boolean(isInitialEmergency), isInitialBench, !isNewSwapBench])) {
			emergency = emergency.filter((id) => id !== initialSwap);
		}
		if (isAllTrue([Boolean(isNewSwapEmergency), isNewSwapBench, !isInitialBench])) {
			emergency = emergency.filter((id) => id !== newSwap);
		}

		return {captain, viceCaptain, emergency};
	};

	utilitySwapCheck = async (initialSwap: ISwapPayloadValue, newToSwap: ISwapPayloadValue) => {
		const team = this._team;
		let hasUtilityChanged = false;
		if (
			isAnyTrue([newToSwap.position === UTILITY.UTIL, initialSwap.position === UTILITY.UTIL])
		) {
			const isInitUtility = initialSwap.position === UTILITY.UTIL;
			const newUtilityId = ifBooleanReturnFirst({
				boolValue: isInitUtility,
				idOne: newToSwap.playerId,
				idTwo: initialSwap.playerId,
			}) as number;
			const newFieldId = ifBooleanReturnFirst({
				boolValue: isInitUtility,
				idOne: initialSwap.playerId,
				idTwo: newToSwap.playerId,
			}) as number;
			const fieldPlayerData = ifBooleanReturnFirst({
				boolValue: isInitUtility,
				idOne: newToSwap,
				idTwo: initialSwap,
			}) as ISwapPayloadValue;
			const fieldPlayerPosStr = getBenchOrLineup(fieldPlayerData.isBench);
			const filteredAccessor = fieldPlayerData.position as PlayerPosition;

			if (this.isTeamStartRoundComplete) {
				try {
					const response = await this._teamApi.swapPlayers({
						swapsPairs: [[newToSwap.playerId, initialSwap.playerId]],
					});
					if (response.data.errors[0]) {
						const error = response.data.errors[0].message;
						throw new Error(error);
					}
					this._isRollbackAvailable = response.data.success.rollbackAvailable;
					this.onSuccessReceiveTeam(response.data.success.team);
					runInAction(() => {
						this._hasUnsavedChanges = false;
						this._activeSwap = DEFAULT_SWAP;
					});
				} catch (err) {
					const error = err as AxiosError<IApiResponse>;
					runInAction(() => {
						this._activeSwap = DEFAULT_SWAP;
					});
					this._modalsStore.showModal(ModalType.ERROR, {
						message: error.response?.data.errors[0].message || "",
					});
				}
			}
			// UTILITY SWAP CHANGES PLAYER IN TEAM TO ORIGINAL UTILITY
			team[fieldPlayerPosStr][filteredAccessor][fieldPlayerData.index] = newFieldId;

			runInAction(() => {
				team.utilityId = newUtilityId;
				this._utilityId = newUtilityId;
				this._team = team;
				this._activeSwap = DEFAULT_SWAP;
				this._hasUnsavedChanges = true;
			});
			hasUtilityChanged = true;
		}
		return hasUtilityChanged;
	};

	checkResponseForError = (response: AxiosResponse<IShowMyTeamResponse>) => {
		if (response.data.errors[0]) {
			const error = response.data.errors[0].message;
			throw new Error(error);
		}
	};
	// TRACKS SWAP PLAYER IN DPP TRADE
	@action
	addSwapToTrade = (playerId: number) => {
		const currentTrades = this._trades;
		// swap trade must be one with trade out and no trade in
		// trade in is empty player that can be swapped around
		const swapTradeIndex = currentTrades.findIndex((trade) => trade.out && !trade.in);
		if (swapTradeIndex !== -1) {
			const currentTrade = currentTrades[swapTradeIndex];
			const currentSwapIds = currentTrade.swapIds || [];
			currentTrades[swapTradeIndex] = {
				...currentTrade,
				swapIds: [...currentSwapIds, playerId],
			};
			runInAction(() => {
				this._trades = currentTrades;
			});
		}
	};
	// SUBSTITUTION ON FIELD
	@action swapPlayer = async ({
		playerId,
		index,
		position,
		isBench,
		isTradeSwap,
	}: {
		playerId: number;
		index: number;
		position: PlayerPosition | UTILITY.UTIL;
		isBench: boolean;
		isTradeSwap?: boolean;
	}) => {
		const initialTeamLineup = this._team.lineup;
		const initialBenchLineup = this._team.bench;
		const initialUtility = this._utilityId;
		const initialCaptain = this._captainId;
		const initialVice = this._viceCaptainId;
		this.checkCanPlayerBeActioned(playerId);

		const initialSwap = this._activeSwap.initialSwap;
		const isInitialSwap = initialSwap.playerId === 0;
		// FIRST SUBSTITUTION CLICK IS INITIAL SWAP
		if (isInitialSwap) {
			const updatedInitial = {
				playerId,
				index,
				position,
				isBench,
			};
			runInAction(() => {
				this._activeSwap = {
					...this._activeSwap,
					initialSwap: updatedInitial,
				};
			});
			// UPDATED INITIAL PLAYER, ALLOWS VALIDATION IN TEAM LINEUP TO RUN
			return;
		}
		// If initial swap is filled we assume the data is for second player involved
		const newToSwap = {
			playerId,
			index,
			position,
			isBench,
		};
		if (
			isAnyTrue([initialSwap.position === null, initialSwap.playerId === newToSwap.playerId])
		) {
			this._activeSwap = DEFAULT_SWAP;
			return;
		}
		const {captain, viceCaptain, emergency} = this.leaderEmergencyCheck(
			initialSwap.playerId,
			newToSwap.playerId,
			initialSwap.position as PlayerPosition,
			newToSwap.position as PlayerPosition
		);

		// GET LINEUP OR BENCH ACCESSOR FOR PLAYERS INVOLVED IN SWAP
		const pOnePositionStr = getBenchOrLineup(initialSwap.isBench);
		const pTwoPositionStr = getBenchOrLineup(newToSwap.isBench);
		const team = this._team;
		const hasUtilitySwapped = await this.utilitySwapCheck(initialSwap, newToSwap);
		const isSkipApi = Boolean(isTradeSwap);

		if (isAllTrue([hasUtilitySwapped, !isSkipApi])) {
			await this.autoSaveTeam();
			return;
		}

		// GET POSITIONS OF BOTH PLAYERS AND SWAP POSITION IN TEAM
		const initialFilteredAccessor = initialSwap.position as PlayerPosition;
		const toSwapFilteredAccessor = newToSwap.position as PlayerPosition;
		team[pOnePositionStr][initialFilteredAccessor][initialSwap.index] = newToSwap.playerId;
		team[pTwoPositionStr][toSwapFilteredAccessor][newToSwap.index] = initialSwap.playerId;

		const hasUnsavedChanges = !this.isTeamStartRoundComplete;
		// TEAM SWAP IS READY ENTER THIS BLOCK, IN SEASON WE HIT SWAP API NOT UPDATE TEAM
		if (isAllTrue([!hasUtilitySwapped, this.isTeamStartRoundComplete])) {
			try {
				// optimistic UI
				runInAction(() => {
					this._team = team;
					this._activeSwap = DEFAULT_SWAP;
					this._captainId = captain;
					this._viceCaptainId = viceCaptain;
				});

				if (isSkipApi) {
					// USED TO TRACK DPP TRADE SWAP IDS, NO API NEEDED YET
					this.addSwapToTrade(playerId);
					return;
				}
				const response = await this._teamApi.swapPlayers({
					swapsPairs: [[newToSwap.playerId, initialSwap.playerId]],
				});
				this.checkResponseForError(response);
				runInAction(() => {
					this._isRollbackAvailable = response.data.success.rollbackAvailable;
					this._hasUnsavedChanges = false;
					this._activeSwap = DEFAULT_SWAP;
				});
				this.onSuccessReceiveTeam(response.data.success.team);
				return;
			} catch (err) {
				const error = err as AxiosError<IApiResponse>;
				runInAction(() => {
					this._activeSwap = DEFAULT_SWAP;
					this._team.lineup = initialTeamLineup;
					this._team.bench = initialBenchLineup;
					this._utilityId = initialUtility;
					this._captainId = initialCaptain;
					this._viceCaptainId = initialVice;
				});
				this.onError(error);
				return;
			}
		}

		runInAction(() => {
			this._hasUnsavedChanges = hasUnsavedChanges;
			this._team = team;
			this._activeSwap = DEFAULT_SWAP;
			this._captainId = captain;
			this._viceCaptainId = viceCaptain;
			this._emergency = emergency;
			this._team.emergency = emergency;
		});
		await this.autoSaveTeam();
	};

	@action
	clearTeamError = () => {
		this._error = null;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestTeamState = RequestState.ERROR;
		const message = error.response?.data.errors[0].message || "";
		const isSentryError = isErrorStatusError(error);
		if (!isSentryError) {
			trackSentryErrors(error, {}, `team store API error: ${message}`);
		}
		this._modalsStore.showModal(ModalType.ERROR, {
			message,
		});
	};

	@action private onErrorUserTeam = (error: AxiosError<IApiResponse>) => {
		const message = error.response?.data.errors[0].message || "";
		trackSentryErrors(error, {}, `team store API error: ${message}`);
		this._modalsStore.showModal(ModalType.ERROR_USER_TEAM, {
			message,
		});
	};

	isTeamFullfilled(): boolean {
		const nonZeroPlayers = this.teamPlayerIds.filter(
			(id) => id !== 0 && id !== null && id !== undefined
		);
		return this.getTeamSize(nonZeroPlayers) === LINEUP_SIZE;
	}

	getTeamSize(team: number[]) {
		return team.length;
	}

	hasEmptySpot(lineup: ILineup, position: PlayerPosition) {
		return lineup[position].some((id) => !id);
	}

	setPositionFixed = (value: boolean) => {
		this._isPositionFixed = value;
	};

	canAddPlayerToTeam = (player: IPlayer) => {
		const utility = this._utilityId;
		if (!player) {
			return false;
		}

		const {cost} = player;
		// Ticket F2P1-53272 - this.remainingSalary - cost <= 0  is change to this.remainingSalary - cost < 0 to accomodate 0 remaining salary.
		if (this.remainingSalary - cost < 0) {
			return false;
		}
		const isEmptyPosition = this.getValidEmptyPositionInTeam(player.position);
		const isUtilityEmpty = utility === 0 || utility === null;
		return Boolean(isEmptyPosition) || isUtilityEmpty;
	};

	// CHECKS IF MATCH STARTED
	checkCanPlayerBeActioned(playerId: number) {
		const playerSquad = this._playersStore.getPlayerSquad(playerId) || 0;
		return this._roundsStore.getIsSquadMatchStarted(playerSquad);
	}

	// TRADE UPDATE LOGIC
	get trades() {
		return this._trades;
	}

	get isTradeMode() {
		return this._isTradeModeEnabled;
	}

	get tradesRemaining() {
		return this._tradesRemaining;
	}

	get isTradeSuccess() {
		return this._isTradeSuccess;
	}

	get isTradeOutDisabled() {
		const trades = this.trades;

		return trades.filter((trade) => !trade.out).length === 0;
	}

	get isTradeInDisabled() {
		const trades = this.trades;

		return trades.filter((trade) => !trade.in).length === 0;
	}

	@action
	handleTradesResponse = (trades: IExistingTrade[]) => {
		let tradesMapped = DEFAULT_TRADES;

		if (trades.length) {
			tradesMapped = trades.map((trade) => {
				const playerOneSquad = this.extendedPlayersById[trade.playerInId].squadId;
				const playerTwoSquad = this.extendedPlayersById[trade.playerOutId].squadId;
				const isLocked =
					this._roundsStore.getIsSquadMatchStarted(playerOneSquad) ||
					this._roundsStore.getIsSquadMatchStarted(playerTwoSquad);

				return {
					id: trade.id,
					in: trade.playerInId,
					out: trade.playerOutId,
					fromBE: true,
					roundId: trade.roundId,
					playerInId: trade.playerInId,
					playerOutId: trade.playerOutId,
					isLocked,
				};
			});
		}
		// BUILD REMAINING TRADE TEMPLATES BASED ON REMAINING TRADES FOR ROUND
		if (tradesMapped.length < AFLW_TRADES_PER_ROUND && tradesMapped.length > 0) {
			const currentLength = tradesMapped.length;
			const difference = AFLW_TRADES_PER_ROUND - currentLength;
			const firstOneId = tradesMapped[0].id;
			for (let i = 0; i < difference; i++) {
				/*
				make pending trade ids below first available trade
				ensure we arent reversing an existing trade for that round
				*/

				tradesMapped.push(mapDefaultTrade(firstOneId - (i + AFLW_TRADES_PER_ROUND)));
			}
		}
		const tradesRemaining = AFLW_TRADES_PER_ROUND - trades.length;
		runInAction(() => {
			this._trades = tradesMapped;
			this._tradesRemaining = tradesRemaining;
		});
	};

	@action
	fetchTrades = async () => {
		const currentRound = this._roundsStore.currentRound?.id;
		const selectedRound = this._roundsStore.selectedRound?.id;
		const isSelectedCurrent = selectedRound === currentRound;
		let payload = {};
		if (!isSelectedCurrent) {
			payload = {
				roundId: selectedRound,
			};
		}
		try {
			const response = await this._teamApi.getTrades(payload);
			const {trades} = response.data.success;
			this.handleTradesResponse(trades);
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	};

	@action
	makeTrades = async () => {
		this._isTradeLoading = RequestState.PENDING;

		const payload = this._trades.filter((trade) => trade.in && trade.out && !trade.fromBE);
		try {
			const response = await this._teamApi.makeTrade({tradePairs: payload});
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			this.handleTradesResponse(response.data.success.trades);
			this.onSuccessReceiveTeam(response.data.success.team);
			this._isTradeSuccess = true;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
			this._isLoading = false;
			this._isTradeLoading = RequestState.IDLE;
			return;
		}

		this._isTradeLoading = RequestState.IDLE;
	};

	@action checkRemoveMismatch(playerId: number) {
		const mismatchArr = this._mismatchPlayerIds;
		const newMismatchArr = mismatchArr.filter((mismatch) => {
			return ![mismatch.in, mismatch.out].includes(playerId);
		});
		this._mismatchPlayerIds = newMismatchArr;
	}
	@action
	setTradeMode(isTradeMode: boolean) {
		this._isTradeModeEnabled = isTradeMode;
	}

	@action
	addPlayerOutTrade(playerId: number) {
		let assignedTrade = {} as ITrade | undefined;
		const partialTrade = this.trades.find((trade) => trade.in && trade.out === 0);
		if (partialTrade) {
			assignedTrade = partialTrade;
		} else {
			assignedTrade = this._trades.find((trade) => trade.out === 0);
		}

		if (assignedTrade) {
			assignedTrade.out = playerId;
		}
		if (assignedTrade && assignedTrade.in) {
			this.checkTradesForLineupChange(assignedTrade.out, assignedTrade.in, assignedTrade.id);
		}
	}
	@action
	addPlayerInTrade(playerId: number) {
		let assignedTrade = {} as ITrade | undefined;
		const partialTrade = this.trades.find((trade) => trade.out && trade.in === 0);
		if (partialTrade) {
			assignedTrade = partialTrade;
		} else {
			assignedTrade = this._trades.find((trade) => trade.in === 0);
		}
		if (assignedTrade) {
			assignedTrade.in = playerId;
		}
		if (assignedTrade && assignedTrade.out) {
			this.checkTradesForLineupChange(assignedTrade.out, playerId, assignedTrade.id);
		}
	}
	@action
	removePlayerInTrade(playerId: number) {
		const trade = this._trades.find((trade) => trade.in === playerId);
		if (trade && trade.out) {
			this.checkTradesForLineupChange(playerId, trade.out, trade.id);
		}
		if (trade) {
			trade.in = 0;
		}
		this.checkRemoveMismatch(playerId);
	}
	@action
	removePlayerOutTrade(playerId: number) {
		const trade = this._trades.find((trade) => trade.out === playerId);
		if (trade && trade.in) {
			this.checkTradesForLineupChange(trade.in, playerId, trade.id);
		}
		if (trade) {
			trade.out = 0;
		}
		this.checkRemoveMismatch(playerId);
	}

	getPlayerPositionGeneral(playerId: number) {
		const player = this.extendedPlayersById[playerId];
		return getPlayerDefaultPosition(player);
	}
	/* 
	COMPLEX LOGIC EXPLAINED:
	- UI ALLOWS FOR USERS TO MAKE > TWO TRADES AT ONCE VIEWING LINEUP
	- FIRST TRADE LINE COULD BE DEF FOR FWD, SECOND FWD FOR DEF
	- IF TRADE ROW IS OF DIFFERENT POSITIONS, 
		WE HOLD THESE PLAYERS IN MISMATCH ARRAY UNTIL 
		WE HAVE MATCHING IN/OUTGOING POSITIONS
	- ONCE POSITION COUNT OF IN VS OUT IS EQUAL 
		WE REMOVE FROM MISMATCH ARR AND REPLACE IN LINEUP
	- MISMATCH ARRAY HELPS CALCULATE REMAINING SALARY 
		DESPITE OUT PLAYER APPEARING IN LINEUP FOR UI PURPOSES
	
	*/
	// heath, bowers, trade 2
	@action
	// eslint-disable-next-line sonarjs/cognitive-complexity, complexity
	checkTradesForLineupChange(playerInLineup: number, newPlayer: number, tradeId: number) {
		const newPlayerPosition = this.getPlayerPositionGeneral(newPlayer); //mid
		const positionOfPlayerInLineup = this.getPositionOfPlayerInLineup(playerInLineup); // def
		const trades = this.trades;
		const trade = trades.find((t) => t.id === tradeId);
		/*
			-if there is a mismatch here, we need to check if there is already another mismatch
			-if no mismatch exists, we do nothing with lineup 
				but need to update the salary remaining temporarily
			- if there is another mismatch then we run logic 
				to check if we can match positons and use replace lineup
		*/
		if (
			newPlayerPosition !== positionOfPlayerInLineup &&
			positionOfPlayerInLineup !== UTILITY.UTIL
		) {
			let isPositionMatch = false;
			const filteredTrades = trades.filter(
				(trade) => trade.in && trade.out && trade.id !== tradeId && !trade.fromBE
			);
			if (
				isAllTrue([
					trade?.out === newPlayer,
					newPlayerPosition !== positionOfPlayerInLineup,
				])
			) {
				const isOtherExistingMisMatchTrade = trades.find((t) =>
					isAllTrue([
						t.id !== tradeId,
						Boolean(t.in),
						Boolean(t.out),
						this.getPlayerPositionGeneral(t.out) !==
							this.getPlayerPositionGeneral(t.in),
					])
				);
				if (isOtherExistingMisMatchTrade) {
					const doPositionsMatchThisTrade =
						this.getPlayerPositionGeneral(newPlayer) ===
							this.getPlayerPositionGeneral(isOtherExistingMisMatchTrade?.in) &&
						this.getPlayerPositionGeneral(playerInLineup) ===
							this.getPlayerPositionGeneral(isOtherExistingMisMatchTrade?.out);
					if (doPositionsMatchThisTrade) {
						this.replacePlayerInLineup(
							isOtherExistingMisMatchTrade.in,
							isOtherExistingMisMatchTrade.out
						);
						this.replacePlayerInLineup(playerInLineup, newPlayer);
						this.forceSwapOfTwoInTeam(isOtherExistingMisMatchTrade.out, newPlayer);
						this._mismatchPlayerIds.push({
							in: isOtherExistingMisMatchTrade.in,
							out: isOtherExistingMisMatchTrade.out,
						});
						return;
					}
				}
				this.replacePlayerInLineup(playerInLineup, newPlayer);

				return;
			}
			filteredTrades.forEach((trade) => {
				const playerOnePos = this.getPlayerPositionGeneral(trade.in);
				const playerTwoPos = this.getPlayerPositionGeneral(trade.out);
				const isMatch =
					(playerOnePos === positionOfPlayerInLineup &&
						playerTwoPos === newPlayerPosition) ||
					(playerOnePos === newPlayerPosition &&
						playerTwoPos === positionOfPlayerInLineup);
				if (isMatch) {
					isPositionMatch = true;
					this.replacePlayerInLineup(playerInLineup, trade.in);
					this.replacePlayerInLineup(trade.out, newPlayer);
					this._mismatchPlayerIds = [];
				}
			});

			if (!isPositionMatch) {
				this._mismatchPlayerIds.push({in: newPlayer, out: playerInLineup});
			}
			return;
		}
		// write case for already remove
		// remove players from mismatch arr

		this.replacePlayerInLineup(playerInLineup, newPlayer);
	}
	@action
	replacePlayerInLineup(playerInLineup: number, newPlayer: number) {
		const team = this._team;
		const lineup = team.lineup;
		const bench = team.bench;

		POSITION_ARR.forEach((position) => {
			const isInLineup = lineup[position].includes(playerInLineup);
			const isInBench = bench[position].includes(playerInLineup);

			if (isInLineup) {
				const index = indexOf(lineup[position], playerInLineup);
				this._team.lineup[position][index] = newPlayer;
			}
			if (isInBench) {
				const index = indexOf(bench[position], playerInLineup);
				this._team.bench[position][index] = newPlayer;
			}
		});
		if (playerInLineup === this._utilityId) {
			this._utilityId = newPlayer;
			this.team.utilityId = newPlayer;
		}
	}

	isLineupOrBench(playerId: number) {
		const positionInTeam = this.getPositionOfPlayerInLineup(playerId) as PlayerPosition;
		const isInLineup = this._team.lineup[positionInTeam].includes(playerId);
		return isInLineup ? "lineup" : "bench";
	}

	forceSwapOfTwoInTeam(playerA: number, playerB: number) {
		const positionInTeamA = this.getPositionOfPlayerInLineup(playerA) as
			| PlayerPosition
			| UTILITY.UTIL;
		const positionInTeamB = this.getPositionOfPlayerInLineup(playerB) as
			| PlayerPosition
			| UTILITY.UTIL;

		if ([positionInTeamA, positionInTeamB].includes(UTILITY.UTIL)) {
			const utility = positionInTeamA === UTILITY.UTIL ? playerA : playerB;
			const notUtility = positionInTeamA === UTILITY.UTIL ? playerB : playerA;
			const otherPosition =
				utility === playerA
					? (positionInTeamB as PlayerPosition)
					: (positionInTeamA as PlayerPosition);
			this._utilityId = notUtility;
			this.team.utilityId = notUtility;
			const lineupOrBench = this.isLineupOrBench(notUtility);
			const playerIndex = this._team[lineupOrBench][otherPosition].findIndex(
				(pId) => pId === notUtility
			);
			this._team[lineupOrBench][otherPosition][playerIndex] = utility;
			return;
		}
		const aPosition = positionInTeamA as PlayerPosition;
		const bPosition = positionInTeamB as PlayerPosition;

		const aLineupOrBench = this.isLineupOrBench(playerA);
		const aIndex = this._team[aLineupOrBench][aPosition].findIndex((pId) => pId === playerA);
		const bLineupOrBench = this.isLineupOrBench(playerB);
		const bIndex = this._team[bLineupOrBench][bPosition].findIndex((pId) => pId === playerB);
		this._team[aLineupOrBench][aPosition][aIndex] = playerB;
		this._team[bLineupOrBench][bPosition][bIndex] = playerA;
	}

	@action
	reverseTradeToBE = async (tradeIds: number[]) => {
		const currentTradesFromBE = this._trades.filter((trade) => trade.fromBE).length;
		const isAllTrades = currentTradesFromBE === tradeIds.length;
		this._isTradeLoading = RequestState.PENDING;
		try {
			const response = await this._teamApi.reverseTrade({tradeIds});
			if (response.data.errors[0]) {
				const error = response.data.errors[0].message;
				throw new Error(error);
			}
			this.onSuccessReceiveTeam(response.data.success.team);
			tradeIds.forEach((tradeId) => this.clearTrade(tradeId));
			this._tradesRemaining += tradeIds.length;
			if (isAllTrades) {
				this._trades = DEFAULT_TRADES;
			}
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
			this._isTradeLoading = RequestState.IDLE;
			return;
		}

		this._isTradeLoading = RequestState.IDLE;
	};

	@action
	clearTradeSuccess = () => {
		this._isTradeSuccess = false;
	};

	@action
	clearTrade = (id: number) => {
		const trade = this._trades.find((trade) => trade.id === id);
		let index = -1;
		if (trade) {
			index = this._trades.indexOf(trade);
		}
		const playerInLineup = trade?.in;
		const playerOut = trade?.out;
		if (playerInLineup && playerOut) {
			this.replacePlayerInLineup(playerInLineup, playerOut);
		}
		const clearedTrade = {
			id: trade?.id || 1,
			in: 0,
			out: 0,
		};
		runInAction(() => {
			this._trades[index] = clearedTrade;
			if (playerInLineup && playerOut) {
				this._mismatchPlayerIds = this._mismatchPlayerIds.filter((mismatch) => {
					const mismatchArr = [mismatch.in, mismatch.out];
					return (
						!mismatchArr.includes(playerInLineup) || !mismatchArr.includes(playerOut)
					);
				});
			}
		});
	};
	@action
	restoreTradesToDefault = () => {
		runInAction(() => {
			this._trades = DEFAULT_TRADES;
		});
	};
}
