import {RequestState, ModalType} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {useNavigate} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";
import {IInvitingLeague} from "data/types/leagues";
import {AxiosError} from "axios";
import type {IAuthController} from "views/controllers/auth/auth.controller";

interface IInit {
	navigate?: ReturnType<typeof useNavigate>;
}

export interface IInvitingLeaguesListController extends ViewController<IInit> {
	isLoaded: boolean;
	invitingLeagues: IInvitingLeague[];

	get isAuthorized(): boolean;

	joinWhenNotJoined(leagueCode: string): Promise<void>;

	declineInvite(leagueId: number): Promise<void>;
}

@injectable()
export class InvitingLeaguesListController implements IInvitingLeaguesListController {
	@observable private _requestState = RequestState.IDLE;

	private _navigate: IInit["navigate"];

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.AuthController) private _authController: IAuthController
	) {
		makeAutoObservable(this);
	}

	get isLoaded() {
		return [RequestState.SUCCESS, RequestState.ERROR].includes(this._requestState);
	}

	get isAuthorized(): boolean {
		return this._authController.isAuthorized;
	}

	get invitingLeagues() {
		return this._leaguesStore.invitingLeagues;
	}

	async _join(leagueCode: string) {
		try {
			const league = await this._leaguesStore.joinLeague(leagueCode);
			if (league?.id) {
				this._modalsStore.showModal(ModalType.LEAGUE_JOINED, {
					message: league?.name || "",
				});
			}

			await this._leaguesStore.fetchInvitingLeagues();
		} catch (err) {
			trackSentryErrors(err, {}, "leagues to join - join");
			const error = err as AxiosError;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
		}
	}

	async joinWhenNotJoined(leagueCode: string) {
		await this._join(leagueCode);
	}

	async _declineInvite(leagueId: number) {
		try {
			await this._leaguesStore.declineInviteLeague(leagueId);
			// await this._leaguesStore.fetchInvitingLeagues();
		} catch (err) {
			trackSentryErrors(err, {}, "leagues to join - decline");
			const error = err as AxiosError;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: error.message,
				errors: error.response?.data,
			});
		}
	}

	async declineInvite(leagueId: number) {
		await this._declineInvite(leagueId);
	}

	init(params: IInit) {
		this._navigate = params.navigate;

		// this._requestState = RequestState.SUCCESS;
		// try {
		// 	await this._leaguesStore.fetchInvitingLeagues();
		// 	runInAction(() => {
		// 		this._requestState = RequestState.SUCCESS;
		// 	});
		// } catch (err) {
		// 	trackSentryErrors(err, {}, "my leagues init");
		// 	const error = err as AxiosError;
		// 	this._requestState = RequestState.ERROR;
		// 	if (this.isAuthorized) {
		// 		this._modalsStore.showModal(ModalType.ERROR, {
		// 			message: error.message,
		// 			errors: error.response?.data,
		// 		});
		// 	}
		// }
	}

	dispose(): void {
		return;
	}
}
