import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {type ILoginController, LoginController} from "views/pages/login/login.controller";
import {
	type ILoginCallbackController,
	LoginCallbackController,
} from "views/pages/login_callback/login_callback.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {RoundsStore, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {PlayersStore, type IPlayersStore} from "data/stores/players/players.store";
import {SquadsStore, type ISquadsStore} from "data/stores/squads/squads.store";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {type IModalsController, ModalsController} from "views/controllers/modals/modals.controller";

import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secrete_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/components/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	IPlayerPoolController,
	PlayerPoolController,
} from "views/components/team/player_pool/player_pool.controller";
import {ITeamStore, TeamStore} from "data/stores/team/team.store";
import {ITeamApiProvider, TeamApiProvider} from "data/providers/api/team.api.provider";
import {ITeamController, TeamController} from "views/pages/team/team.controller";
import {ITeamBuilderStore, TeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {HeaderController, IHeaderController} from "views/components/header/header.controller";
import {
	type IRankingsApiProvider,
	RankingsApiProvider,
} from "data/providers/api/rankings.api.provider";
import {
	type IRankingsController,
	RankingsController,
} from "views/pages/rankings/rankings.controller";
import {type IRankingsStore, RankingsStore} from "data/stores/rankings/rankings.store";

import {
	type ILeaguesApiProvider,
	LeaguesApiProvider,
} from "data/providers/api/leagues.api.provider";
import {
	type IMyLeaguesController,
	MyLeaguesController,
} from "views/pages/my_leagues/my_leagues.controller";
import {type ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	CreateLeagueController,
	ICreateLeagueController,
} from "views/pages/create_league/create_league.controller";
import {
	IInviteLeagueController,
	InviteLeagueController,
} from "views/components/leagues/league_invite_form/league.invite_form.controller";
import {ILeagueController, LeagueController} from "views/pages/league/league.controller";
import {
	ISettingsLeagueController,
	SettingsLeagueController,
} from "views/pages/league_settings/league_settings.controller";
import {
	ILeagueUsersController,
	LeagueUsersController,
} from "views/components/leagues/league_users/league_users.controller";
import {
	ISettingsLeagueFormController,
	SettingsLeagueFormController,
} from "views/components/leagues/league_settings_form/league_settings_form.controller";
import {
	ILeagueControlsController,
	LeagueControlsController,
} from "views/components/leagues/league_controls/league_controls.controller";
import {
	ILeaguesToJoinController,
	LeaguesToJoinController,
} from "views/pages/leagues_to_join/leagues_to_join.controller";
import {
	ITeamLineupController,
	TeamLineupController,
} from "views/components/team/lineup/team_lineup.controller";
import {
	ITeamControlsController,
	TeamControlsController,
} from "views/components/team/team_controls/team_controls.controller";
import {
	IRoundTimerController,
	RoundTimerController,
} from "views/components/team/round_timer/round_timer.controller";
import {
	ITeamFilledController,
	TeamFilledModalController,
} from "views/components/team/team_filled_modal/team_filled_modal.controller";
import {
	IJoinCreateWidgetController,
	JoinCreateWidgetController,
} from "views/components/leagues/widgets/join_create_widget/join_create_widget.controller";
import {
	IListPlayerController,
	ListPlayerController,
} from "views/components/team/lineup/team_player.controller";
import {
	IInvitingLeaguesListController,
	InvitingLeaguesListController,
} from "views/components/leagues/league_inviting_list/league.inviting_list.controller";

import {
	IRoundSelectorController,
	RoundSelectorController,
} from "views/components/round_selector/round_selector.controller";
import {ITradesController, TradesController} from "views/components/trades/trades.controller";
import {
	FixturesLeagueController,
	IFixturesLeagueController,
} from "views/pages/league_fixtures/league_fixtures.controller";
import {
	ILadderLeagueController,
	LadderLeagueController,
} from "views/pages/league_ladder/league_ladder.controller";
import {
	IStatsCenterController,
	StatsCenterController,
} from "views/pages/stats_centre/stats_centre.controller";
import {
	IMatchupLeagueController,
	MatchupLeagueController,
} from "views/pages/league_matchup/league_matchup.controller";
import {
	ILeagueMatchupButtonsController,
	LeagueMatchupButtonsController,
} from "views/components/leagues/league_matchup_buttons/league_matchup_buttons.controller";
import {IInitiateController, InitiateController} from "views/pages/initiate/initiate.controller";
import {ITradeController, TradeController} from "views/components/trades/trade.controller";
import {
	type IPlayerDataController,
	PlayerDataController,
} from "views/controllers/player_data/player_data.controller";
import {
	type IRecoverUserController,
	RecoverUserController,
} from "views/pages/recover/recover.controller";
import {
	type IRegenerateLeaguesController,
	RegenerateLeaguesController,
} from "views/components/leagues/regenerate_leagues/regenerate_leagues.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.ApiHTTPClientFormData).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
			headers: {"Content-Type": "multipart/form-data"},
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<ITeamApiProvider>(Bindings.TeamApiProvider).to(TeamApiProvider);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<IRankingsApiProvider>(Bindings.RankingsApiProvider).to(RankingsApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();
	bind<ITeamStore>(Bindings.TeamStore).to(TeamStore).inSingletonScope();
	bind<ITeamBuilderStore>(Bindings.TeamBuilderStore).to(TeamBuilderStore).inSingletonScope();
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IRankingsStore>(Bindings.RankingsStore).to(RankingsStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<ILoginController>(Bindings.LoginController).to(LoginController);
	bind<IInitiateController>(Bindings.InitiateController).to(InitiateController);
	bind<ILoginCallbackController>(Bindings.LoginCallbackController).to(LoginCallbackController);
	bind<IModalsController>(Bindings.ModalsController).to(ModalsController);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<IInviteLeagueController>(Bindings.InviteLeagueController).to(InviteLeagueController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILeagueUsersController>(Bindings.LeagueUsersController).to(LeagueUsersController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController).inSingletonScope();
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IPlayerPoolController>(Bindings.PlayerPoolController).to(PlayerPoolController);
	bind<ITeamController>(Bindings.TeamController).to(TeamController);
	bind<IRankingsController>(Bindings.RankingsController)
		.to(RankingsController)
		.inSingletonScope();
	bind<ILeagueController>(Bindings.LeagueController).to(LeagueController);
	bind<ISettingsLeagueFormController>(Bindings.SettingsLeagueFormController).to(
		SettingsLeagueFormController
	);
	bind<ILeaguesToJoinController>(Bindings.LeaguesToJoinController)
		.to(LeaguesToJoinController)
		.inSingletonScope();
	bind<ILeagueControlsController>(Bindings.LeagueControlsController).to(LeagueControlsController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<IFixturesLeagueController>(Bindings.FixturesLeagueController).to(FixturesLeagueController);
	bind<ISettingsLeagueController>(Bindings.SettingsLeagueController).to(SettingsLeagueController);
	bind<ILadderLeagueController>(Bindings.LadderLeagueController).to(LadderLeagueController);
	bind<ITeamLineupController>(Bindings.TeamLineupController).to(TeamLineupController);
	bind<ITeamControlsController>(Bindings.TeamControlsController)
		.to(TeamControlsController)
		.inSingletonScope();
	bind<IRoundTimerController>(Bindings.RoundTimerController).to(RoundTimerController);
	bind<ITeamFilledController>(Bindings.TeamFilledModalController).to(TeamFilledModalController);
	bind<IJoinCreateWidgetController>(Bindings.JoinCreateWidgetController).to(
		JoinCreateWidgetController
	);
	bind<IListPlayerController>(Bindings.ListPlayerController).to(ListPlayerController);
	bind<IInvitingLeaguesListController>(Bindings.InvitingLeaguesListController).to(
		InvitingLeaguesListController
	);

	bind<IRoundSelectorController>(Bindings.RoundSelectorController).to(RoundSelectorController);
	bind<ITradesController>(Bindings.TradesController).to(TradesController).inSingletonScope();
	bind<IStatsCenterController>(Bindings.StatsCenterController)
		.to(StatsCenterController)
		.inSingletonScope();
	bind<IMatchupLeagueController>(Bindings.MatchupLeagueController).to(MatchupLeagueController);
	bind<ILeagueMatchupButtonsController>(Bindings.LeagueMatchupButtonsController).to(
		LeagueMatchupButtonsController
	);
	bind<ITradeController>(Bindings.TradeController).to(TradeController);
	bind<IPlayerDataController>(Bindings.PlayerDataController).to(PlayerDataController);
	bind<IRecoverUserController>(Bindings.RecoverUserController).to(RecoverUserController);
	bind<IRegenerateLeaguesController>(Bindings.RegenerateLeagueController).to(
		RegenerateLeaguesController
	);
});
