import styled from "@emotion/styled";

export const LeagueSidebar = styled.div`
	flex: 0 0 auto;
	display: flex;
	background-color: var(--textWhite);
	border-radius: 10px;
	align-items: center;
	flex-flow: column;
	gap: 20px;
	@media (max-width: 1100px) {
		width: 100%;
		margin: 12px 0 0;
	}
`;

export const LeagueSidebarNoMargin = styled(LeagueSidebar)`
	margin-left: 24px;
	width: 380px;
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	flex-flow: column;
	margin-top: 0;
	gap: 20px;
	@media (max-width: 860px) {
		width: 100%;
		margin: 0;
	}
`;
