import {
	PUBLIC_URL,
	PLAYER_IMAGES_URL,
	REACT_APP_SSO_CLIENT_ID,
	PLAYER_PLACEHOLDER_IMG_URL,
	MEDIA_URL,
	GRAND_FINAL_WEEK_ID,
} from "data/constants";
import {LeagueH2H, PlayerPosition, PlayerStatus, RoundStatus, UTILITY} from "data/enums";
import {IUser} from "data/stores/user/user.store";
import {IPlayer} from "data/stores/players/players.store";
import {IPlayerExtended} from "views/components/team/player_pool/player_pool.controller";
import {get, isEmpty} from "lodash";
import {ILeague, ILeagueUser, IRankingTeamObject} from "data/types/leagues";
import {DateTime} from "luxon";
import {IUserRanking} from "data/types/rankings";
import {IRound} from "data/stores/rounds/rounds.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";

export const isAllTrue = (array: boolean[]) => {
	let allTrue = true;
	array.forEach((value) => {
		if (!value) {
			allTrue = false;
		}
	});
	return allTrue;
};

export const isAnyTrue = (array: boolean[]) => {
	let anyTrue = false;
	array.forEach((value) => {
		if (value) {
			anyTrue = true;
		}
	});
	return anyTrue;
};

export const getPreviousRoundId = (currentRoundId: number) => {
	return currentRoundId - 1;
};

export const getPlayerStatusColors = (status: PlayerStatus) => {
	const defaultColor = "#383A36";
	const colorObj = {
		[PlayerStatus.Playing]: "#20B741",
		[PlayerStatus.Injured]: defaultColor,
		[PlayerStatus.Emergency]: defaultColor,
		[PlayerStatus.NotPlaying]: defaultColor,
		[PlayerStatus.Uncertain]: "#FC8E11",
		[PlayerStatus.UncertainGame2]: "#FC8E11",
	};
	return get(colorObj, status);
};

export const getGenders = [
	{
		value: "female",
		text: "Female",
	},
	{
		value: "male",
		text: "Male",
	},
	{
		value: "non_binary",
		text: "Non Binary",
	},
	{
		value: "other",
		text: "Other",
	},
	{
		value: "not_say",
		text: "Prefer Not to Say",
	},
];

export const getStates = [
	{
		value: "ACT",
		text: "Australian Capital Territory",
	},
	{
		value: "NSW",
		text: "New South Wales",
	},
	{
		value: "NT",
		text: "Northern Territory",
	},
	{
		value: "QLD",
		text: "Queensland",
	},
	{
		value: "SA",
		text: "South Australia",
	},
	{
		value: "TAS",
		text: "Tasmania",
	},
	{
		value: "VIC",
		text: "Victoria",
	},
	{
		value: "WA",
		text: "Western Australia",
	},
];

export const getMonths = [
	{text: "Month", value: ""},
	{text: "Jan", value: "1"},
	{text: "Feb", value: "2"},
	{text: "Mar", value: "3"},
	{text: "Apr", value: "4"},
	{text: "May", value: "5"},
	{text: "Jun", value: "6"},
	{text: "Jul", value: "7"},
	{text: "Aug", value: "8"},
	{text: "Sep", value: "9"},
	{text: "Oct", value: "10"},
	{text: "Nov", value: "11"},
	{text: "Dec", value: "12"},
];

export const getPositionFullName = (position: PlayerPosition | UTILITY.UTIL) => {
	switch (position) {
		case PlayerPosition.DEF:
			return "Defender";
		case PlayerPosition.MID:
			return "Midfielder";
		case PlayerPosition.RUC:
			return "Ruck";
		case PlayerPosition.FWD:
			return "Forward";
		case UTILITY.UTIL:
			return "Utility";
		default:
			return "";
	}
};

export const getPositionFullNameJoined = (posArr: PlayerPosition[]) => {
	return posArr.map((pos) => getPositionFullName(pos)).join("/");
};
export const getAvatar = (
	user:
		| IUser
		| ILeagueUser
		| IUserRanking
		| {userId?: number; id?: number; avatarVersion: number; supportedSquadId: number}
) => {
	const {userId, avatarVersion = 0, id} = user;
	return `${MEDIA_URL}avatars/user/${
		(userId as number) || (id as number)
	}.png?v=${avatarVersion}`;
};

export const getLeagueAvatar = ({avatarVersion = 0, id}: ILeague) => {
	return `${MEDIA_URL}avatars/league/${id}.png?v=${avatarVersion}`;
};

export const formatDateMonth = (n: string) => (Number(n) < 10 ? "0" + n : n);
// COMMENTED OUT AS PER https://geniussports.atlassian.net/browse/F2P1-54552
// const AVAILABLE_HEADSHOT_SQUADS = [7889, 8796, 9408, 7887, 9407, 8097];

export const getPlayerimage = (id: number, size: 100 | 450 | 500, squadId: number) => {
	// if (!AVAILABLE_HEADSHOT_SQUADS.includes(squadId)) {
	// 	return PLAYER_PLACEHOLDER_IMG_URL;
	// }
	return `${PLAYER_IMAGES_URL}${id}_${size}.webp` || PLAYER_PLACEHOLDER_IMG_URL;
};

export const getPlayerName = (player: IPlayer | IPlayerExtended) =>
	`${player.firstName[0]}. ${player.lastName}`;

export const getResponsivePlayerName = (player: IPlayer | IPlayerExtended, mobile?: boolean) => {
	if (mobile) {
		return `${player.firstName[0]}. ${player.lastName}`;
	}
	return `${player.firstName} ${player.lastName}`;
};

export const getIsCaptain = (captainId: number | null, playerId: number | null) =>
	captainId === playerId;

export const getLogoutParams = (id_token: string) => {
	const params = [
		`id_token_hint=${id_token}`,
		`post_logout_redirect_uri=${PUBLIC_URL}`,
		`client_id=${REACT_APP_SSO_CLIENT_ID}`,
	];
	return `?${params.join("&")}`;
};

export const handleNullValue = (val: string | number | null, convertToNumber?: boolean) => {
	if (val === null && convertToNumber) {
		return 0;
	}
	if (val === null) {
		return "-";
	}
	return val;
};

export const sumPlayerScoreValue = (valueArr: number[] | string | null) => {
	if (valueArr === null || !valueArr) {
		return 0;
	}
	if (typeof valueArr === "string") {
		return valueArr;
	}
	return valueArr.reduce((total, current) => {
		return (total += current);
	}, 0);
};

export const handleSortNullValue = (val: string | number | null, convertToNumber?: boolean) => {
	if (val === null && convertToNumber) {
		return -1;
	}
	if (val === null) {
		return "-";
	}
	return val;
};

export const getRoundLabel = (round: IRound) => {
	if (round.id === GRAND_FINAL_WEEK_ID) {
		return `Week ${round.id} (Grand Final)`;
	}
	return round.id === 0 ? "All Weeks" : `Week ${round.id}`;
};

export const getAvatarImage = (
	user:
		| IUser
		| ILeagueUser
		| IUserRanking
		| {userId: number; id?: number; avatarVersion: number; supportedSquadId: number}
		| IRankingTeamObject
		| IUserRanking
) => {
	const getUserAvatarVersion = get(user, "avatarVersion", 0);
	const supportedSquadId = get(user, "supportedSquadId", 0);
	if (getUserAvatarVersion > 0) {
		return getAvatar(user);
	}
	if (supportedSquadId > 0) {
		return `${MEDIA_URL}squads/${supportedSquadId}.png?v=${getUserAvatarVersion}`;
	}
	return "";
};

export const getLeagueAvatarImage = (league: ILeague) =>
	!isEmpty(league) && get(league, "avatarVersion", 0) > 0
		? getLeagueAvatar(league)
		: `${MEDIA_URL}squads/${get(league?.leagueCommissioner, "supportedSquadId", 0)}.png`;

export const getLeagueDefaultLink = (league: ILeague, roundID: number) => {
	const leagueFixture = get(league, "fixture", null);
	const leagueStartingRound = get(league, "startId", 0);
	if (roundID > leagueStartingRound) {
		return `/leagues/${league.id}/ladder`;
	}
	if (leagueFixture !== null) {
		return `/leagues/${league.id}/fixtures`;
	}
	return `/leagues/${league.id}/about`;
};

export const capitalizeLeagueType = (_type: LeagueH2H) => {
	if (_type.toLowerCase() === LeagueH2H.H2H) {
		return "Head-to-Head";
	} else if (_type.toLowerCase() === LeagueH2H.Open) {
		return "Open";
	} else {
		return "";
	}
};

// add in the below if we want to wait until
// date entered before throwing error

// const yyyymmdd = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
// const dobComplete = dobyyyymmdd.match(yyyymmdd);

export const parseDob = (dob: string) => {
	const dobArr = dob.split("-");
	const dobDay = formatDateMonth(dobArr[2]);
	const dobMonth = formatDateMonth(dobArr[1]);
	const dobYear = dobArr[0];
	const dobyyyymmdd = `${dobYear}-${dobMonth}-${dobDay}`;
	const luxonDate = DateTime.fromISO(dobyyyymmdd);
	const yearGTNow = Number(dobYear) >= Number(DateTime.now().toFormat("y"));
	const isValidDate = luxonDate.isValid;
	if (!isValidDate || yearGTNow) {
		return {
			isValidDate,
			birthdate: "",
			errorPlace: "birthday",
			errorMsg: yearGTNow ? "Please enter an earlier year." : "Please enter a valid date",
		};
	} else {
		return {
			isValidDate,
			errorMsg: "",
			birthdate: dobyyyymmdd,
			errorPlace: "",
		};
	}
};

export const nextOppStr = (player: IPlayer) => {
	const matchesScheduled = player.fixture.filter((match) => !match.isComplete);
	if (!player.fixture.length) {
		return "BYE";
	}
	const matchAccessed = matchesScheduled[0];
	if (!matchAccessed) {
		return "";
	}
	return matchAccessed.isPlayerHome
		? matchAccessed.awaySquad?.abbreviation
		: matchAccessed.homeSquad?.abbreviation;
};

export const playerSquadFromFixture = (player: IPlayer) => {
	const matchScheduled = player.fixture.find((match) => !match.isComplete);

	return matchScheduled?.isPlayerHome ? matchScheduled?.homeSquad : matchScheduled?.awaySquad;
};

export const getAge = (dob?: string) => {
	if (!dob) {
		return "Unavailable";
	}
	const now = DateTime.now();
	const date2 = DateTime.fromISO(dob);
	const diff = now.diff(date2, ["years"]);
	const age = diff.toObject().years?.toFixed(0);
	return age === "0" ? "Unavailable" : age;
};

export const getPlayerDefaultPosition = (player: IPlayer) => {
	return get(player, "position[0]");
};

export const getPlayerPositionText = (player: IPlayer) => {
	return get(player, "position").join("/");
};

export const onlyUnique = (value: number, index: number, array: number[]) => {
	return array.indexOf(value) === index;
};
export const fantasyStatsPerPoint = {
	K: {label: "Kick", multiplier: 3},
	H: {label: "Handball", multiplier: 2},
	M: {label: "Mark", multiplier: 3},
	T: {label: "Tackle", multiplier: 4},
	FF: {label: "Free Kick For", multiplier: 1},
	FA: {label: "Free Kick Against", multiplier: -3},
	FD: {label: "", multiplier: 1},
	HO: {label: "Hit-out", multiplier: 1},
	G: {label: "Goal", multiplier: 6},
	B: {label: "Behind", multiplier: 1},
	TOG: {label: "Time on ground", multiplier: 1},
	D: {label: "", multiplier: 1},
	ED: {label: "", multiplier: 1},
	IED: {label: "", multiplier: 1},
	I50: {label: "", multiplier: 1},
	R50: {label: "", multiplier: 1},
	CL: {label: "", multiplier: 1},
	SP: {label: "", multiplier: 1},
	CP: {label: "", multiplier: 1},
	UCP: {label: "", multiplier: 1},
	CM: {label: "", multiplier: 1},
	GA: {label: "", multiplier: 1},
	CS: {label: "", multiplier: 1},
};

interface IFantasyStats {
	label: string;
	value: number;
	fantasyPoints: number;
}
interface IStatsConverted {
	[key: string]: IFantasyStats;
}

/* eslint-disable */
export const getStatsEquivalent = (code: string, val: number) => {
	const obj: IStatsConverted = {};
	switch (code) {
		case "K":
			obj[code] = {label: "Kicks", value: val, fantasyPoints: val * 3};
			break;
		case "H":
			obj[code] = {label: "Handballs", value: val, fantasyPoints: val * 3};
			break;
		case "M":
			obj[code] = {label: "Marks", value: val, fantasyPoints: val * 3};
			break;
		case "T":
			obj[code] = {label: "Tackles", value: val, fantasyPoints: val * 4};
			break;
		case "FF":
			obj[code] = {label: "Free Kick For", value: val, fantasyPoints: val};
			break;
		case "FA":
			obj[code] = {label: "Free Kick Against", value: val, fantasyPoints: val * -3};
			break;
		case "G":
			obj[code] = {label: "Goal", value: val, fantasyPoints: val * 6};
			break;
		case "FD":
			obj[code] = {label: "FREES_DIFF", value: val, fantasyPoints: val};
			break;
		case "HO":
			obj[code] = {label: "Hitouts", value: val, fantasyPoints: val};
			break;
		case "B":
			obj[code] = {label: "Behind", value: val, fantasyPoints: val};
			break;
		case "TOG":
			obj[code] = {label: "Time on ground", value: val, fantasyPoints: val};
			break;
		case "D":
			obj[code] = {label: "Disposals", value: val, fantasyPoints: val};
			break;
		case "ED":
			obj[code] = {label: "Effective Disposals", value: val, fantasyPoints: val};
			break;
		case "IED":
			obj[code] = {label: "Ineffective Disposals", value: val, fantasyPoints: val};
			break;
		case "I50":
			obj[code] = {label: "Inside 50", value: val, fantasyPoints: val};
			break;
		case "R50":
			obj[code] = {label: "Rebounds", value: val, fantasyPoints: val};
			break;
		case "CL":
			obj[code] = {label: "Clearances", value: val, fantasyPoints: val};
			break;
		case "SP":
			obj[code] = {label: "Spoils", value: val, fantasyPoints: val};
			break;
		case "CP":
			obj[code] = {label: "Contested Possessions", value: val, fantasyPoints: val};
			break;
		case "UCP":
			obj[code] = {label: "Uncontested Possessions", value: val, fantasyPoints: val};
			break;
		case "CM":
			obj[code] = {label: "Contested Marks", value: val, fantasyPoints: val};
			break;
		case "GA":
			obj[code] = {label: "Goal Assists", value: val, fantasyPoints: val};
			break;
		case "CS":
			obj[code] = {label: "Clangers", value: val, fantasyPoints: val};
			break;
		default:
			obj[code] = {label: "", value: 0, fantasyPoints: 0};
	}
	return obj;
};

export interface IDynamicObject {
	[key: string]: number;
}

export const getFantasyStatsEquivalent = (stats: IDynamicObject) => {
	const newObject: IStatsConverted = {};
	for (const [key, value] of Object.entries(stats)) {
		const objNew = getStatsEquivalent(key, value);
		Object.assign(newObject, objNew);
	}

	return newObject;
};

export const ordinalize = (i: number) => {
	var j = i % 10,
		k = i % 100;
	if (i > 0) {
		if (j == 1 && k != 11) {
			return i + "st";
		}
		if (j == 2 && k != 12) {
			return i + "nd";
		}
		if (j == 3 && k != 13) {
			return i + "rd";
		}
		return i + "th";
	}
	return "";
};

export const getIsFutureRound = (roundId: number, actualRoundId: number) => {
	return roundId > actualRoundId;
};

export const getIsPlayerPlayingAndStarted = (isMatchStarted: boolean, isStatusPlaying: boolean) => {
	return isMatchStarted && isStatusPlaying;
};

export const getIsCurrentScheduledAndLastComplete = (lastRound?: IRound, currentRound?: IRound) => {
	const isCurrentScheduled = currentRound?.status === RoundStatus.Scheduled;
	const isLastComplete = lastRound?.status === RoundStatus.Complete;
	return isCurrentScheduled && isLastComplete;
};

export const getPlayerPosAvail = (playerPos: PlayerPosition) =>
	[PlayerPosition.DEF, PlayerPosition.FWD, PlayerPosition.RUC, PlayerPosition.MID].filter(
		(pos) => pos !== playerPos
	);

export const isErrorStatusError = (error: AxiosError<IApiResponse>) => {
	const errorCode = Number(error.code);
	const errorMessage = error.message;
	const errorCodes = [400, 401, 403, 422, 500];
	return (
		errorCodes.includes(errorCode) ||
		errorCodes.some((err) => errorMessage.includes(String(err)))
	);
};
